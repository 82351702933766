import { API, Auth } from 'aws-amplify';
import { getDebtScore } from './debtScoreActions';

export const GET_RISK_SCORES = 'GET_RISK_SCORES';
export const GET_RISK_SCORES_FAIL = 'GET_RISK_SCORES_FAIL';
export const GET_RISK_SCORES_SUCCESS = 'GET_RISK_SCORES_SUCCESS';

const getRiskScore = async ({ creditReportId }) => {
  const currentSession = await Auth.currentSession();
  const jwt = currentSession.getIdToken().getJwtToken();

  const riskScoreResponse = await API.post('risk', '/risk-score', {
    headers: {
      Authorization: jwt,
      'Content-Type': 'application/json',
    },
    body: {
      creditReportId,
    },
  });

  return riskScoreResponse;
};

const getBorrowerAndSpouseRiskScores = ({ borrowerCreditReportId, spouseCreditReportId }) => async (dispatch) => {
  dispatch({ type: GET_RISK_SCORES });

  try {
    const borrowerRiskScoreResponse = await getRiskScore({ creditReportId: borrowerCreditReportId });
    const spouseRiskScoreResponse = spouseCreditReportId
      ? await getRiskScore({ creditReportId: spouseCreditReportId })
      : { riskScore: { id: undefined } };

    dispatch(getDebtScore({ borrowerRiskScoreId: borrowerRiskScoreResponse.riskScore.id, spouseRiskScoreId: spouseRiskScoreResponse.riskScore.id }));

    dispatch({
      borrowerRiskScore: borrowerRiskScoreResponse.riskScore,
      spouseRiskScore: spouseRiskScoreResponse.riskScore,
      type: GET_RISK_SCORES_SUCCESS,
    });
  } catch (error) {
    console.log(
      `error getting risk scores for borrowerCreditReportId ${borrowerCreditReportId} and spouseCreditReportId ${spouseCreditReportId}`,
      error,
    );

    dispatch({ type: GET_RISK_SCORES_FAIL });
  }
};

export {
  getBorrowerAndSpouseRiskScores,
};
