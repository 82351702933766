import {
  CANCEL_RENTAL_RELIEF_PLAN_CHANGES,
  CLEAR_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED,
  SAVE_RENTAL_RELIEF_PLAN,
  SAVE_RENTAL_RELIEF_PLAN_FAIL,
  SAVE_RENTAL_RELIEF_PLAN_SUCCESS,
  SET_RENTAL_RELIEF_PLAN,
  SET_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED,
  SET_SHOW_RENTAL_RELIEF_PLAN_FORM,
  UPDATE_RENTAL_RELIEF_PLAN,
} from '../actions/rentalReliefPlanActions';
import { formatAwsDateForApplication } from '../../formatters/dateFormatter';

const initialState = {
  data: {
    dateSigned: undefined,
    plannedStartDate: undefined,
    plannedEndDate: undefined,
    monthlyPlanRent: undefined,
    monthlyBackRentDue: undefined,
    monthlyRentalAssistanceFundsApplied: undefined,
    owner: undefined,
  },
  hasGottenRentalReliefPlan: undefined,
  isEnrolledInARentalReliefPlan: undefined,
  isGettingRentalReliefPlan: undefined,
  isSaving: undefined,
  originalDateSigned: undefined,
  originalPlannedStartDate: undefined,
  originalPlannedEndDate: undefined,
  originalMonthlyPlanRent: undefined,
  originalMonthlyPlanBackRentDue: undefined,
  originalMonthlyRentalAssistanceFundsApplied: undefined,
  saveFailed: undefined,
  saveSuccess: undefined,
  showRentalReliefPlanForm: false,
};

const rentalReliefPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_RENTAL_RELIEF_PLAN_CHANGES: {
      return {
        ...state,
        data: {
          ...state.data,
          dateSigned: state.originalDateSigned,
          plannedStartDate: state.originalPlannedStartDate,
          plannedEndDate: state.originalPlannedEndDate,
          monthlyPlanRent: state.originalMonthlyPlanRent,
          monthlyBackRentDue: state.originalMonthlyPlanBackRentDue,
          monthlyRentalAssistanceFundsApplied: state.originalMonthlyRentalAssistanceFundsApplied,
        },
      };
    }

    case CLEAR_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED:
      return { ...state, saveSuccess: undefined };

    case SAVE_RENTAL_RELIEF_PLAN:
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
      };

    case SAVE_RENTAL_RELIEF_PLAN_FAIL:
      return {
        ...state, isSaving: false, saveFailed: true, saveSuccess: false,
      };

    case SAVE_RENTAL_RELIEF_PLAN_SUCCESS: {
      return {
        ...state,
        data: action.rentalReliefPlan,
        isEnrolledInARentalReliefPlan: true,
        isSaving: false,
        originalDateSigned: action.rentalReliefPlan.dateSigned,
        originalPlannedStartDate: action.rentalReliefPlan.plannedStartDate,
        originalPlannedEndDate: action.rentalReliefPlan.plannedEndDate,
        originalMonthlyPlanRent: action.rentalReliefPlan.monthlyPlanRent,
        originalMonthlyPlanBackRentDue: action.rentalReliefPlan.monthlyBackRentDue,
        originalMonthlyRentalAssistanceFundsApplied: action.rentalReliefPlan.monthlyRentalAssistanceFundsApplied,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SET_RENTAL_RELIEF_PLAN: {
      if (!action.rentalReliefPlan) {
        return { ...state, data: { ...initialState.data }, isEnrolledInARentalReliefPlan: false };
      }

      const dateSigned = formatAwsDateForApplication({ awsDate: action.rentalReliefPlan.dateSigned });
      const plannedStartDate = formatAwsDateForApplication({ awsDate: action.rentalReliefPlan.plannedStartDate });
      const plannedEndDate = formatAwsDateForApplication({ awsDate: action.rentalReliefPlan.plannedEndDate });

      return {
        ...state,
        data: {
          ...action.rentalReliefPlan, dateSigned, plannedStartDate, plannedEndDate,
        },
        isEnrolledInARentalReliefPlan: true,
        originalDateSigned: dateSigned,
        originalPlannedStartDate: plannedStartDate,
        originalPlannedEndDate: plannedEndDate,
        originalMonthlyPlanRent: action.rentalReliefPlan.monthlyPlanRent,
        originalMonthlyBackRentDue: action.rentalReliefPlan.monthlyBackRentDue,
        originalMonthlyRentalAssistanceFundsApplied: action.rentalReliefPlan.monthlyRentalAssistanceFundsApplied,
      };
    }

    case SET_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED: {
      return { ...state, saveSuccess: true };
    }

    case SET_SHOW_RENTAL_RELIEF_PLAN_FORM:
      return { ...state, showRentalReliefPlanForm: action.showRentalReliefPlanForm };

    case UPDATE_RENTAL_RELIEF_PLAN: {
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };
    }

    default:
      return state;
  }
};

export default rentalReliefPlanReducer;
