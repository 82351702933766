import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../components/customer-portal/AlertModal/AlertModal';
import Footer from '../components/common/Footer';
import { Header } from '../components/customer-portal';
import { setShowConnectAccountModal } from '../redux/actions/plaidActions';

const CustomerPortalLayout = ({ children }) => {
  const dispatch = useDispatch();
  const showConnectAccountModal = useSelector((state) => state.plaid.showConnectAccountModal);

  const hideAlertModal = () => {
    dispatch(setShowConnectAccountModal({ showConnectAccountModal: false }));
  };

  return (
    <section className="customer-portal">
      {
        showConnectAccountModal
        && (
          <AlertModal
            title="Bank Account Not Connected"
            callToAction="Connect your account"
            hideAlertModal={hideAlertModal}
          />
        )
      }
      <div>
        <Header />
        {children}
        <Footer />
      </div>
    </section>
  );
};

export default CustomerPortalLayout;
