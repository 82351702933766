import React from 'react';
import step3Graphic from '../../../../assets/icons/landing-pages/expense_graphic.svg';

const Step3 = () => (
  <div>
    <h3 className="pb-4 font-normal">Step 3</h3>
    <h1>
      Verify your income and expenses
    </h1>
    <img className="block m-auto pb-4" src={step3Graphic} alt="Expense Verification" />
    <p>
      Connect your checking account and then review and edit income and expense information.
    </p>
    <div className="w-full">
      <ul className="slider-dots text-center">
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot active-dot">&bull;</li>
        <li className="dot">&bull;</li>
      </ul>
    </div>
  </div>
);

export default Step3;
