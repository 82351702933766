import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpouse } from '../../../redux/actions/spouseActions';
import ZipCodeInput from '../common/AddressFields/ZipCodeInput';

const BorrowerZipCodeInput = ({ errors, onChange }) => {
  const dispatch = useDispatch();
  const spouseAddressIsSameAsBorrowerAddress = useSelector((state) => state.spouse.hasSameAddressAsBorrower);

  const onInputValueChange = (event) => {
    if (spouseAddressIsSameAsBorrowerAddress) {
      dispatch(updateSpouse({ propertyName: event.target.name, value: event.target.value }));
    }

    onChange(event);
  };

  return (
    <ZipCodeInput errors={errors} id="borrower-zip-code-input" loadFrom="borrower" onChange={onInputValueChange} validationName="borrowerZipCode" />
  );
};

export default BorrowerZipCodeInput;
