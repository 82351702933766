import { API, Auth } from 'aws-amplify';
import { formatPhoneNumberForCognito } from '../../formatters/phoneNumberFormatter';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CLEAR_RESET_PASSWORD_ERROR = 'CLEAR_RESET_PASSWORD_ERROR';
export const CLEAR_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED = 'CLEAR_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED';
export const CLEAR_SET_INITIAL_PASSWORD_STATUS = 'CLEAR_SET_INITIAL_PASSWORD_STATUS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const GET_USER = 'GET_USER';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_FAIL = 'LIST_USERS_FAIL';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SAVE_UPDATED_USER_ATTRIBUTES = 'SAVE_UPDATED_USER_ATTRIBUTES';
export const SAVE_UPDATED_USER_ATTRIBUTES_FAIL = 'SAVE_UPDATED_USER_ATTRIBUTES_FAIL';
export const SAVE_UPDATED_USER_ATTRIBUTES_SUCCESS = 'SAVE_UPDATED_USER_ATTRIBUTES_SUCCESS';
export const SEND_INITIAL_EMAIL = 'SEND_INITIAL_EMAIL';
export const SEND_INITIAL_EMAIL_FAIL = 'SEND_INITIAL_EMAIL_FAIL';
export const SEND_INITIAL_EMAIL_SUCCESS = 'SEND_INITIAL_EMAIL_SUCCESS';
export const SET_INITIAL_PASSWORD = 'SET_INITIAL_PASSWORD';
export const SET_INITIAL_PASSWORD_FAIL = 'SET_INITIAL_PASSWORD_FAIL';
export const SET_INITIAL_PASSWORD_SUCCESS = 'SET_INITIAL_PASSWORD_SUCCESS';
export const SET_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED = 'SET_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED';
export const SET_USER_MUST_CHANGE_PASSWORD = 'SET_USER_MUST_CHANGE_PASSWORD';
export const SET_USER_MUST_CHANGE_PASSWORD_FAIL = 'SET_USER_MUST_CHANGE_PASSWORD_FAIL';
export const SET_USER_MUST_CHANGE_PASSWORD_SUCCESS = 'SET_USER_MUST_CHANGE_PASSWORD_SUCCESS';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const STORE_FIRST_NAME = 'STORE_FIRST_NAME';
export const STORE_LAST_NAME = 'STORE_LAST_NAME';
export const STORE_PHONE_NUMBER = 'STORE_PHONE_NUMBER';
export const STORE_USERNAME = 'STORE_USERNAME';
export const STORE_VERIFICATION_CODE = 'STORE_VERIFICATION_CODE';

// For some operations, this object must be the *exact* object returned by Auth.signIn().
// It cannot be stored in (and loaded from) Redux or session storage or it loses some necessary functions and properties.
// For example, the call to Auth.completeNewPassword() will throw an error saying "user.completeNewPasswordChallenge is not a function."
let cognitoUser;

Auth.configure({ storage: sessionStorage });

const changeEmail = ({ newEmail }) => async (dispatch) => {
  dispatch({ type: CHANGE_EMAIL });

  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      email: newEmail,
    });

    dispatch({ type: CHANGE_EMAIL_SUCCESS });
  } catch (error) {
    console.log('changeEmail error:', error);

    dispatch({ errorCode: error, type: CHANGE_EMAIL_FAIL });
  }
};

const changePassword = ({ oldPassword, newPassword }) => async (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD });

  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.changePassword(user, oldPassword, newPassword);

    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log('changePassword error:', error);

    dispatch({ type: CHANGE_PASSWORD_FAIL, errorCode: error });
  }
};

const clearResetPasswordError = () => ({ type: CLEAR_RESET_PASSWORD_ERROR });

const clearSaveUpdatedUserAttributesSucceeded = () => ({ type: CLEAR_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED });

const clearSetInitialPasswordStatus = () => ({ type: CLEAR_SET_INITIAL_PASSWORD_STATUS });

const createUserSuccess = ({ response }) => {
  const user = { username: response.username };

  return { type: CREATE_USER_SUCCESS, user };
};

const createUserFail = ({ error }) => {
  console.log('createUserFail was called with:', error);

  return { errorCode: error.code, type: CREATE_USER_FAIL };
};

const createUser = () => async (dispatch, getState) => {
  dispatch({ type: CREATE_USER });

  const intToHex = (nr) => nr.toString(16).padStart(2, '0');

  const getRandomString = (bytes) => {
    const randomValues = new Uint8Array(bytes);

    window.crypto.getRandomValues(randomValues);

    return Array.from(randomValues).map(intToHex).join('');
  };

  const generatedPassword = getRandomString(30);

  const state = getState();

  const {
    email,
    firstName,
    lastName,
    phoneNumber,
  } = state.borrower.data;

  try {
    await Auth.signUp({
      username: email,
      password: generatedPassword,
      attributes: {
        'custom:must_change_password': 'true',
        email,
        name: `${firstName} ${lastName}`,
        phone_number: formatPhoneNumberForCognito({ phoneNumber }),
      },
    });

    try {
      const signInResponse = await Auth.signIn({ username: email, password: generatedPassword });

      dispatch(createUserSuccess({ response: signInResponse }));
    } catch (signInError) {
      dispatch(createUserFail({ error: signInError }));
    }
  } catch (signUpError) {
    dispatch(createUserFail({ error: signUpError }));
  }
};

const forgotPassword = ({ username }) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD });

  try {
    await Auth.forgotPassword(username);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, username });
  } catch (error) {
    console.log('forgotPassword error:', error);

    dispatch({ type: FORGOT_PASSWORD_FAIL });
  }
};

const getUserByUsername = ({ userId }) => async (dispatch) => {
  dispatch({ type: GET_USER });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const user = await Auth.currentAuthenticatedUser();

    const response = await API.get('Cognito', '/user', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      queryStringParameters: {
        username: userId || user.username,
      },
    });

    dispatch({ userAttributes: response.UserAttributes, type: GET_USER_SUCCESS });
  } catch (error) {
    console.error('error getting user:', error);

    dispatch({ type: GET_USER_FAIL });
  }
};

const listUsersWithInformation = () => async (dispatch) => {
  dispatch({ type: LIST_USERS });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const groupname = 'CustomerService-us-east-2';

    const response = await API.get('Cognito', '/users', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      queryStringParameters: {
        groupname,
      },
    });

    dispatch({ list: response, type: LIST_USERS_SUCCESS });
  } catch (error) {
    console.error('error getting user:', error);

    dispatch({ type: LIST_USERS_FAIL });
  }
};

const resetPassword = ({ newPassword }) => async (dispatch, getState) => {
  dispatch({ type: RESET_PASSWORD });

  const state = getState();

  const { username } = state.user.data;
  const { verificationCode } = state.user.data;

  try {
    await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);

    dispatch({ type: RESET_PASSWORD_SUCCESS });
  } catch (error) {
    console.log('forgotPasswordSubmit error:', error);

    dispatch({ errorCode: error.code, type: RESET_PASSWORD_FAIL });
  }
};

const saveUpdatedUserAttributes = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_UPDATED_USER_ATTRIBUTES });

  const state = getState();

  const borrower = state.borrower.data;

  try {
    const user = await Auth.currentAuthenticatedUser();

    Auth.updateUserAttributes(user, {
      email: borrower.email,
      name: `${borrower.firstName} ${borrower.lastName}`,
      phone_number: formatPhoneNumberForCognito({ phoneNumber: borrower.phoneNumber }),
    });

    dispatch({ type: SAVE_UPDATED_USER_ATTRIBUTES_SUCCESS });
  } catch (error) {
    console.log('saveUpdatedUserAttributes error:', error);

    dispatch({ type: SAVE_UPDATED_USER_ATTRIBUTES_FAIL });
  }
};

const sendInitialEmail = () => async (dispatch) => {
  dispatch({ type: SEND_INITIAL_EMAIL });

  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.forgotPassword(user.username);

    dispatch({ type: SEND_INITIAL_EMAIL_SUCCESS });
  } catch (error) {
    console.log('initial e-mail/forgot password error:', error);

    dispatch({ type: SEND_INITIAL_EMAIL_FAIL });
  }
};

const setInitialPassword = ({ password }) => async (dispatch, getState) => {
  dispatch({ type: SET_INITIAL_PASSWORD });

  const { firstName, lastName, phoneNumber } = getState().user.data;

  try {
    await Auth.completeNewPassword(
      cognitoUser,
      password,
      {
        name: `${firstName} ${lastName}`,
        phone_number: formatPhoneNumberForCognito({ phoneNumber }),
      },
    );

    dispatch({ type: SET_INITIAL_PASSWORD_SUCCESS });
  } catch (error) {
    console.log('failed to set initial password:', error);

    dispatch({ type: SET_INITIAL_PASSWORD_FAIL });
  }
};

const setSaveUpdatedUserAttributesSucceeded = () => ({ type: SET_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED });

const setUserMustChangePassword = ({ value }) => async (dispatch) => {
  dispatch({ type: SET_USER_MUST_CHANGE_PASSWORD });

  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, { 'custom:must_change_password': value.toString() });

    dispatch({ type: SET_USER_MUST_CHANGE_PASSWORD_SUCCESS });
  } catch (updateUserAttributesError) {
    console.log('failed to update must_change_password!', updateUserAttributesError);

    dispatch({ type: SET_USER_MUST_CHANGE_PASSWORD_FAIL });
  }
};

const signIn = ({ password, username }) => async (dispatch) => {
  dispatch({ type: SIGN_IN });

  try {
    cognitoUser = await Auth.signIn({ username, password });

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch({ mustSetInitialPassword: true, type: SIGN_IN_SUCCESS, user: cognitoUser });

      return;
    }

    dispatch({ type: SIGN_IN_SUCCESS, user: cognitoUser });
  } catch (error) {
    console.log('sign in failed!', error);

    dispatch({ errorCode: error.code, type: SIGN_IN_FAIL });
  }
};

const signOut = () => async (dispatch) => {
  dispatch({ type: SIGN_OUT });

  try {
    await Auth.signOut();

    dispatch({ type: SIGN_OUT_SUCCESS });
  } catch (signOutError) {
    console.log('signOutError:', signOutError);

    dispatch({ type: SIGN_OUT_FAIL });
  } finally {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  }
};

const storeFirstName = ({ firstName }) => ({ firstName, type: STORE_FIRST_NAME });

const storeLastName = ({ lastName }) => ({ lastName, type: STORE_LAST_NAME });

const storePhoneNumber = ({ phoneNumber }) => ({ phoneNumber, type: STORE_PHONE_NUMBER });

const storeUsername = ({ username }) => ({ type: STORE_USERNAME, username });

const storeVerificationCode = ({ verificationCode }) => ({ type: STORE_VERIFICATION_CODE, verificationCode });

export {
  changeEmail,
  changePassword,
  clearResetPasswordError,
  clearSaveUpdatedUserAttributesSucceeded,
  clearSetInitialPasswordStatus,
  createUser,
  getUserByUsername,
  listUsersWithInformation,
  forgotPassword,
  resetPassword,
  saveUpdatedUserAttributes,
  sendInitialEmail,
  setInitialPassword,
  setSaveUpdatedUserAttributesSucceeded,
  setUserMustChangePassword,
  signIn,
  signOut,
  storeFirstName,
  storeLastName,
  storePhoneNumber,
  storeUsername,
  storeVerificationCode,
};
