import React from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const OfferGeneratedDateInput = ({
  id,
  loadFrom,
  onChange,
  validationName,
  dateFormat = 'MM-dd-yyyy',
  errors = {},
  label = 'Offer generation date',
  mask = '99-99-9999',
  maskChar = null,
  name = 'dateOfBirth',
  placeholder = ' ',
  regex = String.raw`\d{2}-\d{2}-\d{4}`,
  required = true,
  valueOverride = '',
}) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label className="input-container" htmlFor={id}>
          <InputMask
            dateformat={dateFormat}
            id={id}
            mask={mask}
            maskChar={maskChar}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            regex={regex}
            required={required}
            type="tel"
            validationname={validationName}
            value={valueOverride || value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      <p className="input-tool-tip mb-1">Ex. 11-23-1970 (mm-dd-yyyy)</p>
      {(validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isInvalid')}
      </p>
      )}
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default OfferGeneratedDateInput;
