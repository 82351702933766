import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../TextInput';

const AddressLine2TextInput = ({
  errors, id, loadFrom, onBlur, onChange, validationName,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      <TextInput
        errors={errors}
        id={id}
        label={translate('yourcontact:addressFields:addressLine2')}
        loadFrom={loadFrom}
        name="addressLine2"
        onBlur={onBlur}
        onChange={onChange}
        required={false}
        validationName={validationName}
      />
      <p className="input-tool-tip mb-3 -mt-4">{translate('yourcontact:addressFields:tooltipaddressLine2')}</p>
    </div>
  );
};

export default AddressLine2TextInput;
