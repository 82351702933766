import { capitalize } from 'lodash';
import React from 'react';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import foodIcon from '../../../assets/icons/food_icon.svg';
import housingIcon from '../../../assets/icons/housing_icon.svg';
import medicalIcon from '../../../assets/icons/medical_icon.svg';
import miscIcon from '../../../assets/icons/misc_icon.svg';
import otherIcon from '../../../assets/icons/other_icon.svg';
import personalIcon from '../../../assets/icons/personal_icon.svg';
import savingsIcon from '../../../assets/icons/savings_icon.svg';
import studentLoanIcon from '../../../assets/icons/student-loan_icon.svg';
import transportationIcon from '../../../assets/icons/transportation_icon.svg';
import utilitiesIcon from '../../../assets/icons/utilities_icon.svg';

const ColorIconDetail = ({
  value, name, color,
}) => {
  const icons = {
    food: foodIcon,
    housing: housingIcon,
    medical: medicalIcon,
    misc: miscIcon,
    other: otherIcon,
    personal: personalIcon,
    savings: savingsIcon,
    studentLoan: studentLoanIcon,
    transportation: transportationIcon,
    utilities: utilitiesIcon,
  };

  return (
    <div>
      <div className={`color-icon-detail m-auto py-1 px-4 w-full bg-${color}`}>
        <img src={icons[name]} alt={name} className="pl-2 inline-block" />
        <div className="inline-block pl-2">
          {formatNumberAsCurrency({ number: value })}
        </div>
      </div>
      <p className="ml-2 l:ml-4 mt-1">
        {capitalize(name)}
      </p>
    </div>
  );
};

export default ColorIconDetail;
