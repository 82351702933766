import React from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = ({ pageCount, handlePageClick }) => {
  const paginateBasicStyle = 'block px-3 py-2';
  const paginateLinkStyle = 'text-gray-500';
  const paginateActiveLinkStyle = 'text-blue-600';

  return (
    <ReactPaginate
      previousLabel="<"
      previousLinkClassName={paginateActiveLinkStyle}
      nextLabel=">"
      nextLinkClassName={paginateActiveLinkStyle}
      breakLabel="..."
      breakClassName={paginateBasicStyle}
      breakLinkClassName={`${paginateLinkStyle} hover:text-blue-700`}
      containerClassName="flex justify-center list-reset w-auto"
      initialPage={0}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      nextClassName={paginateBasicStyle}
      pageClassName={paginateBasicStyle}
      pageLinkClassName={paginateLinkStyle}
      pageRangeDisplayed={2}
      previousClassName={paginateBasicStyle}
      onPageChange={handlePageClick}
      activeLinkClassName={paginateActiveLinkStyle}
    />

  );
};

export default Paginate;
