import React from 'react';
import { useSelector } from 'react-redux';
import Expenses from '../../common/Expenses/Expenses';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const FinancialSummaryComponent = () => {
  const application = useSelector((state) => state.application.data);
  const estimatedMonthlyIncome = useSelector((state) => state.income.data.estimatedMonthlyIncome);

  const currentMonthlyIncome = application.selfReportedMonthlyCurrentIncome || application.verifiedMonthlyCurrentIncome || estimatedMonthlyIncome;

  const incomeSection = currentMonthlyIncome
    ? (
      <div className="income-amounts lender-tile-animation-1">
        <div>
          <div className="amount text-center">
            <ValueDetail value={currentMonthlyIncome} />
          </div>
          <div className="text-center -mt-2 pb-2">
            <p>Self-Validated Monthly Income</p>
          </div>
        </div>
      </div>
    )
    : (
      <div className="mt-2">
        <p>Income is not available for this borrower</p>
      </div>
    );

  return (
    <section>
      <div className="expenses-summary-component">
        <div className="income-summary">
          <h1>Income</h1>
          {incomeSection}
        </div>
        <div className="divider mb-5" />
        <h1>Expenses</h1>
        <Expenses noExpensesMessage="Expenses are not available for this borrower." showDetailsByDefault />
      </div>
    </section>
  );
};

export default FinancialSummaryComponent;
