import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Chevron from '../../../../assets/icons/chevron.svg';

const DropdownInput = ({
  errors = {},
  id,
  loadFrom,
  name,
  onChange,
  options,
  question,
  required = true,
  validationName,
  valueOverride = '',
}) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');
  const [labelClass, setLabelClass] = useState(value ? 'label on-blur' : 'label');

  if (!options || options.length === 0) {
    return null;
  }

  const onBlur = () => {
    setLabelClass('label on-blur');
  };

  const onFocus = () => {
    setLabelClass('label');
  };

  const selectOptions = [];

  options.forEach((option) => {
    selectOptions.push(React.createElement('option', { key: `${option.value}-option`, value: option.value }, option.label));
  });

  return (
    <div className="dropdown w-full m-auto">
      <div className="mb-4">
        <div className="select-container">
          <img src={Chevron} alt="Chevron Icon" />
          <label htmlFor={id}>
            <select
              id={id}
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              required={required}
              validationname={validationName}
              value={valueOverride || value}
            >
              {!valueOverride && !value && React.createElement('option', {})}
              {selectOptions}
            </select>
            <span className={labelClass}>{question}</span>
            <span className="border" />
          </label>
        </div>
      </div>
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {`${question} ${translate('global:errorMessages:isRequired')}`}
      </p>
      )}
    </div>
  );
};

export default DropdownInput;
