import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const CreditReportPolicy = () => {
  const { t: translate } = useTranslation();
  const showTranslationOption = process.env.REACT_APP_SHOW_TRANSLATION_OPTION === 'true';

  const onLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <section>
      {showTranslationOption
        && (
        <div className="text-left ml-10 pt-10">
          <p className="text-sm w-">
            Select Language:
          </p>
          <select name="language" onChange={onLanguageChange}>
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        )}
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
          <div className="pb-5">
            <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
              {translate('creditreportpolicy:title')}
            </h2>
            <p className="text-center p-5">
              {translate('creditreportpolicy:subtitle')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="text-xl">
              {translate('creditreportpolicy:useOfCreditProfileForIdentityVerification:headline')}
            </h2>
            <p className="pb-5">
              {translate('creditreportpolicy:useOfCreditProfileForIdentityVerification:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="text-xl">
              {translate('creditreportpolicy:creditHistory:headline')}
            </h2>
            <p className="pb-5">
              {translate('creditreportpolicy:creditHistory:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="text-xl">
              {translate('creditreportpolicy:yourRightToFreeCreditReports:headline')}
            </h2>
            <p className="pb-5">
              {translate('creditreportpolicy:yourRightToFreeCreditReports:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="text-xl">
              {translate('creditreportpolicy:privacy:headline')}
            </h2>
            <p className="pb-5">
              {translate('creditreportpolicy:privacy:paragraphOne')}
              {' '}
              <Link className="color-blue" to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              {' '}
              {translate('creditreportpolicy:privacy:paragraphTwo')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditReportPolicy;
