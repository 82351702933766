import React from 'react';
import DateOfBirthInput from '../common/DateOfBirthInput';

const BorrowerDateOfBirthInput = ({ errors, onChange }) => (
  <DateOfBirthInput
    errors={errors}
    id="borrower-date-of-birth-input"
    loadFrom="borrower"
    name="dateOfBirth"
    onChange={onChange}
    validationName="borrowerDateOfBirth"
  />
);

export default BorrowerDateOfBirthInput;
