import React from 'react';
import EmailAddressInput from '../common/EmailAddressInput';

const BorrowerEmailAddressInput = ({
  disabled,
  errors,
  onBlur = () => {},
  onChange,
  label,
}) => (
  <EmailAddressInput
    disabled={disabled}
    errors={errors}
    id="borrower-email-address-input"
    label={label}
    loadFrom="borrower"
    onBlur={onBlur}
    onChange={onChange}
    validationName="borrowerEmail"
  />
);

export default BorrowerEmailAddressInput;
