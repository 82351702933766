import React from 'react';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import customerPortalSummaryPhone from '../../../../assets/images/portal-summary-phone_graphic.png';

const WhyCreditGenie = () => (
  <div className="w-full bg-white">
    <div className="container inner-width flex m-auto sm:py-20 py-10 pt-8 sm:px-0 px-2">
      <div className="w-1/3 sm:block hidden">
        <img src={customerPortalSummaryPhone} alt="Customer portal summary phone" />
      </div>
      <div className="sm:w-2/3 w-full sm:pl-6 pl-0 sm:text-left text-center sm:pr-40 pr-0 sm:mt-40 mt-0">
        <h2 className="font-bold">
          Why Credit Genie?
        </h2>
        <p className="py-4">
          Credit Genie started with the idea that a technology-driven solution was needed to help consumers who got in over their
          heads with personal debt … because let&apos;s face it … it&apos;s happened to all of us at one time or another!
        </p>
        <p className="pb-4">
          We want to help consumers who are going through significant economic hardship and Credit Genie&apos;s Free Debt Analysis tool
          {' '}
          helps you really understand your current financial situation.
        </p>
        <div className="sm:float-left float-none">
          <CallToActionButton />
        </div>
      </div>
    </div>
  </div>
);

export default WhyCreditGenie;
