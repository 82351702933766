import {
  CLEAR_CONTACT_ATTEMPT,
  CLEAR_RECEIVED_OFFER,
  CLEAR_SAVE_CONTACT_ATTEMPT_SUCCEEDED,
  SAVE_CONTACT_ATTEMPT_FAIL,
  SAVE_CONTACT_ATTEMPT_SUCCESS,
  SAVE_CONTACT_ATTEMPT,
  SET_LIST_CONTACT_ATTEMPTS,
  UPDATE_CONTACT_ATTEMPT,
  UPDATE_CONTACT_ATTEMPT_LIST,
} from '../actions/contactAttemptActions';

const initialState = {
  data: {
    applicationId: undefined,
    createdAt: undefined,
    owner: undefined,
    contactedBy: undefined,
    whatWasTheMethodOfContact: undefined,
    whatWasTheOutcome: undefined,
    wasACounselingSessionConducted: undefined,
    didTheBorrowerReceiveAnOffer: undefined,
    whatTypeOfOfferDidTheyReceive: undefined,
  },
  isGettingContactAttempts: undefined,
  isSaving: undefined,
  listContactAttempts: [],
  saveSuccess: undefined,
};

const contactAttemptReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CONTACT_ATTEMPT:
      return { ...state, data: { ...initialState.data } };

    case CLEAR_RECEIVED_OFFER:
      return { ...state, data: { ...state.data, didTheBorrowerReceiveAnOffer: undefined } };

    case CLEAR_SAVE_CONTACT_ATTEMPT_SUCCEEDED:
      return { ...state, saveSuccess: undefined };

    case SAVE_CONTACT_ATTEMPT:
      return {
        ...state,
        isSaving: true,
        saveFailed: undefined,
        saveSuccess: undefined,
      };

    case SAVE_CONTACT_ATTEMPT_FAIL:
      return {
        ...state,
        data: action.contactAttempt,
        isSaving: false,
        saveFailed: true,
        saveSuccess: false,
      };

    case SAVE_CONTACT_ATTEMPT_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SET_LIST_CONTACT_ATTEMPTS:
      return {
        ...state,
        listContactAttempts: action.contactAttempts,
      };

    case UPDATE_CONTACT_ATTEMPT:
      return {
        ...state,
        data: { ...state.data, [action.propertyName]: action.value },
      };

    case UPDATE_CONTACT_ATTEMPT_LIST:
      return {
        ...state,
        listContactAttempts: [...state.listContactAttempts, action.contactAttempt],
      };

    default:
      return state;
  }
};

export default contactAttemptReducer;
