import React from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { assignApplicationToUser, updateApplication } from '../../../../redux/actions/applicationActions';
import { setAssignedBorrowerCount, setContactedBorrowerCount } from '../../../../redux/actions/borrowerActions';
import LinkButton from '../../../common/LinkButton/LinkButton';

const ClaimApplicationLinkButton = ({
  additionalClasses,
  applicationId,
  applicationStatus,
  onClick = () => { },
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.data.username);
  const assignedBorrowerCount = useSelector((state) => state.borrower.assignedBorrowerCount);
  const contactedBorrowerCount = useSelector((state) => state.borrower.contactedBorrowerCount);

  const onClaimButtonClick = (event) => {
    event.preventDefault();
    const currentDateAndTime = DateTime.local();

    dispatch(updateApplication({ propertyName: 'assignedTo', value: userId }));
    dispatch(updateApplication({ propertyName: 'assignedToAt', value: currentDateAndTime }));
    dispatch(assignApplicationToUser({ applicationId, userId }));
    dispatch(setAssignedBorrowerCount({ numberOfAssignedBorrowers: assignedBorrowerCount + 1 }));

    if (applicationStatus === 'Contacted') {
      dispatch(setContactedBorrowerCount({ numberOfContactedBorrowers: contactedBorrowerCount + 1 }));
    }

    onClick();
  };

  return (
    <LinkButton additionalClasses={additionalClasses} onClick={onClaimButtonClick} text="Claim" />
  );
};

export default ClaimApplicationLinkButton;
