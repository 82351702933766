import React from 'react';
import { useSelector } from 'react-redux';
import DoYouOwnYourHomeInput from '../DoYouOwnYourHomeInput/DoYouOwnYourHomeInput';
import DoYouHaveMortgageEscrow from '../DoYouHaveMortgageEscrow/DoYouHaveMortgageEscrow';

const HomeownerComponent = ({ errors, onChange }) => {
  const isHomeowner = useSelector(
    (state) => state.application.data.isHomeowner,
  );

  return (
    <div>
      <DoYouOwnYourHomeInput errors={errors} onChange={onChange} />
      {isHomeowner && <DoYouHaveMortgageEscrow errors={errors} onChange={onChange} />}
    </div>
  );
};

export default HomeownerComponent;
