import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    caches: ['localStorage', 'cookie'],
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    ns: [
      'creditreportpolicy',
      'expenses',
      'global',
      'landingpage',
      'lifeevents',
      'modal',
      'personalinformation',
      'privacypolicy',
      'pendinganddecline',
      'providemonthlyincome',
      'rentalaboutyou',
      'rentalcallus',
      'rentalinformation',
      'termsandconditions',
      'vendorsharing',
      'yourcontact',
    ],
  });

export default i18n;
