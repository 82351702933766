import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/build-tailwind.css';
import './styles/index.scss';
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerArgs = { gtmId: 'GTM-TBDTJS8' };

if (['local', 'main', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT)) {
  tagManagerArgs.auth = 'dWy6CwJm3NTBbWg1M_0LDA';
  tagManagerArgs.preview = 'env-15';
}

TagManager.initialize(tagManagerArgs);

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
const root = document.getElementById('root');

ReactDom.render(app, root);
