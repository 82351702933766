import { API, Auth } from 'aws-amplify';

export const CLEAR_IS_PHONE_NUMBER_VALID = 'CLEAR_IS_PHONE_NUMBER_VALID';
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const VERIFY_PHONE_NUMBER_FAIL = 'VERIFY_PHONE_NUMBER_FAIL';
export const VERIFY_PHONE_NUMBER_SUCCESS = 'VERIFY_PHONE_NUMBER_SUCCESS';

const clearIsPhoneNumberValid = () => ({ type: CLEAR_IS_PHONE_NUMBER_VALID });

const verifyPhoneNumber = () => async (dispatch, getState) => {
  dispatch({ type: VERIFY_PHONE_NUMBER });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const state = getState();

    const verifyPhoneNumberResponse = await API.post('twilio', '/twilio/verify-phone', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        phoneNumber: state.borrower.data.phoneNumber,
      },
    });

    dispatch({ type: VERIFY_PHONE_NUMBER_SUCCESS, isPhoneNumberValid: verifyPhoneNumberResponse.isValid });
  } catch (error) {
    console.error('error verifying phone number:', error);

    dispatch({ error, type: VERIFY_PHONE_NUMBER_FAIL });
  }
};

export {
  clearIsPhoneNumberValid,
  verifyPhoneNumber,
};
