export const UPDATE_SUMMARY_TAB_INDEX = 'UPDATE_SUMMARY_TAB_INDEX';

const updateDefaultSummaryTabIndex = (value) => ({
  type: UPDATE_SUMMARY_TAB_INDEX,
  value,
});

export {
  updateDefaultSummaryTabIndex,
};
