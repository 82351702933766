export const SET_SHOW_HOW_IT_WORKS_MODAL = 'SET_SHOW_HOW_IT_WORKS_MODAL';

const setShowHowItWorksModal = ({ value }) => ({
  type: SET_SHOW_HOW_IT_WORKS_MODAL,
  value,
});

export {
  setShowHowItWorksModal,
};
