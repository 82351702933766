/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert($owner: String) {
    onCreateAlert(owner: $owner) {
      id
      borrowerId
      createdAt
      owner
      updatedAt
      hasBeenSeenByBorrower
      hasBeenSeenByCustomerServiceRepresentative
      type
      triggeredBy {
        id
        type
      }
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert($owner: String) {
    onUpdateAlert(owner: $owner) {
      id
      borrowerId
      createdAt
      owner
      updatedAt
      hasBeenSeenByBorrower
      hasBeenSeenByCustomerServiceRepresentative
      type
      triggeredBy {
        id
        type
      }
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert($owner: String) {
    onDeleteAlert(owner: $owner) {
      id
      borrowerId
      createdAt
      owner
      updatedAt
      hasBeenSeenByBorrower
      hasBeenSeenByCustomerServiceRepresentative
      type
      triggeredBy {
        id
        type
      }
    }
  }
`;
export const onCreateApplication = /* GraphQL */ `
  subscription OnCreateApplication($owner: String) {
    onCreateApplication(owner: $owner) {
      id
      agreedToActionPlan
      assignedTo
      assignedToAt
      createdAt
      docusignEnvelopeId
      isCarOwner
      isHomeowner
      hasServedInMilitary
      hasSpouse
      hasLargeMedicalOrDisabilityExpenses
      hasMortgageEscrow
      hasRecentDeathInImmediateFamily
      hasRecentJobLoss
      hasRecentlyBeenFurloughed
      hasReductionInIncome
      kindOfRelief
      lastCompletedPage
      lastViewedPage
      monthlyRentOrMortgageAmount
      numberOfDependentsInHousehold
      owner
      reasonForNotConnectingBank
      router
      selfReportedMonthlyCurrentIncome
      status
      totalBackTaxesAmount
      totalDebtToFriendsAndFamilyAmount
      totalMedicalDebtAmount
      totalOtherDebtAmount
      type
      updatedAt
      utmCampaign
      utmContent
      utmMedium
      utmSource
      utmTerm
      verifiedMonthlyCurrentIncome
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      contactAttempts {
        items {
          id
          applicationId
          contactedBy
          createdAt
          method
          outcome
          owner
          updatedAt
        }
        nextToken
      }
      dmpEnrollment {
        id
        active
        activeAt
        dateSigned
        firstPaymentDate
        inactiveAt
        monthlyPaymentAmount
        owner
        termInMonths
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        createdAt
        updatedAt
      }
      decisions {
        items {
          id
          applicationId
          createdAt
          owner
          updatedAt
          currentDisposableIncome
          currentExpenses
          currentGrossIncome
          currentNetCashflow
          currentUnsecuredDebtPayment
          isQualified
          numberOfUnsecuredDebts
          proposedDisposableIncome
          proposedExpenses
          proposedUnsecuredDebtPayment
          proposedNetCashflow
          totalUnsecuredDebtBalance
          wasAcceptedByBorrower
          wasPresentedToBorrower
        }
        nextToken
      }
      hardships {
        items {
          id
          applicationId
          key
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      expenses {
        items {
          id
          applicationId
          category
          createdAt
          estimatedValue
          owner
          selfReportedValue
          subCategory
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
    }
  }
`;
export const onUpdateApplication = /* GraphQL */ `
  subscription OnUpdateApplication($owner: String) {
    onUpdateApplication(owner: $owner) {
      id
      agreedToActionPlan
      assignedTo
      assignedToAt
      createdAt
      docusignEnvelopeId
      isCarOwner
      isHomeowner
      hasServedInMilitary
      hasSpouse
      hasLargeMedicalOrDisabilityExpenses
      hasMortgageEscrow
      hasRecentDeathInImmediateFamily
      hasRecentJobLoss
      hasRecentlyBeenFurloughed
      hasReductionInIncome
      kindOfRelief
      lastCompletedPage
      lastViewedPage
      monthlyRentOrMortgageAmount
      numberOfDependentsInHousehold
      owner
      reasonForNotConnectingBank
      router
      selfReportedMonthlyCurrentIncome
      status
      totalBackTaxesAmount
      totalDebtToFriendsAndFamilyAmount
      totalMedicalDebtAmount
      totalOtherDebtAmount
      type
      updatedAt
      utmCampaign
      utmContent
      utmMedium
      utmSource
      utmTerm
      verifiedMonthlyCurrentIncome
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      contactAttempts {
        items {
          id
          applicationId
          contactedBy
          createdAt
          method
          outcome
          owner
          updatedAt
        }
        nextToken
      }
      dmpEnrollment {
        id
        active
        activeAt
        dateSigned
        firstPaymentDate
        inactiveAt
        monthlyPaymentAmount
        owner
        termInMonths
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        createdAt
        updatedAt
      }
      decisions {
        items {
          id
          applicationId
          createdAt
          owner
          updatedAt
          currentDisposableIncome
          currentExpenses
          currentGrossIncome
          currentNetCashflow
          currentUnsecuredDebtPayment
          isQualified
          numberOfUnsecuredDebts
          proposedDisposableIncome
          proposedExpenses
          proposedUnsecuredDebtPayment
          proposedNetCashflow
          totalUnsecuredDebtBalance
          wasAcceptedByBorrower
          wasPresentedToBorrower
        }
        nextToken
      }
      hardships {
        items {
          id
          applicationId
          key
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      expenses {
        items {
          id
          applicationId
          category
          createdAt
          estimatedValue
          owner
          selfReportedValue
          subCategory
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
    }
  }
`;
export const onDeleteApplication = /* GraphQL */ `
  subscription OnDeleteApplication($owner: String) {
    onDeleteApplication(owner: $owner) {
      id
      agreedToActionPlan
      assignedTo
      assignedToAt
      createdAt
      docusignEnvelopeId
      isCarOwner
      isHomeowner
      hasServedInMilitary
      hasSpouse
      hasLargeMedicalOrDisabilityExpenses
      hasMortgageEscrow
      hasRecentDeathInImmediateFamily
      hasRecentJobLoss
      hasRecentlyBeenFurloughed
      hasReductionInIncome
      kindOfRelief
      lastCompletedPage
      lastViewedPage
      monthlyRentOrMortgageAmount
      numberOfDependentsInHousehold
      owner
      reasonForNotConnectingBank
      router
      selfReportedMonthlyCurrentIncome
      status
      totalBackTaxesAmount
      totalDebtToFriendsAndFamilyAmount
      totalMedicalDebtAmount
      totalOtherDebtAmount
      type
      updatedAt
      utmCampaign
      utmContent
      utmMedium
      utmSource
      utmTerm
      verifiedMonthlyCurrentIncome
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      contactAttempts {
        items {
          id
          applicationId
          contactedBy
          createdAt
          method
          outcome
          owner
          updatedAt
        }
        nextToken
      }
      dmpEnrollment {
        id
        active
        activeAt
        dateSigned
        firstPaymentDate
        inactiveAt
        monthlyPaymentAmount
        owner
        termInMonths
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        createdAt
        updatedAt
      }
      decisions {
        items {
          id
          applicationId
          createdAt
          owner
          updatedAt
          currentDisposableIncome
          currentExpenses
          currentGrossIncome
          currentNetCashflow
          currentUnsecuredDebtPayment
          isQualified
          numberOfUnsecuredDebts
          proposedDisposableIncome
          proposedExpenses
          proposedUnsecuredDebtPayment
          proposedNetCashflow
          totalUnsecuredDebtBalance
          wasAcceptedByBorrower
          wasPresentedToBorrower
        }
        nextToken
      }
      hardships {
        items {
          id
          applicationId
          key
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      expenses {
        items {
          id
          applicationId
          category
          createdAt
          estimatedValue
          owner
          selfReportedValue
          subCategory
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
    }
  }
`;
export const onCreateArchive = /* GraphQL */ `
  subscription OnCreateArchive($owner: String) {
    onCreateArchive(owner: $owner) {
      id
      creditReportId
      owner
      createdAt
      updatedAt
      description
      code
      value
    }
  }
`;
export const onUpdateArchive = /* GraphQL */ `
  subscription OnUpdateArchive($owner: String) {
    onUpdateArchive(owner: $owner) {
      id
      creditReportId
      owner
      createdAt
      updatedAt
      description
      code
      value
    }
  }
`;
export const onDeleteArchive = /* GraphQL */ `
  subscription OnDeleteArchive($owner: String) {
    onDeleteArchive(owner: $owner) {
      id
      creditReportId
      owner
      createdAt
      updatedAt
      description
      code
      value
    }
  }
`;
export const onCreateBlsCex = /* GraphQL */ `
  subscription OnCreateBlsCex {
    onCreateBlsCex {
      id
      category
      createdAt
      cus
      minIncome
      maxIncome
      owner
      year
      value
      updatedAt
    }
  }
`;
export const onUpdateBlsCex = /* GraphQL */ `
  subscription OnUpdateBlsCex {
    onUpdateBlsCex {
      id
      category
      createdAt
      cus
      minIncome
      maxIncome
      owner
      year
      value
      updatedAt
    }
  }
`;
export const onDeleteBlsCex = /* GraphQL */ `
  subscription OnDeleteBlsCex {
    onDeleteBlsCex {
      id
      category
      createdAt
      cus
      minIncome
      maxIncome
      owner
      year
      value
      updatedAt
    }
  }
`;
export const onCreateBorrower = /* GraphQL */ `
  subscription OnCreateBorrower($owner: String) {
    onCreateBorrower(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      isOptedOutOfSMS
      lastName
      owner
      partnerCrmId
      phoneNumber
      preferredLanguage
      referralId
      state
      updatedAt
      zipCode
      alerts {
        items {
          id
          borrowerId
          createdAt
          owner
          updatedAt
          hasBeenSeenByBorrower
          hasBeenSeenByCustomerServiceRepresentative
          type
        }
        nextToken
      }
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateBorrower = /* GraphQL */ `
  subscription OnUpdateBorrower($owner: String) {
    onUpdateBorrower(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      isOptedOutOfSMS
      lastName
      owner
      partnerCrmId
      phoneNumber
      preferredLanguage
      referralId
      state
      updatedAt
      zipCode
      alerts {
        items {
          id
          borrowerId
          createdAt
          owner
          updatedAt
          hasBeenSeenByBorrower
          hasBeenSeenByCustomerServiceRepresentative
          type
        }
        nextToken
      }
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteBorrower = /* GraphQL */ `
  subscription OnDeleteBorrower($owner: String) {
    onDeleteBorrower(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      isOptedOutOfSMS
      lastName
      owner
      partnerCrmId
      phoneNumber
      preferredLanguage
      referralId
      state
      updatedAt
      zipCode
      alerts {
        items {
          id
          borrowerId
          createdAt
          owner
          updatedAt
          hasBeenSeenByBorrower
          hasBeenSeenByCustomerServiceRepresentative
          type
        }
        nextToken
      }
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      budgets {
        items {
          id
          applicationId
          borrowerId
          agreedTo
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
      plaidItems {
        items {
          id
          applicationId
          borrowerId
          accessToken
          createdAt
          institutionId
          institutionName
          isConnected
          isFinishedHistoricalUpdate
          owner
          updatedAt
        }
        nextToken
      }
      incomeSnapshots {
        items {
          id
          applicationId
          borrowerId
          createdAt
          owner
          updatedAt
          annualIncome
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
        }
        nextToken
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        lastName
        owner
        phoneNumber
        state
        updatedAt
        zipCode
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
      }
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateBudget = /* GraphQL */ `
  subscription OnCreateBudget($owner: String) {
    onCreateBudget(owner: $owner) {
      id
      applicationId
      borrowerId
      agreedTo
      description
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBudget = /* GraphQL */ `
  subscription OnUpdateBudget($owner: String) {
    onUpdateBudget(owner: $owner) {
      id
      applicationId
      borrowerId
      agreedTo
      description
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBudget = /* GraphQL */ `
  subscription OnDeleteBudget($owner: String) {
    onDeleteBudget(owner: $owner) {
      id
      applicationId
      borrowerId
      agreedTo
      description
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBudgetExpense = /* GraphQL */ `
  subscription OnCreateBudgetExpense($owner: String) {
    onCreateBudgetExpense(owner: $owner) {
      id
      budgetId
      expenseId
      proposedValue
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBudgetExpense = /* GraphQL */ `
  subscription OnUpdateBudgetExpense($owner: String) {
    onUpdateBudgetExpense(owner: $owner) {
      id
      budgetId
      expenseId
      proposedValue
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBudgetExpense = /* GraphQL */ `
  subscription OnDeleteBudgetExpense($owner: String) {
    onDeleteBudgetExpense(owner: $owner) {
      id
      budgetId
      expenseId
      proposedValue
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCollection = /* GraphQL */ `
  subscription OnCreateCollection($owner: String) {
    onCreateCollection(owner: $owner) {
      id
      creditReportId
      accountNumber
      accountRating
      closedIndicator
      currentBalance
      createdAt
      dateClosed
      dateOpened
      ecoaDesignator
      originalLenderName
      originalLenderClassification
      originalBalance
      owner
      pastDue
      portfolioType
      remarkCode
      subscriberIndustryCode
      subscriberMemberCode
      subscriberName
      updatedAt
    }
  }
`;
export const onUpdateCollection = /* GraphQL */ `
  subscription OnUpdateCollection($owner: String) {
    onUpdateCollection(owner: $owner) {
      id
      creditReportId
      accountNumber
      accountRating
      closedIndicator
      currentBalance
      createdAt
      dateClosed
      dateOpened
      ecoaDesignator
      originalLenderName
      originalLenderClassification
      originalBalance
      owner
      pastDue
      portfolioType
      remarkCode
      subscriberIndustryCode
      subscriberMemberCode
      subscriberName
      updatedAt
    }
  }
`;
export const onDeleteCollection = /* GraphQL */ `
  subscription OnDeleteCollection($owner: String) {
    onDeleteCollection(owner: $owner) {
      id
      creditReportId
      accountNumber
      accountRating
      closedIndicator
      currentBalance
      createdAt
      dateClosed
      dateOpened
      ecoaDesignator
      originalLenderName
      originalLenderClassification
      originalBalance
      owner
      pastDue
      portfolioType
      remarkCode
      subscriberIndustryCode
      subscriberMemberCode
      subscriberName
      updatedAt
    }
  }
`;
export const onCreateContactAttempt = /* GraphQL */ `
  subscription OnCreateContactAttempt($owner: String) {
    onCreateContactAttempt(owner: $owner) {
      id
      applicationId
      contactedBy
      createdAt
      method
      outcome
      owner
      updatedAt
    }
  }
`;
export const onUpdateContactAttempt = /* GraphQL */ `
  subscription OnUpdateContactAttempt($owner: String) {
    onUpdateContactAttempt(owner: $owner) {
      id
      applicationId
      contactedBy
      createdAt
      method
      outcome
      owner
      updatedAt
    }
  }
`;
export const onDeleteContactAttempt = /* GraphQL */ `
  subscription OnDeleteContactAttempt($owner: String) {
    onDeleteContactAttempt(owner: $owner) {
      id
      applicationId
      contactedBy
      createdAt
      method
      outcome
      owner
      updatedAt
    }
  }
`;
export const onCreateCreditReport = /* GraphQL */ `
  subscription OnCreateCreditReport($owner: String) {
    onCreateCreditReport(owner: $owner) {
      id
      applicationId
      borrowerId
      spouseId
      createdAt
      s3Key
      vantageScore
      owner
      updatedAt
      archives {
        items {
          id
          creditReportId
          owner
          createdAt
          updatedAt
          description
          code
          value
        }
        nextToken
      }
      collections {
        items {
          id
          creditReportId
          accountNumber
          accountRating
          closedIndicator
          currentBalance
          createdAt
          dateClosed
          dateOpened
          ecoaDesignator
          originalLenderName
          originalLenderClassification
          originalBalance
          owner
          pastDue
          portfolioType
          remarkCode
          subscriberIndustryCode
          subscriberMemberCode
          subscriberName
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          creditReportId
          accountRating
          accountNumber
          accountType
          ecoaDesignator
          createdAt
          creditLimit
          currentBalance
          dateClosed
          dateOpened
          estimatedMonthlyPayment
          highCredit
          late30DaysTotal
          late60DaysTotal
          late90DaysTotal
          lenderIndustryCode
          lenderMemberCode
          lenderName
          minimumMonthlyInterest
          minimumMonthlyPrincipal
          monthsReviewedCount
          mostRecentPayment
          owner
          pastDue
          paymentScheduleMonthCount
          portfolioType
          remarkCode
          scheduledMonthlyPayment
          updatedAt
        }
        nextToken
      }
      inquiries {
        items {
          id
          creditReportId
          createdAt
          ecoaDesignator
          date
          lenderIndustryCode
          lenderMemberCode
          lenderName
          owner
          updatedAt
        }
        nextToken
      }
      riskScores {
        items {
          id
          creditReportId
          createdAt
          logit
          owner
          probability
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCreditReport = /* GraphQL */ `
  subscription OnUpdateCreditReport($owner: String) {
    onUpdateCreditReport(owner: $owner) {
      id
      applicationId
      borrowerId
      spouseId
      createdAt
      s3Key
      vantageScore
      owner
      updatedAt
      archives {
        items {
          id
          creditReportId
          owner
          createdAt
          updatedAt
          description
          code
          value
        }
        nextToken
      }
      collections {
        items {
          id
          creditReportId
          accountNumber
          accountRating
          closedIndicator
          currentBalance
          createdAt
          dateClosed
          dateOpened
          ecoaDesignator
          originalLenderName
          originalLenderClassification
          originalBalance
          owner
          pastDue
          portfolioType
          remarkCode
          subscriberIndustryCode
          subscriberMemberCode
          subscriberName
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          creditReportId
          accountRating
          accountNumber
          accountType
          ecoaDesignator
          createdAt
          creditLimit
          currentBalance
          dateClosed
          dateOpened
          estimatedMonthlyPayment
          highCredit
          late30DaysTotal
          late60DaysTotal
          late90DaysTotal
          lenderIndustryCode
          lenderMemberCode
          lenderName
          minimumMonthlyInterest
          minimumMonthlyPrincipal
          monthsReviewedCount
          mostRecentPayment
          owner
          pastDue
          paymentScheduleMonthCount
          portfolioType
          remarkCode
          scheduledMonthlyPayment
          updatedAt
        }
        nextToken
      }
      inquiries {
        items {
          id
          creditReportId
          createdAt
          ecoaDesignator
          date
          lenderIndustryCode
          lenderMemberCode
          lenderName
          owner
          updatedAt
        }
        nextToken
      }
      riskScores {
        items {
          id
          creditReportId
          createdAt
          logit
          owner
          probability
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCreditReport = /* GraphQL */ `
  subscription OnDeleteCreditReport($owner: String) {
    onDeleteCreditReport(owner: $owner) {
      id
      applicationId
      borrowerId
      spouseId
      createdAt
      s3Key
      vantageScore
      owner
      updatedAt
      archives {
        items {
          id
          creditReportId
          owner
          createdAt
          updatedAt
          description
          code
          value
        }
        nextToken
      }
      collections {
        items {
          id
          creditReportId
          accountNumber
          accountRating
          closedIndicator
          currentBalance
          createdAt
          dateClosed
          dateOpened
          ecoaDesignator
          originalLenderName
          originalLenderClassification
          originalBalance
          owner
          pastDue
          portfolioType
          remarkCode
          subscriberIndustryCode
          subscriberMemberCode
          subscriberName
          updatedAt
        }
        nextToken
      }
      debts {
        items {
          id
          creditReportId
          accountRating
          accountNumber
          accountType
          ecoaDesignator
          createdAt
          creditLimit
          currentBalance
          dateClosed
          dateOpened
          estimatedMonthlyPayment
          highCredit
          late30DaysTotal
          late60DaysTotal
          late90DaysTotal
          lenderIndustryCode
          lenderMemberCode
          lenderName
          minimumMonthlyInterest
          minimumMonthlyPrincipal
          monthsReviewedCount
          mostRecentPayment
          owner
          pastDue
          paymentScheduleMonthCount
          portfolioType
          remarkCode
          scheduledMonthlyPayment
          updatedAt
        }
        nextToken
      }
      inquiries {
        items {
          id
          creditReportId
          createdAt
          ecoaDesignator
          date
          lenderIndustryCode
          lenderMemberCode
          lenderName
          owner
          updatedAt
        }
        nextToken
      }
      riskScores {
        items {
          id
          creditReportId
          createdAt
          logit
          owner
          probability
          updatedAt
          version
        }
        nextToken
      }
    }
  }
`;
export const onCreateDebt = /* GraphQL */ `
  subscription OnCreateDebt($owner: String) {
    onCreateDebt(owner: $owner) {
      id
      creditReportId
      accountRating
      accountNumber
      accountType
      ecoaDesignator
      createdAt
      creditLimit
      currentBalance
      dateClosed
      dateOpened
      estimatedMonthlyPayment
      highCredit
      late30DaysTotal
      late60DaysTotal
      late90DaysTotal
      lenderIndustryCode
      lenderMemberCode
      lenderName
      minimumMonthlyInterest
      minimumMonthlyPrincipal
      monthsReviewedCount
      mostRecentPayment
      owner
      pastDue
      paymentScheduleMonthCount
      portfolioType
      remarkCode
      scheduledMonthlyPayment
      updatedAt
    }
  }
`;
export const onUpdateDebt = /* GraphQL */ `
  subscription OnUpdateDebt($owner: String) {
    onUpdateDebt(owner: $owner) {
      id
      creditReportId
      accountRating
      accountNumber
      accountType
      ecoaDesignator
      createdAt
      creditLimit
      currentBalance
      dateClosed
      dateOpened
      estimatedMonthlyPayment
      highCredit
      late30DaysTotal
      late60DaysTotal
      late90DaysTotal
      lenderIndustryCode
      lenderMemberCode
      lenderName
      minimumMonthlyInterest
      minimumMonthlyPrincipal
      monthsReviewedCount
      mostRecentPayment
      owner
      pastDue
      paymentScheduleMonthCount
      portfolioType
      remarkCode
      scheduledMonthlyPayment
      updatedAt
    }
  }
`;
export const onDeleteDebt = /* GraphQL */ `
  subscription OnDeleteDebt($owner: String) {
    onDeleteDebt(owner: $owner) {
      id
      creditReportId
      accountRating
      accountNumber
      accountType
      ecoaDesignator
      createdAt
      creditLimit
      currentBalance
      dateClosed
      dateOpened
      estimatedMonthlyPayment
      highCredit
      late30DaysTotal
      late60DaysTotal
      late90DaysTotal
      lenderIndustryCode
      lenderMemberCode
      lenderName
      minimumMonthlyInterest
      minimumMonthlyPrincipal
      monthsReviewedCount
      mostRecentPayment
      owner
      pastDue
      paymentScheduleMonthCount
      portfolioType
      remarkCode
      scheduledMonthlyPayment
      updatedAt
    }
  }
`;
export const onCreateDebtScore = /* GraphQL */ `
  subscription OnCreateDebtScore($owner: String) {
    onCreateDebtScore(owner: $owner) {
      id
      applicationId
      borrowerId
      borrowerRiskScoreId
      spouseId
      spouseRiskScoreId
      createdAt
      discount
      monthlyExpense
      monthlyIncome
      monthlyUnsecuredDebt
      owner
      riskScore
      updatedAt
      value
      version
    }
  }
`;
export const onUpdateDebtScore = /* GraphQL */ `
  subscription OnUpdateDebtScore($owner: String) {
    onUpdateDebtScore(owner: $owner) {
      id
      applicationId
      borrowerId
      borrowerRiskScoreId
      spouseId
      spouseRiskScoreId
      createdAt
      discount
      monthlyExpense
      monthlyIncome
      monthlyUnsecuredDebt
      owner
      riskScore
      updatedAt
      value
      version
    }
  }
`;
export const onDeleteDebtScore = /* GraphQL */ `
  subscription OnDeleteDebtScore($owner: String) {
    onDeleteDebtScore(owner: $owner) {
      id
      applicationId
      borrowerId
      borrowerRiskScoreId
      spouseId
      spouseRiskScoreId
      createdAt
      discount
      monthlyExpense
      monthlyIncome
      monthlyUnsecuredDebt
      owner
      riskScore
      updatedAt
      value
      version
    }
  }
`;
export const onCreateDecision = /* GraphQL */ `
  subscription OnCreateDecision($owner: String) {
    onCreateDecision(owner: $owner) {
      id
      applicationId
      createdAt
      owner
      updatedAt
      currentDisposableIncome
      currentExpenses
      currentGrossIncome
      currentNetCashflow
      currentUnsecuredDebtPayment
      isQualified
      numberOfUnsecuredDebts
      proposedDisposableIncome
      proposedExpenses
      proposedUnsecuredDebtPayment
      proposedNetCashflow
      totalUnsecuredDebtBalance
      wasAcceptedByBorrower
      wasPresentedToBorrower
      disqualificationReasons {
        items {
          id
          decisionId
          createdAt
          owner
          updatedAt
          reason
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
    }
  }
`;
export const onUpdateDecision = /* GraphQL */ `
  subscription OnUpdateDecision($owner: String) {
    onUpdateDecision(owner: $owner) {
      id
      applicationId
      createdAt
      owner
      updatedAt
      currentDisposableIncome
      currentExpenses
      currentGrossIncome
      currentNetCashflow
      currentUnsecuredDebtPayment
      isQualified
      numberOfUnsecuredDebts
      proposedDisposableIncome
      proposedExpenses
      proposedUnsecuredDebtPayment
      proposedNetCashflow
      totalUnsecuredDebtBalance
      wasAcceptedByBorrower
      wasPresentedToBorrower
      disqualificationReasons {
        items {
          id
          decisionId
          createdAt
          owner
          updatedAt
          reason
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
    }
  }
`;
export const onDeleteDecision = /* GraphQL */ `
  subscription OnDeleteDecision($owner: String) {
    onDeleteDecision(owner: $owner) {
      id
      applicationId
      createdAt
      owner
      updatedAt
      currentDisposableIncome
      currentExpenses
      currentGrossIncome
      currentNetCashflow
      currentUnsecuredDebtPayment
      isQualified
      numberOfUnsecuredDebts
      proposedDisposableIncome
      proposedExpenses
      proposedUnsecuredDebtPayment
      proposedNetCashflow
      totalUnsecuredDebtBalance
      wasAcceptedByBorrower
      wasPresentedToBorrower
      disqualificationReasons {
        items {
          id
          decisionId
          createdAt
          owner
          updatedAt
          reason
        }
        nextToken
      }
      offers {
        items {
          id
          applicationId
          decisionId
          createdAt
          currentApr
          currentMinimumMonthlyPrincipalPaymentPercent
          estimatedPayoffSavings
          owner
          proposedApr
          proposedMaximumMonthlyPrincipalPayment
          proposedMinimumMonthlyPrincipalPayment
          proposedMonthlyInterestPayment
          proposedMonthlyPrincipalPayment
          status
          term
          totalUnsecuredDebtBalance
          type
          updatedAt
          balance
          lenderConcessionId
          paymentPercentage
          payment
        }
        nextToken
      }
    }
  }
`;
export const onCreateDisqualificationReason = /* GraphQL */ `
  subscription OnCreateDisqualificationReason($owner: String) {
    onCreateDisqualificationReason(owner: $owner) {
      id
      decisionId
      createdAt
      owner
      updatedAt
      reason
    }
  }
`;
export const onUpdateDisqualificationReason = /* GraphQL */ `
  subscription OnUpdateDisqualificationReason($owner: String) {
    onUpdateDisqualificationReason(owner: $owner) {
      id
      decisionId
      createdAt
      owner
      updatedAt
      reason
    }
  }
`;
export const onDeleteDisqualificationReason = /* GraphQL */ `
  subscription OnDeleteDisqualificationReason($owner: String) {
    onDeleteDisqualificationReason(owner: $owner) {
      id
      decisionId
      createdAt
      owner
      updatedAt
      reason
    }
  }
`;
export const onCreateDmpEnrollment = /* GraphQL */ `
  subscription OnCreateDmpEnrollment($owner: String) {
    onCreateDmpEnrollment(owner: $owner) {
      id
      active
      activeAt
      dateSigned
      firstPaymentDate
      inactiveAt
      monthlyPaymentAmount
      owner
      termInMonths
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDmpEnrollment = /* GraphQL */ `
  subscription OnUpdateDmpEnrollment($owner: String) {
    onUpdateDmpEnrollment(owner: $owner) {
      id
      active
      activeAt
      dateSigned
      firstPaymentDate
      inactiveAt
      monthlyPaymentAmount
      owner
      termInMonths
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDmpEnrollment = /* GraphQL */ `
  subscription OnDeleteDmpEnrollment($owner: String) {
    onDeleteDmpEnrollment(owner: $owner) {
      id
      active
      activeAt
      dateSigned
      firstPaymentDate
      inactiveAt
      monthlyPaymentAmount
      owner
      termInMonths
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExpense = /* GraphQL */ `
  subscription OnCreateExpense($owner: String) {
    onCreateExpense(owner: $owner) {
      id
      applicationId
      category
      createdAt
      estimatedValue
      owner
      selfReportedValue
      subCategory
      updatedAt
      blsCex {
        id
        category
        createdAt
        cus
        minIncome
        maxIncome
        owner
        year
        value
        updatedAt
      }
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateExpense = /* GraphQL */ `
  subscription OnUpdateExpense($owner: String) {
    onUpdateExpense(owner: $owner) {
      id
      applicationId
      category
      createdAt
      estimatedValue
      owner
      selfReportedValue
      subCategory
      updatedAt
      blsCex {
        id
        category
        createdAt
        cus
        minIncome
        maxIncome
        owner
        year
        value
        updatedAt
      }
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteExpense = /* GraphQL */ `
  subscription OnDeleteExpense($owner: String) {
    onDeleteExpense(owner: $owner) {
      id
      applicationId
      category
      createdAt
      estimatedValue
      owner
      selfReportedValue
      subCategory
      updatedAt
      blsCex {
        id
        category
        createdAt
        cus
        minIncome
        maxIncome
        owner
        year
        value
        updatedAt
      }
      budgetExpenses {
        items {
          id
          budgetId
          expenseId
          proposedValue
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateHardship = /* GraphQL */ `
  subscription OnCreateHardship($owner: String) {
    onCreateHardship(owner: $owner) {
      id
      applicationId
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHardship = /* GraphQL */ `
  subscription OnUpdateHardship($owner: String) {
    onUpdateHardship(owner: $owner) {
      id
      applicationId
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHardship = /* GraphQL */ `
  subscription OnDeleteHardship($owner: String) {
    onDeleteHardship(owner: $owner) {
      id
      applicationId
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateIncomeSnapshot = /* GraphQL */ `
  subscription OnCreateIncomeSnapshot($owner: String) {
    onCreateIncomeSnapshot(owner: $owner) {
      id
      applicationId
      borrowerId
      createdAt
      owner
      updatedAt
      annualIncome
      incomeTransactionIds
      monthlyIncome
      ninetyDayIncome
    }
  }
`;
export const onUpdateIncomeSnapshot = /* GraphQL */ `
  subscription OnUpdateIncomeSnapshot($owner: String) {
    onUpdateIncomeSnapshot(owner: $owner) {
      id
      applicationId
      borrowerId
      createdAt
      owner
      updatedAt
      annualIncome
      incomeTransactionIds
      monthlyIncome
      ninetyDayIncome
    }
  }
`;
export const onDeleteIncomeSnapshot = /* GraphQL */ `
  subscription OnDeleteIncomeSnapshot($owner: String) {
    onDeleteIncomeSnapshot(owner: $owner) {
      id
      applicationId
      borrowerId
      createdAt
      owner
      updatedAt
      annualIncome
      incomeTransactionIds
      monthlyIncome
      ninetyDayIncome
    }
  }
`;
export const onCreateInquiry = /* GraphQL */ `
  subscription OnCreateInquiry($owner: String) {
    onCreateInquiry(owner: $owner) {
      id
      creditReportId
      createdAt
      ecoaDesignator
      date
      lenderIndustryCode
      lenderMemberCode
      lenderName
      owner
      updatedAt
    }
  }
`;
export const onUpdateInquiry = /* GraphQL */ `
  subscription OnUpdateInquiry($owner: String) {
    onUpdateInquiry(owner: $owner) {
      id
      creditReportId
      createdAt
      ecoaDesignator
      date
      lenderIndustryCode
      lenderMemberCode
      lenderName
      owner
      updatedAt
    }
  }
`;
export const onDeleteInquiry = /* GraphQL */ `
  subscription OnDeleteInquiry($owner: String) {
    onDeleteInquiry(owner: $owner) {
      id
      creditReportId
      createdAt
      ecoaDesignator
      date
      lenderIndustryCode
      lenderMemberCode
      lenderName
      owner
      updatedAt
    }
  }
`;
export const onCreateOffer = /* GraphQL */ `
  subscription OnCreateOffer($owner: String) {
    onCreateOffer(owner: $owner) {
      id
      applicationId
      decisionId
      createdAt
      currentApr
      currentMinimumMonthlyPrincipalPaymentPercent
      estimatedPayoffSavings
      owner
      proposedApr
      proposedMaximumMonthlyPrincipalPayment
      proposedMinimumMonthlyPrincipalPayment
      proposedMonthlyInterestPayment
      proposedMonthlyPrincipalPayment
      status
      term
      totalUnsecuredDebtBalance
      type
      updatedAt
      balance
      lenderConcessionId
      paymentPercentage
      payment
      collection {
        id
        creditReportId
        accountNumber
        accountRating
        closedIndicator
        currentBalance
        createdAt
        dateClosed
        dateOpened
        ecoaDesignator
        originalLenderName
        originalLenderClassification
        originalBalance
        owner
        pastDue
        portfolioType
        remarkCode
        subscriberIndustryCode
        subscriberMemberCode
        subscriberName
        updatedAt
      }
      debt {
        id
        creditReportId
        accountRating
        accountNumber
        accountType
        ecoaDesignator
        createdAt
        creditLimit
        currentBalance
        dateClosed
        dateOpened
        estimatedMonthlyPayment
        highCredit
        late30DaysTotal
        late60DaysTotal
        late90DaysTotal
        lenderIndustryCode
        lenderMemberCode
        lenderName
        minimumMonthlyInterest
        minimumMonthlyPrincipal
        monthsReviewedCount
        mostRecentPayment
        owner
        pastDue
        paymentScheduleMonthCount
        portfolioType
        remarkCode
        scheduledMonthlyPayment
        updatedAt
      }
    }
  }
`;
export const onUpdateOffer = /* GraphQL */ `
  subscription OnUpdateOffer($owner: String) {
    onUpdateOffer(owner: $owner) {
      id
      applicationId
      decisionId
      createdAt
      currentApr
      currentMinimumMonthlyPrincipalPaymentPercent
      estimatedPayoffSavings
      owner
      proposedApr
      proposedMaximumMonthlyPrincipalPayment
      proposedMinimumMonthlyPrincipalPayment
      proposedMonthlyInterestPayment
      proposedMonthlyPrincipalPayment
      status
      term
      totalUnsecuredDebtBalance
      type
      updatedAt
      balance
      lenderConcessionId
      paymentPercentage
      payment
      collection {
        id
        creditReportId
        accountNumber
        accountRating
        closedIndicator
        currentBalance
        createdAt
        dateClosed
        dateOpened
        ecoaDesignator
        originalLenderName
        originalLenderClassification
        originalBalance
        owner
        pastDue
        portfolioType
        remarkCode
        subscriberIndustryCode
        subscriberMemberCode
        subscriberName
        updatedAt
      }
      debt {
        id
        creditReportId
        accountRating
        accountNumber
        accountType
        ecoaDesignator
        createdAt
        creditLimit
        currentBalance
        dateClosed
        dateOpened
        estimatedMonthlyPayment
        highCredit
        late30DaysTotal
        late60DaysTotal
        late90DaysTotal
        lenderIndustryCode
        lenderMemberCode
        lenderName
        minimumMonthlyInterest
        minimumMonthlyPrincipal
        monthsReviewedCount
        mostRecentPayment
        owner
        pastDue
        paymentScheduleMonthCount
        portfolioType
        remarkCode
        scheduledMonthlyPayment
        updatedAt
      }
    }
  }
`;
export const onDeleteOffer = /* GraphQL */ `
  subscription OnDeleteOffer($owner: String) {
    onDeleteOffer(owner: $owner) {
      id
      applicationId
      decisionId
      createdAt
      currentApr
      currentMinimumMonthlyPrincipalPaymentPercent
      estimatedPayoffSavings
      owner
      proposedApr
      proposedMaximumMonthlyPrincipalPayment
      proposedMinimumMonthlyPrincipalPayment
      proposedMonthlyInterestPayment
      proposedMonthlyPrincipalPayment
      status
      term
      totalUnsecuredDebtBalance
      type
      updatedAt
      balance
      lenderConcessionId
      paymentPercentage
      payment
      collection {
        id
        creditReportId
        accountNumber
        accountRating
        closedIndicator
        currentBalance
        createdAt
        dateClosed
        dateOpened
        ecoaDesignator
        originalLenderName
        originalLenderClassification
        originalBalance
        owner
        pastDue
        portfolioType
        remarkCode
        subscriberIndustryCode
        subscriberMemberCode
        subscriberName
        updatedAt
      }
      debt {
        id
        creditReportId
        accountRating
        accountNumber
        accountType
        ecoaDesignator
        createdAt
        creditLimit
        currentBalance
        dateClosed
        dateOpened
        estimatedMonthlyPayment
        highCredit
        late30DaysTotal
        late60DaysTotal
        late90DaysTotal
        lenderIndustryCode
        lenderMemberCode
        lenderName
        minimumMonthlyInterest
        minimumMonthlyPrincipal
        monthsReviewedCount
        mostRecentPayment
        owner
        pastDue
        paymentScheduleMonthCount
        portfolioType
        remarkCode
        scheduledMonthlyPayment
        updatedAt
      }
    }
  }
`;
export const onCreatePlaidAccount = /* GraphQL */ `
  subscription OnCreatePlaidAccount($owner: String) {
    onCreatePlaidAccount(owner: $owner) {
      id
      plaidItemId
      createdAt
      mask
      name
      officialName
      owner
      subType
      type
      verificationStatus
      updatedAt
      balances {
        items {
          id
          plaidAccountId
          available
          createdAt
          current
          isoCurrencyCode
          limit
          owner
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
      plaidTransactions {
        items {
          id
          plaidAccountId
          amount
          authorizedDate
          category
          categoryId
          createdAt
          date
          isoCurrencyCode
          isPredictedAsIncome
          isVerifiedAsIncome
          name
          owner
          paymentChannel
          pending
          pendingTransactionId
          type
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePlaidAccount = /* GraphQL */ `
  subscription OnUpdatePlaidAccount($owner: String) {
    onUpdatePlaidAccount(owner: $owner) {
      id
      plaidItemId
      createdAt
      mask
      name
      officialName
      owner
      subType
      type
      verificationStatus
      updatedAt
      balances {
        items {
          id
          plaidAccountId
          available
          createdAt
          current
          isoCurrencyCode
          limit
          owner
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
      plaidTransactions {
        items {
          id
          plaidAccountId
          amount
          authorizedDate
          category
          categoryId
          createdAt
          date
          isoCurrencyCode
          isPredictedAsIncome
          isVerifiedAsIncome
          name
          owner
          paymentChannel
          pending
          pendingTransactionId
          type
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePlaidAccount = /* GraphQL */ `
  subscription OnDeletePlaidAccount($owner: String) {
    onDeletePlaidAccount(owner: $owner) {
      id
      plaidItemId
      createdAt
      mask
      name
      officialName
      owner
      subType
      type
      verificationStatus
      updatedAt
      balances {
        items {
          id
          plaidAccountId
          available
          createdAt
          current
          isoCurrencyCode
          limit
          owner
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
      plaidTransactions {
        items {
          id
          plaidAccountId
          amount
          authorizedDate
          category
          categoryId
          createdAt
          date
          isoCurrencyCode
          isPredictedAsIncome
          isVerifiedAsIncome
          name
          owner
          paymentChannel
          pending
          pendingTransactionId
          type
          unofficialCurrencyCode
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePlaidAccountBalance = /* GraphQL */ `
  subscription OnCreatePlaidAccountBalance($owner: String) {
    onCreatePlaidAccountBalance(owner: $owner) {
      id
      plaidAccountId
      available
      createdAt
      current
      isoCurrencyCode
      limit
      owner
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onUpdatePlaidAccountBalance = /* GraphQL */ `
  subscription OnUpdatePlaidAccountBalance($owner: String) {
    onUpdatePlaidAccountBalance(owner: $owner) {
      id
      plaidAccountId
      available
      createdAt
      current
      isoCurrencyCode
      limit
      owner
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onDeletePlaidAccountBalance = /* GraphQL */ `
  subscription OnDeletePlaidAccountBalance($owner: String) {
    onDeletePlaidAccountBalance(owner: $owner) {
      id
      plaidAccountId
      available
      createdAt
      current
      isoCurrencyCode
      limit
      owner
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onCreatePlaidItem = /* GraphQL */ `
  subscription OnCreatePlaidItem($owner: String) {
    onCreatePlaidItem(owner: $owner) {
      id
      applicationId
      borrowerId
      accessToken
      createdAt
      institutionId
      institutionName
      isConnected
      isFinishedHistoricalUpdate
      owner
      updatedAt
      plaidAccounts {
        items {
          id
          plaidItemId
          createdAt
          mask
          name
          officialName
          owner
          subType
          type
          verificationStatus
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePlaidItem = /* GraphQL */ `
  subscription OnUpdatePlaidItem($owner: String) {
    onUpdatePlaidItem(owner: $owner) {
      id
      applicationId
      borrowerId
      accessToken
      createdAt
      institutionId
      institutionName
      isConnected
      isFinishedHistoricalUpdate
      owner
      updatedAt
      plaidAccounts {
        items {
          id
          plaidItemId
          createdAt
          mask
          name
          officialName
          owner
          subType
          type
          verificationStatus
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePlaidItem = /* GraphQL */ `
  subscription OnDeletePlaidItem($owner: String) {
    onDeletePlaidItem(owner: $owner) {
      id
      applicationId
      borrowerId
      accessToken
      createdAt
      institutionId
      institutionName
      isConnected
      isFinishedHistoricalUpdate
      owner
      updatedAt
      plaidAccounts {
        items {
          id
          plaidItemId
          createdAt
          mask
          name
          officialName
          owner
          subType
          type
          verificationStatus
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePlaidTransaction = /* GraphQL */ `
  subscription OnCreatePlaidTransaction($owner: String) {
    onCreatePlaidTransaction(owner: $owner) {
      id
      plaidAccountId
      amount
      authorizedDate
      category
      categoryId
      createdAt
      date
      isoCurrencyCode
      isPredictedAsIncome
      isVerifiedAsIncome
      location {
        address
        city
        region
        postalCode
        country
        lat
        lon
        storeNumber
      }
      name
      owner
      paymentChannel
      paymentMeta {
        referenceNumber
        ppdId
        payee
      }
      pending
      pendingTransactionId
      type
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onUpdatePlaidTransaction = /* GraphQL */ `
  subscription OnUpdatePlaidTransaction($owner: String) {
    onUpdatePlaidTransaction(owner: $owner) {
      id
      plaidAccountId
      amount
      authorizedDate
      category
      categoryId
      createdAt
      date
      isoCurrencyCode
      isPredictedAsIncome
      isVerifiedAsIncome
      location {
        address
        city
        region
        postalCode
        country
        lat
        lon
        storeNumber
      }
      name
      owner
      paymentChannel
      paymentMeta {
        referenceNumber
        ppdId
        payee
      }
      pending
      pendingTransactionId
      type
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onDeletePlaidTransaction = /* GraphQL */ `
  subscription OnDeletePlaidTransaction($owner: String) {
    onDeletePlaidTransaction(owner: $owner) {
      id
      plaidAccountId
      amount
      authorizedDate
      category
      categoryId
      createdAt
      date
      isoCurrencyCode
      isPredictedAsIncome
      isVerifiedAsIncome
      location {
        address
        city
        region
        postalCode
        country
        lat
        lon
        storeNumber
      }
      name
      owner
      paymentChannel
      paymentMeta {
        referenceNumber
        ppdId
        payee
      }
      pending
      pendingTransactionId
      type
      unofficialCurrencyCode
      updatedAt
    }
  }
`;
export const onCreateRentalApplication = /* GraphQL */ `
  subscription OnCreateRentalApplication($owner: String) {
    onCreateRentalApplication(owner: $owner) {
      id
      backRentAmount
      ethnicity
      hasBackRent
      hasReceivedRentalSupportInPast
      hasUsedCreditCardToPayRent
      monthlyRentPayment
      numberOfPeopleLivingWithBorrower
      race
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      rentalReliefPlan {
        id
        dateSigned
        plannedStartDate
        plannedEndDate
        monthlyPlanRent
        monthlyBackRentDue
        monthlyRentalAssistanceFundsApplied
        owner
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRentalApplication = /* GraphQL */ `
  subscription OnUpdateRentalApplication($owner: String) {
    onUpdateRentalApplication(owner: $owner) {
      id
      backRentAmount
      ethnicity
      hasBackRent
      hasReceivedRentalSupportInPast
      hasUsedCreditCardToPayRent
      monthlyRentPayment
      numberOfPeopleLivingWithBorrower
      race
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      rentalReliefPlan {
        id
        dateSigned
        plannedStartDate
        plannedEndDate
        monthlyPlanRent
        monthlyBackRentDue
        monthlyRentalAssistanceFundsApplied
        owner
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRentalApplication = /* GraphQL */ `
  subscription OnDeleteRentalApplication($owner: String) {
    onDeleteRentalApplication(owner: $owner) {
      id
      backRentAmount
      ethnicity
      hasBackRent
      hasReceivedRentalSupportInPast
      hasUsedCreditCardToPayRent
      monthlyRentPayment
      numberOfPeopleLivingWithBorrower
      race
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      rentalReliefPlan {
        id
        dateSigned
        plannedStartDate
        plannedEndDate
        monthlyPlanRent
        monthlyBackRentDue
        monthlyRentalAssistanceFundsApplied
        owner
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRentalReliefPlan = /* GraphQL */ `
  subscription OnCreateRentalReliefPlan($owner: String) {
    onCreateRentalReliefPlan(owner: $owner) {
      id
      dateSigned
      plannedStartDate
      plannedEndDate
      monthlyPlanRent
      monthlyBackRentDue
      monthlyRentalAssistanceFundsApplied
      owner
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRentalReliefPlan = /* GraphQL */ `
  subscription OnUpdateRentalReliefPlan($owner: String) {
    onUpdateRentalReliefPlan(owner: $owner) {
      id
      dateSigned
      plannedStartDate
      plannedEndDate
      monthlyPlanRent
      monthlyBackRentDue
      monthlyRentalAssistanceFundsApplied
      owner
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRentalReliefPlan = /* GraphQL */ `
  subscription OnDeleteRentalReliefPlan($owner: String) {
    onDeleteRentalReliefPlan(owner: $owner) {
      id
      dateSigned
      plannedStartDate
      plannedEndDate
      monthlyPlanRent
      monthlyBackRentDue
      monthlyRentalAssistanceFundsApplied
      owner
      rentalApplication {
        id
        backRentAmount
        ethnicity
        hasBackRent
        hasReceivedRentalSupportInPast
        hasUsedCreditCardToPayRent
        monthlyRentPayment
        numberOfPeopleLivingWithBorrower
        race
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        rentalReliefPlan {
          id
          dateSigned
          plannedStartDate
          plannedEndDate
          monthlyPlanRent
          monthlyBackRentDue
          monthlyRentalAssistanceFundsApplied
          owner
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRiskScore = /* GraphQL */ `
  subscription OnCreateRiskScore($owner: String) {
    onCreateRiskScore(owner: $owner) {
      id
      creditReportId
      createdAt
      logit
      owner
      probability
      updatedAt
      version
      riskScoreAttributes {
        items {
          id
          archiveId
          riskScoreId
          code
          createdAt
          owner
          score
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRiskScore = /* GraphQL */ `
  subscription OnUpdateRiskScore($owner: String) {
    onUpdateRiskScore(owner: $owner) {
      id
      creditReportId
      createdAt
      logit
      owner
      probability
      updatedAt
      version
      riskScoreAttributes {
        items {
          id
          archiveId
          riskScoreId
          code
          createdAt
          owner
          score
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteRiskScore = /* GraphQL */ `
  subscription OnDeleteRiskScore($owner: String) {
    onDeleteRiskScore(owner: $owner) {
      id
      creditReportId
      createdAt
      logit
      owner
      probability
      updatedAt
      version
      riskScoreAttributes {
        items {
          id
          archiveId
          riskScoreId
          code
          createdAt
          owner
          score
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateRiskScoreAttribute = /* GraphQL */ `
  subscription OnCreateRiskScoreAttribute($owner: String) {
    onCreateRiskScoreAttribute(owner: $owner) {
      id
      archiveId
      riskScoreId
      code
      createdAt
      owner
      score
      updatedAt
    }
  }
`;
export const onUpdateRiskScoreAttribute = /* GraphQL */ `
  subscription OnUpdateRiskScoreAttribute($owner: String) {
    onUpdateRiskScoreAttribute(owner: $owner) {
      id
      archiveId
      riskScoreId
      code
      createdAt
      owner
      score
      updatedAt
    }
  }
`;
export const onDeleteRiskScoreAttribute = /* GraphQL */ `
  subscription OnDeleteRiskScoreAttribute($owner: String) {
    onDeleteRiskScoreAttribute(owner: $owner) {
      id
      archiveId
      riskScoreId
      code
      createdAt
      owner
      score
      updatedAt
    }
  }
`;
export const onCreateSpouse = /* GraphQL */ `
  subscription OnCreateSpouse($owner: String) {
    onCreateSpouse(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      lastName
      owner
      phoneNumber
      state
      updatedAt
      zipCode
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSpouse = /* GraphQL */ `
  subscription OnUpdateSpouse($owner: String) {
    onUpdateSpouse(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      lastName
      owner
      phoneNumber
      state
      updatedAt
      zipCode
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSpouse = /* GraphQL */ `
  subscription OnDeleteSpouse($owner: String) {
    onDeleteSpouse(owner: $owner) {
      id
      addressLine1
      addressLine2
      city
      createdAt
      dateOfBirth
      email
      firstName
      lastName
      owner
      phoneNumber
      state
      updatedAt
      zipCode
      application {
        id
        agreedToActionPlan
        assignedTo
        assignedToAt
        createdAt
        docusignEnvelopeId
        isCarOwner
        isHomeowner
        hasServedInMilitary
        hasSpouse
        hasLargeMedicalOrDisabilityExpenses
        hasMortgageEscrow
        hasRecentDeathInImmediateFamily
        hasRecentJobLoss
        hasRecentlyBeenFurloughed
        hasReductionInIncome
        kindOfRelief
        lastCompletedPage
        lastViewedPage
        monthlyRentOrMortgageAmount
        numberOfDependentsInHousehold
        owner
        reasonForNotConnectingBank
        router
        selfReportedMonthlyCurrentIncome
        status
        totalBackTaxesAmount
        totalDebtToFriendsAndFamilyAmount
        totalMedicalDebtAmount
        totalOtherDebtAmount
        type
        updatedAt
        utmCampaign
        utmContent
        utmMedium
        utmSource
        utmTerm
        verifiedMonthlyCurrentIncome
        borrower {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          isOptedOutOfSMS
          lastName
          owner
          partnerCrmId
          phoneNumber
          preferredLanguage
          referralId
          state
          updatedAt
          zipCode
        }
        budgets {
          nextToken
        }
        contactAttempts {
          nextToken
        }
        dmpEnrollment {
          id
          active
          activeAt
          dateSigned
          firstPaymentDate
          inactiveAt
          monthlyPaymentAmount
          owner
          termInMonths
          createdAt
          updatedAt
        }
        decisions {
          nextToken
        }
        hardships {
          nextToken
        }
        plaidItems {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        expenses {
          nextToken
        }
        creditReports {
          nextToken
        }
        offers {
          nextToken
        }
        debtScores {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
      }
      borrower {
        id
        addressLine1
        addressLine2
        city
        createdAt
        dateOfBirth
        email
        firstName
        isOptedOutOfSMS
        lastName
        owner
        partnerCrmId
        phoneNumber
        preferredLanguage
        referralId
        state
        updatedAt
        zipCode
        alerts {
          nextToken
        }
        application {
          id
          agreedToActionPlan
          assignedTo
          assignedToAt
          createdAt
          docusignEnvelopeId
          isCarOwner
          isHomeowner
          hasServedInMilitary
          hasSpouse
          hasLargeMedicalOrDisabilityExpenses
          hasMortgageEscrow
          hasRecentDeathInImmediateFamily
          hasRecentJobLoss
          hasRecentlyBeenFurloughed
          hasReductionInIncome
          kindOfRelief
          lastCompletedPage
          lastViewedPage
          monthlyRentOrMortgageAmount
          numberOfDependentsInHousehold
          owner
          reasonForNotConnectingBank
          router
          selfReportedMonthlyCurrentIncome
          status
          totalBackTaxesAmount
          totalDebtToFriendsAndFamilyAmount
          totalMedicalDebtAmount
          totalOtherDebtAmount
          type
          updatedAt
          utmCampaign
          utmContent
          utmMedium
          utmSource
          utmTerm
          verifiedMonthlyCurrentIncome
        }
        budgets {
          nextToken
        }
        creditReports {
          nextToken
        }
        debtScores {
          nextToken
        }
        plaidItems {
          nextToken
        }
        incomeSnapshots {
          nextToken
        }
        spouse {
          id
          addressLine1
          addressLine2
          city
          createdAt
          dateOfBirth
          email
          firstName
          lastName
          owner
          phoneNumber
          state
          updatedAt
          zipCode
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          createdAt
          updatedAt
        }
      }
      creditReports {
        items {
          id
          applicationId
          borrowerId
          spouseId
          createdAt
          s3Key
          vantageScore
          owner
          updatedAt
        }
        nextToken
      }
      debtScores {
        items {
          id
          applicationId
          borrowerId
          borrowerRiskScoreId
          spouseId
          spouseRiskScoreId
          createdAt
          discount
          monthlyExpense
          monthlyIncome
          monthlyUnsecuredDebt
          owner
          riskScore
          updatedAt
          value
          version
        }
        nextToken
      }
    }
  }
`;
