import {
  CLEAR_SAVE_HARDSHIPS_SUCCEEDED,
  SAVE_HARDSHIPS, SAVE_HARDSHIPS_FAILED, SAVE_HARDSHIPS_SUCCEEDED,
  SET_HARDSHIPS,
  SET_SAVE_HARDSHIPS_SUCCEEDED,
  SET_SHOULD_SAVE_HARDSHIPS,
  UPDATE_HARDSHIP,
} from '../actions/hardshipActions';

const initialState = {
  data: [
    {
      id: undefined,
      applicationId: undefined,

      key: undefined,
      value: undefined,
    },
  ],
  isSaving: undefined,
  savingFailed: undefined,
  savingSucceeded: undefined,
  shouldSaveHardships: undefined,
};

const hardshipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SAVE_HARDSHIPS_SUCCEEDED: {
      return {
        ...state,
        savingSucceeded: undefined,
      };
    }

    case SAVE_HARDSHIPS: {
      return {
        ...state,
        isSaving: true,
        savingFailed: undefined,
        savingSucceeded: undefined,
      };
    }

    case SAVE_HARDSHIPS_FAILED: {
      return {
        ...state,
        isSaving: false,
        savingFailed: true,
        savingSucceeded: false,
      };
    }

    case SAVE_HARDSHIPS_SUCCEEDED: {
      return {
        ...state,
        data: action.hardships,
        isSaving: false,
        savingFailed: false,
        savingSucceeded: true,
        shouldSaveHardships: false,
      };
    }

    case SET_HARDSHIPS: {
      return { ...state, data: action.hardships };
    }

    case SET_SAVE_HARDSHIPS_SUCCEEDED: {
      return { ...state, savingSucceeded: true };
    }

    case SET_SHOULD_SAVE_HARDSHIPS: {
      return { ...state, shouldSaveHardships: true };
    }

    case UPDATE_HARDSHIP: {
      const hardships = [...state.data];

      if (hardships.length === 0) {
        hardships.push({});
      }

      let hardshipIndex = hardships.findIndex((hardship) => hardship.key === action.key);

      if (hardshipIndex === -1) {
        hardshipIndex = hardships[0].key === undefined ? 0 : hardships.length;
      }

      if (hardshipIndex === hardships.length) {
        hardships.push({});
      }

      hardships[hardshipIndex].key = action.key;
      hardships[hardshipIndex].value = action.value;

      return { ...state, data: hardships };
    }

    default: {
      return state;
    }
  }
};

export default hardshipsReducer;
