/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../../../components/application/common/CurrencyInput/CurrencyInput';

const CurrentMonthlyIncomeInput = ({ amountDescription, errors, onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="pt-5">
      <CurrencyInput
        amountDescription={amountDescription}
        errors={errors}
        id="self-reported-monthly-current-income"
        name="selfReportedMonthlyCurrentIncome"
        loadFrom="application"
        maxLength={7}
        maxNumericValueLimit={15000}
        maxValueLimitWarningMessage={translate('providemonthlyincome:maxValueLimitWarningMessage')}
        onChange={onChange}
        placeholder="$"
        label={translate('providemonthlyincome:label')}
        labelClass="hidden"
      />
    </div>
  );
};

export default CurrentMonthlyIncomeInput;
