import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../../../components/application/common/ProgressBar/ProgressBar';

import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveRentalApplicationSucceeded } from '../../../redux/actions/rentalApplicationActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import Form from '../../../components/application/common/ApplicationForm/Form';
import {
  AreYouCurrentlyMarriedInput,
  DoYouHaveAnyDependents,
  DoYouOweBackRentComponent,
  HaveYouReceivedRentalSupport,
  HaveYouUsedYourCreditCardToPayRent,
  HowManyPeopleAreLivingWithYou,
  MonthlyRentPaymentAmountInput,
} from '../../../components';
import SubmitInput from '../../../components/application/common/SubmitInput';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';

const RentalInformation = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const errorMessage = translate('global:errorMessages:problemSavingYourInformation');
  const history = useHistory();
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);
  const saveRentalApplicationFailed = useSelector((state) => state.rentalApplication.saveFailed);
  const saveRentalApplicationSucceeded = useSelector((state) => state.rentalApplication.saveSucceeded);

  const {
    errors,
    onApplicationChange,
    onRentalApplicationChange,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    if (saveApplicationSucceeded && saveRentalApplicationSucceeded) {
      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveRentalApplicationSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'RentalInformation', lastViewedPage: 'PersonalInformation' }));

      history.push('/application/personal-information');
    }
  });

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pb-8">
          <ProgressBar pageName="rentalInformation" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center leading-normal">
            {translate('rentalinformation:title')}
          </h2>
          <div className="form-width mx-auto m-2">
            <Form onSubmit={onSubmit}>
              <MonthlyRentPaymentAmountInput errors={errors} onChange={onRentalApplicationChange} />
              <DoYouOweBackRentComponent errors={errors} onChange={onRentalApplicationChange} />
              <HaveYouUsedYourCreditCardToPayRent errors={errors} onChange={onRentalApplicationChange} />
              <HaveYouReceivedRentalSupport errors={errors} onChange={onRentalApplicationChange} />
              <HowManyPeopleAreLivingWithYou errors={errors} onChange={onRentalApplicationChange} />
              <AreYouCurrentlyMarriedInput errors={errors} onChange={onApplicationChange} />
              <DoYouHaveAnyDependents errors={errors} onChange={onApplicationChange} />
              <SubmitInput text={translate('global:submit:text')} />
            </Form>
          </div>
          {saveRentalApplicationFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default RentalInformation;
