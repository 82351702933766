import React from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { saveApplication, updateApplication } from '../../../../../redux/actions/applicationActions';
import {
  saveContactAttempt,
  updateContactAttempt,
  updateContactAttemptList,
} from '../../../../../redux/actions/contactAttemptActions';
import WhatWasTheMethodOfContact from './WhatWasTheMethodOfContact';
import WhatWasTheOutcome from './WhatWasTheOutcome';

const ContactedQuestions = ({
  showContactedQuestions, showContactSummaryTable, toggleContactedQuestions, toggleContactSummaryTable,
}) => {
  const applicationId = useSelector((state) => state.application.data.id);
  const contactedBy = useSelector((state) => state.user.data.username);
  const dispatch = useDispatch();

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(updateContactAttempt({ propertyName: 'applicationId', value: applicationId }));
    dispatch(updateContactAttempt({ propertyName: 'contactedBy', value: contactedBy }));
    dispatch(updateContactAttempt({ propertyName: 'createdAt', value: DateTime.local() }));
    dispatch(updateContactAttemptList());
    dispatch(updateApplication({ propertyName: 'status', value: 'Contacted' }));

    dispatch(saveContactAttempt());
    dispatch(saveApplication());

    toggleContactedQuestions(!showContactedQuestions);
    toggleContactSummaryTable(!showContactSummaryTable);
  };

  return (
    <div className="contract-summary-questions text-center mt-16">
      <h1>Answer A Few Questions</h1>
      <form className="w-2/5 mx-auto mt-5" onSubmit={onSubmit}>
        <WhatWasTheMethodOfContact />
        <WhatWasTheOutcome />

        <div className="divider mt-3" />

        <div className="py-2 w-full mt-2 mb-4 text-center inline-flex">
          <div
            role="button"
            tabIndex={0}
            className="call-to-action cancel-btn text-center inline-flex items-center justify-center mr-2"
            onClick={() => {
              toggleContactedQuestions(!showContactedQuestions);
              toggleContactSummaryTable(!showContactSummaryTable);
            }}
            onKeyDown={() => {
              toggleContactedQuestions(!showContactedQuestions);
              toggleContactSummaryTable(!showContactSummaryTable);
            }}
          >
            Cancel
          </div>
          <input
            className="call-to-action text-center inline-flex items-center justify-center"
            data-cy="submit-button"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactedQuestions;
