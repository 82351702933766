import InputMask from 'react-input-mask';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DateInput = ({
  dateFormat = 'MM-dd-yyyy',
  errors = {},
  exampleText = 'E.g. 11-23-1970 (mm-dd-yyyy)',
  id,
  label,
  loadFrom,
  mask = '99-99-9999',
  maskChar = null,
  maxDateAllowed = undefined,
  maxDateExceededMessage = undefined,
  minDateAllowed = undefined,
  minDateExceededMessage = undefined,
  name,
  onChange,
  placeholder = ' ',
  regex = String.raw`\d{2}-\d{2}-\d{4}`,
  required = true,
  validationName,
}) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  return (
    <div className="w-full m-auto">
      <div>
        <label className="input-container" htmlFor={id}>
          <InputMask
            dateformat={dateFormat}
            id={id}
            mask={mask}
            maskChar={maskChar}
            max={maxDateAllowed}
            min={minDateAllowed}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            regex={regex}
            required={required}
            type="tel"
            validationname={validationName}
            value={value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {
        exampleText && <p className="input-tool-tip mb-1">{exampleText}</p>
      }
      {
        (validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
          <p className="validation">{`${label} ${translate('global:errorMessages:isInvalid')}`}</p>
        )
      }
      {
        (validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
          <p className="validation">{`${label} ${translate('global:errorMessages:isRequired')}`}</p>
        )
      }
      {
        (validationName ? errors[validationName] === 'minValNotMet' : errors[name] === 'minValNotMet') && (
          <p className="validation">{minDateExceededMessage || `${label} ${translate('global:errorMessages:isInvalid')}`}</p>
        )
      }
      {
        (validationName ? errors[validationName] === 'maxValExceeded' : errors[name] === 'maxValExceeded') && (
          <p className="validation">{maxDateExceededMessage || `${label} ${translate('global:errorMessages:isInvalid')}`}</p>
        )
      }
    </div>
  );
};

export default DateInput;
