const getNumericValue = ({ allowNegative = false, allowZero = false, nonNumericValue }) => {
  if (!nonNumericValue && !(allowZero && nonNumericValue === 0)) {
    return undefined;
  }

  const baseTen = 10;
  const regex = allowNegative ? /[^-0-9]/g : /[^0-9]/g;

  const numericValue = parseInt(nonNumericValue.toString().replace(regex, ''), baseTen) || 0;

  if (!allowZero && numericValue === 0) {
    return undefined;
  }

  return numericValue;
};

const roundNumberUp = ({ number }) => {
  const numberLength = Math.round(number).toString().length;
  const roundTo = parseInt(`1${'0'.repeat(numberLength - 1)}`, 10);
  const roundedUpNumber = Math.ceil(Math.abs(number) / roundTo) * roundTo;

  return roundedUpNumber;
};

const convertToKFormat = ({ number }) => {
  if (number.toString().length < 4) {
    return number;
  }

  const kFormattedNumber = `${number.toString().slice(0, -3)}k`;

  return kFormattedNumber;
};

export {
  convertToKFormat,
  getNumericValue,
  roundNumberUp,
};
