import {
  CLEAR_SAVE_INCOME_PREDICTIONS_SUCCEEDED,
  GET_INCOME_PREDICTIONS, GET_INCOME_PREDICTIONS_FAIL, GET_INCOME_PREDICTIONS_SUCCESS,
  SAVE_INCOME_PREDICTIONS, SAVE_INCOME_PREDICTIONS_FAIL, SAVE_INCOME_PREDICTIONS_SUCCESS,
  SET_INCOME_PREDICTIONS,
  SET_INCOME_SNAPSHOTS,
  UNVERIFY_ALL_INCOME_PREDICTIONS, UNVERIFY_INCOME_PREDICTION,
  VERIFY_ALL_INCOME_PREDICTIONS, VERIFY_INCOME_PREDICTION,
} from '../actions/incomeActions';

const intitialState = {
  data: {
    estimatedMonthlyIncome: 0,
    incomePredictions: {},
  },
  getIncomePredictionsFailed: undefined,
  incomeSnapshots: [],
  isGettingIncomePredictions: undefined,
  isSavingIncomePredictions: undefined,
  saveIncomePredictionsFailed: undefined,
  saveIncomePredictionsSucceeded: undefined,
};

const incomeReducer = (state = intitialState, action) => {
  const getEstimatedMonthlyIncome = ({ incomePredictions }) => {
    const baseTen = 10;
    const numberOfMonthsOfDeposits = 3;

    const sumOfVerifiedIncomeDeposits = Object.keys(incomePredictions)
      .map((incomePredictionId) => incomePredictions[incomePredictionId])
      .filter((incomePrediction) => incomePrediction.isVerifiedAsIncome)
      .reduce((accumulator, currentIncomePrediction) => accumulator + currentIncomePrediction.amount, 0);

    const estimatedMonthlyIncome = Math.abs(parseInt(sumOfVerifiedIncomeDeposits / numberOfMonthsOfDeposits, baseTen));

    return estimatedMonthlyIncome;
  };

  switch (action.type) {
    case CLEAR_SAVE_INCOME_PREDICTIONS_SUCCEEDED:
      return { ...state, saveIncomePredictionsSucceeded: undefined };

    case GET_INCOME_PREDICTIONS:
      return { ...state, getIncomePredictionsFailed: undefined, isGettingIncomePredictions: true };

    case GET_INCOME_PREDICTIONS_FAIL:
      return { ...state, getIncomePredictionsFailed: true, isGettingIncomePredictions: false };

    case GET_INCOME_PREDICTIONS_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, incomePredictions: action.incomePredictions },
        getIncomePredictionsFailed: false,
        isGettingIncomePredictions: false,
      };
    }

    case SAVE_INCOME_PREDICTIONS:
      return {
        ...state, isSavingIncomePredictions: true, saveIncomePredictionsFailed: undefined, saveIncomePredictionsSucceeded: undefined,
      };

    case SAVE_INCOME_PREDICTIONS_FAIL:
      return {
        ...state, isSavingIncomePredictions: false, saveIncomePredictionsFailed: true, saveIncomePredictionsSucceeded: false,
      };

    case SAVE_INCOME_PREDICTIONS_SUCCESS:
      return {
        ...state, isSavingIncomePredictions: false, saveIncomePredictionsFailed: false, saveIncomePredictionsSucceeded: true,
      };

    case SET_INCOME_PREDICTIONS: {
      const incomePredictions = {};

      action.incomePredictions.forEach((incomePrediction) => {
        incomePredictions[incomePrediction.id] = incomePrediction;
      });

      return {
        ...state,
        data: {
          ...state.data,
          estimatedMonthlyIncome: getEstimatedMonthlyIncome({ incomePredictions }),
          incomePredictions,
        },
      };
    }

    case SET_INCOME_SNAPSHOTS:
      return {
        ...state,
        incomeSnapshots: action.incomeSnapshots || [],
      };

    case UNVERIFY_ALL_INCOME_PREDICTIONS: {
      const unverifiedIncomePredictions = {};

      Object.keys(state.data.incomePredictions).forEach((incomePredictionId) => {
        const incomePrediction = state.data.incomePredictions[incomePredictionId];

        unverifiedIncomePredictions[incomePrediction.id] = { ...incomePrediction, isVerifiedAsIncome: false };
      });

      return { ...state, data: { ...state.data, estimatedMonthlyIncome: 0, incomePredictions: unverifiedIncomePredictions } };
    }

    case UNVERIFY_INCOME_PREDICTION: {
      const incomePredictionsAfterUnverifying = {
        ...state.data.incomePredictions,
        [action.incomePredictionId]: {
          ...state.data.incomePredictions[action.incomePredictionId],
          isVerifiedAsIncome: false,
        },
      };

      return {
        ...state,
        data: {
          ...state.data,
          estimatedMonthlyIncome: getEstimatedMonthlyIncome({ incomePredictions: incomePredictionsAfterUnverifying }),
          incomePredictions: incomePredictionsAfterUnverifying,
        },
      };
    }

    case VERIFY_ALL_INCOME_PREDICTIONS: {
      const verifiedIncomePredictions = {};

      Object.keys(state.data.incomePredictions).forEach((incomePredictionId) => {
        const incomePrediction = state.data.incomePredictions[incomePredictionId];

        verifiedIncomePredictions[incomePredictionId] = { ...incomePrediction, isVerifiedAsIncome: true };
      });

      return {
        ...state,
        data: {
          ...state.data,
          estimatedMonthlyIncome: getEstimatedMonthlyIncome({ incomePredictions: verifiedIncomePredictions }),
          incomePredictions: verifiedIncomePredictions,
        },
      };
    }

    case VERIFY_INCOME_PREDICTION: {
      const incomePredictionsAfterVerifying = {
        ...state.data.incomePredictions,
        [action.incomePredictionId]: {
          ...state.data.incomePredictions[action.incomePredictionId],
          isVerifiedAsIncome: true,
        },
      };

      return {
        ...state,
        data: {
          ...state.data,
          estimatedMonthlyIncome: getEstimatedMonthlyIncome({ incomePredictions: incomePredictionsAfterVerifying }),
          incomePredictions: incomePredictionsAfterVerifying,
        },
      };
    }

    default:
      return state;
  }
};

export default incomeReducer;
