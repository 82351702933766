import {
  SET_OFFERS,
} from '../actions/offersActions';

const intitialState = {
  data: [
    {
      applicationId: undefined,
      balance: 0,
      currentApr: 0,
      currentMinimumMonthlyPrincipalPaymentPercent: 0,
      debt: {
        accountType: undefined,
        currentBalance: 0,
        estimatedMonthlyPayment: 0,
        id: undefined,
        lenderName: undefined,
        minimumMonthlyInterest: 0,
        minimumMonthlyPrincipal: 0,
        portfolioType: undefined,
        scheduledMonthlyPayment: 0,
      },
      estimatedPayoffSavings: 0,
      id: undefined,
      lenderConcessionId: undefined,
      payment: 0,
      paymentPercentage: 0,
      proposedApr: 0,
      proposedMaximumMonthlyPrincipalPayment: 0,
      proposedMinimumMonthlyPrincipalPayment: 5,
      proposedMonthlyInterestPayment: 0,
      proposedMonthlyPrincipalPayment: 0,
      status: undefined,
      term: 0,
      totalUnsecuredDebtBalance: 0,
      type: undefined,
    },
  ],
};

const offersReducer = (state = intitialState, action) => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        ...state,
        data: action.offers || [],
      };

    default:
      return state;
  }
};

export default offersReducer;
