/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router-dom';
import ApplicationLayout from './ApplicationLayout';
import {
  BankConnection,
  CreditGenieAboutYou,
  CreditHistory,
  Expenses,
  LenderGuide,
  LifeEvents,
  Pending,
  ProposedPaymentSummary,
  ProvideMonthlyIncome,
  ReasonForNotConnectingAccount,
  YourContact,
} from '../../pages';

import {
  CreditGenieFooter,
  CreditGenieHeader,
} from '../../components';

const CreditGenieApplicationLayout = () => {
  const creditGenieRoutes = [
    <Route component={CreditGenieAboutYou} key="credit-genie-about-you-page" path="/application/about-you" />,
    <Route component={LifeEvents} key="life-events-page" path="/application/life-events" />,
    <Route component={CreditHistory} key="credit-history-page" path="/application/credit-history" />,
    <Route component={YourContact} key="your-contact-page" path="/application/your-contact" />,
    <Route component={ProvideMonthlyIncome} key="provide-monthly-income-page" path="/application/income/provide-monthly-income" />,
    <Route component={Expenses} key="expenses-page" path="/application/expenses" />,
    <Route component={BankConnection} key="bank-connection-page" path="/application/bank-connection" />,
    <Route component={ReasonForNotConnectingAccount} key="reason-for-not-connecting-account-page" path="/application/income/reason-for-not-connecting-account" />,
    <Route component={ProposedPaymentSummary} key="proposed-payment-summary-page" path="/offers/proposed-payment-summary" />,
    <Route component={LenderGuide} key="lender-guide-page" path="/offers/lender-guide" />,
    <Route component={Pending} key="pending-page" path="/offers/pending" />,
  ];

  const creditGenieHeader = <CreditGenieHeader />;

  const creditGenieFooter = <CreditGenieFooter />;

  return <ApplicationLayout environmentSpecificRoutes={creditGenieRoutes} environmentSpecificHeader={creditGenieHeader} environmentSpecificFooter={creditGenieFooter} />;
};

export default CreditGenieApplicationLayout;
