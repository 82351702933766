import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../../application/common/DropdownInput';

const WhatIsYourEthnicity = ({
  errors, onChange,
}) => {
  const { t: translate } = useTranslation();
  const options = [
    { label: translate('personalinformation:whatIsYourEthnicity:hispanicOrLatino'), value: 'Hispanic or Latino' },
    { label: translate('personalinformation:whatIsYourEthnicity:notHispanicOrLatino'), value: 'Not Hispanic or Latino' },
  ];

  return (
    <DropdownInput
      errors={errors}
      id="what-is-your-ethnicity-select"
      loadFrom="rentalApplication"
      name="ethnicity"
      onChange={onChange}
      options={options}
      question={translate('personalinformation:whatIsYourEthnicity:question')}
      required={false}
    />
  );
};

export default WhatIsYourEthnicity;
