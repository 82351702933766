import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCognitoPhoneNumberForPortal } from '../../../formatters/phoneNumberFormatter';
import { getCurrentAuthenticatedUser } from '../../../helpers/authHelper';

const CustomerServiceInformationTile = () => {
  const defaultUser = { attributes: { email: undefined, name: undefined, phone_number: undefined } };
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const getCurrentUser = async () => {
      const currentUser = await getCurrentAuthenticatedUser();

      setUser(currentUser);
    };

    if (user === defaultUser) {
      getCurrentUser();
    }
  });

  return (
    <div className="box bg-white">
      <div className="pb-2 customer-service">
        <h1>{user.attributes.name}</h1>
        <h3>{user.attributes.email}</h3>
        <h3>{formatCognitoPhoneNumberForPortal({ cognitoPhoneNumber: user.attributes.phone_number })}</h3>
      </div>
      <div className="divider" />
      <div className="pt-2">
        <Link className="color-blue" to="/change-email">Change email</Link>
      </div>
      <div className="pt-2">
        <Link className="color-blue" to="/change-password">Change password</Link>
      </div>
    </div>
  );
};

export default CustomerServiceInformationTile;
