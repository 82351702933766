import {
  CLEAR_IS_PHONE_NUMBER_VALID,
  VERIFY_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER_FAIL,
  VERIFY_PHONE_NUMBER_SUCCESS,
} from '../actions/twilioActions';

const initialState = {
  data: {
    isPhoneNumberValid: undefined,
  },
  isVerifyingPhoneNumber: undefined,
  verifyPhoneNumberFailed: undefined,
  verifyPhoneNumberSucceeded: undefined,
};

const twilioReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_IS_PHONE_NUMBER_VALID:
      return { ...state, data: { ...state.data, isPhoneNumberValid: undefined } };

    case VERIFY_PHONE_NUMBER:
      return {
        ...state,
        data: { ...state.data, isPhoneNumberValid: undefined },
        isVerifyingPhoneNumber: true,
        verifyPhoneNumberFailed: undefined,
        verifyPhoneNumberSucceeded: undefined,
      };

    case VERIFY_PHONE_NUMBER_FAIL:
      return {
        ...state,
        isVerifyingPhoneNumber: false,
        verifyPhoneNumberFailed: true,
        verifyPhoneNumberSucceeded: false,
      };

    case VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        data: { ...state.data, isPhoneNumberValid: action.isPhoneNumberValid },
        isVerifyingPhoneNumber: false,
        verifyPhoneNumberFailed: false,
        verifyPhoneNumberSucceeded: true,
      };

    default:
      return state;
  }
};

export default twilioReducer;
