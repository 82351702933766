import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formatNumberAsCurrency from '../../../../formatters/currencyFormatter';
import { getNumericValue } from '../../../../helpers/numberHelper';

const CurrencyInput = ({
  allowZero = false,
  amountDescription = undefined,
  ariaLabel = 'currency input',
  autoFocusThis,
  errors = {}, id,
  label,
  labelClass,
  loadFrom,
  maxLength = 12,
  maxNumericValueLimit = Number.MAX_SAFE_INTEGER,
  maxValueLimitWarningMessage = 'global:errorMessages:maxValueLimitWarningMessage',
  name,
  onChange,
  placeholder = ' ',
  required = true,
  valueOverride = undefined,
}) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => {
    const storedValue = loadFrom && name && state[loadFrom].data[name];

    if (allowZero && storedValue === 0) {
      return storedValue;
    }

    return storedValue || '';
  });
  const [numericValue, setNumericValue] = useState(value || 0);

  const valid = ((allowZero && value === 0) || value) ? 'true' : 'false';

  const onInputValueChange = (event) => {
    const inputValue = getNumericValue({ allowZero, nonNumericValue: event.target.value });
    setNumericValue(inputValue || 0);
    onChange(event, inputValue || 0);
  };

  return (
    <div className="w-full m-auto">
      <div>
        <label className="input-container" htmlFor={id}>
          <input
            aria-label={ariaLabel}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocusThis}
            id={id}
            maxLength={maxLength}
            name={name}
            onChange={onInputValueChange}
            placeholder={placeholder}
            required={required}
            type="tel"
            valid={valid}
            value={formatNumberAsCurrency({ allowZero, number: (valueOverride !== undefined ? valueOverride : value) })}
          />
          <span className={`label ${labelClass}`}>{label}</span>
          <span className="border" />
        </label>
      </div>
      {amountDescription && (
      <div className="amount-description">
        <p>{amountDescription}</p>
      </div>
      )}
      {errors[name] === 'required' && (
        <p className="validation top-adjustment">
          {label}
          {' '}
          {translate('global:errorMessages:isRequired')}
        </p>
      )}
      {(numericValue > maxNumericValueLimit) && (
      <p className="validation-warning mt-8 mb-2">
        {translate(maxValueLimitWarningMessage)}
      </p>
      )}
    </div>
  );
};

export default CurrencyInput;
