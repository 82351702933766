import React from 'react';
import CityTextInput from '../common/AddressFields/CityTextInput';

const SpouseCityTextInput = ({ errors, onBlur, onChange }) => (
  <CityTextInput
    errors={errors}
    id="spouse-city-input"
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseCity"
  />
);

export default SpouseCityTextInput;
