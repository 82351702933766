import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ApplicationSummaryInput from './ApplicationSummaryInput';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const ApplicationReadOnlyComponent = () => {
  const application = useSelector((state) => state.application.data);
  const borrower = useSelector((state) => state.borrower.data);
  const hardships = useSelector((state) => state.hardships.data || []);
  const spouse = useSelector((state) => state.spouse.data);

  const {
    hasServedInMilitary,
    hasSpouse,
    isCarOwner,
    isHomeOwner,
    monthlyRentOrMortgageAmount,
    numberOfDependentsInHousehold,
  } = application;

  const {
    addressLine1: borrowerAddressLine1,
    addressLine2: borrowerAddressLine2,
    city: borrowerCity,
    dateOfBirth: borrowerDateOfBirth,
    email: borrowerEmailAddress,
    firstName: borrowerFirstName,
    lastName: borrowerLastName,
    phoneNumber: borrowerPhoneNumber,
    preferredLanguage,
    state: borrowerState,
    zipCode: borrowerZipCode,
  } = borrower;

  const hardshipsObject = hardships.reduce((newObject, currentHardship) => ({ ...newObject, [currentHardship.key]: currentHardship.value }), {});

  const {
    hasCovidSpecificHardship,
    hasIncreasedExpenses,
    hasJobLossOrReducedIncome,
    hasMedicalDebtOrExpenses,
    hasOtherHardship,
    hasStudentLoans,
    hasTroubleMakingRentPayments,
    hasUtilityPayments,
  } = hardshipsObject;

  const {
    addressLine1: spouseAddressLine1,
    addressLine2: spouseAddressLine2,
    city: spouseCity,
    dateOfBirth: spouseDateOfBirth,
    email: spouseEmailAddress,
    firstName: spouseFirstName,
    lastName: spouseLastName,
    phoneNumber: spousePhoneNumber,
    state: spouseState,
    zipCode: spouseZipCode,
  } = spouse;

  const borrowerAddressLine1Ref = useRef();
  const borrowerAddressLine2Ref = useRef();
  const borrowerDateOfBirthRef = useRef();
  const borrowerCityRef = useRef();
  const borrowerEmailAddressRef = useRef();
  const borrowerFirstNameRef = useRef();
  const borrowerLastNameRef = useRef();
  const borrowerPhoneNumberRef = useRef();
  const borrowerStateRef = useRef();
  const borrowerZipCodeRef = useRef();
  const hasCovidSpecificHardshipRef = useRef();
  const hasIncreasedExpensesRef = useRef();
  const hasJobLossOrReducedIncomeRef = useRef();
  const hasMedicalDebtOrExpensesRef = useRef();
  const hasOtherHardshipRef = useRef();
  const hasServedInMilitaryRef = useRef();
  const hasSpouseRef = useRef();
  const hasStudentLoansRef = useRef();
  const hasTroubleMakingRentPaymentsRef = useRef();
  const hasUtilityPaymentsRef = useRef();
  const isCarOwnerRef = useRef();
  const isHomeOwnerRef = useRef();
  const monthlyRentOrMortgageAmountRef = useRef();
  const numberOfDependentsRef = useRef();
  const preferredLanguageRef = useRef();
  const spouseAddressLine1Ref = useRef();
  const spouseAddressLine2Ref = useRef();
  const spouseCityRef = useRef();
  const spouseDateOfBirthRef = useRef();
  const spouseEmailAddressRef = useRef();
  const spouseFirstNameRef = useRef();
  const spouseLastNameRef = useRef();
  const spousePhoneNumberRef = useRef();
  const spouseStateRef = useRef();
  const spouseZipCodeRef = useRef();

  return (
    <section>
      <div className="application-summary-read-only-components">
        <div className="lender-tile-animation-1">
          <h1>Application page 1</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">First name:</div>
              <ApplicationSummaryInput inputValue={borrowerFirstName} inputRef={borrowerFirstNameRef} />

              <div className="application-label pt-4">Email address:</div>
              <ApplicationSummaryInput inputValue={borrowerEmailAddress} inputRef={borrowerEmailAddressRef} />
            </div>
            <div className="w-full sm:w-1/2 ">
              <div className="application-label pt-4">Last name:</div>
              <ApplicationSummaryInput inputValue={borrowerLastName} inputRef={borrowerLastNameRef} />

              <div className="application-label pt-4">Phone number:</div>
              <ApplicationSummaryInput inputValue={borrowerPhoneNumber} inputRef={borrowerPhoneNumberRef} />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 2</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Trouble making rent payments:</div>
              <ApplicationSummaryInput inputValue={hasTroubleMakingRentPayments ? 'Yes' : 'No'} inputRef={hasTroubleMakingRentPaymentsRef} />

              <div className="application-label pt-4">Job loss or reduced income:</div>
              <ApplicationSummaryInput inputValue={hasJobLossOrReducedIncome ? 'Yes' : 'No'} inputRef={hasJobLossOrReducedIncomeRef} />

              <div className="application-label pt-4">Significant increase in expenses:</div>
              <ApplicationSummaryInput inputValue={hasIncreasedExpenses ? 'Yes' : 'No'} inputRef={hasIncreasedExpensesRef} />

              <div className="application-label pt-4">COVID-specific hardship:</div>
              <ApplicationSummaryInput inputValue={hasCovidSpecificHardship ? 'Yes' : 'No'} inputRef={hasCovidSpecificHardshipRef} />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Medical debt or expenses:</div>
              <ApplicationSummaryInput inputValue={hasMedicalDebtOrExpenses ? 'Yes' : 'No'} inputRef={hasMedicalDebtOrExpensesRef} />

              <div className="application-label pt-4">Student loan debt:</div>
              <ApplicationSummaryInput inputValue={hasStudentLoans ? 'Yes' : 'No'} inputRef={hasStudentLoansRef} />

              <div className="application-label pt-4">Utility payments:</div>
              <ApplicationSummaryInput inputValue={hasUtilityPayments ? 'Yes' : 'No'} inputRef={hasUtilityPaymentsRef} />

              <div className="application-label pt-4">Other hardship:</div>
              <ApplicationSummaryInput inputValue={hasOtherHardship ? 'Yes' : 'No'} inputRef={hasOtherHardshipRef} />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 3</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Monthly housing cost:</div>
              <ApplicationSummaryInput
                inputValue={formatNumberAsCurrency({ allowZero: true, number: monthlyRentOrMortgageAmount })}
                inputRef={monthlyRentOrMortgageAmountRef}
              />

              <div className="application-label pt-4">Home owner:</div>
              <ApplicationSummaryInput inputValue={isHomeOwner ? 'Yes' : 'No'} inputRef={isHomeOwnerRef} />

              <div className="application-label pt-4">Marital status:</div>
              <ApplicationSummaryInput inputValue={hasSpouse ? 'Married' : 'Single'} inputRef={hasSpouseRef} />

              <div className="application-label pt-4">Military service:</div>
              <ApplicationSummaryInput inputValue={hasServedInMilitary ? 'Yes' : 'No'} inputRef={hasServedInMilitaryRef} />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Preferred language:</div>
              <ApplicationSummaryInput inputValue={preferredLanguage} inputRef={preferredLanguageRef} />

              <div className="application-label pt-4">Car owner:</div>
              <ApplicationSummaryInput inputValue={isCarOwner ? 'Yes' : 'No'} inputRef={isCarOwnerRef} />

              <div className="application-label pt-4">Number of dependents:</div>
              <ApplicationSummaryInput inputValue={numberOfDependentsInHousehold} inputRef={numberOfDependentsRef} />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 4</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Address line 1:</div>
              <ApplicationSummaryInput inputValue={borrowerAddressLine1} inputRef={borrowerAddressLine1Ref} />

              <div className="application-label pt-4">City:</div>
              <ApplicationSummaryInput inputValue={borrowerCity} inputRef={borrowerCityRef} />

              <div className="application-label pt-4">Zip code:</div>
              <ApplicationSummaryInput inputValue={borrowerZipCode} inputRef={borrowerZipCodeRef} />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Address line 2:</div>
              <ApplicationSummaryInput inputValue={borrowerAddressLine2} inputRef={borrowerAddressLine2Ref} />

              <div className="application-label pt-4">State:</div>
              <ApplicationSummaryInput inputValue={borrowerState} inputRef={borrowerStateRef} />

              <div className="application-label pt-4">Date of birth:</div>
              <ApplicationSummaryInput inputValue={borrowerDateOfBirth} inputRef={borrowerDateOfBirthRef} />
            </div>
          </div>
          {
            application.hasSpouse
            && (
              <div>
                <div className="divider" />
                <div className="information-bg">
                  <div className="font-bold pt-4">
                    Spouse information
                  </div>
                  <div className="flex">
                    <div className="w-full sm:w-1/2">
                      <div className="application-label pt-4">First name:</div>
                      <ApplicationSummaryInput inputValue={spouseFirstName} inputRef={spouseFirstNameRef} />

                      <div className="application-label pt-4">Email address:</div>
                      <ApplicationSummaryInput inputValue={spouseEmailAddress} inputRef={spouseEmailAddressRef} />

                      <div className="application-label pt-4">Address line 1:</div>
                      <ApplicationSummaryInput inputValue={spouseAddressLine1} inputRef={spouseAddressLine1Ref} />

                      <div className="application-label pt-4">City:</div>
                      <ApplicationSummaryInput inputValue={spouseCity} inputRef={spouseCityRef} />

                      <div className="application-label pt-4">Zip code:</div>
                      <ApplicationSummaryInput inputValue={spouseZipCode} inputRef={spouseZipCodeRef} />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="application-label pt-4">Last name:</div>
                      <ApplicationSummaryInput inputValue={spouseLastName} inputRef={spouseLastNameRef} />

                      <div className="application-label pt-4">Phone number:</div>
                      <ApplicationSummaryInput inputValue={spousePhoneNumber} inputRef={spousePhoneNumberRef} />

                      <div className="application-label pt-4">Address line 2:</div>
                      <ApplicationSummaryInput inputValue={spouseAddressLine2} inputRef={spouseAddressLine2Ref} />

                      <div className="application-label pt-4">State:</div>
                      <ApplicationSummaryInput inputValue={spouseState} inputRef={spouseStateRef} />

                      <div className="application-label pt-4">Date of birth:</div>
                      <ApplicationSummaryInput inputValue={spouseDateOfBirth} inputRef={spouseDateOfBirthRef} />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default ApplicationReadOnlyComponent;
