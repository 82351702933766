import React from 'react';
import CurrencyInput from '../../../../application/common/CurrencyInput';

const MonthlyBackRentDue = ({
  errors, onChange, validationName, valueOverride,
}) => (
  <div className="pb-2">
    <CurrencyInput
      errors={errors}
      id="monthly-back-rent-due-input"
      label="Monthly back rent due"
      loadFrom="rentalReliefPlan"
      name="monthlyBackRentDue"
      onChange={onChange}
      maxLength={6}
      validationName={validationName}
      valueOverride={valueOverride}
    />
  </div>
);

export default MonthlyBackRentDue;
