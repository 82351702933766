import React from 'react';
import logo from '../../../assets/logos/creditgenie_logo_KO.svg';

const CreditGenieHeader = () => (
  <div className="header">
    <nav className="container flex items-center justify-between flex-wrap sm:pt-6 sm:pb-6 pt-3 pb-3 mx-auto">
      <div className="items-center sm:mr-6 sm:m-0 m-auto">
        <div className="inline-block sm:mt-0 mt-1">
          <img className="block pl-2" src={logo} alt="Credit Genie Logo Knockout" />
        </div>
      </div>
    </nav>
  </div>
);

export default CreditGenieHeader;
