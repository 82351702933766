import React from 'react';
import CurrencyInput from '../../../../application/common/CurrencyInput';

const MonthlyPlanRentAmountInput = ({
  errors, onChange, validationName, valueOverride,
}) => (
  <div className="pb-2">
    <CurrencyInput
      errors={errors}
      id="monthly-plan-rent-amount-input"
      label="Monthly plan rent amount"
      loadFrom="rentalReliefPlan"
      name="monthlyPlanRent"
      onChange={onChange}
      maxLength={6}
      validationName={validationName}
      valueOverride={valueOverride}
    />
  </div>
);

export default MonthlyPlanRentAmountInput;
