import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearSpouseAddress, setSpouseAddressToBorrowerAddress } from '../../../redux/actions/spouseActions';
import YesNoRadioButtonInput, { noValue, yesValue } from '../common/RadioButtonInput/YesNoRadioButtonInput';

const SameAddressAsAboveInput = ({ errors }) => {
  const { t: translate } = useTranslation();
  const checkedValue = useSelector((state) => {
    if (state.spouse.hasSameAddressAsBorrower === undefined) {
      return undefined;
    }

    if (state.spouse.hasSameAddressAsBorrower === false) {
      return noValue;
    }

    return yesValue;
  });

  const dispatch = useDispatch();

  const onInputValueChange = (event, valueOverride) => {
    if (valueOverride) {
      dispatch(setSpouseAddressToBorrowerAddress());
    } else {
      dispatch(clearSpouseAddress());
    }
  };

  return (
    <div className="mb-4">
      <YesNoRadioButtonInput
        checkedValue={checkedValue}
        errors={errors}
        id="same-address-as-above-input"
        name="sameAddressAsAbove"
        onChange={onInputValueChange}
        question={translate('yourcontact:sameAddressAsAbove')}
      />
    </div>
  );
};

export default SameAddressAsAboveInput;
