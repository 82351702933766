import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelRentalReliefPlanChanges,
  clearSaveRentalReliefPlanSucceeded,
  setShowRentalReliefPlanForm,
} from '../../../../../redux/actions/rentalReliefPlanActions';
import Form from '../../../../application/common/ApplicationForm/Form';
import MonthlyBackRentDue from './MonthlyBackRentDue';
import MonthlyPlanRentAmountInput from './MonthlyPlanRentAmountInput';
import MonthlyRentalAssistanceFundsInput from './MonthlyRentalAssistanceFundsInput';
import PlannedStartDateInput from './PlannedStartDateInput';
import PlannedEndDateInput from './PlannedEndDateInput';
import RentalReliefPlanEnrollmentCancelInput from '../RentalReliefPlanEnrollmentCancelInput';
import SubmitInput from '../../../../application/common/SubmitInput';
import useForm from '../../../../application/common/ApplicationForm/useForm';
import useOutsideClick from '../../../../../hooks/useOutsideClick/useOutsideClick';
import validate from '../../../../application/common/ApplicationForm/validator';

const RentalReliefEnrollmentQuestions = () => {
  const dispatch = useDispatch();
  const saveRentalReliefPlanFailed = useSelector((state) => state.rentalReliefPlan.saveFailed === true);
  const saveRentalReliefPlanSucceeded = useSelector((state) => state.rentalReliefPlan.saveSuccess === true);

  const {
    errors,
    onRentalReliefPlanChange,
    onSubmit,
  } = useForm({ validate });

  const errorMessage = saveRentalReliefPlanFailed && 'There was a problem saving the Rental Relief plan information. Please try again.';
  const formModalRef = useRef();

  useEffect(() => {
    if (saveRentalReliefPlanSucceeded) {
      dispatch(clearSaveRentalReliefPlanSucceeded());
      dispatch(setShowRentalReliefPlanForm({ showRentalReliefPlanForm: false }));
    }
  }, [dispatch, saveRentalReliefPlanSucceeded]);

  useOutsideClick(formModalRef, () => {
    dispatch(cancelRentalReliefPlanChanges());
    dispatch(setShowRentalReliefPlanForm({ showRentalReliefPlanForm: false }));
  });

  return (
    <div>
      <h2 className="text-center text-md pb-5">Rental Relief Plan Information</h2>
      <Form errorMessage={errorMessage} onSubmit={onSubmit}>
        <MonthlyPlanRentAmountInput errors={errors} onChange={onRentalReliefPlanChange} />
        <MonthlyBackRentDue errors={errors} onChange={onRentalReliefPlanChange} />
        <MonthlyRentalAssistanceFundsInput errors={errors} onChange={onRentalReliefPlanChange} />
        <PlannedStartDateInput errors={errors} onChange={onRentalReliefPlanChange} />
        <PlannedEndDateInput errors={errors} onChange={onRentalReliefPlanChange} />
        <div className="pt-2 flex">
          <div className="w-1/2">
            <RentalReliefPlanEnrollmentCancelInput />
          </div>
          <div className="w-1/2 ml-2">
            <SubmitInput />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RentalReliefEnrollmentQuestions;
