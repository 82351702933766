import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getExpenseCategoryTotals } from '../../../redux/actions/expensesActions';
import { getUniqueUnsecuredDebts } from '../../../redux/actions/debtsActions';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const FinancialBreakdown = () => {
  const application = useSelector((state) => state.application.data);
  const debts = useSelector((state) => state.debts.data);
  const estimatedMonthlyIncome = useSelector((state) => state.application.estimatedMonthlyIncome);
  const expenses = useSelector((state) => state.expenses.list);
  const monthlyIncome = application.selfReportedMonthlyCurrentIncome || application.verifiedMonthlyCurrentIncome || estimatedMonthlyIncome;
  const [minMonthlyPaymentsPerDebt, setMinMonthlyPaymentsPerDebt] = useState([]);
  const [unsecuredDebts, setUnsecuredDebts] = useState([{ minimumMonthlyInterest: 0, minimumMonthlyPrincipal: 0 }]);
  const totalMonthlyDebtPayments = parseInt(minMonthlyPaymentsPerDebt.reduce((sum, minMonthlyPayment) => sum + minMonthlyPayment, 0), 10);

  const [totalExpenses, setTotalExpenses] = useState(0);
  useEffect(() => {
    const { totalVerifiedExpenses } = getExpenseCategoryTotals({ expenses });

    setTotalExpenses(totalVerifiedExpenses);
  }, [expenses]);

  useEffect(() => {
    const uniqueUnsecuredDebts = getUniqueUnsecuredDebts({ debts });

    setUnsecuredDebts(uniqueUnsecuredDebts);
  }, [debts]);


  useEffect(() => {
    const minimumMonthlyPaymentsPerDebt = unsecuredDebts.map((unsecuredDebt) => {
      const payments = [unsecuredDebt.minimumMonthlyInterest, unsecuredDebt.minimumMonthlyPrincipal];

      return payments.reduce((acc, unsecuredDebtPayment) => acc + unsecuredDebtPayment, 0);
    });

    setMinMonthlyPaymentsPerDebt(minimumMonthlyPaymentsPerDebt);
  }, [unsecuredDebts]);

  return (
    <div className="financial-breakdown py-8 m-auto md:w-1/2 w-full text-center">
      <h3>Monthly financial breakdown</h3>
      <div className="sub-text">
        {/* TO DO - Wire up the month in which this data is being displayed */}
        August
      </div>
      <div className="flex pt-5">
        <div className="w-1/3">
          <div className="value green">
            <ValueDetail
              value={monthlyIncome}
            />
          </div>
          <div className="sub-text">
            Income
          </div>
        </div>
        <div className="w-1/3">
          <div className="value red">
            <ValueDetail
              value={totalExpenses}
            />
          </div>
          <div className="sub-text">
            Expenses
          </div>
        </div>
        <div className="w-1/3">
          <div className="value red">
            <ValueDetail
              value={totalMonthlyDebtPayments}
            />
          </div>
          <div className="sub-text">
            Debt Payments
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialBreakdown;
