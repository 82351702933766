import React from 'react';
import WhoIsCreditly from '../../../components/brochure/WhoIsCreditly';
import HardshipHowItWorks from '../../../components/brochure/HardshipHowItWorks';
import HardshipHero from '../../../components/brochure/common/HardshipHero/HardshipHero';

const HardshipHomepage = () => (
  <div className="leading-normal tracking-normal">
    <div className="w-full">
      <HardshipHero />
      <WhoIsCreditly />
      <HardshipHowItWorks />
    </div>
  </div>
);

export default HardshipHomepage;
