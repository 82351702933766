import React from 'react';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import { LenderBalance, PaymentHistory, PaymentSummary } from '../../../components/customer-portal';

const CustomerPayments = () => (
  <div className="customer-payments container m-auto pb-10 px-4">
    <h2 className="text-center">Payments Summary</h2>
    <div className="text-center lender-tile-animation-1">
      <div className="value rift-it green text-4xl">
        {formatNumberAsCurrency({ number: 16000 })}
      </div>
      <span className="sub-text -mt-2">Lifetime savings to date</span>
    </div>
    <div className="divider pb-8" />
    <div className="payment-components">
      <div className="md:flex flex-none m-auto md:w-1/2 w-full pt-8">
        <div className="md:w-1/2 w-full lender-tile-animation-2 pb-8">
          <PaymentSummary />
        </div>
        <PaymentHistory />
      </div>
    </div>
    <LenderBalance />
  </div>
);

export default CustomerPayments;
