import React from 'react';
import formatNumberAsPercentage from '../../../../formatters/percentageFormatter';

const PaymentSummaryComponent = () => (
  // TO DO - Need to make the graphic animate and populate the number of payments remaining
  <div className="payment-summary-graphic">
    <svg width="180px" height="164px" viewBox="0 0 180 164">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-117.000000, -597.000000)">
          <g transform="translate(117.000000, 509.000000)">
            <g transform="translate(0.000000, 92.000000)">
              <text fontFamily="Rift-Bold, Rift" fontSize="48" fontWeight="bold" letterSpacing="1.46666667" fill="#FFFFFF">
                <tspan x="79.4026667" y="70">8</tspan>
              </text>
              <text fontFamily="Helvetica" fontSize="15" fontWeight="normal" fill="#DBDBDB">
                <tspan x="56.652832" y="90">Payments </tspan>
                <tspan x="54.1479492" y="108">Remaining</tspan>
              </text>
              <path
                d="M90,156 C46.9361345,156 12,121.063866 12,78 C12,34.9361345 46.9361345,0 90,0 C133.063866,0 168,34.9361345 168,78"
                stroke="#4DDD8C"
                strokeWidth="8"
                strokeLinecap="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    {/* TO DO - Need to make these values dynamic based on payment data */}
    <div className="payments-summary-details pt-4">
      <div>
        Completion %:
        {' '}
        <span className="color-green">{formatNumberAsPercentage({ number: 0.74, numberOfDecimalPoints: 0 })}</span>
      </div>
      <div>
        Payments made: 24
      </div>
    </div>
  </div>
);

export default PaymentSummaryComponent;
