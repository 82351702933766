import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../components/application/common/BackButton';
import { ConfirmEmailAddressInput } from '../../../components/portal/ResetPasswordInputs';
import { forgotPassword } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(undefined);
  const hasForgottenPassword = useSelector((state) => state.user.hasForgottenPassword);

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(forgotPassword({ username: email }));
  };

  useEffect(() => {
    if (hasForgottenPassword) {
      console.log('going from /forgot-password to /please-check-your-email');
      history.push('/please-check-your-email');
    }
  });

  // TODO: Handle when forgetting the password fails.
  // TODO: Handle too many frequent requests: {
  //   code: "LimitExceededException"
  //   name: "LimitExceededException"
  //   message: "Attempt limit exceeded, please try after some time."
  // }

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <BackButton />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Forgot Password
          </h2>
          <p className="text-center">
            If you have forgotten your password, enter your e-mail address below
            {' '}
            <br />
            and we&apos;ll email you a verification code. You can use that to reset your password.
          </p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <form onSubmit={onSubmit}>
                <ConfirmEmailAddressInput onChange={(event) => { setEmail(event.target.value); }} valueOverride={email} />
                <SubmitInput />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
