import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../../../components/application/common/ProgressBar/ProgressBar';

import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveRentalApplicationSucceeded } from '../../../redux/actions/rentalApplicationActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import Form from '../../../components/application/common/ApplicationForm/Form';
import SubmitInput from '../../../components/application/common/SubmitInput';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';
import { MilitaryServiceRadioButton, WhatIsYourEthnicity, WhatIsYourRace } from '../../../components';

const PersonalInformation = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const errorMessage = 'There was a problem saving your information. Please try again.';
  const history = useHistory();
  const saveRentalApplicationFailed = useSelector((state) => state.rentalApplication.saveFailed);
  const saveRentalApplicationSucceeded = useSelector((state) => state.rentalApplication.saveSucceeded);

  const {
    errors,
    onApplicationChange,
    onRentalApplicationChange,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    if (saveRentalApplicationSucceeded) {
      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveRentalApplicationSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'PersonalInformation', lastViewedPage: 'YourContact' }));

      history.push('/application/your-contact');
    }
  });

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pb-8">
          <ProgressBar pageName="personalInformation" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center leading-normal">
            {translate('personalinformation:title')}
          </h2>
          <div className="form-width mx-auto m-8">
            <Form onSubmit={onSubmit}>
              <MilitaryServiceRadioButton errors={errors} onChange={onApplicationChange} />
              <WhatIsYourRace errors={errors} onChange={onRentalApplicationChange} />
              <WhatIsYourEthnicity errors={errors} onChange={onRentalApplicationChange} />
              <SubmitInput text={translate('global:submit:text')} />
            </Form>
          </div>
          {saveRentalApplicationFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
