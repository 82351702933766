import { getAssetsUrl } from '../../helpers/assetsHelper';

const useLogo = (lenderFriendlyName) => {
  const assetsUrl = getAssetsUrl();
  const logoSrc = lenderFriendlyName ? `${assetsUrl}/global/lenders/${lenderFriendlyName}.png` : undefined;

  return logoSrc;
};

export default useLogo;
