import { Link } from 'react-router-dom';
import React from 'react';
import CreditGenieLogo from '../../../assets/logos/creditgenie_logo_blue.svg';

const Footer = () => (
  <section className="footer bg-white py-8">
    <div className="container mx-auto mt-8 mb-8">
      <div className="ml-2 sm:ml-0">
        <img src={CreditGenieLogo} alt="Credit Genie Logo" />
        <p className="pt-2">
          Creditly Corp. copyright &copy;
          {' '}
          {(new Date()).getFullYear()}
        </p>
        <div className="border-b pb-3 w-full sm:w-1/4" />
        <ul>
          <li>
            <Link data-cy="privacy" to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link data-cy="terms" to="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link data-cy="credit-report-policy" to="/credit-report-policy" target="_blank">
              Credit Report Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Footer;
