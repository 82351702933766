import React from 'react';
import YesNoRadioButtonInput from '../common/RadioButtonInput';
import ToolTip from '../../common/ToolTip';

const DoYouHaveMortgageEscrow = ({ errors, onChange }) => (
  <div className="escrow radio-tooltip-adjusments-home w-50">
    <div className="escrow-tooltip">
      <ToolTip tooltipDescription="You would be escrowing if you pay your homeowners insurance and property taxes as part of your mortgage payment." />
    </div>
    <YesNoRadioButtonInput
      errors={errors}
      id="do-you-have-mortgage-escrow"
      loadFrom="application"
      name="hasMortgageEscrow"
      onChange={onChange}
      question="Do you escrow your homeowners insurance and taxes?"
    />
  </div>
);

export default DoYouHaveMortgageEscrow;
