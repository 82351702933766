import React from 'react';
import logo from '../../../../assets/logos/creditgenie_logo_KO.svg';

const OnBoardingHeader = () => (
  <div className="header">
    <nav className="container flex items-center justify-between flex-wrap pt-6 pb-6 mx-auto">
      <div className="sm:flex flex-none block items-center flex-no-shrink sm:mr-6 sm:m-0 m-auto">
        <img className="block pl-2" src={logo} alt="Creditly Logo Knockout" />
      </div>
    </nav>
  </div>
);

export default OnBoardingHeader;
