import React from 'react';
import PreferredLanguageRadioButtonInput from '../common/RadioButtonInput/PreferredLanguageRadioButtonInput';

const BorrowerPreferredLanguageRadioButtonInput = ({ errors, onChange }) => (
  <div>
    <PreferredLanguageRadioButtonInput
      errors={errors}
      id="what-is-your-preferred-language"
      loadFrom="borrower"
      name="preferredLanguage"
      onChange={onChange}
      question="What is your preferred language?"
    />
  </div>
);

export default BorrowerPreferredLanguageRadioButtonInput;
