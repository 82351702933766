/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  getCreditCounselingAgencyFullName, getCreditCounselingMissionStatement,
} from '../../../helpers/creditCounselingAgencyHelper';

const WhoWeAre = () => {
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();
  const creditCounselingAgencyMissionStatement = getCreditCounselingMissionStatement();

  return (
    <div className="md:w-1/2 w-full text-center m-auto py-10">
      <p className="section-header text-center w-full md:w-1/2 m-auto p-2 px-1">
        Who we are
      </p>
      <p className="px-4 pb-2">
        The National Foundation for Credit Counseling (NFCC) is the largest and longest-serving network of nonprofit financial counselors. Our mission is to help all Americans gain control over their finances.
      </p>
      <p className="px-4">
        <span className="font-bold">
          We&apos;re pleased to introduce you to
        </span>
        {' '}
        <span className="font-bold">
          {creditCounselingAgencyFullName}
        </span>
        {' '}
        a nonprofit member agency of the NFCC.
        {' '}
        <span className="font-bold">
          {creditCounselingAgencyMissionStatement}
        </span>
      </p>
    </div>
  );
};

export default WhoWeAre;
