import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { createBorrower, updateBorrower } from '../../graphql/customMutations';
import { getBorrower, listBorrowers } from '../../graphql/customQueries';
import { formatApplicationDateForAws } from '../../formatters/dateFormatter';
import { formatPhoneNumberForBorrowerAndSpouse } from '../../formatters/phoneNumberFormatter';
import getLenderFriendlyNameMap from '../../helpers/lenderFriendlyNameHelper';
import { setDebts, SET_CREDIT_REPORTS } from './debtsActions';
import { SET_DEBT_SCORES } from './debtScoreActions';
import { SET_INCOME_PREDICTIONS, SET_INCOME_SNAPSHOTS } from './incomeActions';
import { SET_PLAID_ITEMS } from './plaidActions';

export const CLEAR_ALL_BORROWERS = 'CLEAR_ALL_BORROWERS';
export const CLEAR_IS_GETTING_BORROWER_LIST = 'CLEAR_IS_GETTING_BORROWER_LIST';
export const CLEAR_SAVE_BORROWER_SUCCEEDED = 'CLEAR_SAVE_BORROWER_SUCCEEDED';
export const GET_ALL_BORROWERS = 'GET_ALL_BORROWERS';
export const GET_ALL_BORROWERS_FAIL = 'GET_ALL_BORROWERS_FAIL';
export const GET_ALL_BORROWERS_SUCCESS = 'GET_ALL_BORROWERS_SUCCESS';
export const GET_BORROWER_FOR_USER = 'GET_BORROWER_FOR_USER';
export const GET_BORROWER_FOR_USER_FAIL = 'GET_BORROWER_FOR_USER_FAIL';
export const GET_BORROWER_FOR_USER_SUCCESS = 'GET_BORROWER_FOR_USER_SUCCESS';
export const SAVE_BORROWER = 'SAVE_BORROWER';
export const SAVE_BORROWER_FAIL = 'SAVE_BORROWER_FAIL';
export const SAVE_BORROWER_SUCCESS = 'SAVE_BORROWER_SUCCESS';
export const SET_ASSIGNED_BORROWER_COUNT = 'SET_ASSIGNED_BORROWER_COUNT';
export const SET_CONTACTED_BORROWER_COUNT = 'SET_CONTACTED_BORROWER_COUNT';
export const SET_BORROWER = 'SET_BORROWER';
export const SET_BORROWER_CONSENTED_TO_CREDIT_POLICY = 'SET_BORROWER_CONSENTED_TO_CREDIT_POLICY';
export const SET_BORROWER_HAS_CHANGES = 'SET_BORROWER_HAS_CHANGES';
export const SET_SAVE_BORROWER_SUCCEEDED = 'SET_SAVE_BORROWER_SUCCEEDED';
export const UPDATE_BORROWER = 'UPDATE_BORROWER';

const clearAllBorrowers = () => ({ type: CLEAR_ALL_BORROWERS });

const clearIsGettingBorrowerList = () => ({ type: CLEAR_IS_GETTING_BORROWER_LIST });

const clearSaveBorrowerSucceeded = () => ({ type: CLEAR_SAVE_BORROWER_SUCCEEDED });

const getAllBorrowers = () => async (dispatch) => {
  dispatch({ type: GET_ALL_BORROWERS });

  const getAllBorrowersIncrementally = async ({ limit = 1000, nextToken } = {}) => {
    const nextIncrementResponse = await API.graphql(graphqlOperation(listBorrowers, { limit, nextToken }));

    const { items: borrowers, nextToken: nextIncrementNextToken } = nextIncrementResponse.data.listBorrowers;

    if (nextIncrementNextToken) {
      return [...borrowers, ...await getAllBorrowersIncrementally({ limit, nextToken: nextIncrementNextToken })];
    }

    return borrowers;
  };

  try {
    const borrowers = await getAllBorrowersIncrementally();

    borrowers.forEach((borrower, borrowerIndex) => {
      if (borrower.application) {
        const { decisions, plaidItems } = borrower.application;

        // eslint-disable-next-line prefer-destructuring
        const mostRecentDecision = decisions.items.sort((decisionA, decisionB) => new Date(decisionB.createdAt) - new Date(decisionA.createdAt))[0];

        if (mostRecentDecision) {
          mostRecentDecision.offers = mostRecentDecision.isQualified ? mostRecentDecision.offers.items : [];
        }

        borrowers[borrowerIndex].application.decision = mostRecentDecision || {};

        // plaidItem.isConnected === null signifies that the user connected a bank account before the isConnected field was added. We are assuming the
        // account is still connected unless we are notified by the Plaid Error Webhook.
        borrowers[borrowerIndex].hasConnectedAccount = plaidItems
          && plaidItems.items.some((plaidItem) => plaidItem.isConnected || plaidItem.isConnected === null);

        // We don't need this, and we can only store so much, so we should just delete it
        delete borrowers[borrowerIndex].application.decisions;
        delete borrowers[borrowerIndex].application.plaidItems;
      }

      borrowers[borrowerIndex].alerts = borrower.alerts ? borrower.alerts.items : [];
    });

    dispatch({ borrowers, type: GET_ALL_BORROWERS_SUCCESS });
  } catch (listBorrowersError) {
    console.log('listBorrowersError:', listBorrowersError);

    dispatch({ type: GET_ALL_BORROWERS_FAIL });
  }
};

const getBorrowerInformationForUser = () => async (dispatch, getState) => {
  dispatch({ type: GET_BORROWER_FOR_USER });

  const state = getState();
  const borrowerId = state.borrower.data.id;

  const filterStartDate = DateTime.fromISO(DateTime.local().minus({ months: 5 }).startOf('month')).toISO();
  const filterEndDate = DateTime.fromISO(DateTime.local().endOf('month')).toISO();
  const filterBetweenLastSixMonths = { createdAt: { between: [filterStartDate, filterEndDate] } };

  try {
    const getBorrowerResponse = await API.graphql(graphqlOperation(getBorrower, {
      borrowerId,
      incomeSnapshotsFilter: filterBetweenLastSixMonths,
    }));

    const borrower = getBorrowerResponse.data.getBorrower;
    const { spouse } = borrower;

    const lenderFriendlyNameMap = await getLenderFriendlyNameMap();

    const borrowerCreditReports = borrower.creditReports.items;
    const borrowerDebts = borrowerCreditReports.flatMap((creditReport) => creditReport.debts.items);
    const debtScores = borrower.debtScores.items;
    const incomeSnapshots = borrower.incomeSnapshots.items;
    const plaidItems = borrower.plaidItems.items;
    const plaidAccounts = plaidItems.flatMap((plaidItem) => plaidItem.plaidAccounts.items);
    const plaidTransactions = plaidAccounts.flatMap((plaidAccount) => plaidAccount.plaidTransactions.items);
    const spouseCreditReports = spouse ? spouse.creditReports.items : [];
    const spouseDebts = spouseCreditReports.flatMap((spouseCreditReport) => spouseCreditReport.debts.items);

    borrowerCreditReports.forEach((creditReport, creditReportIndex) => {
      borrowerCreditReports[creditReportIndex].debts = creditReport.debts.items;
    });

    dispatch({ creditReports: [...borrowerCreditReports, ...spouseCreditReports], type: SET_CREDIT_REPORTS });
    dispatch(setDebts({ borrowerDebts, spouseDebts, lenderFriendlyNameMap }));
    dispatch({ debtScores, type: SET_DEBT_SCORES });
    dispatch({ incomePredictions: plaidTransactions, type: SET_INCOME_PREDICTIONS });
    dispatch({ incomeSnapshots, type: SET_INCOME_SNAPSHOTS });
    dispatch({ plaidItems, type: SET_PLAID_ITEMS });

    dispatch({ type: GET_BORROWER_FOR_USER_SUCCESS });
  } catch (error) {
    console.log('failed to get borrower:', error);

    dispatch({ type: GET_BORROWER_FOR_USER_FAIL });
  }
};

const saveBorrower = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_BORROWER });

  const state = getState();

  const applicationId = state.application.data.id;
  const borrower = state.borrower.data;
  const rentalApplicationId = state.rentalApplication.data.id;
  const spouseId = state.spouse.data.id;

  const formattedDateOfBirth = formatApplicationDateForAws({ applicationDate: borrower.dateOfBirth });
  const formattedPhoneNumber = formatPhoneNumberForBorrowerAndSpouse({ phoneNumber: borrower.phoneNumber });

  const input = {
    addressLine1: borrower.addressLine1,
    addressLine2: borrower.addressLine2,
    borrowerApplicationId: applicationId,
    borrowerRentalApplicationId: rentalApplicationId,
    borrowerSpouseId: spouseId,
    city: borrower.city,
    dateOfBirth: formattedDateOfBirth,
    email: borrower.email,
    firstName: borrower.firstName,
    id: borrower.id,
    isOptedOutOfSMS: borrower.isOptedOutOfSMS,
    lastName: borrower.lastName,
    partnerCrmId: borrower.partnerCrmId,
    phoneNumber: formattedPhoneNumber,
    preferredLanguage: borrower.preferredLanguage,
    referralId: borrower.referralId,
    state: borrower.state,
    zipCode: borrower.zipCode,
  };

  const saveOperation = borrower.id ? updateBorrower : createBorrower;

  try {
    const saveBorrowerResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const borrowerId = borrower.id || saveBorrowerResponse.data.createBorrower.id;

    dispatch({ borrowerId, type: SAVE_BORROWER_SUCCESS });
  } catch (error) {
    console.log('saveBorrowerFail was called with:', error);

    dispatch({ type: SAVE_BORROWER_FAIL });
  }
};

const setBorrowerHasChanges = ({ borrowerHasChanges }) => ({ borrowerHasChanges, type: SET_BORROWER_HAS_CHANGES });

const setConsentedToCreditPolicy = ({ consentedToCreditPolicy }) => ({ consentedToCreditPolicy, type: SET_BORROWER_CONSENTED_TO_CREDIT_POLICY });

const setSaveBorrowerSucceeded = () => ({ type: SET_SAVE_BORROWER_SUCCEEDED });

const setAssignedBorrowerCount = ({ numberOfAssignedBorrowers }) => ({
  numberOfAssignedBorrowers,
  type: SET_ASSIGNED_BORROWER_COUNT,
});

const setContactedBorrowerCount = ({ numberOfContactedBorrowers }) => ({
  numberOfContactedBorrowers,
  type: SET_CONTACTED_BORROWER_COUNT,
});

const updateBorrowerInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_BORROWER,
  value,
});

export {
  clearAllBorrowers,
  clearIsGettingBorrowerList,
  clearSaveBorrowerSucceeded,
  getAllBorrowers,
  getBorrowerInformationForUser as getBorrowerForUser,
  saveBorrower,
  setAssignedBorrowerCount,
  setContactedBorrowerCount,
  setBorrowerHasChanges,
  setConsentedToCreditPolicy,
  setSaveBorrowerSucceeded,
  updateBorrowerInformation as updateBorrower,
};
