import React from 'react';
import { useTranslation } from 'react-i18next';

const VendorSharing = () => {
  const { t: translate } = useTranslation();

  return (
    <section>
      <div className="policy-tables leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
          <div className="pb-5">
            <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
              {translate('vendorsharing:title')}
            </h2>
            <p className="text-center p-5">
              {translate('vendorsharing:date')}
            </p>
          </div>
          <div className="pb-5">
            <p className="pb-5">
              {translate('vendorsharing:paragraphOne')}
            </p>
            <table>
              <thead>
                <tr>
                  <th>{translate('vendorsharing:table:col1Row1')}</th>
                  <th>{translate('vendorsharing:table:col2Row1')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translate('vendorsharing:table:col1Row2')}</td>
                  <td>{translate('vendorsharing:table:col2Row2')}</td>
                </tr>
                <tr>
                  <td>{translate('vendorsharing:table:col1Row3')}</td>
                  <td>{translate('vendorsharing:table:col2Row3')}</td>
                </tr>
                <tr>
                  <td>{translate('vendorsharing:table:col2Row4')}</td>
                  <td>{translate('vendorsharing:table:col2Row4')}</td>
                </tr>
                <tr>
                  <td>{translate('vendorsharing:table:col1Row5')}</td>
                  <td>{translate('vendorsharing:table:col2Row5')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VendorSharing;
