import React from 'react';
import EmailAddressInput from '../../application/common/EmailAddressInput';

const ConfirmEmailAddressInput = ({ onChange, valueOverride }) => (
  <section>
    <EmailAddressInput label="Confirm email address" id="confirm-email-address-input" onChange={onChange} valueOverride={valueOverride} />
  </section>
);

export default ConfirmEmailAddressInput;
