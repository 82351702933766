import React from 'react';
import { DateTime } from 'luxon';
import DateInput from '../../../../application/common/DateInput/DateInput';

const PlannedStartDateInput = (
  {
    errors = {},
    id,
    onChange,
    validationName,
  },
) => {
  const oneYearAgo = DateTime.local().minus({ years: 1 }).toFormat('MM-dd-yyyy');
  const oneYearFromNow = DateTime.local().plus({ years: 1 }).toFormat('MM-dd-yyyy');

  return (
    <div className="pb-2">
      <DateInput
        errors={errors}
        id={id}
        label="Planned start date"
        loadFrom="rentalReliefPlan"
        maxDateAllowed={oneYearFromNow}
        minDateAllowed={oneYearAgo}
        name="plannedStartDate"
        onChange={onChange}
        validationName={validationName}
      />
    </div>
  );
};

export default PlannedStartDateInput;
