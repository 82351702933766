import React from 'react';
import logo from '../../../../assets/logos/creditgenie_logo_KO.svg';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';

const Hero = () => (
  <div className="debt-analysis hero bg-cover bg-center bg-no-repeat md:bg-top" id="top-of-page">
    <div className="w-full lg:w-3/5 m-auto">
      <div className="container sm:pt-56 pt-8 m-auto sm:px-0 px-2">
        <img className="block m-auto" src={logo} alt="Credit Genie Logo Knockout" />
        <h1 className="text-white text-center pt-4 pb-4 md:text-6xl font-bold">
          Need help with your credit card bills?
        </h1>
        <h2 className="sub-headline text-white pb-10 sm:w-3/4 w-full m-auto">
          Complete a
          {' '}
          <span className="font-bold">FREE</span>
          {' '}
          debt analysis that will help you lower your debt payments today.
        </h2>
        <CallToActionButton />
      </div>
    </div>
  </div>
);

export default Hero;
