import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NFCCCoBrandLogo from '../../../assets/logos/NFCC_HPN_logo.svg';
import { getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';


const RentalFooter = () => {
  const { t: translate } = useTranslation();
  const router = useSelector((state) => state.application.data.router);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });

  return (
    <section className="rental-footer bg-white py-8 text-black">
      <div className="container mx-auto mt-8 mb-8">
        <div className="ml-2 sm:ml-0">
          <img className="nfcc-hpn-footer-logo" src={NFCCCoBrandLogo} alt="NFCC Logo" />
          <p className="pt-5 font-bold">
            {translate('global:footer:getHelpNow')}
          </p>
          <p>
            {translate('global:footer:talkToACreditCounselor')}
          </p>
          <a className="font-fold" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
      </div>
    </section>
  );
};

export default RentalFooter;
