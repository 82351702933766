import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import AlertBadge from '../../../portal/Alerts/AlertBadge';
import Chevron from '../../../../assets/icons/chevron-blue_icon.svg';
import ClaimApplicationLinkButton from '../../common/ClaimApplicationLinkButton/ClaimApplicationLinkButton';
import { getApplicationInformation } from '../../../../redux/actions/applicationActions';
import { updateDefaultSummaryTabIndex } from '../../../../redux/actions/portalActions';
import UnclaimApplicationLinkButton from '../../common/UnclaimApplicationLinkButton/UnclaimApplicationLinkButton';
import checkmark from '../../../../assets/icons/checkmark-green_icon.svg';

const BorrowerTile = (
  {
    alerts,
    applicationDate,
    applicationId,
    applicationStatus,
    applicationType,
    assignedTo,
    borrowerId,
    borrowerName,
    decision,
    enrolledInDmp,
    enrolledInRentalReliefPlan,
    hasConnectedAccount,
    lastCompletedPage,
    preferredLanguage,
    router,
  },
) => {
  const applicationDateToString = DateTime.fromISO(applicationDate).toFormat('MM-dd-yyyy hh:mm a');
  const bankConnectionStatus = hasConnectedAccount ? 'Connected' : 'Not Connected';
  const dispatch = useDispatch();
  const [internalAssignedTo, setInternalAssignedTo] = useState(assignedTo);
  const isRentalApplication = useSelector((state) => state.application.data.type === 'rental');
  const userId = useSelector((state) => state.user.data.username);
  const userList = useSelector((state) => state.user.list);
  const assignedToDetails = assignedTo ? userList.filter((user) => user.id === assignedTo) : userList.filter((user) => user.id === userId);
  const showAlertsCount = process.env.REACT_APP_SHOW_COUNSELOR_ALERTS === 'true';
  const unseenAlerts = alerts.filter((alert) => !alert.hasBeenSeenByCustomerServiceRepresentative);

  const getBorrowerSummaryTabIndex = () => {
    if (isRentalApplication || applicationStatus === 'In Progress') {
      return 0;
    }

    return 3;
  };

  const getShowDetailsText = () => {
    if (applicationStatus === 'In Progress' || !decision || !decision.isQualified || isRentalApplication) {
      return 'Review Application';
    }

    return 'Review Offers';
  };

  const onShowDetailsClick = () => {
    const borrowerSummaryTabIndex = getBorrowerSummaryTabIndex();

    dispatch(getApplicationInformation({ applicationId }));
    dispatch(updateDefaultSummaryTabIndex(borrowerSummaryTabIndex));
  };

  return (
    <section>
      <div className="container mx-auto pl-2 pr-2 pb-5 text-center w-full">
        <div
          className={`borrower-tile borrower-tile-${applicationStatus.replace(/\s/g, '-').toLowerCase()} bg-white pl-5 pr-5`}
          data-cy="borrower-tile"
        >
          <div className="flex pt-2">
            <div className="w-1/2 text-left">
              <div className="flex">
                <p className="inline-block font-bold pt-4">{borrowerName}</p>
                {showAlertsCount && unseenAlerts.length > 0 && (
                  <div className="mt-4">
                    <AlertBadge numberOfAlerts={unseenAlerts.length} />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div>
                <span className="detail-label text-right">Referred From:</span>
                <span className="detail-value font-bold text-right">{(router && router !== 'nfcc' ? startCase(router) : 'NFCC')}</span>
              </div>
              <div className="divider-vertical" />
              <div>
                <span className="detail-label text-right">Application Type:</span>
                <span className="detail-value font-bold text-right">{startCase(applicationType) || 'Credit'}</span>
              </div>
              <div className="divider-vertical" />
              <div>
                <span className="detail-label text-right">Application Status:</span>
                <span className="detail-value font-bold text-right">{applicationStatus || 'Unknown'}</span>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="w-full block pb-2 flex-none sm:flex">
            <div className="w-full sm:w-1/3">
              <p className="text-left pt-1">
                <span className="detail-label">Application Created:</span>
              </p>
              <p className="detail-value text-left">
                {applicationDate ? applicationDateToString : 'Unknown'}
              </p>
            </div>
            <div className="w-full sm:w-1/2">
              <p className="text-left pt-1">
                <span className="detail-label">Borrower ID:</span>
              </p>
              <p className="detail-value text-left">
                {borrowerId}
              </p>
            </div>
            <div className="w-1/3">
              <p className="text-left pt-1">
                <span className="detail-label">Bank Connection Status:</span>
              </p>
              <p className="text-left detail-value">
                {bankConnectionStatus}
              </p>
            </div>
          </div>
          <div className="w-full pb-2 flex -mt-2">
            <div className="w-1/4">
              <p className="text-left pt-1">
                <span className="detail-label">Assigned To:</span>
              </p>
              {
                internalAssignedTo
                  ? (
                    <div>
                      <p className="detail-value text-left">
                        {assignedToDetails && assignedToDetails[0] && assignedToDetails[0].name}
                        {(internalAssignedTo === userId) && ' (You)'}
                      </p>
                      <UnclaimApplicationLinkButton
                        additionalClasses="float-left"
                        applicationId={applicationId}
                        applicationStatus={applicationStatus}
                        onClick={() => setInternalAssignedTo(undefined)}
                      />
                    </div>
                  )
                  : (
                    <ClaimApplicationLinkButton
                      additionalClasses="float-left"
                      applicationId={applicationId}
                      applicationStatus={applicationStatus}
                      onClick={() => setInternalAssignedTo(userId)}
                    />
                  )
              }
            </div>
            <div className="w-1/4 pl-6">
              <p className="text-left pt-1">
                <span className="detail-label">Offer Status:</span>
              </p>
              <p className="detail-value text-left">
                {(decision && decision.isQualified) ? 'Has Offers' : 'No Offers'}
              </p>
            </div>
            <div className="w-1/4">
              <p className="text-left pt-1">
                <span className="detail-label">Last Completed Page:</span>
              </p>
              <p className="detail-value text-left truncate pr-4">
                {lastCompletedPage}
              </p>
            </div>
            <div className="w-1/4">
              <p className="text-left pt-1">
                <span className="detail-label">Preferred Language:</span>
              </p>
              <p className="detail-value text-left">
                {preferredLanguage || 'Unknown'}
              </p>
            </div>
          </div>
          <div className="divider" />
          <div className="flex">
            <div className="plan-enrollment-summary w-1/2 text-left">
              {(enrolledInDmp || enrolledInRentalReliefPlan) && (
                <div className="enrolled pr-5 pt-2">
                  <img className="inline-block" src={checkmark} alt="Checkmark" />
                  <div className="inline-block pl-2">{enrolledInDmp ? 'Enrolled in a DMP' : 'Enrolled in a Rental Relief Plan'}</div>
                </div>
              )}
            </div>
            <div className="borrower-details py-2 text-right w-1/2">
              <span className="flex float-right">
                <Link onClick={onShowDetailsClick} to="/borrower-summary">
                  <p className="inline-block" data-cy="review-offer-status">
                    {getShowDetailsText()}
                  </p>
                  <img className="inline-block relative pl-2" src={Chevron} alt="White Chevron Icon" />
                </Link>
              </span>
              <span className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BorrowerTile;
