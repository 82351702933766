import { nfccPhoneNumber } from '../../helpers/creditCounselingAgencyHelper';
import { UPDATE_CURRENT_PHONE_NUMBER } from '../actions/customerServiceActions';

const initialState = {
  currentPhoneNumber: nfccPhoneNumber,
};

const customerServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_PHONE_NUMBER:
      return { ...state, currentPhoneNumber: action.currentPhoneNumber };
    default:
      return state;
  }
};

export default customerServiceReducer;
