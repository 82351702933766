import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TextInput = ({
  disabled = false,
  errors = {},
  id,
  label,
  loadFrom,
  maxLength = 100,
  name,
  onBlur = () => {},
  onChange,
  placeholder = ' ',
  required = true,
  validationName = '',
  valueOverride = '',
}) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label className="input-container" htmlFor={id}>
          <input
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            type="text"
            validationname={validationName}
            value={valueOverride || value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default TextInput;
