import { getNumericValue } from '../helpers/numberHelper';

const formatNumberAsCurrency = ({
  allowNegative = false, allowZero = false, currency = 'USD', locale = 'en-US', number, numberOfDecimalPoints = 0,
}) => {
  if (!number && !(allowZero && number === 0)) {
    return '';
  }

  const numericValue = getNumericValue({ allowNegative, allowZero, nonNumericValue: number });

  if (!numericValue && !(allowZero && numericValue === 0)) {
    return '';
  }

  const numberAsCurrency = new Intl.NumberFormat(locale, {
    currency, minimumFractionDigits: numberOfDecimalPoints, maximumFractionDigits: numberOfDecimalPoints, style: 'currency',
  }).format(numericValue);

  return numberAsCurrency;
};

export default formatNumberAsCurrency;
