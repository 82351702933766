import React from 'react';
import { useSelector } from 'react-redux';
import { FinancialBreakdown, ScoreComponents } from '../../../components/customer-portal';
import DebtSummaryComponent from '../../../components/common/DebtSummaryComponent/DebtSummaryComponent';

const CustomerFinancials = () => {
  const debts = useSelector((state) => state.debts.data);
  const openDebts = debts.filter((debt) => !debt.dateClosed);

  return (
    <div className="customer-financials container m-auto pb-10 px-4">
      <ScoreComponents />
      <div className="divider" />
      <FinancialBreakdown />
      <div className="divider" />
      <div className="pt-8">
        <h3 className="text-center">Debt Summary</h3>
        <div className="sub-text text-center pb-8">
          {/* TO DO - Wire up the date in which this data is being displayed based on the most recent credit pull */}
          Last updated June 10, 2020
        </div>
        <DebtSummaryComponent debts={openDebts} showFilter={false} showSearchBar={false} />
      </div>
    </div>
  );
};

export default CustomerFinancials;
