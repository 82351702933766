import { cgCommon } from '@creditly/creditgenie-common';

export const creditGeniePhoneNumber = '+1-844-358-0403';
export const nfccPhoneNumber = '+1-844-865-2461';

const { phoneNumberService, counselorGenieStages } = cgCommon;

const creditCounselingAgencyInformation = {
  afs: {
    disclosureName: 'North Seattle Community College Foundation dba American Financial Solutions',
    fullName: 'American Financial Solutions',
    missionStatement: '',
  },
  apprisen: {
    disclosureName: 'Consumer Credit Counseling Service of the Midwest, Inc.',
    fullName: 'Apprisen',
    missionStatement: '',
  },
  cambridge: {
    disclosureName: 'Cambridge Credit Counseling Corp.',
    fullName: 'Cambridge',
    missionStatement: '',
  },
  cccsmd: {
    disclosureName: 'Consumer Credit Counseling Service of Maryland and Delaware, Inc.',
    fullName: 'Consumer Credit Counseling Service of Maryland and Delaware',
    missionStatement: '',
  },
  cesi: {
    disclosureName: 'Cesi - Consumer Education Services, Inc.',
    fullName: 'Cesi - Consumer Education Services, Inc.',
    missionStatement: 'Consumer Education Services, Inc. empowers people to overcome their financial challenges and lead financially-healthy lives.',
  },
  creditgenie: {
    disclosureName: 'Creditly Corp.',
    fullName: 'Creditly Corp.',
    missionStatement: '',
    preCreditPullPhoneNumber: creditGeniePhoneNumber,
    postCreditPullPhoneNumber: creditGeniePhoneNumber,
  },
  creditorg: {
    disclosureName: 'Credit.org',
    fullName: 'Credit.org',
  },
  lssminn: {
    disclosureName: 'Lutheran Social Service of Minnesota',
    fullName: 'Lutheran Social Service of Minnesota',
    missionStatement: '',
  },
  navicore: {
    disclosureName: 'Garden State Consumer Credit Counseling, Inc. d/b/a Navicore Solutions',
    fullName: 'Navicore Solutions',
    missionStatement: '',
  },
};

const getCreditCounselingAgencyName = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  switch (environment) {
    case 'afs':
      return 'AFS';

    case 'apprisen':
      return 'Apprisen';

    case 'cambridge':
      return 'Cambridge';

    case 'cccsmd':
      return 'CCCSMD';

    case 'cesi':
      return 'CESI';

    case 'creditorg':
      return 'Creditorg';

    case 'lssminn':
      return 'LSSMinn';

    case 'navicore':
      return 'Navicore';

    default:
      return 'CreditGenie';
  }
};

const getCreditCounselingAgencyDisclosureName = () => {
  const agencyName = getCreditCounselingAgencyName().toLowerCase();

  const { disclosureName } = creditCounselingAgencyInformation[agencyName];

  return disclosureName;
};

const getCreditCounselingAgencyFullName = () => {
  const agencyName = getCreditCounselingAgencyName().toLowerCase();

  const { fullName } = creditCounselingAgencyInformation[agencyName];

  return fullName;
};

const getCreditCounselingMissionStatement = () => {
  const agencyName = getCreditCounselingAgencyName().toLowerCase();

  const { missionStatement } = creditCounselingAgencyInformation[agencyName];

  return missionStatement;
};

const getCreditCounselingAgencyPhoneNumber = ({ isPreLead = false, router }) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const stage = isPreLead ? counselorGenieStages.preLead : counselorGenieStages.postLead;

  const phoneNumber = phoneNumberService.getCounselorGeniePhoneNumber(router, env, stage);

  return phoneNumber;
};

const getCreditCounselingAgencyPreLeadPhoneNumber = ({ router }) => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  const phoneNumber = phoneNumberService.getCounselorGeniePhoneNumber(router, env, counselorGenieStages.preLead);

  return phoneNumber;
};

const getCreditCounselingAgencyPostLeadPhoneNumber = ({ router }) => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  const phoneNumber = phoneNumberService.getCounselorGeniePhoneNumber(router, env, counselorGenieStages.postLead);

  return phoneNumber;
};

export {
  getCreditCounselingAgencyDisclosureName,
  getCreditCounselingAgencyFullName,
  getCreditCounselingMissionStatement,
  getCreditCounselingAgencyPhoneNumber,
  getCreditCounselingAgencyPreLeadPhoneNumber,
  getCreditCounselingAgencyPostLeadPhoneNumber,
  getCreditCounselingAgencyName,
};
