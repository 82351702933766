import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CommentBoxTextAreaInput = (
  {
    cols = 50,
    errors = {},
    id,
    label,
    loadFrom,
    maxLength = 245,
    name,
    onBlur = () => {},
    onChange,
    placeholder = ' ',
    required = true,
    rows = 5,
    validationName = '',
    valueOverride = '',
  },
) => {
  const { t: translate } = useTranslation();
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  return (
    <div className="comment-box-text-area-input w-full m-auto">
      <div className="mb-4">
        <label className="input-container text-area" htmlFor={id}>
          <textarea
            cols={cols}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            rows={rows}
            validationname={validationName}
            value={valueOverride || value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default CommentBoxTextAreaInput;
