import React from 'react';
import { startCase } from 'lodash';
import SummaryFilterCheckbox from '../SummaryFilterCheckbox/SummaryFilterCheckbox';

const SummaryFilterCategory = (
  {
    filterKeys,
    filterState,
    filterErrors,
    onFilterChange,
    propertyName,
    tempFilterState,
    title,
  },
) => (
  <div className="filter-group">
    <div className="filter-title">
      <h2>{title}</h2>
    </div>
    <div className="flex flex-wrap mb-4">
      {filterKeys.map((filterKey) => (
        <div className="w-1/2 h-12" key={`${filterKey.replace(/\s+/g, '-')}-filter`}>
          <SummaryFilterCheckbox
            filterCategory={propertyName}
            filterState={filterState[propertyName]}
            id={`${filterKey.replace(/\s+/g, '-')}-filter`}
            label={startCase(filterKey)}
            name={filterKey}
            onChange={onFilterChange}
            tempFilterState={tempFilterState[propertyName]}
          />
        </div>
      ))}
      {
        filterErrors[propertyName] === 'required'
        && <p className="failure filter-failure w-full" id="required-error">Please select at least one filter</p>
      }
    </div>
    <hr />
  </div>
);

export default SummaryFilterCategory;
