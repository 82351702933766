import React from 'react';
import FirstNameTextInput from '../FirstNameTextInput';

const SpouseFirstNameTextInput = ({
  errors, label, onBlur, onChange,
}) => (
  <FirstNameTextInput
    errors={errors}
    id="spouse-first-name-input"
    label={label}
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseFirstName"
  />
);

export default SpouseFirstNameTextInput;
