/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debtIcon from '../../../assets/icons/rental/credit-card-blue_icon.svg';
import houseIcon from '../../../assets/icons/rental/house-blue_icon.svg';
import paperIcon from '../../../assets/icons/rental/paper-blue_icon.svg';
import phoneIcon from '../../../assets/icons/phone_icon.svg';
import CreditCounselingAgencyLogo from '../../../components/common/CreditCounselingAgencyLogo/CreditCounselingAgencyLogo';
import {
  getCreditCounselingAgencyDisclosureName,
  getCreditCounselingAgencyFullName,
  getCreditCounselingAgencyPhoneNumber,
} from '../../../helpers/creditCounselingAgencyHelper';

import wellsFargoLogo from '../../../assets/logos/wells-fargo_square_logo.jpg';

const RentalApplicationCallUs = () => {
  const { t: translate } = useTranslation();
  const creditCounselingAgencyDisclosureName = getCreditCounselingAgencyDisclosureName();
  const creditCounselingAgencyName = getCreditCounselingAgencyFullName();
  const decision = useSelector((state) => state.decision.data);
  const owesBackRent = useSelector((state) => state.rentalApplication.data.backRentAmount > 0);
  const router = useSelector((state) => state.application.data.router);
  const troublePayingRentHardship = useSelector((state) => state.hardships.data.find((hardship) => hardship.key === 'hasTroubleMakingRentPayments'));

  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });
  const isHavingTroublePayingRent = troublePayingRentHardship && troublePayingRentHardship.value;

  return (
    <div className="pending">
      <div className="leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pt-10 pb-10 text-center w-full">
          <div>
            <p className="w-full sm:w-1/3 m-auto text-md sm:text-xl font-bold">
              {translate('rentalcallus:title')}
            </p>
            <div className="credit-counseling-section">
              <p className="pt-4 font-bold text-md sm:text-xl ">
                {translate('rentalcallus:nextStep')}
              </p>
              <p className="w-full sm:w-1/3 m-auto py-4 text-md sm:text-xl">
                <img src={phoneIcon} alt="Phone icon" className="m-auto pb-2" />
                <span className="font-bold">
                  <a href={`tel:${phoneNumber}`} className="offer-phone-numbers">{phoneNumber}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white pb-40">
        <div className="container pt-8 m-auto text-center">
          <p className="w-full sm:w-1/2 m-auto sm:py-4">
            {translate('rentalcallus:basedOn')}
          </p>
          <ul className="font-bold">
            {
              (isHavingTroublePayingRent || owesBackRent) && (
                <li>
                  <img className="block m-auto" src={houseIcon} alt="Blue House" />
                  {translate('rentalcallus:housing')}
                </li>
              )
            }
            {
              decision.isQualified && (
                <li>
                  <img className="block m-auto pt-4" src={debtIcon} alt="Blue Credit Cards" />
                  {translate('rentalcallus:debt')}
                </li>
              )
            }
            <li>
              <img className="block m-auto pt-4" src={paperIcon} alt="Blue Paper" />
              {translate('rentalcallus:budget')}
            </li>
          </ul>
          <div className="w-full sm:w-1/2 m-auto pt-16 pb-8">
            <p>
              {translate('rentalcallus:callACounselor')}
              {' '}
              <span className="font-bold">{creditCounselingAgencyName}</span>
              <sup>*</sup> {translate('rentalcallus:at')}
              {' '}
              <a href={`tel:${phoneNumber}`} className="offer-phone-numbers font-bold">{phoneNumber}</a> {translate('rentalcallus:toTakeNextStep')}
            </p>
            <p className="font-bold mt-2">
              {translate('rentalcallus:translationServicesAvailable')}
            </p>
            <CreditCounselingAgencyLogo />
          </div>
          <p className="text-xs">
            <sup>*</sup>
            {`${creditCounselingAgencyDisclosureName} ${translate('rentalcallus:agencyDisclosure')}`}
          </p>
          <div className="mt-8">
            <p className="font-bold">
              {translate('rentalcallus:sponsoredBy')}
            </p>
            <img className="block m-auto" src={wellsFargoLogo} alt="Wells Fargo Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalApplicationCallUs;
