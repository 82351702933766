/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router-dom';
import LandingPageLayout from './LandingPageLayout';
import {
  RenterAdvantage,
} from '../pages/landing-pages';

import {
  RentalFooter,
} from '../components';

const RentalLandingPageLayout = () => {
  const rentalLandingPageRoutes = [
    <Route component={RenterAdvantage} key="renter-advantage-page" path="/renter-advantage" />,
  ];

  const rentalFooter = <RentalFooter />;

  return <LandingPageLayout environmentSpecificRoutes={rentalLandingPageRoutes} environmentSpecificFooter={rentalFooter} />;
};

export default RentalLandingPageLayout;
