import React from 'react';
import PasswordInput from '../../application/common/PasswordInput';

const PasswordSignInInput = ({ id, onChange }) => (
  <div>
    <PasswordInput
      id={id}
      label="Password"
      maxLength={60}
      onChange={onChange}
    />
  </div>
);

export default PasswordSignInInput;
