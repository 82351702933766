import {
  CLEAR_ALL_BORROWERS, CLEAR_IS_GETTING_BORROWER_LIST, CLEAR_SAVE_BORROWER_SUCCEEDED,
  GET_ALL_BORROWERS, GET_ALL_BORROWERS_FAIL, GET_ALL_BORROWERS_SUCCESS,
  GET_BORROWER_FOR_USER, GET_BORROWER_FOR_USER_FAIL, GET_BORROWER_FOR_USER_SUCCESS,
  SAVE_BORROWER, SAVE_BORROWER_FAIL, SAVE_BORROWER_SUCCESS,
  SET_ASSIGNED_BORROWER_COUNT, SET_CONTACTED_BORROWER_COUNT,
  SET_BORROWER,
  SET_BORROWER_CONSENTED_TO_CREDIT_POLICY,
  SET_BORROWER_HAS_CHANGES,
  SET_SAVE_BORROWER_SUCCEEDED,
  UPDATE_BORROWER,
} from '../actions/borrowerActions';
import { formatAwsDateForApplication } from '../../formatters/dateFormatter';

const initialState = {
  consentedToCreditPolicy: false,
  data: {
    // unique identifier
    id: undefined,

    // the owner of the data
    owner: undefined,

    // Borrower properties
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    dateOfBirth: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    preferredLanguage: undefined,
    referralId: undefined,
    state: undefined,
    zipCode: undefined,

    // Borrower -> Application relationship link
    borrowerApplicationId: undefined,

    // Borrower -> Rental Application relationship link
    borrowerRentalApplicationId: undefined,

    // Borrower -> Spouse relationship link
    borrowerSpouseId: undefined,
  },
  assignedBorrowersCount: 0,
  contactedBorrowersCount: 0,
  hasChanges: false,
  hasGottenBorrower: undefined,
  hasGottenBorrowerForUser: undefined,
  hasGottenBorrowerList: undefined,
  isGettingBorrower: undefined,
  isGettingBorrowerForUser: undefined,
  isGettingBorrowerList: undefined,
  isSaving: undefined,
  list: [],
  saveFailed: undefined,
  saveSuccess: undefined,
};

const borrowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL_BORROWERS:
      return {
        ...state, hasGottenBorrowerList: undefined, isGettingBorrowerList: undefined, list: [],
      };

    case CLEAR_IS_GETTING_BORROWER_LIST:
      return { ...state, isGettingBorrowerList: undefined };

    case CLEAR_SAVE_BORROWER_SUCCEEDED:
      return { ...state, saveSuccess: undefined };

    case GET_ALL_BORROWERS:
      return {
        ...state, hasGottenBorrowerList: false, isGettingBorrowerList: true, list: [],
      };

    case GET_ALL_BORROWERS_FAIL:
      return { ...state, hasGottenBorrowerList: true, isGettingBorrowerList: false };

    case GET_ALL_BORROWERS_SUCCESS:
      return {
        ...state, hasGottenBorrowerList: true, isGettingBorrowerList: false, list: action.borrowers,
      };

    case GET_BORROWER_FOR_USER:
      return {
        ...state,
        isGettingBorrowerForUser: true,
        hasGottenBorrowerForUser: undefined,
      };

    case GET_BORROWER_FOR_USER_FAIL:
      return {
        ...state,
        isGettingBorrowerForUser: false,
        hasGottenBorrowerForUser: false,
      };

    case GET_BORROWER_FOR_USER_SUCCESS:
      return {
        ...state,
        isGettingBorrowerForUser: false,
        hasGottenBorrowerForUser: true,
      };

    case SET_BORROWER: {
      const propertiesToKeep = Object.keys(initialState.data);

      Object.keys(action.borrower).forEach((property) => {
        if (!propertiesToKeep.includes(property)) {
          // delete the relational objects the database provided (e.g. application, spouse, etc.)
          // eslint-disable-next-line no-param-reassign
          delete action.borrower[property];
        }
      });

      const dateOfBirth = formatAwsDateForApplication({ awsDate: action.borrower.dateOfBirth });

      return {
        ...state,
        data: { ...action.borrower, dateOfBirth },
        hasGottenBorrower: true,
        isGettingBorrower: false,
      };
    }

    case SAVE_BORROWER:
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
      };

    case SAVE_BORROWER_FAIL: {
      return {
        ...state, isSaving: false, saveFailed: true, saveSuccess: false,
      };
    }
    case SAVE_BORROWER_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, id: action.borrowerId },
        hasChanges: false,
        isSaving: false,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SET_ASSIGNED_BORROWER_COUNT: {
      return { ...state, assignedBorrowerCount: action.numberOfAssignedBorrowers };
    }

    case SET_CONTACTED_BORROWER_COUNT: {
      return { ...state, contactedBorrowerCount: action.numberOfContactedBorrowers };
    }

    case SET_BORROWER_CONSENTED_TO_CREDIT_POLICY:
      return { ...state, consentedToCreditPolicy: action.consentedToCreditPolicy };

    case SET_BORROWER_HAS_CHANGES:
      return { ...state, hasChanges: action.borrowerHasChanges };

    case SET_SAVE_BORROWER_SUCCEEDED:
      return { ...state, saveSuccess: true };

    case UPDATE_BORROWER:
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };

    default:
      return state;
  }
};

export default borrowerReducer;
