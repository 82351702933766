import React, { useRef, useState } from 'react';
import CurrencyOfferDetail from '../../../pages/offers/OfferDetail/CurrencyOfferDetail';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import PercentageOfferDetail from '../../../pages/offers/OfferDetail/PercentageOfferDetail';
import OfferDetailNoFormat from '../../../pages/offers/OfferDetail/OfferDetailNoFormat';
import LenderLogo from '../../common/LenderLogo/LenderLogo';
import useOutsideClick from '../../../hooks/useOutsideClick/useOutsideClick';

const LenderOfferTile = ({ offer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const showDetailsRef = useRef();
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const estimatedMonthlyCollectionPayment = (offer.collection && offer.collection.currentBalance * 0.03) || 0;
  const estimatedMonthlyDebtPayment = (offer.debt && (offer.debt.scheduledMonthlyPayment || offer.debt.estimatedMonthlyPayment)) || 0;
  const estimatedMonthlyPayment = estimatedMonthlyCollectionPayment || estimatedMonthlyDebtPayment;
  const estimatedSavings = Math.round(estimatedMonthlyPayment) - Math.round(offer.payment);
  const lenderName = (offer.debt && offer.debt.lenderName) || (offer.collection && offer.collection.subscriberName);
  const lenderFriendlyName = (offer.debt && offer.debt.lenderFriendlyName) || (offer.collection && offer.collection.lenderFriendlyName);
  const offerType = (offer.debt && 'Debt') || (offer.collection && 'Collections');

  useOutsideClick(showDetailsRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="w-full">
      <div className="lender-offer-tile m-auto">
        <div className="lender flex">
          <div className="pl-4 mt-2 w-1/2 font-bold">
            <LenderLogo
              lenderFriendlyName={lenderFriendlyName}
              lenderName={lenderName}
            />
          </div>
          <div className="w-1/2 text-right pr-4 my-auto">
            {offerType}
          </div>
        </div>
        <div className="flex lender-tile-container m-auto">
          <div className="w-1/2 text-left estimated-payment-amount">
            <div className="name-detail">
              Est. Monthly Payment
            </div>
            <div className="value" data-cy="est-monthly-payment">
              {formatNumberAsCurrency({ allowZero: true, number: Math.round(estimatedMonthlyPayment) })}
            </div>
          </div>
          <div className="w-1/2 text-right new-payment-amount">
            <div className="name-detail">
              New Monthly Payment
            </div>
            <div className="value" data-cy="new-monthly-payment">
              {formatNumberAsCurrency({ allowZero: true, number: Math.round(offer.payment) })}
            </div>
          </div>
        </div>
        <div className="lender-details pt-8">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <p
            className="cursor-pointer text-right details-toggleIsOpen color-blue label py-2 pr-4"
            onClick={toggleIsOpen}
            onKeyDown={toggleIsOpen}
            ref={showDetailsRef}
          >
            {isOpen ? <span>Hide details</span> : <span>Show details</span>}
          </p>
          <div role="button" tabIndex="0" className={isOpen ? 'details active p-4 lender-tile-animation-1' : 'details'}>
            <div className="pt-6">
              <div className="w-full">
                <PercentageOfferDetail name="Estimated APR" value={offer.currentApr} />
                <PercentageOfferDetail name="New APR" value={offer.proposedApr} />
                <OfferDetailNoFormat name="Debt Relief Term" value={`${offer.term} months`} />
                <CurrencyOfferDetail name="Total Balance" value={Math.round(offer.balance)} />
                <CurrencyOfferDetail name="Estimated Savings" value={estimatedSavings} />
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    </div>
  );
};

export default LenderOfferTile;
