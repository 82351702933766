import React from 'react';
import { useSelector } from 'react-redux';
import DoYouOweBackRent from './DoYouOweBackRent';
import BackRentAmountInput from './BackRentAmountInput';

const ReasonForNotConnectingAccountComponent = ({ errors, onChange }) => {
  const hasBackRent = useSelector((state) => state.rentalApplication.data.hasBackRent);

  return (
    <div>
      <DoYouOweBackRent errors={errors} onChange={onChange} />
      {hasBackRent && <BackRentAmountInput errors={errors} onChange={onChange} />}
    </div>
  );
};

export default ReasonForNotConnectingAccountComponent;
