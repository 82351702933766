import React from 'react';

const LinkButton = ({ additionalClasses, onClick, text }) => {
  const defaultClasses = 'background-transparent color-blue focus:outline-none font-bold';

  return (
    <button
      className={additionalClasses ? `${defaultClasses} ${additionalClasses}` : `${defaultClasses}`}
      onClick={onClick}
      type="button"
    >
      {text}
    </button>
  );
};

export default LinkButton;
