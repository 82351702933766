import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentBoxTextAreaInput from '../common/CommentBoxTextAreaInput/CommentBoxTextAreaInput';
import { setOtherReasonForNotConnectingAccount } from '../../../redux/actions/applicationActions';

const OtherReasonForNotConnectingAccountTextInput = ({ errors, onBlur, onChange }) => {
  const dispatch = useDispatch();
  const otherReasonForNotConnectingAccount = useSelector((state) => state.application.otherReasonForNotConnectingAccount);

  const onInputBlur = (event) => {
    const { value } = event.target;

    const trimmedValue = value.trim();

    dispatch(setOtherReasonForNotConnectingAccount({ value: trimmedValue }));

    onBlur(event, trimmedValue || 'Other');
  };

  const onInputChange = (event) => {
    const { value } = event.target;

    dispatch(setOtherReasonForNotConnectingAccount({ value }));

    onChange(event, value || 'Other');
  };

  return (
    <CommentBoxTextAreaInput
      errors={errors}
      id="other-reason-for-not-connecting-account-input"
      label="Other Reason"
      name="reasonForNotConnectingBank"
      onBlur={onInputBlur}
      onChange={onInputChange}
      validationName="otherReasonForNotConnectingAccount"
      valueOverride={otherReasonForNotConnectingAccount}
    />
  );
};

export default OtherReasonForNotConnectingAccountTextInput;
