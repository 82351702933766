import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import formatNumberAsPercentage from '../../../formatters/percentageFormatter';
import { getNumericValue } from '../../../helpers/numberHelper';

export const currencyOfferDetailFormat = {
  getFormattedValue: (unformattedValue) => formatNumberAsCurrency({ allowZero: true, number: unformattedValue, numberOfDecimalPoints: 0 }),
  name: 'currency',
};

export const numberOfferDetailFormat = {
  getFormattedValue: (unformattedValue) => getNumericValue({ allowZero: true, nonNumericValue: unformattedValue }),
  name: 'number',
};

export const percentageOfferDetailFormat = {
  getFormattedValue: (unformattedValue) => formatNumberAsPercentage({ number: unformattedValue, numberOfDecimalPoints: 2 }),
  name: 'percentage',
};
