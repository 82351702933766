import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getApplicationWithExpenses } from '../../graphql/customQueries';
import { updateExpense } from '../../graphql/customMutations';

export const CANCEL_EXPENSE_VALUE_CHANGE = 'CANCEL_EXPENSE_VALUE_CHANGE';
export const CHANGE_EXPENSE_VALUE = 'CHANGE_EXPENSE_VALUE';
export const GET_EXPENSE_ESTIMATES = 'GET_EXPENSE_ESTIMATES';
export const GET_EXPENSE_ESTIMATES_FAIL = 'GET_EXPENSE_ESTIMATES_FAIL';
export const GET_EXPENSE_ESTIMATES_SUCCESS = 'GET_EXPENSE_ESTIMATES_SUCCESS';
export const GET_EXPENSES_BY_APPLICATION_ID = 'GET_EXPENSES_BY_APPLICATION_ID';
export const GET_EXPENSES_BY_APPLICATION_ID_FAIL = 'GET_EXPENSES_BY_APPLICATION_ID_FAIL';
export const GET_EXPENSES_BY_APPLICATION_ID_SUCCESS = 'GET_EXPENSES_BY_APPLICATION_ID_SUCCESS';
export const SAVE_EXPENSES = 'SAVE_EXPENSES';
export const SAVE_EXPENSES_FAIL = 'SAVE_EXPENSES_FAIL';
export const SAVE_EXPENSES_SUCCESS = 'SAVE_EXPENSES_SUCCESS';
export const SET_EXPENSE_LIST = 'SET_EXPENSE_LIST';
export const SUBMIT_EXPENSE_VALUE_CHANGE = 'SUBMIT_EXPENSE_VALUE_CHANGE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';

const cancelExpenseValueChange = ({ category, subCategory }) => ({ category, subCategory, type: CANCEL_EXPENSE_VALUE_CHANGE });

const changeExpenseValue = ({ category, subCategory, value }) => (
  {
    category,
    subCategory,
    type: CHANGE_EXPENSE_VALUE,
    value,
  }
);

const convertExpenseArrayToExpensesObject = ({ expenseArray, useEstimatedValues = false }) => {
  const expenses = {
    food: {
      away: 0,
      home: 0,
    },
    healthcare: {
      drugs: 0,
      healthInsurance: 0,
      lifeInsurance: 0,
      medicalServices: 0,
    },
    housing: {
      hoa: 0,
      insurance: 0,
      maintenanceImprovements: 0,
      mortgage: 0,
      rent: 0,
      taxes: 0,
    },
    miscellaneous: {
      alimony: 0,
      childcare: 0,
      childSupport: 0,
      education: 0,
      other: 0,
      pets: 0,
    },
    otherDebts: {
      backtaxes: 0,
      friendsFamily: 0,
      medical: 0,
      other: 0,
    },
    personal: {
      apparel: 0,
      entertainment: 0,
      personalCare: 0,
    },
    savings: {
      donations: 0,
      savings: 0,
    },
    studentLoans: {
      studentLoans: 0,
    },
    transportation: {
      carPayment: 0,
      gas: 0,
      insurance: 0,
      maintenanceRepairs: 0,
      publicTransportation: 0,
    },
    utilities: {
      cableInternetLandline: 0,
      electricity: 0,
      gas: 0,
      water: 0,
      wireless: 0,
    },
  };

  expenseArray.forEach((expense) => {
    const {
      category,
      estimatedValue,
      selfReportedValue,
      subCategory,
    } = expense;

    expenses[category][subCategory] = (useEstimatedValues ? estimatedValue : selfReportedValue) || 0;
  });

  return expenses;
};

const getExpenseEstimates = () => async (dispatch, getState) => {
  dispatch({ type: GET_EXPENSE_ESTIMATES });

  const state = getState();

  try {
    const application = state.application.data;
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const rentalApplication = state.rentalApplication.data;

    const expenseEstimates = await API.post('expenses', '/expenses/estimate', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: application.id,
      },
    });

    if (!application.isHomeowner) {
      const indexOfRent = expenseEstimates.findIndex((expense) => expense.category === 'housing' && expense.subCategory === 'rent');

      expenseEstimates[indexOfRent].selfReportedValue = application.monthlyRentOrMortgageAmount || rentalApplication.monthlyRentPayment;
    }

    if (application.totalMedicalDebtAmount > 0) {
      const indexOfMedicalDebt = expenseEstimates.findIndex((expense) => expense.category === 'otherDebts' && expense.subCategory === 'medical');

      expenseEstimates[indexOfMedicalDebt].selfReportedValue = application.totalMedicalDebtAmount;
    }

    dispatch({ expenseEstimates, type: GET_EXPENSE_ESTIMATES_SUCCESS });
  } catch (error) {
    console.log('error getting expense estimates:', error);

    dispatch({ type: GET_EXPENSE_ESTIMATES_FAIL });
  }
};

const getIndividualExpenses = ({ expenses }) => {
  const { alimony } = expenses.miscellaneous;
  const backTaxes = expenses.otherDebts.backtaxes;
  const { cableInternetLandline } = expenses.utilities;
  const carInsurance = expenses.transportation.insurance;
  const { carPayment } = expenses.transportation;
  const carRepairs = expenses.transportation.maintenanceRepairs;
  const { childcare } = expenses.miscellaneous;
  const { childSupport } = expenses.miscellaneous;
  const clothing = expenses.personal.apparel;
  const { donations } = expenses.savings;
  const { education } = expenses.miscellaneous;
  const { electricity } = expenses.utilities;
  const { entertainment } = expenses.personal;
  const friendsAndFamily = expenses.otherDebts.friendsFamily;
  const { gas } = expenses.utilities;
  const gasAndTolls = expenses.transportation.gas;
  const groceries = expenses.food.home;
  const { healthInsurance } = expenses.healthcare;
  const hoaFees = expenses.housing.hoa;
  const homeownersInsurance = expenses.housing.insurance;
  const homeRepairs = expenses.housing.maintenanceImprovements;
  const { lifeInsurance } = expenses.healthcare;
  const medicalDebt = expenses.otherDebts.medical;
  const { mortgage } = expenses.housing;
  const otherDebt = expenses.otherDebts.other;
  const otherMiscellaneous = expenses.miscellaneous.other;
  const { personalCare } = expenses.personal;
  const petcare = expenses.miscellaneous.pets;
  const prescriptionDrugs = expenses.healthcare.drugs;
  const propertyTaxes = expenses.housing.taxes;
  const { publicTransportation } = expenses.transportation;
  const { rent } = expenses.housing;
  const rentersInsurance = expenses.housing.insurance;
  const restaurants = expenses.food.away;
  const { savings } = expenses.savings;
  const servicesAndAppointments = expenses.healthcare.medicalServices;
  const studentLoanPayment = expenses.studentLoans.studentLoans;
  const { water } = expenses.utilities;
  const wirelessPhone = expenses.utilities.wireless;

  return {
    alimony,
    backTaxes,
    cableInternetLandline,
    carInsurance,
    carPayment,
    carRepairs,
    childcare,
    childSupport,
    clothing,
    donations,
    education,
    electricity,
    entertainment,
    friendsAndFamily,
    gas,
    gasAndTolls,
    groceries,
    healthInsurance,
    hoaFees,
    homeownersInsurance,
    homeRepairs,
    lifeInsurance,
    medicalDebt,
    mortgage,
    otherDebt,
    otherMiscellaneous,
    personalCare,
    petcare,
    prescriptionDrugs,
    propertyTaxes,
    publicTransportation,
    rent,
    rentersInsurance,
    restaurants,
    savings,
    servicesAndAppointments,
    studentLoanPayment,
    water,
    wirelessPhone,
  };
};

const getExpenseCategoryTotals = ({ expenses }) => {
  const {
    alimony,
    backTaxes,
    cableInternetLandline,
    carInsurance,
    carPayment,
    carRepairs,
    childcare,
    childSupport,
    clothing,
    donations,
    education,
    electricity,
    entertainment,
    friendsAndFamily,
    gas,
    gasAndTolls,
    groceries,
    healthInsurance,
    hoaFees,
    homeownersInsurance,
    homeRepairs,
    lifeInsurance,
    medicalDebt,
    mortgage,
    otherDebt,
    otherMiscellaneous,
    personalCare,
    petcare,
    prescriptionDrugs,
    propertyTaxes,
    publicTransportation,
    rent,
    rentersInsurance,
    restaurants,
    savings,
    servicesAndAppointments,
    water,
    wirelessPhone,
  } = getIndividualExpenses({ expenses });

  const totalFoodExpenses = groceries + restaurants;
  const totalHomeOwnerExpenses = hoaFees + homeownersInsurance + homeRepairs + mortgage + propertyTaxes;
  const totalHomeRentalExpenses = rent + rentersInsurance;
  const totalMedicalExpenses = prescriptionDrugs + healthInsurance + lifeInsurance + servicesAndAppointments;
  const totalMiscExpenses = childcare + education + petcare + childSupport + alimony + otherMiscellaneous;
  const totalOtherDebts = medicalDebt + friendsAndFamily + backTaxes + otherDebt;
  const totalPersonalExpenses = entertainment + clothing + personalCare;
  const totalSavingsAndDonations = savings + donations;
  const totalTransportationExpenses = carPayment + gasAndTolls + carInsurance + carRepairs + publicTransportation;
  const totalUtilitiesExpenses = cableInternetLandline + electricity + gas + water + wirelessPhone;

  const totalVerifiedExpenses = totalFoodExpenses + totalHomeOwnerExpenses + totalHomeRentalExpenses + totalMedicalExpenses
    + totalMiscExpenses + totalOtherDebts + totalPersonalExpenses + totalSavingsAndDonations + totalTransportationExpenses + totalUtilitiesExpenses;

  return {
    totalFoodExpenses,
    totalHomeOwnerExpenses,
    totalHomeRentalExpenses,
    totalMedicalExpenses,
    totalMiscExpenses,
    totalOtherDebts,
    totalPersonalExpenses,
    totalSavingsAndDonations,
    totalTransportationExpenses,
    totalUtilitiesExpenses,
    totalVerifiedExpenses,
  };
};

const getExpenseEstimatesByApplicationId = ({ applicationId }) => async (dispatch) => {
  dispatch({ type: GET_EXPENSE_ESTIMATES });

  try {
    const applicationWithExpenses = await API.graphql(graphqlOperation(getApplicationWithExpenses, { applicationId, limit: 1000 }));

    const expenseList = applicationWithExpenses.data.getApplication.expenses.items;

    dispatch({ expenseEstimates: expenseList, type: GET_EXPENSE_ESTIMATES_SUCCESS });
  } catch (error) {
    console.log('failed to get expense estimates:', error);

    dispatch({ type: GET_EXPENSE_ESTIMATES_FAIL });
  }
};

const getExpensesByApplicationId = ({ applicationId }) => async (dispatch) => {
  dispatch({ type: GET_EXPENSES_BY_APPLICATION_ID });

  try {
    const applicationWithExpenses = await API.graphql(graphqlOperation(getApplicationWithExpenses, { applicationId, limit: 1000 }));

    const expenseList = applicationWithExpenses.data.getApplication.expenses.items;

    const expenses = convertExpenseArrayToExpensesObject({ expenseArray: expenseList });

    dispatch({ expenses, type: GET_EXPENSES_BY_APPLICATION_ID_SUCCESS });
  } catch (error) {
    console.log(`failed to get expenses for applicationId ${applicationId}:`, error);

    dispatch({ type: GET_EXPENSES_BY_APPLICATION_ID_FAIL });
  }
};

const setExpenseList = ({ expenseList }) => ({ expenseList, type: SET_EXPENSE_LIST });

const submitExpenseValueChange = ({ category, subCategory, value }) => (
  {
    category,
    subCategory,
    type: SUBMIT_EXPENSE_VALUE_CHANGE,
    value,
  }
);

const saveExpenses = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_EXPENSES });

  const otherDebtsSubCategoryToApplicationPropertyNameMap = {
    backtaxes: 'totalBackTaxesAmount',
    friendsFamily: 'totalDebtToFriendsAndFamilyAmount',
    medical: 'totalMedicalDebtAmount',
    other: 'totalOtherDebtAmount',
  };

  const state = getState();

  let saveSucceeded = true;

  // eslint-disable-next-line arrow-body-style
  await Promise.all(Object.keys(state.expenses.data.expenseEstimates).map((category) => {
    return Object.keys(state.expenses.data.expenseEstimates[category]).map(async (subCategory) => {
      const application = state.application.data;
      const baseTen = 10;
      const estimatedOtherDebtMonthlyPaymentPercentage = 0.02;
      const expense = state.expenses.data.expenseEstimates[category][subCategory];
      const totalOtherDebtAmount = application[otherDebtsSubCategoryToApplicationPropertyNameMap[subCategory]] || 0;

      const selfReportedValue = category === 'otherDebts'
        ? parseInt(totalOtherDebtAmount * estimatedOtherDebtMonthlyPaymentPercentage, baseTen)
        : expense.lastSubmittedValue;

      try {
        await API.graphql(graphqlOperation(
          updateExpense,
          { input: { id: expense.id, selfReportedValue } },
        ));
      } catch (error) {
        console.log('failed to save expense:', error);
        saveSucceeded = false;
      }
    });
  }).flat());

  const successOrFailAction = saveSucceeded ? { type: SAVE_EXPENSES_SUCCESS } : { type: SAVE_EXPENSES_FAIL };

  dispatch(successOrFailAction);
};

export {
  cancelExpenseValueChange,
  changeExpenseValue,
  convertExpenseArrayToExpensesObject,
  getExpenseCategoryTotals,
  getExpenseEstimates,
  getExpenseEstimatesByApplicationId,
  getExpensesByApplicationId,
  getIndividualExpenses,
  saveExpenses,
  setExpenseList,
  submitExpenseValueChange,
};
