import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBadge from '../../../portal/Alerts/AlertBadge';
import CloseIcon from '../../../../assets/icons/close_icon.svg';
import { getCurrentAuthenticatedUser, doesUserNeedToChangePassword, isUserSignedIn } from '../../../../helpers/authHelper';
import Hamburger from '../../../../assets/icons/hamburger_icon-KO.svg';
import logo from '../../../../assets/logos/creditgenie_logo_KO.svg';
import { signOut } from '../../../../redux/actions/userActions';
import profileDefault from '../../../../assets/icons/profile-default_KO_icon.svg';
import DownArrow from '../../../../assets/icons/down-arrow-white_icon.svg';

const Header = ({ history }) => {
  const alerts = useSelector((state) => state.alerts.data);
  const defaultUser = { attributes: { name: undefined } };
  const dispatch = useDispatch();
  const [financialsAreOpen, setFinancialsAreOpen] = useState(false);
  const showAlertsCount = process.env.REACT_APP_SHOW_COUNSELOR_ALERTS === 'true';
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [user, setUser] = useState(defaultUser);
  const [userHasClickedSignOutLink, setUserHasClickedSignOutLink] = useState(false);
  const userHasResetPassword = useSelector((state) => state.user.hasResetPassword);
  const [userIsOpen, setUserIsOpen] = useState(false);

  const toggleFinancials = () => setFinancialsAreOpen(!financialsAreOpen);
  const toggleUser = () => setUserIsOpen(!userIsOpen);
  const unseenAlerts = alerts.filter((alert) => !alert.hasBeenSeenByBorrower);

  const onSignOut = (event) => {
    event.preventDefault();

    setUserHasClickedSignOutLink(true);

    dispatch(signOut());
  };


  useEffect(() => {
    if (userHasClickedSignOutLink) {
      history.push('/sign-in');

      return;
    }

    const checkIfUserIsSignedIn = async () => {
      const isSignedIn = await isUserSignedIn();

      if (!isSignedIn) {
        history.push('/sign-in');

        return;
      }

      if (!userHasResetPassword) {
        const userNeedsToChangePassword = await doesUserNeedToChangePassword();

        if (userNeedsToChangePassword) {
          history.push('/please-check-your-email');

          return;
        }
      }

      if (user === defaultUser) {
        const currentUser = await getCurrentAuthenticatedUser();

        setUser(currentUser);
      }
    };

    checkIfUserIsSignedIn();

    if (showMobileNav) {
      history.listen(() => {
        setShowMobileNav(!showMobileNav);
      });
    }
  });

  return (
    <div className="customer-portal-header">
      <nav className="navigation container flex items-center justify-between flex-wrap pt-6 pb-6 m-auto">
        <div
          role="button"
          tabIndex="0"
          className="hamburger sm:ml-4 ml-0 cursor-pointer"
          onKeyDown={() => setShowMobileNav(!showMobileNav)}
          onClick={() => setShowMobileNav(!showMobileNav)}
        >
          <img src={Hamburger} alt="Hamburger Icon" />
          {showAlertsCount && unseenAlerts.length > 0 && (
          <AlertBadge
            numberOfAlerts={unseenAlerts.length}
          />
          )}
        </div>
        {showMobileNav && (
        <div className="mobile-nav">
          <div className="pt-10">
            <Link to="/summary">
              <img className="h-8 mr-2" src={logo} alt="Credit Genie Logo Blue" />
            </Link>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setShowMobileNav(!showMobileNav)}
              onKeyDown={() => setShowMobileNav(!showMobileNav)}
            >
              <img src={CloseIcon} className="close-icon float-right" alt="Close X Icon" />
            </div>
          </div>
          <div>
            <div className="py-5 m-auto">
              <img className="profile m-auto" src={profileDefault} alt="Profile" />
              <p className="text-center pt-2">{user.attributes.name}</p>
            </div>
            <div className="flex">
              <div className="w-1/2">
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/summary">
                      Summary
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/expense-summary">
                      Expenses
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/financials">
                      Financials
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/offers-summary">
                      Offer
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/bank-connections">
                      Bank Connections
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-1/2">
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                  <li className="mr-3 py-2 px-4">
                    <Link to="/customer-portal/alerts">
                      Alerts
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/faq">
                      FAQ
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link to="/customer-portal/settings">
                      Settings
                    </Link>
                  </li>
                  <li className="mr-3 px-4">
                    <Link onClick={onSignOut} to="/sign-in">Sign Out</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        )}
        <div className="logo flex items-center flex-no-shrink lg:mr-6 mx-auto mt-2">
          <Link to="/customer-portal/summary">
            <img className="h-8 mr-2" src={logo} alt="Credit Genie Logo Blue" />
          </Link>
        </div>
        <div className="
          w-full lg:items-center lg:w-autow-full flex-grow
          lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li>
              <Link to="/customer-portal/summary" className="inline-block py-2 pr-4 no-underline">
                Summary
              </Link>
            </li>
            <li className="ml-3 px-5">
              <ul>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <li className={financialsAreOpen ? 'm-menu -active' : 'm-menu '} onClick={toggleFinancials} onKeyDown={toggleFinancials}>
                  <button type="button" className="dropdown-btn" onClick={toggleFinancials}>
                    Financial Summary
                    <img src={DownArrow} alt="Down Arrow Icon" />
                  </button>
                  <ul className="m-menu__list">
                    <li className="m-menu__item mb-2">
                      <Link to="/customer-portal/bank-connections">
                        <div className="m-menu__link">Bank Connections</div>
                      </Link>
                    </li>
                    <li className="m-menu__item">
                      <Link to="/customer-portal/expense-summary">
                        <div className="m-menu__link">Expenses</div>
                      </Link>
                    </li>
                    <li className="m-menu__item">
                      <Link to="/customer-portal/financials">
                        <div className="m-menu__link">Financials</div>
                      </Link>
                    </li>
                    <li className="m-menu__item">
                      <Link to="/customer-portal/offers-summary">
                        <div className="m-menu__link">Offer</div>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="pr-5">
              <Link to="/customer-portal/alerts" className="inline-block py-2 px-4 -mr-4 no-underline">
                Alerts
              </Link>
              {showAlertsCount && unseenAlerts.length > 0 && (
              <AlertBadge
                numberOfAlerts={unseenAlerts.length}
              />
              )}
            </li>
            <li className="ml-3">
              <ul>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <li className={userIsOpen ? 'm-menu -active' : 'm-menu '} onClick={toggleUser} onKeyDown={toggleUser}>
                  <button type="button" className="dropdown-btn" onClick={toggleUser}>
                    {user.attributes.name}
                    <img src={DownArrow} alt="Down Arrow Icon" />
                  </button>
                  <ul className="m-menu__list">
                    <li className="m-menu__item">
                      <Link to="/customer-portal/settings">
                        <div className="m-menu__link">Settings</div>
                      </Link>
                    </li>
                    <li className="m-menu__item">
                      <Link to="/customer-portal/faq">
                        <div className="m-menu__link">FAQ</div>
                      </Link>
                    </li>
                    <li className="m-menu__item">
                      <Link onClick={onSignOut} to="/sign-in"><div className="m-menu__link">Sign Out</div></Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(Header);
