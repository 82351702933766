import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BorrowerEmailAddressInput,
  BorrowerPhoneNumberInput,
  BorrowerFirstNameTextInput,
  BorrowerLastNameTextInput,
} from '../../../components/application/BorrowerInputs';
import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveRentalApplicationSucceeded } from '../../../redux/actions/rentalApplicationActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import Form from '../../../components/application/common/ApplicationForm/Form';
import { clearSaveUpdatedUserAttributesSucceeded, saveUpdatedUserAttributes } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import TermsAgreementCheckbox from '../../../components/application/TermsAgreementCheckbox';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';
import logo from '../../../assets/logos/creditgenie_logo_blue.svg';
import { getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';

const RentalAboutYou = ({ nextPage = 'LifeEvents', nextPageRoute = '/application/life-events' }) => {
  const { t: translate } = useTranslation();

  const borrowerEmail = useSelector((state) => state.borrower.data.email);
  const borrowerFullName = useSelector((state) => `${state.borrower.data.firstName} ${state.borrower.data.lastName}`);
  const borrowerId = useSelector((state) => state.borrower.data.id);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.user.errorMessage || 'There was a problem saving your information. Please try again.');
  const history = useHistory();
  const router = useSelector((state) => state.application.data.router);
  const saveBorrowerSucceeded = useSelector((state) => state.borrower.saveSuccess);
  const saveFailed = useSelector((state) => state.application.saveFailed
    || state.borrower.saveFailed
    || state.twilio.verifyPhoneNumberFailed
    || state.user.saveFailed);
  const saveUpdatedUserAttributesSucceeded = useSelector((state) => state.user.saveUpdatedUserAttributesSucceeded);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });

  const {
    errors,
    onBorrowerChange,
    onBorrowerTextInputBlur,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    if (saveBorrowerSucceeded) {
      if (saveUpdatedUserAttributesSucceeded === undefined) {
        dispatch(saveUpdatedUserAttributes());

        return;
      }

      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveRentalApplicationSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(clearSaveUpdatedUserAttributesSucceeded());

      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'AboutYou', lastViewedPage: nextPage }));

      if (window.FS) {
        window.FS.identify(borrowerId, {
          displayName: borrowerFullName,
          email: borrowerEmail,
        });
      }

      history.push(nextPageRoute);
    }
  });

  return (
    <div>
      <div className="rental-about-you leading-normal tracking-normal">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <h2 className="headline text-xl sm:text-2xl w-full m-auto text-center">
            {translate('rentalaboutyou:aboutYou:heading:title')}
          </h2>
          <p className="without-a-loan text-center w-full md:w-1/2 m-auto">
            {translate('rentalaboutyou:aboutYou:heading:subtitle')}
          </p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto">
              <div className="text-center">
                <div className="powered-by pb-6 pt-2">
                  <span>
                    {translate('rentalaboutyou:aboutYou:heading:poweredBy')}
                  </span>
                  <img src={logo} alt="Credit Genie Logo" />
                </div>
              </div>
              <Form onSubmit={onSubmit}>
                <BorrowerFirstNameTextInput
                  errors={errors}
                  label={translate('rentalaboutyou:aboutYou:firstName:label')}
                  onBlur={onBorrowerTextInputBlur}
                  onChange={onBorrowerChange}
                />
                <BorrowerLastNameTextInput
                  errors={errors}
                  label={translate('rentalaboutyou:aboutYou:lastName:label')}
                  onBlur={onBorrowerTextInputBlur}
                  onChange={onBorrowerChange}
                />
                <BorrowerEmailAddressInput
                  errors={errors}
                  label={translate('rentalaboutyou:aboutYou:emailAddress:label')}
                  onChange={onBorrowerChange}
                />
                <BorrowerPhoneNumberInput
                  errors={errors}
                  label={translate('rentalaboutyou:aboutYou:phoneNumber:label')}
                  onChange={onBorrowerChange}
                />
                <TermsAgreementCheckbox errors={errors} />
                <SubmitInput text={translate('global:submit:text')} />
              </Form>
              <p className="landlord-disclosure text-center pt-1 pb-4">
                {translate('rentalaboutyou:aboutYou:landlordDisclosure:text')}
              </p>
            </div>
            <p className="text-black text-center font-bold pt-8">
              {translate('rentalaboutyou:aboutYou:alternativeLanguages:text')}
              {' '}
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default RentalAboutYou;
