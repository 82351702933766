import React, { useState } from 'react';
import ApplicationReferralInput from './ApplicationReferralInput/ApplicationReferralInput';
import ButtonInput from '../../common/ButtonInput/ButtonInput';

const NewApplicationModal = ({ hideNewApplicationModal }) => {
  const [applicationReferral, setApplicationReferral] = useState(undefined);
  const [errors, setErrors] = useState({});

  const onApplicationReferralChange = (event) => {
    setApplicationReferral(event.target.value.toLowerCase());
  };

  const handleSubmitClick = () => {
    if (!applicationReferral) {
      setErrors({ applicationReferral: 'required' });

      return;
    }

    hideNewApplicationModal();

    let applicationType;

    if (applicationReferral === 'eden' || applicationReferral === 'midpen') {
      applicationType = 'rental';
    }

    if (applicationReferral === 'fps') {
      applicationType = 'credit';
    }

    const newApplicationWindow = window.open(
      `application/about-you?router=${applicationReferral}&applicationType=${applicationType}`,
      '_blank',
    );

    newApplicationWindow.focus();
    newApplicationWindow.sessionStorage.clear();
  };

  return (
    <div className="fixed inset-0 overflow-y-auto z-20 pt-20 alert-modal-bg">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          // eslint-disable-next-line max-len
          className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full alert-modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white text-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h4 className="text-black mb-4 font-bold text-lg">
              To begin an application, select the referral environment in which the borrower will be applying.
            </h4>

            <ApplicationReferralInput errors={errors} onChange={onApplicationReferralChange} applicationReferral={applicationReferral} />

            <div className="flex">
              <input
                className="ko-call-to-action w-1/2 py-3 mt-5 cursor-pointer"
                id="cancel-button"
                onClick={() => hideNewApplicationModal()}
                type="button"
                value="Cancel"
              />
              <ButtonInput className="w-1/2" id="start-application-button" onClick={() => handleSubmitClick()} text="Start Application" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewApplicationModal;
