import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RadioButtonInput = ({
  checkedValue,
  errors = {},
  id,
  name,
  onChange,
  options,
  question,
  required = true,
}) => {
  const { t: translate } = useTranslation();
  const [valid, setValid] = useState(checkedValue !== undefined);

  if (!options || options.length === 0) {
    return null;
  }

  const onInputValueChange = (event) => {
    setValid(true);

    onChange(event);
  };

  const radioButtonsAndLabels = [];

  options.forEach((option) => {
    radioButtonsAndLabels.push(React.createElement('input', {
      'aria-labelledby': `${option.id}-label`,
      defaultChecked: checkedValue === option.value ? true : undefined,
      id: `${option.id}-radio-button`,
      key: `${option.id}-radio-button`,
      name,
      onChange: onInputValueChange,
      required,
      type: 'radio',
      valid: valid === undefined ? undefined : valid.toString(),
      value: option.value,
    }));
    radioButtonsAndLabels.push(React.createElement('label', {
      htmlFor: `${option.id}-radio-button`,
      id: `${option.id}-label`,
      key: `${option.id}-label`,
      style: { paddingRight: '20px' },
    }, [option.label]));
  });

  return (
    <div className="radio-button-input w-full m-auto">
      <div className="mb-4">
        <div aria-labelledby={`${id}-radio-question`} role="radiogroup">
          <div className="radio-question" id={`${id}-radio-question`}>
            {question}
          </div>
          {radioButtonsAndLabels}
        </div>
      </div>
      {errors[name] === 'required' && !valid && (
      <p className="validation">
        {`${question} ${translate('global:errorMessages:isRequired')}`}
      </p>
      )}
    </div>
  );
};

export default RadioButtonInput;
