import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AreYouCurrentlyMarriedInput from '../../../components/application/AreYouCurrentlyMarriedInput';
import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import DoYouHaveAnyDependents from '../../../components/application/DoYouHaveAnyDependents/DoYouHaveAnyDependents';
import DoYouOwnACar from '../../../components/application/DoYouOwnACar/DoYouOwnACar';
import Form from '../../../components/application/common/ApplicationForm/Form';
import MonthlyRentOrMortgagePaymentAmountInput from '../../../components/application/MonthlyRentOrMortgagePaymentAmountInput';
import ProgressBar from '../../../components/application/common/ProgressBar';
import SubmitInput from '../../../components/application/common/SubmitInput';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';
import { BorrowerPreferredLanguageRadioButtonInput } from '../../../components/application/BorrowerInputs';
import { MilitaryServiceRadioButton } from '../../../components';
import HomeownerComponent from '../../../components/application/HomeownerComponent/HomeownerComponent';

const CreditHistory = ({ nextPage = 'YourContact', nextPageRoute = '/application/your-contact' }) => {
  const onSuccessfulSubmit = () => { };

  const dispatch = useDispatch();
  const errorMessage = 'There was a problem saving your information. Please try again.';
  const {
    errors, onApplicationChange, onBorrowerChange, onSubmit,
  } = useForm({ callback: onSuccessfulSubmit, validate });
  const history = useHistory();
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);
  const saveBorrowerSucceeded = useSelector((state) => state.borrower.saveSuccess);
  const saveFailed = useSelector((state) => state.application.saveFailed);

  useEffect(() => {
    if (saveApplicationSucceeded && saveBorrowerSucceeded) {
      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'CreditHistory', lastViewedPage: nextPage }));

      history.push(nextPageRoute);
    }
  });

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <ProgressBar pageName="creditHistory" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Tell us about your household
          </h2>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <Form onSubmit={onSubmit}>
                <AreYouCurrentlyMarriedInput errors={errors} onChange={onApplicationChange} />
                <DoYouHaveAnyDependents errors={errors} onChange={onApplicationChange} />
                <MonthlyRentOrMortgagePaymentAmountInput errors={errors} onChange={onApplicationChange} />
                <HomeownerComponent errors={errors} onChange={onApplicationChange} />
                <DoYouOwnACar errors={errors} onChange={onApplicationChange} />
                <BorrowerPreferredLanguageRadioButtonInput errors={errors} onChange={onBorrowerChange} />
                <MilitaryServiceRadioButton errors={errors} onChange={onApplicationChange} />
                <div className="pt-2">
                  <SubmitInput />
                </div>
              </Form>
            </div>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreditHistory;
