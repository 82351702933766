import { API, graphqlOperation } from 'aws-amplify';
import { createRentalReliefPlan, updateRentalApplication, updateRentalReliefPlan } from '../../graphql/customMutations';
import { formatApplicationDateForAws } from '../../formatters/dateFormatter';

export const CANCEL_RENTAL_RELIEF_PLAN_CHANGES = 'CANCEL_RENTAL_RELIEF_PLAN_CHANGES';
export const CLEAR_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED = 'CLEAR_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED';
export const SAVE_RENTAL_RELIEF_PLAN = 'SAVE_RENTAL_RELIEF_PLAN';
export const SAVE_RENTAL_RELIEF_PLAN_FAIL = 'SAVE_RENTAL_RELIEF_PLAN_FAIL';
export const SAVE_RENTAL_RELIEF_PLAN_SUCCESS = 'SAVE_RENTAL_RELIEF_PLAN_SUCCESS';
export const SET_RENTAL_RELIEF_PLAN = 'SET_RENTAL_RELIEF_PLAN';
export const SET_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED = 'SET_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED';
export const SET_SHOW_RENTAL_RELIEF_PLAN_FORM = 'SET_SHOW_RENTAL_RELIEF_PLAN_FORM';
export const UPDATE_RENTAL_RELIEF_PLAN = 'UPDATE_RENTAL_RELIEF_PLAN';

const cancelRentalReliefPlanChanges = () => ({ type: CANCEL_RENTAL_RELIEF_PLAN_CHANGES });

const clearSaveRentalReliefPlanSucceeded = () => ({ type: CLEAR_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED });

const saveRentalReliefPlan = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_RENTAL_RELIEF_PLAN });

  const state = getState();

  const borrowerId = state.borrower.data.id;
  const rentalApplicationId = state.rentalApplication.data.id;
  const rentalReliefPlan = state.rentalReliefPlan.data;

  const formattedDateSigned = formatApplicationDateForAws({ applicationDate: rentalReliefPlan.dateSigned });
  const formattedPlannedStartDate = formatApplicationDateForAws({ applicationDate: rentalReliefPlan.plannedStartDate });
  const formattedPlannedEndDate = formatApplicationDateForAws({ applicationDate: rentalReliefPlan.plannedEndDate });

  const input = {
    ...rentalReliefPlan,
    dateSigned: formattedDateSigned,
    owner: borrowerId,
    plannedStartDate: formattedPlannedStartDate,
    plannedEndDate: formattedPlannedEndDate,
    rentalReliefPlanRentalApplicationId: rentalApplicationId,
  };

  const saveOperation = rentalReliefPlan.id ? updateRentalReliefPlan : createRentalReliefPlan;

  try {
    const saveRentalReliefPlanResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const savedRentalReliefPlan = (
      saveRentalReliefPlanResponse.data.createRentalReliefPlan || saveRentalReliefPlanResponse.data.updateRentalReliefPlan
    );

    dispatch({ rentalReliefPlan: { ...rentalReliefPlan, id: savedRentalReliefPlan.id }, type: SAVE_RENTAL_RELIEF_PLAN_SUCCESS });

    if (!savedRentalReliefPlan.rentalApplication.rentalReliefPlan) {
      try {
        await API.graphql(graphqlOperation(updateRentalApplication, {
          input: { id: rentalApplicationId, rentalApplicationRentalReliefPlanId: savedRentalReliefPlan.id },
        }));
      } catch (error) {
        console.log('error saving rentalReliefPlan to rentalApplication', error);
      }
    }
  } catch (error) {
    console.log('saveRentalReliefPlan failed:', error);
    dispatch({ type: SAVE_RENTAL_RELIEF_PLAN_FAIL });
  }
};

const setSaveRentalReliefPlanSucceeded = () => ({ type: SET_SAVE_RENTAL_RELIEF_PLAN_SUCCEEDED });

const setRentalReliefPlan = ({ rentalReliefPlan }) => ({ rentalReliefPlan, type: SET_RENTAL_RELIEF_PLAN });

const setShowRentalReliefPlanForm = ({ showRentalReliefPlanForm }) => ({ showRentalReliefPlanForm, type: SET_SHOW_RENTAL_RELIEF_PLAN_FORM });

const updateRentalReliefPlanInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_RENTAL_RELIEF_PLAN,
  value,
});

export {
  cancelRentalReliefPlanChanges,
  clearSaveRentalReliefPlanSucceeded,
  saveRentalReliefPlan,
  setRentalReliefPlan,
  setSaveRentalReliefPlanSucceeded,
  setShowRentalReliefPlanForm,
  updateRentalReliefPlanInformation as updateRentalReliefPlan,
};
