import { SET_DEFAULT_SEARCH_VALUE } from '../actions/searchBarActions';

const initialState = {
  defaultSearchValue: undefined,
};

const searchBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_SEARCH_VALUE:
      return { ...state, defaultSearchValue: action.value };

    default:
      return state;
  }
};

export default searchBarReducer;
