import React from 'react';

const ParentOfferDetail = ({ format, icon, value }) => (
  <div className="w-full">
    <div className="debt-icons align-middle">
      {icon && <img src={icon} alt="Debt Icon" className="debt-icons" />}
    </div>
    <span className="align-middle">
      {format.getFormattedValue(value)}
    </span>
  </div>
);

export default ParentOfferDetail;
