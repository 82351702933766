import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from '../../../common/LinkButton/LinkButton';
import { setAssignedBorrowerCount, setContactedBorrowerCount } from '../../../../redux/actions/borrowerActions';
import { unassignApplicationFromUser, updateApplication } from '../../../../redux/actions/applicationActions';

const UnclaimApplicationLinkButton = ({
  additionalClasses,
  applicationId,
  applicationStatus,
  onClick = () => {},
}) => {
  const assignedBorrowerCount = useSelector((state) => state.borrower.assignedBorrowerCount);
  const contactedBorrowerCount = useSelector((state) => state.borrower.contactedBorrowerCount);
  const dispatch = useDispatch();

  const onUnclaimButtonClick = (event) => {
    event.preventDefault();

    dispatch(updateApplication({ propertyName: 'assignedTo', value: undefined }));
    dispatch(updateApplication({ propertyName: 'assignedToAt', value: undefined }));
    dispatch(unassignApplicationFromUser({ applicationId }));
    dispatch(setAssignedBorrowerCount({ numberOfAssignedBorrowers: assignedBorrowerCount - 1 }));

    if (applicationStatus === 'Contacted') {
      dispatch(setContactedBorrowerCount({ numberOfContactedBorrowers: contactedBorrowerCount - 1 }));
    }

    onClick();
  };

  return (
    <LinkButton additionalClasses={additionalClasses} onClick={onUnclaimButtonClick} text="Unclaim" />
  );
};

export default UnclaimApplicationLinkButton;
