import React from 'react';
import { useTranslation } from 'react-i18next';

import DropdownInput from '../../application/common/DropdownInput/DropdownInput';

const HowManyPeopleAreLivingWithYou = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  const options = [
    { label: translate('global:numbers:one'), value: 1 },
    { label: translate('global:numbers:two'), value: 2 },
    { label: translate('global:numbers:three'), value: 3 },
    { label: `${translate('global:numbers:four')}+`, value: 4 },
  ];

  const onSelectValueChange = (event) => {
    const baseTen = 10;
    const numericValue = parseInt(event.target.value, baseTen);

    onChange(event, numericValue);
  };

  return (
    <div>
      <DropdownInput
        errors={errors}
        id="how-many-people-are-living-with-you-select"
        loadFrom="rentalApplication"
        name="numberOfPeopleLivingWithBorrower"
        onChange={onSelectValueChange}
        options={options}
        question={translate('rentalinformation:howManyPeopleAreLivingWithYou:label')}
      />
      <p className="font-bold text-xs">{translate('rentalinformation:howManyPeopleAreLivingWithYou:note')}</p>
    </div>
  );
};

export default HowManyPeopleAreLivingWithYou;
