/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import { animated, useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { clamp } from 'lodash';

const Carousel = ({ components, name }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = useRef(0);

  const [props, set] = useSprings(components.length, (i) => ({
    x: i * window.innerWidth,
    scale: 1,
    display: i === 0 ? 'block' : 'none',
  }));

  const bind = useDrag((
    {
      down, movement: [mx], direction: [xDir], distance, cancel,
    },
  ) => {
    if (down && distance > window.innerWidth / 2) {
      cancel((carouselRef.current = clamp(carouselRef.current + (xDir > 0 ? -1 : 1), 0, components.length - 1)));
    }
    set((i) => {
      setCarouselIndex(carouselRef.current);
      if (i !== carouselRef.current) return { display: 'none' };
      const x = (i - carouselRef.current) * window.innerWidth + (down ? mx : 0);
      const scale = down ? 1 - distance / window.innerWidth / 2 : 1;
      return { x, scale, display: 'block' };
    });
  });

  const setAnimation = () => {
    set((i) => {
      if (i !== carouselRef.current) return { display: 'none' };
      return {
        x: (i - carouselRef.current) * window.innerWidth,
        scale: 1,
        display: 'block',
      };
    });
  };

  const handleSlideClick = (event) => {
    const clickedSlideIndex = parseInt(event.target.getAttribute('data-index'), 10);
    carouselRef.current = clickedSlideIndex;
    setCarouselIndex(clickedSlideIndex);
    setAnimation();
  };

  const handlePrevClick = () => {
    if (carouselRef.current !== 0) {
      carouselRef.current -= 1;
      setCarouselIndex(carouselRef.current);
    }
    setAnimation();
  };

  const handleNextClick = () => {
    if (carouselRef.current !== components.length - 1) {
      carouselRef.current += 1;
      setCarouselIndex(carouselRef.current);
    }
    setAnimation();
  };

  return (
    <div>
      <div className="directional-arrows">
        <div
          className="arrow left"
          onClick={handlePrevClick}
          onKeyPress={handlePrevClick}
          role="link"
          tabIndex={0}
        />
        {props.map(({ x, display, scale }, i) => (
          <animated.div className="carousel cursor-pointer m-auto" key={`div-${i}`} style={{ display, x }}>
            <animated.div {...bind()} className="w-full carousel-animation-1" style={{ scale }}>
              {components[i]}
            </animated.div>
          </animated.div>
        ))}
        <div
          className="arrow right"
          onClick={handleNextClick}
          onKeyPress={handleNextClick}
          role="link"
          tabIndex={0}
        />
      </div>
      <div className="carousel-indicators">
        {components.map((component, currentComponentIndex) => (
          <div
            className={`inline-block pr-2 outline-none no-highlight ${carouselIndex === currentComponentIndex ? 'carousel-selected' : ''}`}
            data-index={currentComponentIndex}
            htmlFor="carousel-1"
            key={`${name}-slide-${currentComponentIndex}`}
            onClick={handleSlideClick}
            onKeyPress={handleSlideClick}
            role="link"
            tabIndex={0}
          >
            •
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
