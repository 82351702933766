import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationsForServicePortal } from '../../../redux/actions/applicationActions';
import { SummaryFilterComponent } from '../../../components/customer-service-portal';

const Summary = () => {
  const applications = useSelector((state) => (Array.isArray(state.application.list) ? state.application.list : []));
  const [applicationsWereRetrieved, setApplicationsWereRetrieved] = useState(false);
  const dispatch = useDispatch();

  const borrowers = applications.map((application) => ({
    application: {
      assignedTo: application.assignedTo,
      createdAt: application.createdAt,
      decision: application.decision,
      dmpEnrollment: application.dmpEnrollment,
      id: application.id,
      lastCompletedPage: application.lastCompletedPage,
      router: application.router,
      status: application.status,
      type: application.type,
    },
    hasConnectedAccount: application.hasConnectedAccount,
    ...application.borrower,
  }));

  useEffect(() => {
    if (!applicationsWereRetrieved) {
      setApplicationsWereRetrieved(true);
      dispatch(getApplicationsForServicePortal());
    }
  }, [applicationsWereRetrieved, dispatch]);

  return (
    <section>
      <div>
        <div className="summary-container">
          <SummaryFilterComponent borrowers={borrowers} filterResultsTextColor="white" />
        </div>
      </div>
    </section>
  );
};

export default Summary;
