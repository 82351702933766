import { API, graphqlOperation } from 'aws-amplify';
import {
  getDebtScoreWithCreatedAt,
  getIncomeSnapshotWithCreatedAt,
  getInquiryWithDate,
  getPlaidAccountBalanceWithCreatedAt,
  getPlaidTransactionWithDate,
} from '../../graphql/customQueries';
import { updateAlert } from '../../graphql/customMutations';

export const SAVE_ALERT = 'SAVE_ALERT';
export const SAVE_ALERT_FAIL = 'SAVE_ALERT_FAIL';
export const SAVE_ALERT_SUCCESS = 'SAVE_ALERT_SUCCESS';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_EVENT_DATE = 'SET_EVENT_DATE';
export const UPDATE_ALERT = 'UPDATE_ALERT';

const getAlertEventDate = ({ alert }) => async (dispatch) => {
  const alertType = alert.type;

  if (alertType === 'balanceBelowDMPPayment') {
    const plaidAccountBalanceId = [...alert.triggeredBy].filter((triggeredBy) => triggeredBy.type === 'PlaidAccountBalance').pop().id;

    try {
      const getPlaidAccountBalanceResponse = await API.graphql(graphqlOperation(getPlaidAccountBalanceWithCreatedAt, { plaidAccountBalanceId }));

      const plaidAccountBalance = getPlaidAccountBalanceResponse.data.getPlaidAccountBalance;

      dispatch({ alertId: alert.id, eventDate: plaidAccountBalance.createdAt, type: SET_EVENT_DATE });
    } catch (error) {
      console.log('error getting PlaidAccountBalance:', error);
    }

    return;
  }

  if (alertType === 'bankAccountDisconnected') {
    dispatch({ alertId: alert.id, eventDate: alert.createdAt, type: SET_EVENT_DATE });

    return;
  }

  if (alertType === 'debtScore30DayDecrease') {
    const debtScoreId = alert.triggeredBy[1].id;

    try {
      const getDebtScoreResponse = await API.graphql(graphqlOperation(getDebtScoreWithCreatedAt, { debtScoreId }));

      const debtScore = getDebtScoreResponse.data.getDebtScore;

      dispatch({ alertId: alert.id, eventDate: debtScore.createdAt, type: SET_EVENT_DATE });
    } catch (error) {
      console.log('error getting DebtScore:', error);
    }

    return;
  }

  if (alertType === 'insufficientFundsTransaction' || alertType === 'overdraftTransaction') {
    const plaidTransactionId = alert.triggeredBy[0].id;

    try {
      const getPlaidTransactionResponse = await API.graphql(graphqlOperation(getPlaidTransactionWithDate, { plaidTransactionId }));

      const plaidTransaction = getPlaidTransactionResponse.data.getPlaidTransaction;

      dispatch({ alertId: alert.id, eventDate: plaidTransaction.date, type: SET_EVENT_DATE });
    } catch (error) {
      console.log('error getting PlaidTransaction:', error);
    }

    return;
  }

  if (alertType === 'newCreditInquiry') {
    const inquiryId = alert.triggeredBy[0].id;

    try {
      const getInquiryResponse = await API.graphql(graphqlOperation(getInquiryWithDate, { inquiryId }));

      const inquiry = getInquiryResponse.data.getInquiry;

      dispatch({ alertId: alert.id, eventDate: inquiry.date, type: SET_EVENT_DATE });
    } catch (error) {
      console.log('error getting Inquiry:', error);
    }
  }

  if (alertType === 'monthlyIncomeDecrease') {
    const getIncomeSnapshotPromises = [];

    try {
      alert.triggeredBy.forEach((trigger) => {
        getIncomeSnapshotPromises.push(API.graphql(graphqlOperation(getIncomeSnapshotWithCreatedAt, { incomeSnapshotId: trigger.id })));
      });

      const getIncomeSnapshotResponses = await Promise.all(getIncomeSnapshotPromises);

      const incomeSnapshots = getIncomeSnapshotResponses.map((getIncomeSnapshotResponse) => getIncomeSnapshotResponse.data.getIncomeSnapshot);

      incomeSnapshots.sort((incomeSnapshot1, incomeSnapshot2) => incomeSnapshot2.createdAt - incomeSnapshot1.createdAt);

      const newestIncomeSnapshot = incomeSnapshots[0];

      dispatch({ alertId: alert.id, eventDate: newestIncomeSnapshot.createdAt, type: SET_EVENT_DATE });
    } catch (error) {
      console.log('error getting IncomeSnapshot:', error);
    }
  }
};

const saveAlert = ({ alertId }) => async (dispatch, getState) => {
  dispatch({ type: SAVE_ALERT });

  const state = getState();

  const alerts = state.alerts.data;
  const alertToUpdate = alerts.find((alert) => alert.id === alertId);
  const { hasBeenSeenByBorrower, hasBeenSeenByCustomerServiceRepresentative } = alertToUpdate;

  try {
    await API.graphql(graphqlOperation(updateAlert, { input: { id: alertId, hasBeenSeenByBorrower, hasBeenSeenByCustomerServiceRepresentative } }));

    dispatch({ type: SAVE_ALERT_SUCCESS });
  } catch (error) {
    console.log('error saving alert', error);

    dispatch({ type: SAVE_ALERT_FAIL });
  }
};

const setAlerts = ({ alerts }) => ({ alerts, type: SET_ALERTS });

const updateAlertInformation = ({ alertId, propertyName, value }) => ({
  alertId,
  propertyName,
  type: UPDATE_ALERT,
  value,
});

export {
  getAlertEventDate,
  saveAlert,
  setAlerts,
  updateAlertInformation as updateAlert,
};
