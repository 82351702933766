
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AgencyCoBranding from './AgencyCoBranding';
import { getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';

const AgencyFooter = () => {
  const isPreLead = useSelector((state) => state.borrower.data.id === undefined);
  const router = useSelector((state) => state.application.data.router);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ isPreLead, router });
  const { t: translate } = useTranslation();

  return (
    <section className="agencies footer bg-white py-8">
      <div className="container mx-auto sm:mt-8 mt-0 sm:mb-8 mb-0">
        <div className="ml-2 sm:ml-0 text-xl">
          <AgencyCoBranding />
          <p className="pt-2">{translate('global:footer:getHelpNow')}</p>
          <p className="pt-2">{translate('global:footer:talkToACreditCounselor')}</p>
          <p className="pt-2 font-bold">
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AgencyFooter;
