import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelRentalReliefPlanChanges, setShowRentalReliefPlanForm } from '../../../../redux/actions/rentalReliefPlanActions';

const RentalReliefPlanEnrollmentCancelInput = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(cancelRentalReliefPlanChanges());
    dispatch(setShowRentalReliefPlanForm({ showRentalReliefPlanForm: false }));
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className="call-to-action-KO py-3 mt-5 text-center block cursor-pointer"
      data-cy="filter-cancel"
      onClick={onClick}
      onKeyDown={onClick}
      onChange={() => { }}
    >
      Cancel
    </div>
  );
};

export default RentalReliefPlanEnrollmentCancelInput;
