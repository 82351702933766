import React from 'react';
import ParentOfferDetail from './ParentOfferDetail';
import { currencyOfferDetailFormat } from './offerDetailFormats';


const ValueDetail = ({ icon, value }) => (
  <div className="w-full">
    <ParentOfferDetail format={currencyOfferDetailFormat} icon={icon} value={value} />
  </div>
);

export default ValueDetail;
