import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NFCCAndHPNLogo from '../../../assets/logos/NFCC_HPN_logo.svg';
import { getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';

const RentalHeader = () => {
  const { t: translate } = useTranslation();
  const router = useSelector((state) => state.application.data.router);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });

  return (
    <div>
      <nav className="container sm:pt-6 pt-3 mx-auto">
        <div className="rental-header m-auto w-full">
          <div className="block">
            <img className="logo block m-auto px-2" src={NFCCAndHPNLogo} alt="NFCC and HPN Renter Advantage" />
          </div>
        </div>
        <div className="text-center px-12 mt-2">
          <p className="m-auto">
            {translate('global:header:needHelp')}
            {' '}
            <a href={`tel:${phoneNumber}`} className="font-bold">{phoneNumber}</a>
          </p>
        </div>
      </nav>
    </div>
  );
};

export default RentalHeader;
