import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearIsPhoneNumberValid } from '../../../redux/actions/twilioActions';
import PhoneNumberInput from '../common/PhoneNumberInput';

const BorrowerPhoneNumberInput = ({
  disabled,
  errors,
  label,
  onChange,
}) => {
  const dispatch = useDispatch();
  const isPhoneNumberInvalid = useSelector((state) => state.twilio.data.isPhoneNumberValid === false);
  const validationName = 'borrowerPhoneNumber';

  const errorsPlusTwilioValidation = { ...errors, [validationName]: isPhoneNumberInvalid ? 'invalid' : errors[validationName] };

  const onInputValueChange = (event) => {
    event.preventDefault();

    dispatch(clearIsPhoneNumberValid());

    onChange(event);
  };

  return (
    <PhoneNumberInput
      disabled={disabled}
      errors={errorsPlusTwilioValidation}
      id="borrower-phone-number-input"
      label={label}
      loadFrom="borrower"
      onChange={onInputValueChange}
      validationName={validationName}
    />
  );
};

export default BorrowerPhoneNumberInput;
