import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../components/application/common/BackButton';
import { changePassword, signOut } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import ResetPasswordVerification from '../../../components/portal/ResetPasswordValidation/ResetPasswordValidation';
import PasswordInput from '../../../components/application/common/PasswordInput/PasswordInput';
import objectValueChecker from '../../../helpers/objectValueHelper';

const initialStatePasswordValidators = {
  validationNumAndChar: false,
  validationLength: false,
  validationInvalidChar: false,
};

const ChangePassword = ({ history }) => {
  const [confirmNewPassword, setConfirmNewPassword] = useState(undefined);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const isChangingPassword = useSelector((state) => state.user.isChangingPassword);
  const [newPassword, setNewPassword] = useState(undefined);
  const [newPasswordValidators, setNewPasswordValidators] = useState(initialStatePasswordValidators);
  const [oldPassword, setOldPassword] = useState(undefined);
  const changePasswordErrorCode = useSelector((state) => state.user.changePasswordErrorCode);
  const changePasswordFailed = useSelector((state) => state.user.changePasswordFailed);
  const userHasChangedPassword = useSelector((state) => state.user.hasChangedPassword);
  const username = useSelector((state) => state.user.data.username);

  const onSubmit = (event) => {
    event.preventDefault();

    setErrorMessage(undefined);

    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Passwords do not match.');

      return;
    }

    const validatorsAreTrue = objectValueChecker(newPasswordValidators, true);
    if (validatorsAreTrue) {
      dispatch(changePassword({ oldPassword, newPassword }));
    } else {
      setErrorMessage('Your new password does not meet the requirements.');
    }
  };

  useEffect(() => {
    const passwordValidation = () => {
      const regexNumAndChar = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
      const regexInvalidChar = /^(?:[A-Za-z0-9!.@#$^*]+)$/;

      if (newPassword) {
        const validationNumAndChar = regexNumAndChar.test(newPassword);
        const validationLength = newPassword.length >= 8 && newPassword.length <= 20;
        const validationInvalidChar = regexInvalidChar.test(newPassword);

        setNewPasswordValidators((prevState) => ({
          ...prevState,
          validationNumAndChar,
          validationLength,
          validationInvalidChar,
        }));
      }
    };

    passwordValidation();

    if (changePasswordFailed) {
      setErrorMessage(changePasswordErrorCode.message);
    }

    if (isChangingPassword) {
      dispatch(signOut());
      history.push('/sign-in', { message: 'Password successfully reset. Please sign in with your new credentials.' });
    }
  },
  [
    changePasswordErrorCode,
    changePasswordFailed,
    dispatch,
    history,
    isChangingPassword,
    newPassword,
    username,
    userHasChangedPassword,
  ]);

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <BackButton />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Reset your password
          </h2>
          <p className="text-center">
            Please enter and confirm a new password.
          </p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <form onSubmit={onSubmit}>
                <PasswordInput
                  id="old-password-input"
                  label="Old password"
                  onChange={(event) => setOldPassword(event.target.value)}
                />
                <PasswordInput
                  id="new-password-input"
                  label="New password"
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                {newPassword && (
                  <ResetPasswordVerification isValidated={newPasswordValidators} />
                )}
                <PasswordInput
                  id="confirm-password-input"
                  label="Confirm password"
                  onChange={(event) => setConfirmNewPassword(event.target.value)}
                />
                <SubmitInput />
              </form>
            </div>
          </div>
          {errorMessage && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePassword);
