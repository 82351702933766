import React from 'react';
import {
  getCreditCounselingAgencyFullName,
} from '../../../../helpers/creditCounselingAgencyHelper';

const LPHowItWorks = () => {
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();

  return (
    <div className="w-full text-center m-auto py-10 bg-white">
      <div className="sm:w-1/2 w-full container m-auto">
        <h2 className="text-center w-full md:w-1/2 m-auto p-2 px-1">
          How it Works
        </h2>
        <p className="md:w-3/4 w-full m-auto px-4">
          Your lender and a NFCC certified credit counselor
          {' '}
          <span className="font-bold">{creditCounselingAgencyFullName}</span>
          {' '}
          use the information you provide on the Credit Genie platform to offer you a debt relief plan.
        </p>
        <div className="steps">
          <div className="w-full md:flex flex-none">
            <div className="md:w-1/2 w-full mx-auto pt-4 px-4">
              <div className="step-number">1</div>
              <div className="step-details">
                <h2>Tell Us About Yourself</h2>
                <p>
                  Enter information about yourself and then verify your income and expenses.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 w-full m-auto pt-4 px-4">
              <div className="step-number">2</div>
              <div className="step-details">
                <h2>Connect Your Accounts</h2>
                <p>
                  We&apos;ll ask your permission to pull your credit history and to connect your online banking accounts.
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex flex-none w-full">
            <div className="md:w-1/2 w-full m-auto pt-4 px-4">
              <div className="step-number">3</div>
              <div className="step-details">
                <h2>We Analyze That</h2>
                <p>
                  Credit Genie&apos;s automated system, partnering with your lenders and the NFCC, will analyze your debt profile
                  and present your offer to be reviewed with a credit counselor.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 w-full m-auto pt-4 px-4">
              <div className="step-number">4</div>
              <div className="step-details">
                <h2>Get Your Evaluation</h2>
                <p>
                  Once you&apos;ve agreed to the offer in consultation with a credit counselor, the debt adjustment takes
                  effect immediately from your lender, so you breathe easier.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LPHowItWorks;
