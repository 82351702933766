import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BorrowerAddressFields, BorrowerDateOfBirthInput, BorrowerEmailAddressInput,
  BorrowerFirstNameTextInput, BorrowerLastNameTextInput, BorrowerPhoneNumberInput,
} from '../../../components/application/BorrowerInputs';
import BorrowerCreditReportPolicyCheckbox from '../../../components/application/BorrowerInputs/BorrowerCreditReportPolicyCheckbox';
import Form from '../../../components/application/common/ApplicationForm/Form';
import ProgressBar from '../../../components/application/common/ProgressBar/ProgressBar';
import { pullCredit } from '../../../redux/actions/transunionActions';
import {
  saveLastCompletedAndLastViewedPage,
  setHasSubmittedPrimaryApplication,
  clearSaveApplicationSucceeded,
} from '../../../redux/actions/applicationActions';
import {
  SpouseAddressFields, SpouseDateOfBirthInput, SpouseEmailAddressInput, SpouseFirstNameTextInput, SpouseLastNameTextInput, SpousePhoneNumberInput,
} from '../../../components/application/SpouseInputs';
import SpouseCreditReportPolicyCheckbox from '../../../components/application/SpouseInputs/SpouseCreditReportPolicyCheckbox';
import SubmitInput from '../../../components/application/common/SubmitInput/SubmitInput';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';

const YourContact = () => {
  const { t: translate } = useTranslation();
  const onSuccessfulSubmit = () => { };

  const application = useSelector((state) => state.application.data);
  const dispatch = useDispatch();
  const errorMessage = translate('global:errorMessages:problemSavingYourInformation');
  const hasPulledCredit = useSelector((state) => state.transunion.hasPulledCredit);
  const hasSubmittedPrimaryApplication = useSelector((state) => state.application.hasSubmittedPrimaryApplication);
  const history = useHistory();

  const {
    errors,
    onBorrowerChange,
    onBorrowerTextInputBlur,
    onSpouseChange,
    onSpouseEmailAddressInputBlur,
    onSpouseTextInputBlur,
    onSubmit,
  } = useForm({ callback: onSuccessfulSubmit, validate });

  const saveBorrowerSucceeded = useSelector((state) => state.borrower.saveSuccess);
  const saveFailed = useSelector((state) => state.borrower.saveFailed || state.spouse.saveFailed);
  const saveSpouseSucceeded = useSelector((state) => state.spouse.saveSuccess);

  useEffect(() => {
    if (hasSubmittedPrimaryApplication) {
      history.push('/application/income/provide-monthly-income');

      return;
    }

    if (saveBorrowerSucceeded && (application.hasSpouse ? saveSpouseSucceeded : true)) {
      if (hasPulledCredit === undefined) {
        dispatch(pullCredit());
      }

      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'YourContact', lastViewedPage: 'ProvideMonthlyIncome' }));
      dispatch(setHasSubmittedPrimaryApplication());
      dispatch(clearSaveApplicationSucceeded());

      history.push('/application/income/provide-monthly-income');
    }
  });

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pb-8">
          <ProgressBar pageName="yourContact" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            {translate('yourcontact:title')}
          </h2>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <Form onSubmit={onSubmit}>
                <BorrowerFirstNameTextInput disabled errors={errors} onChange={onBorrowerChange} />
                <BorrowerLastNameTextInput disabled errors={errors} onChange={onBorrowerChange} />
                <BorrowerEmailAddressInput disabled errors={errors} onChange={onBorrowerChange} />
                <BorrowerPhoneNumberInput disabled errors={errors} onChange={onBorrowerChange} />
                <BorrowerAddressFields errors={errors} onBlur={onBorrowerTextInputBlur} onChange={onBorrowerChange} />
                <BorrowerDateOfBirthInput errors={errors} onChange={onBorrowerChange} />
                <BorrowerCreditReportPolicyCheckbox errors={errors} />
                {
                  application.hasSpouse
                  && (
                  <div className="mt-5">
                    <span className="font-bold color-blue">{translate('yourcontact:spouseOrDomesticPartnerInformation')}</span>
                    <SpouseFirstNameTextInput
                      errors={errors}
                      onBlur={onSpouseTextInputBlur}
                      onChange={onSpouseChange}
                      label={translate('global:userInformation:firstName')}
                    />
                    <SpouseLastNameTextInput
                      errors={errors}
                      onBlur={onSpouseTextInputBlur}
                      onChange={onSpouseChange}
                      label={translate('global:userInformation:lastName')}
                    />
                    <SpouseEmailAddressInput
                      errors={errors}
                      label={translate('global:userInformation:email')}
                      onBlur={onSpouseEmailAddressInputBlur}
                      onChange={onSpouseChange}
                    />
                    <SpousePhoneNumberInput errors={errors} onChange={onSpouseChange} label={translate('global:userInformation:phoneNumber')} />
                    <SpouseAddressFields errors={errors} onBlur={onSpouseTextInputBlur} onChange={onSpouseChange} />
                    <SpouseDateOfBirthInput errors={errors} onChange={onSpouseChange} />
                    <SpouseCreditReportPolicyCheckbox errors={errors} />
                  </div>
                  )
                }
                <SubmitInput text={translate('global:submit:text')} />
              </Form>
              <div className="policy text-center text-sm mt-2 mb-4 font-bold">
                <span>{translate('yourcontact:policyParagraph1')}</span>
              </div>
              <div className="policy text-center text-xs mx-6">
                <span>
                  {translate('yourcontact:policyParagraph2')}
                </span>
              </div>
            </div>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default YourContact;
