import React from 'react';
import { useTranslation } from 'react-i18next';
import YesNoRadioButtonInput from '../common/RadioButtonInput';

const MilitaryServiceRadioButton = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <YesNoRadioButtonInput
      errors={errors}
      id="has-served-in-military-radio-button"
      loadFrom="application"
      name="hasServedInMilitary"
      onChange={onChange}
      question={translate('personalinformation:militaryServiceRadioButton')}
      required={false}
    />
  );
};

export default MilitaryServiceRadioButton;
