import axios from 'axios';
import { getAssetsUrl } from './assetsHelper';

const getLenderFriendlyNameMap = async () => {
  const assetsUrl = getAssetsUrl();
  const lenderFriendlyNameMapUrl = `${assetsUrl}/global/lenderFriendlyNameMap.json`;

  try {
    const lenderFriendlyNameMapRequest = await axios.get(lenderFriendlyNameMapUrl);
    const lenderFriendlyNameMap = lenderFriendlyNameMapRequest.data;

    return lenderFriendlyNameMap;
  } catch (error) {
    console.log('failed to retrieve lenderFriendlyNameMap:', error);

    return {};
  }
};

export default getLenderFriendlyNameMap;
