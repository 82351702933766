import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCreditCounselingAgencyFullName, getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';

const Pending = () => {
  const { t: translate } = useTranslation();
  const applicationType = useSelector((state) => state.application.data.type);
  const router = useSelector((state) => state.application.data.router);

  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });
  const noteMessage = applicationType === 'rental'
    ? `${translate('pendinganddecline:subtitle')}`
    : (
      <p className="w-full sm:w-1/2 m-auto pb-5">
        It looks like we need a little more information to be able to provide you with your estimate. We&apos;re connecting
        you with a certified credit counselor from
        {' '}
        {creditCounselingAgencyFullName}
        .
        {' '}
        They will review the information you&apos;ve provided and work with you on next steps. Be on the look out for their call!
      </p>
    );

  return (
    <div className="pending">
      <div className="leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pt-10 pb-56 text-center w-full">
          <h2 className="text-xl sm:text-2xl w-full m-auto">
            {translate('pendinganddecline:title')}
          </h2>
          <div>
            {noteMessage}
            <div>
              <p className="w-full sm:w-1/3 m-auto py-4 text-md sm:text-xl">
                {translate('pendinganddecline:toProceed')}
              </p>
              <p className="color-blue font-bold offer-phone-numbers text-2xl">
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pending;
