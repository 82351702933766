import React from 'react';
import { useSelector } from 'react-redux';
import RadioButtonInput from './RadioButtonInput';

export const englishValue = 'English';
export const spanishValue = 'Spanish';

const PreferredLanguageRadioButtonInput = ({
  checkedValue,
  errors,
  id,
  loadFrom,
  name,
  onChange,
  question,
}) => {
  const storedValue = useSelector((state) => loadFrom && name && state[loadFrom].data[name]);

  const onInputValueChange = (event) => {
    const valueOverride = event.target.value === englishValue;

    onChange(event, valueOverride);
  };

  const options = [];

  options.push({ id: `${id}-english`, label: 'English', value: englishValue });
  options.push({ id: `${id}-spanish`, label: 'Spanish', value: spanishValue });

  return (
    <RadioButtonInput
      checkedValue={checkedValue !== undefined ? checkedValue : storedValue}
      errors={errors}
      name={name}
      onChange={onInputValueChange}
      options={options}
      question={question}
    />
  );
};

export default PreferredLanguageRadioButtonInput;
