import React from 'react';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import OfferPagePhone from '../../../../assets/images/offer-page-phone_graphic.png';

const GetAnAnalysis = () => (
  <div className="w-full bg-dark-blue">
    <div className="container inner-width sm:flex flex-none m-auto sm:py-20 py-10 sm:px-0 px-2">
      <div className="sm:w-2/3 w-full sm:pr-6 pr-0">
        <h2 className="text-white sm:text-right text-center font-bold sm:pt-32">
          Get an automated analysis that is tailored to your financial situation and demonstrates what you can pay towards
          your debt on a monthly basis.
          <sup>*</sup>
        </h2>
        <div className="sm:float-right float-none mt-6">
          <CallToActionButton />
        </div>
        <div className="clearfix" />
        <p className="legal-disclosure text-white sm:float-right float-none mt-20">
          <sup>*</sup>
          Credit Genie does not guarantee any debt reduction and consumers may have other forms of debt we cannot address for them.
        </p>
      </div>
      <div className="w-1/3 sm:block hidden">
        <img src={OfferPagePhone} alt="The offer presentment" />
      </div>
    </div>
  </div>
);

export default GetAnAnalysis;
