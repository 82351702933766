import React from 'react';
import { useDispatch } from 'react-redux';
import DTCOfferPhoneScreen from '../../../../assets/images/dtc-offer_phone.svg';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import PlayButton from '../../../../assets/icons/landing-pages/play_icon.svg';
import { setShowVideo } from '../../../../redux/actions/videoActions';
import { setShowHowItWorksModal } from '../../../../redux/actions/howItWorksModalActions';
import TrustBar from '../../common/TrustBar';

const Hero = () => {
  const dispatch = useDispatch();

  const onWatchTheVideoClick = () => {
    dispatch(setShowVideo({ value: true }));
  };

  const onHowItWorksClick = () => {
    dispatch(setShowHowItWorksModal({ value: true }));
  };

  return (
    <div className="debt-relief-hero">
      <div className="w-full m-auto">
        <div className="container m-auto pt-4 px-2">
          <div className="sm:flex flex-none">
            <div className="sm:w-3/4 w-full sm:pt-10 pt-2">
              <h1 className="pt-4 sm:pb-12 pb-4 sm:text-6xl text-left xl:pr-40 pr-0 font-black">
                The smart solution to paying off your credit cards.
              </h1>
              <h2 className="text-left xl:pr-40 pr-0 mb-8">
                Get started today to find out how much you can save with Credit Genie.
                {' '}
                <strong>It only takes 5 minutes!</strong>
              </h2>
              <CallToActionButton />
              <div className="mt-4 ko-call-to-action outline-none" onClick={onHowItWorksClick} onKeyDown={onHowItWorksClick} role="button" tabIndex="0">
                See how it works
              </div>
              <div className="video mt-8" onClick={onWatchTheVideoClick} onKeyDown={onWatchTheVideoClick} role="button" tabIndex="0">
                <div className="inline-block">
                  Watch the video
                </div>
                <img className="inline-block ml-2" src={PlayButton} alt="Play button" />
              </div>
              <div className="clearfix" />
            </div>
            <div className="sm:block hidden">
              <img src={DTCOfferPhoneScreen} alt="Offer Page" />
            </div>
          </div>
        </div>
        <TrustBar />
      </div>
    </div>
  );
};

export default Hero;
