import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const MedicalDebtOrExpensesCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <CheckboxInput
      id="medical-debt-or-expenses-checkbox"
      label={translate('lifeevents:medicalDebtOrExpenses')}
      loadFrom="hardships"
      name="hasMedicalDebtOrExpenses"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default MedicalDebtOrExpensesCheckbox;
