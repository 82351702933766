import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RadioButtonInput from './RadioButtonInput';

export const noValue = 'no';
export const yesValue = 'yes';

const YesNoRadioButtonInput = ({
  checkedValue,
  errors,
  id,
  loadFrom,
  name,
  onChange,
  question,
  required = true,
}) => {
  const { t: translate } = useTranslation();
  const storedValue = useSelector((state) => {
    const value = loadFrom && name && state[loadFrom].data[name];

    if (value === undefined || value === null) {
      return undefined;
    }

    return value === true ? yesValue : noValue;
  });

  const onInputValueChange = (event) => {
    const valueOverride = event.target.value === yesValue;

    onChange(event, valueOverride);
  };

  const options = [];
  options.push({ id: `${id}-yes`, label: translate('global:yesNoRadioButton:yes'), value: yesValue });
  options.push({ id: `${id}-no`, label: translate('global:yesNoRadioButton:no'), value: noValue });

  return (
    <RadioButtonInput
      checkedValue={checkedValue !== undefined ? checkedValue : storedValue}
      errors={errors}
      id={id}
      name={name}
      onChange={onInputValueChange}
      options={options}
      question={question}
      required={required}
    />
  );
};

export default YesNoRadioButtonInput;
