import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Loading from '../components/common/Loading';
import OnBoardingHeader from '../components/customer-service-portal/common/OnBoardingHeader';
import { setDefaultSearchValue } from '../redux/actions/searchBarActions';
import { storeUsername } from '../redux/actions/userActions';

const OnBoardingLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state) => state.application.isGettingApplication
    || state.borrower.isGettingBorrower
    || state.expenses.isGettingExpenseEstimates
    || state.spouse.isGettingSpouse);

  const isProcessingForgotPassword = useSelector((state) => state.user.isProcessingForgotPassword);
  const isResettingPassword = useSelector((state) => state.user.isResettingPassword);
  const isSettingInitialPassword = useSelector((state) => state.user.isSettingInitialPassword);
  const isSigningIn = useSelector((state) => state.user.isSigningIn);
  const isSigningOut = useSelector((state) => state.user.isSigningOut);

  useEffect(() => {
    if (history.location && history.location.search) {
      const queryString = new URLSearchParams(history.location.search);

      const emailAddress = queryString.get('email');
      const searchValue = queryString.get('searchValue');

      dispatch(setDefaultSearchValue({ value: searchValue }));
      dispatch(storeUsername({ username: emailAddress }));
    }
  }, [dispatch, history]);

  return (
    <div className="on-boarding">
      {isLoading && <Loading message="Please wait..." subText="we are pulling in your application information" />}
      {isProcessingForgotPassword && <Loading message="Processing..." />}
      {isResettingPassword && <Loading message="Processing..." />}
      {isSettingInitialPassword && <Loading message="Processing..." />}
      {isSigningIn && <Loading message="Signing in..." />}
      {isSigningOut && <Loading message="Signing out..." />}
      <OnBoardingHeader />
      {children}
      <Footer />
    </div>
  );
};

export default OnBoardingLayout;
