import React from 'react';
import ClockIcon from '../../../assets/icons/clock_icon.svg';
import NoFeeIcon from '../../../assets/icons/no-fee_icon.svg';
import PaperIcon from '../../../assets/icons/paper_icon.svg';

const WhoIsCreditly = () => (
  <section className="value-props bg-blue border-b py-8">
    <div className="container max-w-5xl mx-auto">
      <h2 className="text-2xl md:text-4xl text-center text-white w-full md:w-1/2 m-auto p-2 px-1">
        So Who is Credit Genie?
      </h2>
      <p className="text-center text-white px-2">
        Credit Genie is a FinTech company that uses data and analytics to rapidly assess a borrower&apos;s financial health. We then
        partner with the National Foundation for Credit Counseling to right-size a borrower&apos;s debt based on our analysis of
        their financial health.
      </p>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <img className="mx-auto text-center" src={NoFeeIcon} alt="No Fee Icon" />
          <h3 className="pt-5 text-center text-white">Free for you</h3>
          <p className="text-center text-white">
            Credit Genie makes its platform available to borrowers at no cost.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <img className="mx-auto text-center cc-margin" src={PaperIcon} alt="Paper Icon" />
          <h3 className="pt-5 text-center text-white">No Paperwork</h3>
          <p className="text-center text-white">
            Unlike other debt solutions, Credit Genie&apos;s application is completely digital and doesn&apos;t require you to submit
            reams of paperwork.
          </p>
        </div>
        <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <img className="mx-auto text-center" src={ClockIcon} alt="Clock Icon" />
          <h3 className="pt-5 text-center text-white">Instantaneous</h3>
          <p className="text-center text-white">
            Get an evaluation within 5 minutes and receive an immediate, customized recommendation.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default WhoIsCreditly;
