import React, { useEffect, useState } from 'react';
import { formatCognitoPhoneNumberForPortal } from '../../../formatters/phoneNumberFormatter';
import { getCurrentAuthenticatedUser } from '../../../helpers/authHelper';

const EditPreferences = () => {
  const defaultUser = { attributes: { email: undefined, name: undefined, phone_number: undefined } };
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const getCurrentUser = async () => {
      const currentUser = await getCurrentAuthenticatedUser();

      setUser(currentUser);
    };

    if (user === defaultUser) {
      getCurrentUser();
    }
  });

  return (
    <div className="edit-preferences lender-tile-animation">
      <div className="w-full flex pt-3">
        <div className="w-1/2 pt-2">
          <h1>Contact information</h1>
          <ul>
            <li>{user.attributes.name}</li>
            <li>{user.attributes.email}</li>
            <li>{formatCognitoPhoneNumberForPortal({ cognitoPhoneNumber: user.attributes.phone_number })}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditPreferences;
