import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  doesUserNeedToChangePassword,
  getCurrentAuthenticatedUser,
  isCustomerServiceUser,
  isUserSignedIn,
} from '../../../helpers/authHelper';
import { EmailAddressSignInInput, PasswordSignInInput } from '../../../components/portal/SignInInputs';
import { getApplicationForUser } from '../../../redux/actions/applicationActions';
import { clearAllBorrowers, getBorrowerForUser } from '../../../redux/actions/borrowerActions';
import { getRouteForBorrower, getRouteForCustomerServiceUser } from './signInRouter';
import { setUserMustChangePassword, signIn } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import { updateCurrentPhoneNumberAfterSignIn } from '../../../redux/actions/customerServiceActions';

const SignIn = ({ history }) => {
  const applicationId = useSelector((state) => state.application.data.id);
  const borrower = useSelector((state) => state.borrower.data);
  const dispatch = useDispatch();
  const [email, setEmail] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(history.location.state && history.location.state.errorMessage);
  const hasGottenApplication = useSelector((state) => state.application.hasGottenApplication);
  const hasGottenBorrower = useSelector((state) => state.borrower.hasGottenBorrower);
  const hasGottenBorrowerForUser = useSelector((state) => state.borrower.hasGottenBorrowerForUser);
  const isGettingApplication = useSelector((state) => state.application.isGettingApplication);
  const isSigningIn = useSelector((state) => state.user.isSigningIn);
  const lastViewedPage = useSelector((state) => state.application.data.lastViewedPage);
  const message = history.location.state && history.location.state.message;
  const [password, setPassword] = useState(undefined);
  const router = useSelector((state) => state.application.data.router);
  const [shouldGetApplication, setShouldGetApplication] = useState(undefined);
  const userHasResetPassword = useSelector((state) => state.user.hasResetPassword);
  const userMustSetInitialPassword = useSelector((state) => state.user.mustSetInitialPassword);
  const userSignInError = useSelector((state) => state.user.signInErrorCode);
  const userSignInFailed = useSelector((state) => state.user.signInFailed);
  const username = useSelector((state) => state.user.data.username);

  const onSubmit = (event) => {
    event.preventDefault();

    setShouldGetApplication(true);
    setErrorMessage(undefined);

    dispatch(signIn({ password, username: username || email }));
  };

  useEffect(() => {
    if (isSigningIn) {
      return;
    }

    if (userSignInFailed) {
      switch (userSignInError) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          setErrorMessage('Incorrect Username or Password');
          break;

        default:
          break;
      }

      return;
    }

    if (userMustSetInitialPassword) {
      history.push('/user-information');

      return;
    }

    const checkIfUserIsSignedIn = async () => {
      const isSignedIn = await isUserSignedIn();

      if (isSignedIn) {
        const isCustomerService = await isCustomerServiceUser();

        if (window.FS) {
          if (isCustomerService) {
            const currentAuthenticatedUser = await getCurrentAuthenticatedUser();

            window.FS.identify(currentAuthenticatedUser.username, {
              displayName: currentAuthenticatedUser.attributes.name,
              email: currentAuthenticatedUser.attributes.email,
            });
          } else if (hasGottenBorrower) {
            window.FS.identify(borrower.id, {
              displayName: `${borrower.firstName} ${borrower.lastName}`,
              email: borrower.email,
            });
          }
        }

        dispatch(clearAllBorrowers());

        if (userHasResetPassword) {
          dispatch(setUserMustChangePassword({ value: false }));
        } else {
          const userNeedsToChangePassword = await doesUserNeedToChangePassword();

          if (userNeedsToChangePassword) {
            console.log('going from /sign-in to /please-check-your-email - user needs to change password!');

            history.push('/please-check-your-email');

            return;
          }
        }

        if (isCustomerService) {
          const customerServiceRoute = getRouteForCustomerServiceUser();

          history.push(customerServiceRoute);

          return;
        }

        if (shouldGetApplication && !isGettingApplication && !hasGottenApplication) {
          setShouldGetApplication(false);
          dispatch(getApplicationForUser());

          return;
        }

        if (shouldGetApplication === false && hasGottenApplication === false) {
          setErrorMessage('There was a problem getting your application information. Please try again.');

          return;
        }

        if (hasGottenApplication) {
          const borrowerRoute = getRouteForBorrower({ lastViewedPage });

          if (borrowerRoute === '/customer-portal/summary') {
            if (hasGottenBorrowerForUser === undefined) {
              dispatch(getBorrowerForUser());
            }

            if (hasGottenBorrowerForUser) {
              history.push('/customer-portal/summary');
            }

            return;
          }

          dispatch(updateCurrentPhoneNumberAfterSignIn({ route: borrowerRoute, router }));

          history.push(borrowerRoute);
        }
      }
    };

    checkIfUserIsSignedIn();
  },
  [
    applicationId,
    borrower,
    dispatch,
    hasGottenApplication,
    hasGottenBorrower,
    hasGottenBorrowerForUser,
    history,
    isGettingApplication,
    isSigningIn,
    lastViewedPage,
    router,
    shouldGetApplication,
    userHasResetPassword,
    userMustSetInitialPassword,
    userSignInError,
    userSignInFailed,
  ]);

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Sign In
          </h2>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8 sign-in">
              <form onSubmit={onSubmit}>
                {!username && <EmailAddressSignInInput onChange={(event) => setEmail(event.target.value)} valueOverride={email} /> }
                <PasswordSignInInput onChange={(event) => setPassword(event.target.value)} />
                <div className="mb-4">
                  <div className="text-right forgot-password">
                    <Link data-cy="forgotpassword" to="/forgot-password">Forgot password?</Link>
                  </div>
                </div>
                <SubmitInput />
              </form>
            </div>
          </div>
          {!errorMessage && message && <p className="success sm:w-1/2 w-full">{message}</p>}
          {errorMessage && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
