import InputMask from 'react-input-mask';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ZipCodeInput = ({
  errors = {},
  id,
  loadFrom,
  mask = '99999',
  maskChar = null,
  name = 'zipCode',
  onChange,
  placeholder = ' ',
  regex = String.raw`\d{5}`,
  required = true,
  validationName,
}) => {
  const { t: translate } = useTranslation();
  const label = translate('yourcontact:addressFields:zip');
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label className="input-container" htmlFor={id}>
          <InputMask
            id={id}
            mask={mask}
            maskChar={maskChar}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            regex={regex}
            required={required}
            type="tel"
            validationname={validationName}
            value={value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isInvalid')}
      </p>
      )}
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default ZipCodeInput;
