import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, saveUpdatedUserAttributes, setSaveUpdatedUserAttributesSucceeded } from '../../../../redux/actions/userActions';
import {
  saveApplication,
  saveBorrower,
  saveRentalApplication,
  saveSpouse,
  updateApplication,
  updateBorrower,
  updateRentalApplication,
  updateSpouse,
} from '../../../../redux/actions/actions';
import {
  saveHardships,
  setSaveHardshipsSucceeded,
  setShouldSaveHardships,
  updateHardship,
} from '../../../../redux/actions/hardshipActions';
import { setSaveApplicationSucceeded } from '../../../../redux/actions/applicationActions';
import { setBorrowerHasChanges, setSaveBorrowerSucceeded } from '../../../../redux/actions/borrowerActions';
import { setSaveRentalApplicationSucceeded } from '../../../../redux/actions/rentalApplicationActions';
import { setSaveSpouseSucceeded } from '../../../../redux/actions/spouseActions';
import { verifyPhoneNumber } from '../../../../redux/actions/twilioActions';
import {
  updateDmpEnrollment,
  saveDmpEnrollment,
  setSaveDmpEnrollmentSucceeded,
} from '../../../../redux/actions/dmpEnrollmentActions';
import {
  updateRentalReliefPlan,
  saveRentalReliefPlan,
  setSaveRentalReliefPlanSucceeded,
} from '../../../../redux/actions/rentalReliefPlanActions';

const useForm = ({ callback = () => {}, validate }) => {
  const borrower = useSelector((state) => state.borrower.data);
  const borrowerAlreadyExists = useSelector((state) => state.borrower.data.id && true);
  const borrowerHasChanges = useSelector((state) => state.borrower.hasChanges);
  const [delayedVerifyPhoneNumber, setDelayedVerifyPhoneNumber] = useState(undefined);
  const [delayedSaveApplication, setDelayedSaveApplication] = useState(undefined);
  const [delayedSaveBorrower, setDelayedSaveBorrower] = useState(undefined);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const isCreatingUser = useSelector((state) => state.user.isSaving);
  const isPhoneNumberValid = useSelector((state) => state.twilio.data.isPhoneNumberValid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isVerifyingPhoneNumber = useSelector((state) => state.twilio.isVerifyingPhoneNumber);
  const saveApplicationFailed = useSelector((state) => state.application.saveFailed);
  const saveBorrowerFailed = useSelector((state) => state.borrower.saveFailed);
  const saveDmpEnrollmentFailed = useSelector((state) => state.dmpEnrollment.saveFailed);
  const saveRentalReliefPlanFailed = useSelector((state) => state.rentalReliefPlan.saveFailed);
  const saveHardshipsFailed = useSelector((state) => state.hardships.savingFailed);
  const saveRentalApplicationFailed = useSelector((state) => state.rentalApplication.saveFailed);
  const saveSpouseFailed = useSelector((state) => state.spouse.saveFailed);
  const saveUpdatedUserAttributesFailed = useSelector((state) => state.user.saveUpdatedUserAttributesSucceeded === false);
  const [shouldSaveApplication, setShouldSaveApplication] = useState(false);
  const shouldSaveHardships = useSelector((state) => state.hardships.shouldSaveHardships);
  const [shouldSaveSpouse, setShouldSaveSpouse] = useState(false);
  const [shouldSaveDmpEnrollment, setShouldSaveDmpEnrollment] = useState(false);
  const [shouldSaveRentalApplication, setShouldSaveRentalApplication] = useState(false);
  const [shouldSaveRentalReliefPlan, setShouldSaveRentalReliefPlan] = useState(false);
  const userAlreadyExists = useSelector((state) => state.user.data.username && true);
  const [values, setValues] = useState({});

  const giveFocusToFirstErrorElement = (validationErrors) => {
    let firstErrorElement;

    const firstErrorElementName = Object.keys(validationErrors)[0];

    const matchingNodesByElementName = document.getElementsByName(firstErrorElementName);

    firstErrorElement = matchingNodesByElementName.length ? matchingNodesByElementName[0] : undefined;

    if (firstErrorElement) {
      firstErrorElement.focus();

      return;
    }

    const validationNameQuery = `[data-validation-name="${firstErrorElementName}"], [validationname="${firstErrorElementName}"]`;

    const matchingNodesByValidationName = document.querySelectorAll(validationNameQuery);

    firstErrorElement = matchingNodesByValidationName.length ? matchingNodesByValidationName[0] : undefined;

    if (firstErrorElement) {
      firstErrorElement.focus();
    }
  };

  const validateElements = ({ elements, valueOverrides = {} }) => {
    if (!elements || !Array.isArray(elements) || !elements.length > 0) {
      return {};
    }

    const dateFormats = {};
    const elementValues = {};
    const emptyValues = {};
    const minValues = {};
    const maxValues = {};
    const regularExpressions = {};
    const requiredValues = {};

    elements.forEach((element) => {
      const validationNameAttribute = element.attributes.validationName || element.attributes['data-validation-name'];

      const elementName = validationNameAttribute ? validationNameAttribute.value : element.name;

      if (element.type === 'checkbox' || element.type === 'radio') {
        if (element.checked) {
          elementValues[elementName] = element.value;
        }
      } else {
        elementValues[elementName] = element.value;
      }

      emptyValues[elementName] = undefined;
      requiredValues[elementName] = element.required;

      const regexAttribute = element.attributes.regex || element.attributes['data-regex'];

      if (regexAttribute && (element.required || (!element.required && element.value))) {
        regularExpressions[elementName] = regexAttribute.value;
      }

      if (element.attributes.dateFormat && (element.required || (!element.required && element.value))) {
        dateFormats[elementName] = element.attributes.dateFormat.value;
      }

      if (element.attributes.valid && element.attributes.valid.value === 'true') {
        dateFormats[elementName] = undefined;
        regularExpressions[elementName] = undefined;
        requiredValues[elementName] = undefined;
      }

      if (element.attributes.min && (element.required || (!element.required && element.value))) {
        minValues[elementName] = element.attributes.min.value;
      }

      if (element.attributes.max) {
        maxValues[elementName] = element.attributes.max.value;
      }
    });

    const validationErrors = validate({
      dateFormats,
      minValues,
      maxValues,
      regularExpressions,
      requiredValues,
      values: {
        ...emptyValues,
        ...elementValues,
        ...values,
        ...valueOverrides,
      },
    });

    return validationErrors;
  };

  const validateComponent = ({ event, valueOverride }) => {
    const element = event.target;

    const validationName = element.attributes.validationName || element.attributes['data-validation-name'];

    const elementName = validationName ? validationName.value : element.name;

    const validationErrors = validateElements({ elements: [element], valueOverrides: { ...valueOverride } });

    setErrors({ ...errors, [elementName]: validationErrors[elementName] });
  };

  const onApplicationChange = (event, valueOverride) => {
    const { name, value } = event.target;

    if (typeof valueOverride !== 'undefined') {
      dispatch(updateApplication({ propertyName: name, value: valueOverride }));
      setValues({ ...values, [name]: valueOverride });
    } else {
      dispatch(updateApplication({ propertyName: name, value: value === '' ? undefined : value }));
      setValues({ ...values, [name]: value });
    }

    setShouldSaveApplication(true);

    if (isSubmitting) {
      validateComponent({ event, valueOverride: typeof valueOverride === 'undefined' ? undefined : { [name]: valueOverride } });
    }
  };

  const onBorrowerChange = (event) => {
    const { name, value } = event.target;

    dispatch(updateBorrower({ propertyName: name, value: value === '' ? undefined : value }));
    setValues({ ...values, [name]: value });

    dispatch(setBorrowerHasChanges({ borrowerHasChanges: true }));

    if (isSubmitting) {
      validateComponent({ event });
    }
  };

  const onDmpEnrollmentChange = (event, valueOverride) => {
    const { name, value } = event.target;

    if (typeof valueOverride !== 'undefined') {
      dispatch(updateDmpEnrollment({ propertyName: name, value: valueOverride }));
      setValues({ ...values, [name]: valueOverride });
    } else {
      dispatch(updateDmpEnrollment({ propertyName: name, value: value === '' ? undefined : value }));
      setValues({ ...values, [name]: value });
    }

    setShouldSaveDmpEnrollment(true);

    if (isSubmitting) {
      validateComponent({ event, valueOverride: typeof valueOverride === 'undefined' ? undefined : { [name]: valueOverride } });
    }
  };

  const onEmailAddressInputBlur = (event, valueOverride, updateFunction) => {
    const { target: element } = event;

    const { name, value } = element;

    const trimmedValue = valueOverride !== undefined ? valueOverride : value.trim();

    // inputs of type email automatically ignore leading and trailing spaces regarding their internal values, but preserve them in the actual inputs
    // As a result, updating the values to the trimmed values doesn't actually change anything, and the spaces remain in the inputs
    // We can get around this by removing the values entirely and then updating them to the trimmed values
    element.value = undefined;
    element.value = trimmedValue;

    dispatch(updateFunction({ propertyName: name, value: trimmedValue === '' ? undefined : trimmedValue }));
    setValues({ ...values, [name]: trimmedValue });

    if (isSubmitting) {
      validateComponent({ event });
    }
  };

  const onHardshipChange = (event) => {
    const { checked, name } = event.target;

    dispatch(updateHardship({ key: name, value: checked }));
    dispatch(setShouldSaveHardships());
  };

  const onRentalApplicationChange = (event, valueOverride) => {
    const { name, value } = event.target;

    if (typeof valueOverride !== 'undefined') {
      dispatch(updateRentalApplication({ propertyName: name, value: valueOverride }));
      setValues({ ...values, [name]: valueOverride });
    } else {
      dispatch(updateRentalApplication({ propertyName: name, value: value === '' ? undefined : value }));
      setValues({ ...values, [name]: value });
    }

    setShouldSaveRentalApplication(true);

    if (isSubmitting) {
      validateComponent({ event, valueOverride: typeof valueOverride === 'undefined' ? undefined : { [name]: valueOverride } });
    }
  };

  const onRentalReliefPlanChange = (event, valueOverride) => {
    const { name, value } = event.target;

    if (typeof valueOverride !== 'undefined') {
      dispatch(updateRentalReliefPlan({ propertyName: name, value: valueOverride }));
      setValues({ ...values, [name]: valueOverride });
    } else {
      dispatch(updateRentalReliefPlan({ propertyName: name, value: value === '' ? undefined : value }));
      setValues({ ...values, [name]: value });
    }

    setShouldSaveRentalReliefPlan(true);

    if (isSubmitting) {
      validateComponent({ event, valueOverride: typeof valueOverride === 'undefined' ? undefined : { [name]: valueOverride } });
    }
  };

  const onSpouseChange = (event) => {
    const { name, value } = event.target;

    dispatch(updateSpouse({ propertyName: name, value: value === '' ? undefined : value }));
    setValues({ ...values, [name]: value });

    setShouldSaveSpouse(true);

    if (isSubmitting) {
      validateComponent({ event });
    }
  };

  const onTextInputBlur = (event, valueOverride, updateFunction) => {
    const { name, value } = event.target;

    const trimmedValue = valueOverride !== undefined ? valueOverride : value.trim();

    dispatch(updateFunction({ propertyName: name, value: trimmedValue === '' ? undefined : trimmedValue }));
    setValues({ ...values, [name]: trimmedValue });

    if (isSubmitting) {
      validateComponent({ event });
    }
  };

  const validateForm = ({ event }) => {
    const validationErrors = validateElements({ elements: [...event.target] });

    setErrors(validationErrors);

    return validationErrors;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const validationErrors = validateForm({ event });

    if (Object.keys(validationErrors).length > 0) {
      giveFocusToFirstErrorElement(validationErrors);

      return;
    }

    if (!userAlreadyExists && !isCreatingUser) {
      dispatch(createUser());
    }

    if (shouldSaveApplication || saveApplicationFailed) {
      if (userAlreadyExists && borrowerAlreadyExists) {
        dispatch(saveApplication());
      } else {
        setDelayedSaveApplication(() => () => saveApplication());
      }
    } else if (userAlreadyExists && borrowerAlreadyExists) {
      dispatch(setSaveApplicationSucceeded());
    }

    if (borrowerHasChanges || saveBorrowerFailed) {
      if (userAlreadyExists && isPhoneNumberValid) {
        dispatch(saveBorrower());
      } else {
        setDelayedVerifyPhoneNumber(() => () => verifyPhoneNumber());
        setDelayedSaveBorrower(() => () => saveBorrower());
      }
    } else if (userAlreadyExists) {
      dispatch(setSaveBorrowerSucceeded());
      dispatch(setSaveUpdatedUserAttributesSucceeded());
    }

    if (saveUpdatedUserAttributesFailed) {
      dispatch(saveUpdatedUserAttributes());
    }

    if (shouldSaveHardships || saveHardshipsFailed) {
      dispatch(saveHardships());
    } else {
      dispatch(setSaveHardshipsSucceeded());
    }

    if (shouldSaveRentalApplication || saveRentalApplicationFailed) {
      dispatch(saveRentalApplication());
    } else {
      dispatch(setSaveRentalApplicationSucceeded());
    }

    if (shouldSaveSpouse || saveSpouseFailed) {
      dispatch(saveSpouse());
    } else {
      dispatch(setSaveSpouseSucceeded());
    }

    if (shouldSaveDmpEnrollment || saveDmpEnrollmentFailed) {
      dispatch(saveDmpEnrollment());
    } else {
      dispatch(setSaveDmpEnrollmentSucceeded());
    }

    if (shouldSaveRentalReliefPlan || saveRentalReliefPlanFailed) {
      dispatch(saveRentalReliefPlan());
    } else {
      dispatch(setSaveRentalReliefPlanSucceeded());
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      callback();

      setIsSubmitting(false);

      return;
    }

    if (delayedVerifyPhoneNumber && userAlreadyExists) {
      setDelayedVerifyPhoneNumber(undefined);
      dispatch(delayedVerifyPhoneNumber());
    }

    if (delayedSaveBorrower && userAlreadyExists && !isVerifyingPhoneNumber && isPhoneNumberValid) {
      setDelayedSaveBorrower(undefined);
      dispatch(delayedSaveBorrower());
    }

    if (delayedSaveApplication && userAlreadyExists && borrowerAlreadyExists) {
      setDelayedSaveApplication(undefined);
      dispatch(delayedSaveApplication());
    }
  }, [
    borrowerAlreadyExists,
    callback,
    delayedSaveApplication,
    delayedSaveBorrower,
    delayedVerifyPhoneNumber,
    dispatch,
    errors,
    isPhoneNumberValid,
    isSubmitting,
    isVerifyingPhoneNumber,
    userAlreadyExists]);

  return {
    onApplicationChange,
    onApplicationTextInputBlur: (event, valueOverride) => onTextInputBlur(event, valueOverride, updateApplication),
    onBorrowerChange,
    onBorrowerEmailAddressInputBlur: (event, valueOverride) => onEmailAddressInputBlur(event, valueOverride, updateBorrower),
    onBorrowerTextInputBlur: (event, valueOverride) => onTextInputBlur(event, valueOverride, updateBorrower),
    onDmpEnrollmentChange,
    onHardshipChange,
    onRentalApplicationChange,
    onRentalReliefPlanChange,
    onSpouseChange,
    onSpouseEmailAddressInputBlur: (event, valueOverride) => onEmailAddressInputBlur(event, valueOverride, updateSpouse),
    onSpouseTextInputBlur: (event, valueOverride) => onTextInputBlur(event, valueOverride, updateSpouse),
    onSubmit,
    borrower,
    errors,
  };
};

export default useForm;
