import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checkmark from '../../../assets/icons/checkmark-green_icon.svg';
import LinkButton from '../../common/LinkButton/LinkButton';
import NameDetail from '../../../pages/offers/OfferDetail/NameDetail';
import { setShowDmpEnrollmentForm } from '../../../redux/actions/dmpEnrollmentActions';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const DmpEnrollment = () => {
  const dispatch = useDispatch();
  const dmpEnrollment = useSelector((state) => state.dmpEnrollment.data);

  const onEditClick = () => {
    dispatch(setShowDmpEnrollmentForm({ value: true }));
  };

  return (
    <div className="lender-tile-animation-1 w-full">
      <div className="plan-enrollment-summary">
        <div className="flex">
          <div className="enrolled pr-5">
            <img className="inline-block" src={checkmark} alt="Checkmark" />
            <div className="inline-block pl-2">Enrolled in a DMP</div>
          </div>
          <LinkButton additionalClasses="edit pl-5" onClick={onEditClick} text="Edit" />
        </div>
        <div className="w-full text-center pt-8">
          Monthly DMP payment amount:
          <div className="payment-amount -mt-2">
            <ValueDetail value={dmpEnrollment.monthlyPaymentAmount} />
          </div>
          <div className="sub-text -mt-2">
            <NameDetail name={`${dmpEnrollment.termInMonths} Months`} />
          </div>
        </div>
        <div className="divider" />
        <div className="flex text-center pt-5">
          <div className="w-1/2">
            <div className="sub-text">
              Date of first due DMP payment:
            </div>
            <div className="-mt-2">
              <NameDetail name={dmpEnrollment.firstPaymentDate} />
            </div>
          </div>
          <div className="w-1/2">
            <div className="sub-text">
              Date of signed DMP agreement:
            </div>
            <div className="-mt-2">
              <NameDetail name={dmpEnrollment.dateSigned} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmpEnrollment;
