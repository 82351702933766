import React, { useState } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateIcon from '../../../common/DateIcon/DateIcon';
import SummaryFilterCategory from '../SummaryFilterCategory/SummaryFilterCategory';

const SummaryFilter = (
  {
    filterErrors,
    filterState,
    onDateChange,
    onDateClear,
    onFilterChange,
    onFilterReset,
    onSubmit,
    tempFilterStartDate,
    tempFilterEndDate,
    tempFilterState,
    toggle,
  },
) => {
  const applicationStatuses = Object.keys(filterState.applicationStatus);
  const applicationTypes = ['credit', 'rental'];
  const claimedStatuses = Object.keys(filterState.claimedStatus);
  const offerStatuses = Object.keys(filterState.offerStatus);
  const planEnrollmentStatuses = filterState.planEnrollmentStatus && Object.keys(filterState.planEnrollmentStatus);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDateAlreadyExists, setStartDateAlreadyExists] = useState(false);

  const formattedStartDate = tempFilterStartDate && DateTime.fromJSDate(tempFilterStartDate).toFormat('MM/dd/yyyy');
  const formattedEndDate = tempFilterEndDate && DateTime.fromJSDate(tempFilterEndDate).toFormat('MM/dd/yyyy');

  const onDatePickerChange = (date) => {
    if (startDateAlreadyExists) {
      setShowDatePicker(false);
    } else {
      setStartDateAlreadyExists(true);
    }

    onDateChange(date);
  };

  const onDatePickerClear = () => {
    setStartDateAlreadyExists(false);

    onDateClear();
  };

  const onResetFiltersClick = () => {
    setStartDateAlreadyExists(false);

    onFilterReset();
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="filter-container">
          <div className="filter-contents">
            <div className="filter-header">
              <h1>FILTER BY</h1>
              <button
                className="text-red-600"
                data-cy="reset-default-filter"
                onClick={onResetFiltersClick}
                type="button"
              >
                Reset to Default
              </button>
            </div>
            <hr />
            <div className="filter-group pb-4">
              <div className="filter-title">
                <h2>Date</h2>
              </div>
              <div className="flex">
                <DateIcon date={tempFilterStartDate} />
                <DatePicker
                  className="date-picker"
                  endDate={tempFilterEndDate}
                  minDate={tempFilterStartDate}
                  onChange={onDatePickerChange}
                  onClickOutside={() => setShowDatePicker(false)}
                  onInputClick={() => setShowDatePicker(true)}
                  open={showDatePicker}
                  placeholderText={!tempFilterStartDate ? 'Select a Date Range' : undefined}
                  selected={tempFilterStartDate}
                  startDate={tempFilterStartDate}
                />
                <span className="date-picker-display">
                  {formattedStartDate && formattedStartDate}
                  {formattedEndDate && ` - ${formattedEndDate}`}
                </span>
                <button
                  className="clear-date"
                  onClick={onDatePickerClear}
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
            <hr />
            <SummaryFilterCategory
              filterKeys={offerStatuses}
              filterErrors={filterErrors}
              filterState={filterState}
              onFilterChange={onFilterChange}
              propertyName="offerStatus"
              tempFilterState={tempFilterState}
              title="Offer Status"
            />
            <SummaryFilterCategory
              filterKeys={applicationStatuses}
              filterErrors={filterErrors}
              filterState={filterState}
              onFilterChange={onFilterChange}
              propertyName="applicationStatus"
              tempFilterState={tempFilterState}
              title="Application Status"
            />
            <SummaryFilterCategory
              filterKeys={applicationTypes}
              filterErrors={filterErrors}
              filterState={filterState}
              onFilterChange={onFilterChange}
              propertyName="applicationType"
              tempFilterState={tempFilterState}
              title="Application Type"
            />
            <SummaryFilterCategory
              filterKeys={claimedStatuses}
              filterErrors={filterErrors}
              filterState={filterState}
              onFilterChange={onFilterChange}
              propertyName="claimedStatus"
              tempFilterState={tempFilterState}
              title="Claimed/Unclaimed"
            />
            {
              planEnrollmentStatuses && (
                <SummaryFilterCategory
                  filterKeys={planEnrollmentStatuses}
                  filterErrors={filterErrors}
                  filterState={filterState}
                  onFilterChange={onFilterChange}
                  propertyName="planEnrollmentStatus"
                  tempFilterState={tempFilterState}
                  title="Plan Enrollment Status"
                />
              )
            }
            <div className="flex py-5">
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className="cancel-value w-1/2 cursor-pointer"
                data-cy="filter-cancel"
                onClick={toggle}
                onKeyDown={() => toggle}
              >
                Cancel
              </p>
              <button type="submit" className="call-to-action w-1/2" data-cy="filter-submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SummaryFilter;
