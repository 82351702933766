import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../components/application/common/BackButton';
import { ConfirmEmailAddressInput } from '../../../components/portal/ResetPasswordInputs';
import { isCustomerServiceUser } from '../../../helpers/authHelper';
import { storeUsername, storeVerificationCode } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import VerificationCodeInput from './VerificationCodeInput';

const VerificationCode = ({ history, location }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(undefined);
  const storedVerificationCode = useSelector((state) => state.user.data.verificationCode);
  const username = useSelector((state) => state.user.data.username);
  const [verificationCode, setVerificationCode] = useState(undefined);

  const onSubmit = (event) => {
    event.preventDefault();

    if (email) {
      dispatch(storeUsername({ username: email }));
    }

    dispatch(storeVerificationCode({ verificationCode }));
  };

  useEffect(() => {
    if (location && location.search) {
      const queryString = new URLSearchParams(location.search);

      const emailParameter = queryString.get('email');
      const codeParameter = queryString.get('code');

      dispatch(storeUsername({ username: emailParameter }));
      dispatch(storeVerificationCode({ verificationCode: codeParameter }));

      history.push('/reset-password');

      return;
    }

    if (storedVerificationCode) {
      history.push('/reset-password');

      return;
    }

    const checkIfUserIsCustomerService = async () => {
      const customerServiceUser = await isCustomerServiceUser();
      if (customerServiceUser) {
        history.push('/user-information');
      }
    };

    checkIfUserIsCustomerService();
  });

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <BackButton />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Verification Code
          </h2>
          <p className="text-center">
            Please enter your
            {' '}
            {!username && 'email address and'}
            {' '}
            verification code.
          </p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <form onSubmit={onSubmit}>
                {
                  !username
                  && <ConfirmEmailAddressInput onChange={(event) => { setEmail(event.target.value); }} valueOverride={email} />
                }
                <VerificationCodeInput onChange={(event) => { setVerificationCode(event.target.value); }} />
                <SubmitInput />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VerificationCode);
