import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BorrowerTile from '../BorrowerTile/BorrowerTile';
import FilterResults from '../../common/FilterResults/FilterResults';
import { getApplicationsForServicePortal } from '../../../../redux/actions/applicationActions';
import { listUsersWithInformation } from '../../../../redux/actions/userActions';
import objectValueChecker from '../../../../helpers/objectValueHelper';
import Paginate from '../../../common/Pagination/Paginate';
import SearchBar from '../../../common/SearchBar/SearchBar';
import SummaryFilter from '../SummaryFilter/SummaryFilter';
import { updateSummaryFilters } from '../../../../redux/actions/summaryFilterActions';

const SummaryFilterComponent = (
  {
    borrowers,
    filterResultsTextColor,
    shouldFilterByUserId,
  },
) => {
  const borrowersPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const customerServiceUsers = useSelector((state) => state.user.list);
  const defaultSearchValue = useSelector((state) => state.searchBar.defaultSearchValue);
  const dispatch = useDispatch();
  const indexOfLastBorrower = currentPage * borrowersPerPage;
  const indexOfFirstBorrower = indexOfLastBorrower - borrowersPerPage;
  const initialFilterState = useSelector((state) => state.summaryFilters.initialState || state.summaryFilters);
  const filters = useSelector((state) => state.summaryFilters.updatedState || state.summaryFilters);
  const [filteredBorrowers, setFilteredBorrowers] = useState([]);
  const [filterErrors, setFilterErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(defaultSearchValue);
  const seventyTwoHoursAgo = DateTime.local().minus({ hours: 72 }).toFormat('yyyy-MM-dd');
  const [tempFilters, setTempFilters] = useState(filters);
  const [tempFilterStartDate, setTempFilterStartDate] = useState(
    (filters.dateRange && filters.dateRange.startDate && DateTime.fromISO(filters.dateRange.startDate).toJSDate()) || undefined,
  );
  const [tempFilterEndDate, setTempFilterEndDate] = useState(
    (filters.dateRange && filters.dateRange.endDate && DateTime.fromISO(filters.dateRange.endDate).toJSDate()) || undefined,
  );
  const userId = useSelector((state) => state.user.data.username);

  const pageCount = Math.ceil(filteredBorrowers.length / borrowersPerPage);

  const toggle = () => {
    setFilterErrors({});
    setTempFilters(filters);
    setIsOpen(!isOpen);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const onDateChange = (date) => {
    if (!tempFilterStartDate && !tempFilterEndDate) {
      setTempFilterStartDate(date);
    } else if (tempFilterStartDate && !tempFilterEndDate) {
      setTempFilterEndDate(date);
    } else if (tempFilterStartDate && tempFilterEndDate) {
      setTempFilterStartDate(date);
      setTempFilterEndDate(undefined);
    }
  };

  const onDateClear = () => {
    setTempFilterStartDate(undefined);
    setTempFilterEndDate(undefined);
  };

  const onFilterChange = (event, valueOverride, filterCategory) => {
    const { name } = event.target;
    setTempFilters({ ...tempFilters, [filterCategory]: { ...tempFilters[filterCategory], [name]: valueOverride } });
  };

  const onFilterReset = () => {
    setTempFilters(initialFilterState);
    onDateClear();
  };

  const onFilterSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    setFilterErrors({});

    const tempFilterKeys = Object.keys(tempFilters);

    tempFilterKeys.forEach((key) => {
      if (key === 'dateRange') {
        return;
      }

      const filterNotSelected = objectValueChecker(tempFilters[key], false);

      if (filterNotSelected) {
        errors[key] = 'required';
      }
    });

    setFilterErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (tempFilters.dateRange) {
      tempFilters.dateRange.startDate = tempFilterStartDate
        ? DateTime.fromJSDate(tempFilterStartDate).toFormat('yyyy-MM-dd')
        : seventyTwoHoursAgo;

      tempFilters.dateRange.endDate = (
        (tempFilterEndDate && DateTime.fromJSDate(tempFilterEndDate))
        || (tempFilterStartDate && DateTime.fromJSDate(tempFilterStartDate))
        || DateTime.local()
      ).plus({ days: 1 }).toFormat('yyyy-MM-dd');
    }

    dispatch(updateSummaryFilters(tempFilters));
    dispatch(getApplicationsForServicePortal({ shouldFilterByUserId, userId }));
    toggle();
    setCurrentPage(1);
  };

  const borrowerTiles = filteredBorrowers
    .slice(indexOfFirstBorrower, indexOfLastBorrower)
    .map((borrower) => (
      <div className="borrower-tile-animation" key={`borrower-tile-${borrower.id}`}>
        <BorrowerTile
          alerts={borrower.alerts}
          applicationDate={borrower.application.createdAt}
          applicationId={borrower.application.id}
          applicationStatus={borrower.application.status}
          applicationType={borrower.application.type}
          assignedTo={borrower.application.assignedTo}
          borrowerId={borrower.id}
          borrowerName={`${borrower.firstName} ${borrower.lastName}`}
          borrowerPhoneNumber={borrower.phoneNumber}
          decision={borrower.application.decision}
          enrolledInDmp={borrower.application.dmpEnrollment && borrower.application.dmpEnrollment.active}
          enrolledInRentalReliefPlan={borrower.rentalApplication && borrower.rentalApplication.rentalReliefPlan}
          hasConnectedAccount={borrower.hasConnectedAccount}
          lastCompletedPage={borrower.application.lastCompletedPage}
          preferredLanguage={borrower.preferredLanguage}
          rentalApplication={borrower.rentalApplication}
          router={borrower.application.router}
        />
      </div>
    ));

  const filterByApplicationType = useCallback((borrower) => {
    const filterApplicationType = filters.applicationType || { credit: true, rental: true };
    const applicationType = borrower.application.type;

    const enabledApplicationTypeFilters = Object.keys(filterApplicationType).filter((key) => filterApplicationType[key]);

    const isCreditApplication = (applicationType === 'credit' || !applicationType) && enabledApplicationTypeFilters.includes('credit');
    const isRentalApplication = applicationType === 'rental' && enabledApplicationTypeFilters.includes('rental');

    return isCreditApplication || isRentalApplication;
  }, [filters]);

  const filterByClaimedStatus = useCallback((borrower) => {
    const { claimedStatus } = filters;

    const claimed = borrower.application.assignedTo;
    const isClaimed = claimedStatus.claimed && claimed;
    const unClaimed = claimedStatus.unclaimed && !claimed;

    return isClaimed || unClaimed;
  }, [filters]);

  const filterByOfferStatus = useCallback((borrower) => {
    const { offerStatus } = filters;
    const { decision } = borrower.application;

    if (offerStatus.receivedOffer && offerStatus.noOffer) {
      return true;
    }

    const hasOffers = decision.isQualified && offerStatus.hasOffers;
    const noOffers = !decision.isQualified && offerStatus.noOffers;

    return hasOffers || noOffers;
  }, [filters]);

  const filterByReliefPlanEnrollmentStatus = useCallback((borrower) => {
    const planEnrollmentStatus = filters.planEnrollmentStatus || { isEnrolledInAPlan: true, isNotEnrolledInAPlan: true };

    const dmpEnrollment = borrower.application.dmpEnrollment || { active: false };
    const rentalReliefPlan = borrower.rentalApplication && borrower.rentalApplication.rentalReliefPlan;

    const isEnrolledInAPlan = planEnrollmentStatus.isEnrolledInAPlan && (dmpEnrollment.active || rentalReliefPlan);
    const isNotEnrolledInAPlan = planEnrollmentStatus.isNotEnrolledInAPlan && (!dmpEnrollment.active && !rentalReliefPlan);

    return isEnrolledInAPlan || isNotEnrolledInAPlan;
  }, [filters]);

  const filterBySearch = useCallback((borrower) => {
    if (!search) {
      return true;
    }

    setCurrentPage(1);

    const propertiesToSearchFor = [
      'email',
      'id',
      'firstName',
      'fullName',
      'lastName',
      'phoneNumber',
    ];

    const fullName = { fullName: `${borrower.firstName} ${borrower.lastName}` };

    Object.assign(borrower, fullName);

    const substring = search.toLowerCase().trim().replace(/[\])[(]/g, '').replace(/[+]/g, '\\+');

    try {
      const matchWasFound = propertiesToSearchFor.some((property) => borrower[property].toLowerCase().match(substring));

      return matchWasFound;
    } catch (error) {
      return false;
    }
  }, [search]);

  useEffect(() => {
    if (customerServiceUsers.length === 0) {
      dispatch(listUsersWithInformation());
    }
  }, [customerServiceUsers, dispatch]);

  useEffect(() => {
    const filterBorrowers = borrowers
      .filter(filterByClaimedStatus)
      .filter(filterByApplicationType)
      .filter(filterByOfferStatus)
      .filter(filterByReliefPlanEnrollmentStatus)
      .filter(filterBySearch);

    setFilteredBorrowers(filterBorrowers);
  }, [
    borrowers,
    dispatch,
    filters,
    filterByApplicationType,
    filterByClaimedStatus,
    filterByOfferStatus,
    filterByReliefPlanEnrollmentStatus,
    filterBySearch,
    search,
    setFilteredBorrowers,
  ]);

  return (
    <section>
      {isOpen && (
        <SummaryFilter
          filterErrors={filterErrors}
          filterState={filters}
          onDateChange={onDateChange}
          onDateClear={onDateClear}
          onFilterChange={onFilterChange}
          onFilterReset={onFilterReset}
          onSubmit={onFilterSubmit}
          tempFilterStartDate={tempFilterStartDate}
          tempFilterEndDate={tempFilterEndDate}
          tempFilterState={tempFilters}
          toggle={toggle}
        />
      )}
      <SearchBar defaultValue={defaultSearchValue} onChange={(event) => setSearch(event.target.value)} onClick={toggle} />
      <FilterResults data={filteredBorrowers} dataName="Borrowers" textColor={filterResultsTextColor} />
      <div className="tiles">
        {borrowerTiles}
      </div>
      <div className="container mx-auto pl-2 pr-2 pb-20 text-center w-full">
        <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </div>
    </section>
  );
};

export default SummaryFilterComponent;
