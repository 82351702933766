/* eslint-disable max-len */
import React from 'react';
import Accordion from '../../../components/brochure/Accordion/Accordion';

const CustomerFaq = () => (
  <div className="container mx-auto pl-2 pr-2 text-center w-full h-screen">
    <h2 className="text-xl sm:text-2xl w-full mx-auto mb-4">
      FAQ
    </h2>
    <div className="text-left">
      <Accordion
        question="What is a Credit Genie Debt Score? "
        answer={(
          <div>
            <p className="mb-2">A Credit Genie Debt Score is a proprietary score developed by Credit Genie to help a consumer understand their ability to manage and pay their current unsecured debt obligations (credit cards, unsecured personal loans, unsecured lines of credit) in relation to their income and expenses.</p>
            <p>The higher a consumer&apos;s Debt Score is, the more likely they are going to be able to actively manage their current debt levels without any intervention.</p>
          </div>
      )}
      />

      <Accordion
        question="Why do I need to connect my bank account?"
        answer={(
          <div>
            <p className="mb-2">To provide users with a complete assessment of their financial health, Credit Genie may ask you to link your bank account during the application process to provide a better understanding of your full financial picture.</p>
            <p>When you link your bank account, we analyze your transaction-level information, like the bills you pay and the money you make, to help you understand your financial health and provide you with guidance on how to manage your debt. Make sure to link the account where you receive your income, as this will allow us to generate the most accurate financial health assessment for you.</p>
          </div>
      )}
      />

      <Accordion
        question="Worried about security?"
        answer={(
          <div>
            <p className="mb-2">
              We get it, and we&apos;re here to help break it down.
            </p>
            <ul className="list-disc mb-4 list-inside">
              <li>Security of your personal information and data are top priorities at Plaid</li>
              <li>We use Plaid, the nation&apos;s largest bank connection provider, to securely link your accounts</li>
              <li>Credit Genie only has read access to the accounts you link and we never store your login credentials</li>
              <li>If you decide not to link your account, you can still speak to a counselor (don&apos;t worry — it won&apos;t affect your credit score)</li>
            </ul>
            <p className="mb-2">
              For more information about Plaid, feel free to check out their privacy policy
              {' '}
              <a href="https://plaid.com/legal/" className="underline text-blue-500">
                here
              </a>
              .
            </p>
            <p><a href="https://www.nfcc.org/faqs/credit-and-debt-counseling/" className="underline text-blue-500">https://www.nfcc.org/faqs/credit-and-debt-counseling/</a></p>
            <p><a href="https://www.nfcc.org/faqs/credit-reports-and-credit-scores/" className="underline text-blue-500">https://www.nfcc.org/faqs/credit-reports-and-credit-scores/</a></p>
            <p><a href="https://www.nfcc.org/faqs/debt-counseling/" className="underline text-blue-500">https://www.nfcc.org/faqs/debt-counseling/</a></p>
          </div>
      )}
      />
    </div>
  </div>
);

export default CustomerFaq;
