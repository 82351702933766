import React from 'react';

const ButtonInput = ({
  className,
  id,
  onClick,
  text,
}) => (
  <input
    className={`${className} call-to-action py-3 mt-5 ml-auto mr-auto text-center block cursor-pointer`}
    id={id}
    onClick={onClick}
    type="button"
    value={text}
  />
);

export default ButtonInput;
