const numberOfCharactersInDateString = 10;

const formatApplicationDateForAws = ({ applicationDate }) => {
  if (!applicationDate || applicationDate.length !== numberOfCharactersInDateString) {
    return undefined;
  }

  // applicationDate is in the format MM-DD-YYYY
  const day = applicationDate.substring(3, 5);
  const month = applicationDate.substring(0, 2);
  const year = applicationDate.substring(6, 10);

  const awsDate = `${year}-${month}-${day}`;

  return awsDate;
};

const formatAwsDateForApplication = ({ awsDate }) => {
  if (!awsDate || awsDate.length !== numberOfCharactersInDateString) {
    return undefined;
  }

  // awsDate is in the format YYYY-MM-DD
  const day = awsDate.substring(8, 10);
  const month = awsDate.substring(5, 7);
  const year = awsDate.substring(0, 4);

  const applicationDate = `${month}-${day}-${year}`;

  return applicationDate;
};

export {
  formatApplicationDateForAws,
  formatAwsDateForApplication,
};
