import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../application/common/CurrencyInput/CurrencyInput';

const BackRentAmountInput = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="pt-2">
      <CurrencyInput
        allowZero
        errors={errors}
        id="back-rent-amount-input"
        label={translate('rentalinformation:amountOfBackrentDue')}
        loadFrom="rentalApplication"
        name="backRentAmount"
        onChange={onChange}
        maxLength={6}
      />
    </div>
  );
};

export default BackRentAmountInput;
