import React from 'react';
import { DateTime } from 'luxon';

const DebtScoreCalendar = ({ debtScores }) => {
  const circleNumbers = ['one', 'two', 'three', 'four', 'five', 'six'];
  const cxValues = [60, 114, 171, 225, 282, 338];
  const months = [...Array(6)].map((x, i) => DateTime.local().minus({ months: i }).toFormat('MMM'));
  const monthlyDebtScores = [];
  const orderedMonthlyDebtScores = [];

  debtScores.forEach((debtScore) => {
    const { month } = DateTime.fromISO(debtScore.createdAt);
    const match = monthlyDebtScores.find((o) => o.month === month);
    if (!match) {
      monthlyDebtScores.push({ value: debtScore.value, month });
    }
  });

  months.forEach((month, index) => {
    const monthNum = DateTime.fromFormat(month, 'MMM').toFormat('M');
    const debtScoreMatch = monthlyDebtScores.find((o) => o.month === parseInt(monthNum, 10));

    orderedMonthlyDebtScores[index] = debtScoreMatch || undefined;
  });

  const debtScorePoints = orderedMonthlyDebtScores
    .reverse()
    .map((debtScore, index) => (
      <svg key={months[index]}>
        {debtScore
          && (
            <circle
              className={circleNumbers[index]}
              cx={cxValues[index]}
              cy={(100 - debtScore.value) + 10}
              r="5"
            />
          )}
      </svg>
    ));

  const debtScoreLines = orderedMonthlyDebtScores
    .slice(0, orderedMonthlyDebtScores.length)
    .map((debtScore, index) => (
      <svg key={months[index]}>
        {debtScore && orderedMonthlyDebtScores[index + 1]
          && (
            <line
              style={{ animation: `opacity-transition ${0.15 + (0.10 * index)} linear` }}
              x1={cxValues[index] + 1.5}
              y1={(100 - debtScore.value + 9.5)}
              x2={cxValues[index + 1] + 0.5}
              y2={(100 - orderedMonthlyDebtScores[index + 1].value + 9.5)}
            />
          )}
      </svg>
    ));

  return (
    <div className="debt-score-calendar">
      <svg
        viewBox="0 0 359 124"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="path">
          <g className="path-container" id="Portal-Summary" transform="translate(-27.000000, -269.000000)">
            <g transform="translate(26.000000, 263.000000)">
              <text className="months">
                <tspan x="50" y="127">{months[5]}</tspan>
                <tspan x="105" y="127">{months[4]}</tspan>
                <tspan x="155" y="127">{months[3]}</tspan>
                <tspan x="215" y="127">{months[2]}</tspan>
                <tspan x="275" y="127">{months[1]}</tspan>
                <tspan x="330" y="127">{months[0]}</tspan>
              </text>
              <text className="score-range">
                <tspan x="-3" y="20">100</tspan>
                <tspan x="3.82617188" y="44">75</tspan>
                <tspan x="3.82617188" y="65">50</tspan>
                <tspan x="3.82617188" y="87">25</tspan>
                <tspan x="7.16308594" y="110">0</tspan>
              </text>
              <rect className="clips red-clip" x="26" y="93" />
              <rect className="bars red-bar" x="30" y="93" />
              <rect className="clips orange-clip" x="26" y="72" />
              <rect className="bars orange-bar" x="30" y="72" />
              <rect className="clips yellow-clip" x="26" y="51" />
              <rect className="bars yellow-bar" x="30" y="51" />
              <rect className="clips green-clip" x="26" y="30" />
              <rect className="bars green-bar" x="30" y="30" />
              <rect className="clips light-blue-clip" x="26" y="9" />
              <rect className="bars light-blue-bar" x="30" y="9" />

              {debtScorePoints}
              {debtScoreLines}
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default DebtScoreCalendar;
