import React from 'react';
import { CustomerServiceInformationTile, EditPreferences } from '../../../components/customer-service-portal';

const Preferences = () => (
  <div>
    <div className="leading-normal tracking-normal grey-bg pb-20">
      <div className="borrower-summary-components container mx-auto pl-2 pr-2 pt-8 pb-8 sm:flex flex-none">
        <div className="borrower-info">
          <CustomerServiceInformationTile />
        </div>
        <div className="dynamic-info box bg-white">
          <h1>Preferences</h1>
          <EditPreferences />
        </div>
      </div>
    </div>
  </div>
);

export default Preferences;
