import React from 'react';
import StepsIcon from '../../../assets/icons/steps_icon.svg';

const HardshipHowItWorks = () => (
  <section className="border-b py-8">
    <div className="container max-w-5xl mx-auto m-8">
      <h2 className="text-2xl md:text-4xl text-center w-full md:w-1/2 m-auto pb-10">
        How it works
      </h2>
      <div className="flex flex-wrap sm:mr-0 mr-5">
        <div className="w-1/3 flex flex-col flex-grow flex-shrink">
          <div className="flex-row">
            <h3 className="pt-2 sm:pt-5 text-right pr-5">Tell Us About Yourself</h3>
          </div>
          <div className="flex-row left-side-margin-top">
            <h3 className="pt-5 text-right pr-5">Verify Your Information</h3>
          </div>
          <div className="flex-row left-side-margin-top">
            <h3 className="pt-5 text-right pr-5">Speak to a Certified Credit Counselor</h3>
          </div>
          <div className="flex-row left-side-margin-top get-your-evaluation">
            <h3 className="pt-5 text-right pr-5">Get Your Evaluation</h3>
          </div>
        </div>
        <div className="steps-icon sm:flex flex-col flex-shrink">
          <img src={StepsIcon} alt="Steps Icon" />
        </div>
        <div className="w-1/3 sm:flex hidden flex-col flex-grow flex-shrink">
          <div className="flex-row">
            <p className="pt-5 pr-20 pl-5">Tell us your financial story. With any information you share, we make security a priority.</p>
          </div>
          <div className="flex-row right-side-margin-top based-on">
            <p className="pt-5 pr-20 pl-5">Based on your current debt, income, and spending, we&apos;ll assess your ability to manage your debt.</p>
          </div>
          <div className="flex-row right-side-margin-top you-will-receive">
            <p className="pt-5 pr-20 pl-5">
              You&apos;ll receive an immediate recommendation on managing your debt, which may include a reduction to your debt or interest rate.
            </p>
          </div>
          <div className="flex-row right-side-margin-top debt-adjustment">
            <p className="pt-5 pr-20 pl-5">Debt adjustment takes effect immediately from your lender, so you breathe easier.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HardshipHowItWorks;
