/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import DebtReliefEnrollmentQuestions from '../Dmp/DebtReliefEnrollmentQuestions/DebtReliefEnrollmentQuestions';
import RentalReliefEnrollmentQuestions from '../RentalReliefPlan/RentalReliefPlanEnrollment/RentReliefEnrollmentQuestions/RentalReliefEnrollmentQuestions';

const PlanEnrollmentForm = () => {
  const showDebtReliefQuestions = useSelector((state) => state.application.data.type === 'credit');
  const showRentalReliefQuestions = useSelector((state) => state.application.data.type === 'rental');

  return (
    <div className="modal-bg absolute pin z-50 overflow-auto flex w-full h-screen">
      <div className="modal-form relative p-8 bg-white m-auto shadow-xl">
        {showDebtReliefQuestions && <DebtReliefEnrollmentQuestions />}
        {showRentalReliefQuestions && <RentalReliefEnrollmentQuestions />}
      </div>
    </div>
  );
};

export default PlanEnrollmentForm;
