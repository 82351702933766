import React from 'react';
import CurrencyInput from '../CurrencyInput';

const TextInputDetail = ({
  ariaLabel = undefined,
  errors,
  id,
  label,
  name,
  onChange,
  value,
}) => (
  <CurrencyInput
    allowZero
    ariaLabel={ariaLabel}
    autoFocusThis
    errors={errors}
    id={id}
    label={label}
    maxLength={5}
    name={name}
    onChange={onChange}
    valueOverride={value}
  />
);

export default TextInputDetail;
