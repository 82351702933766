import React from 'react';
import { DateTime } from 'luxon';
import DateInput from '../../../../application/common/DateInput/DateInput';

const PlannedEndDateInput = (
  {
    errors = {},
    id,
    onChange,
    validationName,
  },
) => {
  const oneYearAgo = DateTime.local().minus({ years: 1 }).toFormat('MM-dd-yyyy');
  const sixYearsFromNow = DateTime.local().plus({ years: 6 }).toFormat('MM-dd-yyyy');

  return (
    <DateInput
      errors={errors}
      id={id}
      label="Planned end date"
      loadFrom="rentalReliefPlan"
      maxDateAllowed={sixYearsFromNow}
      minDateAllowed={oneYearAgo}
      name="plannedEndDate"
      onChange={onChange}
      validationName={validationName}
    />
  );
};

export default PlannedEndDateInput;
