const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');

    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (error) {
    console.log('failed to load state:', error);

    return null;
  }
};

const saveState = ({ state }) => {
  try {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    console.log('failed to save state:', error);
  }
};

export {
  loadState,
  saveState,
};
