import React from 'react';
import { DateTime } from 'luxon';
import DateInput from '../../../application/common/DateInput/DateInput';

const DmpDateInactiveDateInput = (
  {
    errors = {},
    id,
    onChange,
    validationName,
  },
) => {
  const oneYearAgo = DateTime.local().minus({ years: 1 }).toFormat('MM-dd-yyyy');
  const today = DateTime.local().toFormat('MM-dd-yyyy');

  return (
    <DateInput
      errors={errors}
      id={id}
      label="Date DMP became inactive"
      loadFrom="dmpEnrollment"
      maxDateAllowed={today}
      minDateAllowed={oneYearAgo}
      name="inactiveAt"
      onChange={onChange}
      required={false}
      validationName={validationName}
    />
  );
};

export default DmpDateInactiveDateInput;
