import React from 'react';
import { useSelector } from 'react-redux';
import ButtonInput from '../../../components/common/ButtonInput/ButtonInput';
import {
  DebtScore,
  DebtScoreCalendar,
  IncomeAndCheckingBalanceCalendar,
  MonthlyAndTotalDebtCalendar,
} from '../../../components/customer-portal/index';

const CustomerSummary = ({ history }) => {
  const debtScores = useSelector((state) => state.debtScores.data);

  const debtScoresSortedByCreatedAt = debtScores
    .sort((debtScoreA, debtScoreB) => new Date(debtScoreB.createdAt) - new Date(debtScoreA.createdAt));

  return (
    <div className="customer-summary container m-auto pb-20">
      <DebtScore
        debtScores={debtScoresSortedByCreatedAt}
      />
      <DebtScoreCalendar
        debtScores={debtScoresSortedByCreatedAt}
      />
      <div className="divider" />
      <div className="cashflow-debts-expenses">
        <div className="sm:flex flex-none">
          <div className="sm:w-1/2 w-full">
            <h2 className="text-lg">Income Summary</h2>
            <IncomeAndCheckingBalanceCalendar />
          </div>
          <div className="sm:w-1/2 w-full">
            <h2 className="text-lg">Debt Summary</h2>
            <MonthlyAndTotalDebtCalendar />
          </div>
        </div>
        <div className="pt-5">
          <ButtonInput className="customer-portal-input" text="Review Financials" onClick={() => history.push('/customer-portal/financials')} />
        </div>
      </div>
    </div>
  );
};

export default CustomerSummary;
