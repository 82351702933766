import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { CustomerAlertTile } from '../../../components/customer-portal';

const CustomerAlerts = () => {
  const alerts = useSelector((state) => state.alerts.data);
  const application = useSelector((state) => state.application.data);
  const alertEventDates = useSelector((state) => state.alerts.eventDates);
  const plaidItems = useSelector((state) => state.plaid.plaidItems);
  const applicationCreatedAt = DateTime.fromISO(application.createdAt).toFormat('MM-dd-yyyy');
  const bankDisconnectedAlerts = alerts.filter((alert) => alert.type === 'bankAccountDisconnected' && !alert.hasBeenSeenByBorrower);

  return (
    <div className="customer-alerts container m-auto px-6 pb-20">
      <div className="customer-portal-title">Alerts</div>
      <div className="sm:w-1/2 w-full m-auto pt-4">
        {plaidItems.length === 0 && (
          <CustomerAlertTile
            alert="Bank Not Connected"
            date={applicationCreatedAt}
            description="You do not have a bank account connected."
            key={`bank-not-connected-tile-${alert.id}`}
          />
        )}
        {
          bankDisconnectedAlerts.length > 0 && bankDisconnectedAlerts.map((alert) => (
            <CustomerAlertTile
              alert="Account Disconnected"
              date={(new Date(alertEventDates[alert.id])).toLocaleDateString('en-US', { timeZone: 'UTC' })}
              description="Your bank account was disconnected."
              key={`bank-not-connected-tile-${alert.id}`}
            />
          ))
        }
      </div>
    </div>
  );
};

export default CustomerAlerts;
