import React from 'react';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import logo from '../../../../assets/logos/creditgenie_logo_KO.svg';
import NFCCLogo from '../../../../assets/logos/NFCC-logo_KO.svg';

const Hero = () => (
  <div className="hero bg-cover bg-center bg-no-repeat md:bg-top" id="top-of-page">
    <div className="w-full lg:w-3/5 m-auto">
      <div className="container sm:pt-40 pt-8 m-auto">
        <div className="nonprofit text-white">
          Trusted nonprofit
        </div>
        <img className="logo mt-4 mx-auto block" src={NFCCLogo} alt="NFCC Logo" />
        <h1 className="text-white text-center pt-4 pb-12 sm:text-6xl font-bold md:px-10">
          Get help with your debt with nonprofit credit counseling
        </h1>
        <CallToActionButton />
        <div className="powered-by pb-4 text-white">
          <p>Powered by</p>
          <img src={logo} alt="Credit Genie Logo" />
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
