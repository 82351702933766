import React from 'react';
import { getAssetsUrl } from '../../../helpers/assetsHelper';
import { getCreditCounselingAgencyName } from '../../../helpers/creditCounselingAgencyHelper';

const CreditCounselingAgencyLogo = () => {
  const assetsUrl = getAssetsUrl();
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const creditCounselingAgencyName = getCreditCounselingAgencyName();
  const logoImageName = `${creditCounselingAgencyName.toLowerCase()}_logo.svg`;

  return (
    <img
      alt={`${creditCounselingAgencyName} Logo`}
      className="inline-block mt-4"
      src={`${assetsUrl}/${environment}/${logoImageName}`}
    />
  );
};

export default CreditCounselingAgencyLogo;
