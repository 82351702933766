import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonInput from '../../../components/common/ButtonInput/ButtonInput';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import ProposedPaymentTile from '../../../components/offers/ProposedPaymentsCarousel/ProposedPaymentTile';

const LenderGuide = () => {
  const application = useSelector((state) => state.application.data);
  const baseTen = 10;
  const currentDisposableIncome = useSelector((state) => parseInt(state.decision.data.currentDisposableIncome, baseTen));
  const expenseEstimates = useSelector((state) => state.expenses.data.expenseEstimates);
  const [minMonthlyPaymentsPerDebt, setMinMonthlyPaymentsPerDebt] = useState([]);
  const monthlyIncome = application.selfReportedMonthlyCurrentIncome;
  const offers = useSelector((state) => state.offers.data);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const totalMonthlyDebtPayment = parseInt(minMonthlyPaymentsPerDebt.reduce((sum, minMonthlyPayment) => sum + minMonthlyPayment, 0), baseTen);

  const proposedPaymentTiles = offers.map((offer, offerIndex) => {
    const estimatedMonthlyCollectionPayment = (offer.collection && offer.collection.currentBalance * 0.03) || 0;
    const estimatedMonthlyDebtPayment = (offer.debt && (offer.debt.scheduledMonthlyPayment || offer.debt.estimatedMonthlyPayment)) || 0;

    const estimatedCurrentMonthlyPayment = estimatedMonthlyCollectionPayment || estimatedMonthlyDebtPayment;
    const recommendedMonthlyPayment = offer.payment;

    return (
      <ProposedPaymentTile
        estimatedCurrentMonthlyPayment={estimatedCurrentMonthlyPayment}
        key={offer.id || offerIndex}
        lenderFriendlyName={(offer.debt && offer.debt.lenderFriendlyName) || (offer.collection && offer.collection.lenderFriendlyName)}
        lenderName={(offer.debt && offer.debt.lenderName) || (offer.collection && offer.collection.subscriberName)}
        recommendedMonthlyPayment={recommendedMonthlyPayment}
      />
    );
  });

  const onDownloadOrPrintClick = (event) => {
    event.preventDefault();

    window.print();
  };

  useEffect(() => {
    const expenseValues = [];

    Object.keys(expenseEstimates)
      .map((category) => Object.keys(expenseEstimates[category])
        .forEach((subCategory) => { expenseValues.push(expenseEstimates[category][subCategory].value); }));

    const totalExpensesAmount = expenseValues.reduce((sum, expenseValue) => sum + expenseValue, 0);

    setTotalExpenses(totalExpensesAmount);
  }, [expenseEstimates]);

  useEffect(() => {
    const collections = offers.map((offer) => offer.collection).filter((collection) => collection);
    const debts = offers.map((offer) => offer.debt).filter((debt) => debt);
    const minimumMonthlyPayments = [];

    collections.forEach((collection) => {
      minimumMonthlyPayments.push(collection.currentBalance * 0.03);
    });

    debts.forEach((debt) => {
      minimumMonthlyPayments.push(debt.scheduledMonthlyPayment || debt.estimatedMonthlyPayment);
    });

    setMinMonthlyPaymentsPerDebt(minimumMonthlyPayments);
  }, [offers]);

  return (
    <div className="proposed-payment-summary">
      <div className="bg-blue w-full text-white">
        <div className="container mx-auto text-center sm:w-1/4 w-full">
          <h2 className="w-full m-auto text-center">Monthly Financial Analysis Summary</h2>
          <div className="flex pt-5 pb-4">
            <div className="w-1/3">
              <div className="value green-text">
                {formatNumberAsCurrency({ allowZero: true, number: monthlyIncome })}
              </div>
              <div className="sub-text">
                Income
              </div>
            </div>
            <div className="w-1/3">
              <div className="value red-text">
                {formatNumberAsCurrency({ allowZero: true, number: totalExpenses })}
              </div>
              <div className="sub-text">
                Expenses
              </div>
            </div>
            <div className="w-1/3">
              <div className="value red-text">
                {formatNumberAsCurrency({ allowZero: true, number: totalMonthlyDebtPayment })}
              </div>
              <div className="sub-text">
                Debt Payments
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="pt-4 pb-4">
            <div className={`value ${currentDisposableIncome < 0 ? 'red-text' : 'green-text'}`}>
              {formatNumberAsCurrency({ allowNegative: true, allowZero: true, number: currentDisposableIncome })}
            </div>
            <div className="sub-text">
              Disposable Income
            </div>
          </div>
        </div>
      </div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 sm:pt-8 pt-0 pb-8">
          <p className="text-center w-full md:w-1/2 m-auto font-bold">
            We put together a quick guide for you to speak with each one of your creditors
            to help to reduce your monthly payments.
          </p>
          <br />
          <p className="text-center w-full md:w-1/2 m-auto">
            I have recently completed a financial summary through Credit Genie, to get a better understanding of
            my expenses and ability to pay all of my unsecured credit obligations.
          </p>
          <br />
          <p className="text-center w-full md:w-1/2 m-auto">
            Based on this analysis, taking into account my income, household expenses, and
            unsecured debt payments, I&apos;m currently unable to pay my regular monthly bills as required.
          </p>
          <br />
          <p className="text-center w-full md:w-1/2 m-auto">
            Based on my current financial situtation, I can only afford a monthly payment of
            <span className="font-bold"> [REFER to the monthly payments listed below for each creditor]</span>
            . This will allow me to continue to pay down my debt while I go through financial hardships.
          </p>
        </div>
        <div className="container mx-auto pl-2 pr-2 text-center w-full md:w-1/2 mb-1">
          <div className="lender-guide-cols">
            <div className="cols z-10">
              {proposedPaymentTiles.map((proposedPaymentTile, proposedPaymentTileIndex) => proposedPaymentTileIndex % 2 === 0 && proposedPaymentTile)}
            </div>
            <div className="cols ml-0 xl:ml-10">
              {proposedPaymentTiles.map((proposedPaymentTile, proposedPaymentTileIndex) => proposedPaymentTileIndex % 2 === 1 && proposedPaymentTile)}
            </div>
          </div>
        </div>
        <br />
        <div className="leading-normal tracking-normal">
          <ButtonInput id="download-or-print-guide-button" onClick={onDownloadOrPrintClick} text="Download or print guide" />
        </div>
        <br />
        <p className="text-center text-gray-600 text-xs w-full md:w-1/4 m-auto">
          Disclosure: Credit Genie cannot guarantee any debt reduction will be
          available to you. Credit Genie recommendations are meant for your
          personal understanding of your financial health.
        </p>
      </div>
    </div>
  );
};

export default LenderGuide;
