import React from 'react';
import YesNoRadioButtonInput from '../common/RadioButtonInput';

const DoYouOwnACar = ({ errors, onChange }) => (
  <div>
    <YesNoRadioButtonInput
      errors={errors}
      id="do-you-own-a-car"
      loadFrom="application"
      name="isCarOwner"
      onChange={onChange}
      question="Do you own a car?"
    />
  </div>
);

export default DoYouOwnACar;
