import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  getCreditCounselingAgencyFullName,
} from '../../../../helpers/creditCounselingAgencyHelper';

const WhoIsYourCounselor = () => {
  const { t: translate } = useTranslation();

  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();

  return (
    <div className="w-full bg-light-grey">
      <div className="container inner-width m-auto sm:py-10 py-10 text-center sm:px-0 px-2 sm:w-1/2 w-full">
        <h2 className="text-center w-full md:w-1/2 m-auto p-2 px-1">
          {translate('landingpage:renterAdvantage:whoIsYourCounselor:title')}
        </h2>
        <span>
          <Trans components={{ bold: <span className="font-bold" />, p: <p className="pt-4" /> }} values={{ agency: creditCounselingAgencyFullName }}>
            landingpage:renterAdvantage:whoIsYourCounselor:description
          </Trans>
        </span>
      </div>
    </div>
  );
};

export default WhoIsYourCounselor;
