import React from 'react';
import step2Graphic from '../../../../assets/icons/landing-pages/credit-cards_graphic.svg';

const Step2 = () => (
  <div>
    <h3 className="pb-4 font-normal">Step 2</h3>
    <h1>
      Let&apos;s verify your credit card debt
    </h1>
    <img className="block m-auto pb-4" src={step2Graphic} alt="Verify Credit" />
    <p>
      Allow Credit Genie to pull your credit report so that we can verify your credit card balances.
      {' '}
      <span className="font-bold">This will not impact your credit score.</span>
    </p>
    <div className="w-full">
      <ul className="slider-dots text-center">
        <li className="dot">&bull;</li>
        <li className="dot active-dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
      </ul>
    </div>
  </div>
);

export default Step2;
