import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowDmpEnrollmentForm } from '../../../../redux/actions/dmpEnrollmentActions';
import DmpActiveInput from './DmpActiveInput';
import DmpCancelInput from '../DmpCancelInput';
import DmpDateActiveDateInput from './DmpDateActiveDateInput';
import DmpDateInactiveDateInput from './DmpDateInactiveDateInput';
import DmpDateSignedDateInput from './DmpDateSignedDateInput';
import DmpFirstPaymentDateDateInput from './DmpFirstPaymentDateDateInput';
import DmpMonthlyPaymentAmountTextInput from './DmpMonthlyPaymentAmountTextInput';
import DmpTermInMonths from './DmpTermInMonths';
import Form from '../../../application/common/ApplicationForm/Form';
import SubmitInput from '../../../application/common/SubmitInput';
import useForm from '../../../application/common/ApplicationForm/useForm';
import validate from '../../../application/common/ApplicationForm/validator';

const DebtReliefEnrollmentQuestions = () => {
  const dispatch = useDispatch();
  const saveDmpEnrollmentFailed = useSelector((state) => state.dmpEnrollment.saveFailed === true);
  const saveDmpEnrollmentSucceeded = useSelector((state) => state.dmpEnrollment.saveSuccess === true);

  const {
    errors,
    onDmpEnrollmentChange,
    onSubmit,
  } = useForm({ validate });

  const errorMessage = saveDmpEnrollmentFailed && 'There was a problem saving the DMP information. Please try again.';

  useEffect(() => {
    if (saveDmpEnrollmentSucceeded) {
      dispatch(setShowDmpEnrollmentForm({ value: false }));
    }
  }, [dispatch, saveDmpEnrollmentSucceeded]);

  return (
    <div>
      <h2 className="text-center text-md pb-5">DMP Information</h2>
      <Form errorMessage={errorMessage} onSubmit={onSubmit}>
        <DmpMonthlyPaymentAmountTextInput errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpTermInMonths errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpFirstPaymentDateDateInput errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpDateSignedDateInput errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpActiveInput errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpDateActiveDateInput errors={errors} onChange={onDmpEnrollmentChange} />
        <DmpDateInactiveDateInput errors={errors} onChange={onDmpEnrollmentChange} />
        <div className="pt-2 flex">
          <div className="w-1/2">
            <DmpCancelInput />
          </div>
          <div className="w-1/2 ml-2">
            <SubmitInput />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DebtReliefEnrollmentQuestions;
