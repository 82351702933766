import React from 'react';
import { useSelector } from 'react-redux';
import OtherReasonForNotConnectingAccountTextInput from './OtherReasonForNotConnectingAccountTextInput';
import ReasonForNotConnectingAccountDropdownInput from './ReasonForNotConnectingAccountDropdownInput';

const ReasonForNotConnectingAccountComponent = ({ errors, onApplicationTextInputBlur, onChange }) => {
  const otherIsSelected = useSelector(
    (state) => state.application.otherReasonForNotConnectingAccount || (state.application.data.reasonForNotConnectingBank === 'Other'),
  );

  return (
    <div>
      <ReasonForNotConnectingAccountDropdownInput errors={errors} onChange={onChange} />
      {otherIsSelected && <OtherReasonForNotConnectingAccountTextInput errors={errors} onBlur={onApplicationTextInputBlur} onChange={onChange} />}
    </div>
  );
};

export default ReasonForNotConnectingAccountComponent;
