import React from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import DateInput from '../DateInput/DateInput';

const DateOfBirthInput = (
  {
    errors = {},
    id,
    loadFrom,
    onChange,
    validationName,
  },
) => {
  const { t: translate } = useTranslation();
  const oneHundredAndTwentyYearsAgo = DateTime.local().minus({ years: 120 }).toFormat('MM-dd-yyyy');
  const thirteenYearsAgo = DateTime.local().minus({ years: 13 }).toFormat('MM-dd-yyyy');

  return (
    <DateInput
      errors={errors}
      id={id}
      label={translate('yourcontact:dateOfBirth:label')}
      loadFrom={loadFrom}
      maxDateAllowed={thirteenYearsAgo}
      maxDateExceededMessage={translate('yourcontact:dateOfBirth:maxDateExceededMessage')}
      minDateAllowed={oneHundredAndTwentyYearsAgo}
      name="dateOfBirth"
      onChange={onChange}
      validationName={validationName}
    />
  );
};

export default DateOfBirthInput;
