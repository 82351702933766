import InputMask from 'react-input-mask';
import React from 'react';

const VerificationCodeInput = ({
  id, label = 'Verification code', mask = '999999', maskChar = null, onChange, placeholder = ' ', required = true,
}) => (
  <div className="w-full m-auto">
    <div className="mb-4">
      <label className="input-container" htmlFor={id}>
        <InputMask
          id={id}
          mask={mask}
          maskChar={maskChar}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type="tel"
        />
        <span className="label">{label}</span>
        <span className="border" />
      </label>
    </div>
  </div>
);

export default VerificationCodeInput;
