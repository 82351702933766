/*
  The filter for the getApplication query pulls in PlaidTransactions with an amount < 0, meaning deposits.
  The deposit amounts are negative because as far as the banks are concerned, those are payments made to customers.
*/
const getApplication = /* GraphQL */ `
  query getApplication(
    $applicationId: ID!
    $alertsLimit: Int = 100,
    $debtsLimit: Int = 1000,
    $expensesLimit: Int = 100,
    $offersLimit: Int = 100,
    $plaidTransactionsLimit: Int = 1000,
  ) {
    getApplication(id: $applicationId) {
      id
      assignedTo
      assignedToAt
      createdAt
      docusignEnvelopeId
      hasSpouse
      hasLargeMedicalOrDisabilityExpenses
      hasMortgageEscrow
      hasRecentDeathInImmediateFamily
      hasRecentJobLoss
      hasRecentlyBeenFurloughed
      hasReductionInIncome
      hasServedInMilitary
      isCarOwner
      isHomeowner
      kindOfRelief
      lastCompletedPage
      lastViewedPage
      monthlyRentOrMortgageAmount
      numberOfDependentsInHousehold
      reasonForNotConnectingBank
      router
      selfReportedMonthlyCurrentIncome
      status
      totalBackTaxesAmount
      totalDebtToFriendsAndFamilyAmount
      totalMedicalDebtAmount
      totalOtherDebtAmount
      type
      utmCampaign
      utmContent
      utmMedium
      utmSource
      utmTerm
      verifiedMonthlyCurrentIncome
      borrower {
        id
        addressLine1
        addressLine2
        city
        dateOfBirth
        email
        firstName
        lastName
        phoneNumber
        preferredLanguage
        state
        zipCode
        creditReports {
          items {
            id
            createdAt
            updatedAt
            vantageScore
            debts(limit: $debtsLimit) {
              items {
                id
                accountNumber
                accountType
                createdAt
                currentBalance
                dateClosed
                dateOpened
                ecoaDesignator
                estimatedMonthlyPayment
                highCredit
                late30DaysTotal
                late60DaysTotal
                late90DaysTotal
                lenderName
                minimumMonthlyInterest
                minimumMonthlyPrincipal
                mostRecentPayment
                portfolioType
                pastDue
                scheduledMonthlyPayment
              }
            }
          }
        }
        rentalApplication {
          id
          backRentAmount
          ethnicity
          hasBackRent
          hasReceivedRentalSupportInPast
          hasUsedCreditCardToPayRent
          monthlyRentPayment
          numberOfPeopleLivingWithBorrower
          race
          rentalReliefPlan {
            id
            dateSigned
            plannedStartDate
            plannedEndDate
            monthlyPlanRent
            monthlyBackRentDue
            monthlyRentalAssistanceFundsApplied
          }
        }
        alerts(limit: $alertsLimit) {
          items {
            id
            createdAt
            hasBeenSeenByBorrower
            hasBeenSeenByCustomerServiceRepresentative
            type
            triggeredBy {
              id
              type
            }
          }
        }
        debtScores {
          items {
            createdAt
            id
            value
          }
        }
      }
      contactAttempts {
        items {
          id
          contactedBy
          createdAt
          method
          outcome
        }
      }
      decisions {
        items {
          createdAt
          currentDisposableIncome
          currentUnsecuredDebtPayment
          isQualified
          proposedUnsecuredDebtPayment
          disqualificationReasons {
            items {
              reason
            }
          }
          offers(limit: $offersLimit) {
            items {
              id
              balance
              createdAt
              lenderConcessionId
              payment
              paymentPercentage
              term
              updatedAt
              collection {
                id
                currentBalance
                subscriberName
              }
              debt {
                id
                lenderName
                minimumMonthlyInterest
                minimumMonthlyPrincipal
                scheduledMonthlyPayment
              }
            }
          }
        }
      }
      dmpEnrollment {
        id
        active
        activeAt
        dateSigned
        firstPaymentDate
        inactiveAt
        monthlyPaymentAmount
        termInMonths
      }
      expenses(limit: $expensesLimit) {
        items {
          id
          category
          estimatedValue
          selfReportedValue
          subCategory
        }
      }
      hardships {
        items {
          id
          key
          value
        }
      }
      plaidItems {
        items {
          plaidAccounts {
            items {
              plaidTransactions(filter: { amount: { lt: 0 } }, limit: $plaidTransactionsLimit) {
                items {
                  id
                  amount
                  date
                  isVerifiedAsIncome
                  name
                }
              }
            }
          }
        }
      }
      spouse {
        id
        addressLine1
        addressLine2
        city
        dateOfBirth
        email
        firstName
        lastName
        phoneNumber
        state
        zipCode
        creditReports {
          items {
            debts(limit: $debtsLimit) {
              items {
                id
                accountNumber
                accountType
                createdAt
                currentBalance
                dateClosed
                dateOpened
                ecoaDesignator
                estimatedMonthlyPayment
                highCredit
                late30DaysTotal
                late60DaysTotal
                late90DaysTotal
                lenderName
                minimumMonthlyInterest
                minimumMonthlyPrincipal
                mostRecentPayment
                portfolioType
                pastDue
                scheduledMonthlyPayment
              }
            }
          }
        }
      }
    }
  }
`;

const getApplicationWithExpenses = /* GraphQL */ `
  query getApplicationById($applicationId: ID!, $limit: Int, $nextToken: String) {
    getApplication(id: $applicationId) {
      id
      expenses(limit: $limit, nextToken: $nextToken) {
        items {
          id
          category
          estimatedValue
          selfReportedValue
          subCategory
        }
        nextToken
      }
    }
  }
`;

const getBorrower = /* GraphQL */ `
  query getBorrower(
    $borrowerId: ID!
    $creditReportsLimit: Int = 100,
    $debtsLimit: Int = 1000,
    $debtScoresLimit: Int = 100,
    $incomeSnapshotsFilter: ModelIncomeSnapshotFilterInput
    $incomeSnapshotsLimit: Int = 100,
    $plaidItemsLimit: Int = 100,
    $plaidAccountsLimit: Int = 100,
    $plaidTransactionsLimit: Int = 1000,
  ) {
    getBorrower(id: $borrowerId) {
      creditReports(limit: $creditReportsLimit) {
        items {
          id
          createdAt
          updatedAt
          vantageScore
          debts (limit: $debtsLimit) {
            items {
              id
              accountNumber
              accountType
              createdAt
              currentBalance
              dateClosed
              dateOpened
              ecoaDesignator
              estimatedMonthlyPayment
              highCredit
              late30DaysTotal
              late60DaysTotal
              late90DaysTotal
              lenderName
              minimumMonthlyInterest
              minimumMonthlyPrincipal
              portfolioType
              pastDue
              scheduledMonthlyPayment
            }
          }
        }
      }
      debtScores(limit: $debtScoresLimit) {
        items {
          createdAt
          id
          value
        }
      }
      incomeSnapshots(
        filter: $incomeSnapshotsFilter
        limit: $incomeSnapshotsLimit
        ) {
        items {
          annualIncome
          createdAt
          id
          incomeTransactionIds
          monthlyIncome
          ninetyDayIncome
          updatedAt
        }
      }
      plaidItems(limit: $plaidItemsLimit) {
        items {
          createdAt
          institutionId
          institutionName
          isConnected
          plaidAccounts(limit: $plaidAccountsLimit) {
            items {
              plaidTransactions(
                  filter: { amount: { lt: 0 } },
                  limit: $plaidTransactionsLimit
                ) {
                items {
                  id
                  amount
                  date
                  isVerifiedAsIncome
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getBorrowerWithPlaidItems = /* GraphQL */ `
  query getBorrowerWithPlaidItems($borrowerId: ID!) {
    getBorrower(id: $borrowerId) {
      plaidItems {
        items {
          createdAt
          institutionId
          institutionName
          isConnected
        }
      }
    }
  }
`;

const getBorrowerWithPlaidAccountBalances = /* GraphQL */ `
  query getBorrowerWithPlaidAccountBalances(
    $balancesFilter: ModelPlaidAccountBalanceFilterInput,
    $balancesLimit: Int = 300,
    $borrowerId: ID!
  ) {
    getBorrower(id: $borrowerId) {
      plaidItems {
        items {
          plaidAccounts {
            items {
              subType
              balances(filter: $balancesFilter, limit: $balancesLimit) {
                items {
                  available
                  current
                  createdAt
                  id
                  plaidAccountId
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getDebtScoreWithCreatedAt = /* GraphQL */ `
  query GetDebtScoreWithCreatedAt($debtScoreId: ID!) {
    getDebtScore(id: $debtScoreId) {
      id
      createdAt
    }
  }
`;

const getIncomeSnapshotWithCreatedAt = /* GraphQL */ `
  query GetIncomeSnapshotWithCreatedAt($incomeSnapshotId: ID!) {
    getIncomeSnapshot(id: $incomeSnapshotId) {
      id
      createdAt
    }
  }
`;

const getInquiryWithDate = /* GraphQL */ `
  query GetInquiryWithDate($inquiryId: ID!) {
    getInquiry(id: $inquiryId) {
      id
      date
    }
  }
`;

const getPlaidAccountBalanceWithCreatedAt = /* GraphQL */ `
  query GetPlaidAccountBalanceWithCreatedAt($plaidAccountBalanceId: ID!) {
    getPlaidAccountBalance(id: $plaidAccountBalanceId) {
      id
      createdAt
    }
  }
`;

const getPlaidTransactionWithDate = /* GraphQL */ `
  query GetPlaidTransactionWithDate($plaidTransactionId: ID!) {
    getPlaidTransaction(id: $plaidTransactionId) {
      id
      date
    }
  }
`;

const getRentalApplication = /* GraphQL */ `
  query getRentalApplication(
    $rentalApplicationId: ID!
  ) {
    getRentalApplication(id: $rentalApplicationId) {
      id
      rentalReliefPlan {
        id
        dateSigned
        plannedStartDate
        plannedEndDate
        monthlyPlanRent
        monthlyBackRentDue
        monthlyRentalAssistanceFundsApplied
      }
    }
  }
`;

const listApplicationsByOwnerWithIds = /* GraphQL */ `
  query listApplicationsByOwnerWithIds(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $owner: String!
  ) {
    listApplicationsByOwner(filter: $filter, limit: $limit, nextToken: $nextToken, owner: $owner) {
      items {
        id
      }
      nextToken
    }
  }
`;

const listApplicationsByStatusSortedByCreatedAt = /* GraphQL */ `
  query ListApplicationsByStatusSortedByCreatedAt(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationsByStatusSortedByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignedTo
        createdAt
        lastCompletedPage
        router
        status
        type
        borrower {
          id
          email
          firstName
          lastName
          phoneNumber
          preferredLanguage
          alerts {
            items {
              id
              hasBeenSeenByCustomerServiceRepresentative
            }
          },
          rentalApplication {
            id
            rentalReliefPlan {
              id
            }
          }
        },
        decisions {
          items {
            createdAt
            id
            isQualified
            offers {
              items {
                id
              }
            }
          }
        },
        dmpEnrollment {
          active
        },
        plaidItems {
          items {
            isConnected
          }
        }
      }
      nextToken
    }
  }
`;

const listBorrowers = /* GraphQL */ `
  query listBorrowers(
    $filter: ModelBorrowerFilterInput
    $limit: Int = 100
    $alertsLimit: Int = 100
    $decisionsLimit: Int = 100
    $offersLimit: Int = 100
    $plaidItemsLimit: Int = 100
    $nextToken: String
  ) {
    listBorrowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        preferredLanguage
        alerts(limit: $alertsLimit) {
          items {
            id
            hasBeenSeenByCustomerServiceRepresentative
            type
            triggeredBy {
              id
              type
            }
          }
        }
        application {
          assignedTo
          createdAt
          id
          lastCompletedPage
          router
          status
          type
          decisions(limit: $decisionsLimit) {
            items {
              createdAt
              id
              isQualified
              offers(limit: $offersLimit) {
                items {
                  id
                }
              }
            }
          }
          dmpEnrollment {
            active
          }
          plaidItems(limit: $plaidItemsLimit) {
            items {
              isConnected
            }
          }
        }
        rentalApplication {
          id
          rentalReliefPlan {
            id
          }
        }
        spouse {
          id
        }
      }
      nextToken
    }
  }
`;

export {
  getApplication,
  getApplicationWithExpenses,
  getBorrower,
  getBorrowerWithPlaidAccountBalances,
  getBorrowerWithPlaidItems,
  getDebtScoreWithCreatedAt,
  getIncomeSnapshotWithCreatedAt,
  getInquiryWithDate,
  getPlaidAccountBalanceWithCreatedAt,
  getPlaidTransactionWithDate,
  getRentalApplication,
  listApplicationsByOwnerWithIds,
  listApplicationsByStatusSortedByCreatedAt,
  listBorrowers,
};
