import { DateTime } from 'luxon';
import {
  CONNECT_SUCCESS,
  GET_PLAID_ACCOUNT_BALANCES, GET_PLAID_ACCOUNT_BALANCES_FAIL, GET_PLAID_ACCOUNT_BALANCES_SUCCESS,
  GET_PLAID_ITEMS, GET_PLAID_ITEMS_FAIL, GET_PLAID_ITEMS_SUCCESS,
  SAVE_PLAID_ITEM, SAVE_PLAID_ITEM_SUCCESS, SAVE_PLAID_ITEM_FAIL,
  SET_PLAID_ITEMS,
  SET_SHOW_CONNECT_ACCOUNT_MODAL,
  SUBSCRIBING, SUBSCRIBING_FAIL, SUBSCRIBING_SUCCESS,
  UPDATE_PLAID_ITEM_SUBSCRIPTIONS,
  UPDATE_PLAID_LIST,
} from '../actions/plaidActions';

const intitialState = {
  data: {
    metadata: undefined,
    publicToken: undefined,
  },
  hasGottenPlaidAccountBalances: undefined,
  isConnected: false,
  isGettingPlaidAccountBalances: undefined,
  isGettingPlaidItems: undefined,
  isHistoricalUpdateFinished: undefined,
  isSavingPlaidItem: undefined,
  isSubscribing: undefined,
  plaidItems: [],
  plaidAccountBalances: [],
  plaidItemSubscriptions: {},
  savePlaidItemFailed: undefined,
  showConnectAccountModal: false,
  subscribingFailed: undefined,
};

const plaidReducer = (state = intitialState, action) => {
  switch (action.type) {
    case CONNECT_SUCCESS:
      return {
        ...state,
        isConnected: true,
        data: {
          metadata: action.metadata,
          publicToken: action.public_token,
        },
      };

    case GET_PLAID_ACCOUNT_BALANCES:
      return {
        ...state,
        hasGottenPlaidAccountBalances: undefined,
        isGettingPlaidAccountBalances: true,
        plaidAccountBalances: [],
      };

    case GET_PLAID_ACCOUNT_BALANCES_FAIL:
      return {
        ...state,
        hasGottenPlaidAccountBalances: false,
        isGettingPlaidAccountBalances: false,
      };

    case GET_PLAID_ACCOUNT_BALANCES_SUCCESS:
      return {
        ...state,
        hasGottenPlaidAccountBalances: true,
        isGettingPlaidAccountBalances: false,
        plaidAccountBalances: action.plaidAccountBalances,
      };

    case GET_PLAID_ITEMS:
      return {
        ...state,
        isGettingPlaidItems: true,
        plaidItems: [],
      };

    case GET_PLAID_ITEMS_FAIL:
      return {
        ...state,
        isGettingPlaidItems: false,
      };

    case GET_PLAID_ITEMS_SUCCESS:
      return {
        ...state,
        isGettingPlaidItems: false,
        plaidItems: action.plaidItems,
      };

    case SAVE_PLAID_ITEM:
      return { ...state, isSavingPlaidItem: true, savePlaidItemFailed: undefined };

    case SAVE_PLAID_ITEM_FAIL:
      return { ...state, isSavingPlaidItem: false, savePlaidItemFailed: true };

    case SAVE_PLAID_ITEM_SUCCESS:
      return { ...state, isSavingPlaidItem: false, savePlaidItemFailed: false };

    case SET_PLAID_ITEMS:
      return { ...state, plaidItems: action.plaidItems || [], showConnectAccountModal: action.plaidItems.length === 0 };

    case SET_SHOW_CONNECT_ACCOUNT_MODAL:
      return { ...state, showConnectAccountModal: action.showConnectAccountModal };

    case SUBSCRIBING:
      return { ...state, isHistoricalUpdateFinished: undefined, isSubscribing: true };

    case SUBSCRIBING_FAIL:
      return {
        ...state,
        isHistoricalUpdateFinished: false,
        isSubscribing: false,
        subscribingFailed: true,
      };

    case SUBSCRIBING_SUCCESS:
      return {
        ...state,
        isHistoricalUpdateFinished: true,
        isSubscribing: false,
        subscribingFailed: false,
      };

    case UPDATE_PLAID_ITEM_SUBSCRIPTIONS:
      return { ...state, plaidItemSubscriptions: { ...state.plaidItemSubscriptions, [action.plaidItemId]: action.subscription } };

    case UPDATE_PLAID_LIST:
      return {
        ...state,
        plaidItems: [...state.plaidItems, {
          createdAt: DateTime.local(),
          institutionId: action.institution.institution_id,
          institutionName: action.institution.name,
          isConnected: true,
        }],
      };

    default:
      return state;
  }
};

export default plaidReducer;
