import React from 'react';
import { useSelector } from 'react-redux';
import { DTCHeader, HowItWorksModal, VideoModal } from '../components/landing-pages';

const DTCLandingPageLayout = ({ children }) => {
  const showVideo = useSelector((state) => state.video.showVideo);
  const showHowItWorksModal = useSelector((state) => state.howItWorksModal.showHowItWorksModal);
  return (
    <section className="bg-white">
      {showVideo && <VideoModal />}
      {showHowItWorksModal && <HowItWorksModal />}
      <div className="dtc-landing-pages">
        <DTCHeader />
        {children}
      </div>
    </section>
  );
};

export default DTCLandingPageLayout;
