import React from 'react';
import Footer from '../components/common/Footer';
import PlainHeader from '../components/brochure/common/PlainHeader/PlainHeader';

const HardshipBrochureLayout = ({ children }) => (
  <section>
    <div>
      <PlainHeader />
      {children}
      <Footer />
    </div>
  </section>
);

export default HardshipBrochureLayout;
