import React from 'react';
import DropdownInput from '../../../application/common/DropdownInput';

const ApplicationReferralInput = ({ errors, applicationReferral, onChange }) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const environmentToOptionsMap = {
    cesi: [{ label: 'Dreamkey', value: 'dreamkey' }],
    creditorg: [{ label: 'Midpen', value: 'midpen' }],
    navicore: [{ label: 'Eden', value: 'eden' }, { label: 'FPS', value: 'fps' }],
  };

  return (
    <DropdownInput
      errors={errors}
      id="application-referral-select"
      name="applicationReferral"
      onChange={onChange}
      options={environmentToOptionsMap[environment]}
      question="Select Your Application Referral"
      valueOverride={applicationReferral}
    />
  );
};

export default ApplicationReferralInput;
