import React, { useState, useRef } from 'react';
import ToolTipIcon from '../../../assets/icons/tooltip_icon.svg';
import useOutsideClick from '../../../hooks/useOutsideClick/useOutsideClick';

const ToolTip = ({ tooltipDescription }) => {
  const [showEditComponent, toggleEditComponent] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (showEditComponent) toggleEditComponent(false);
  });
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        className="cursor-pointer block m-0 float-right pr-2 pt-1"
        src={ToolTipIcon}
        alt="Tooltip icon"
        onClick={() => { toggleEditComponent(!showEditComponent); }}
        onKeyDown={() => { toggleEditComponent(!showEditComponent); }}
      />
      {showEditComponent && (
        <div className="tooltip tooltip--fixed-width" ref={ref}>
          <p className="tooltip__text-block">
            {tooltipDescription}
          </p>
        </div>
      )}
    </>
  );
};

export default ToolTip;
