import { UPDATE_DEBT_SUMMARY_FILTERS } from '../actions/debtSummaryFilterActions';

const initialState = {
  accountType: {
    attorneyFees: true,
    automobile: true,
    chargeAccount: true,
    conventionalRealEstateMortgage: true,
    creditCard: true,
    flexibleSpendingCreditCard: true,
    lineOfCredit: true,
    secondMortgage: true,
    studentLoan: true,
    unsecured: true,
    other: true,
  },
  debtStatus: {
    open: true,
    closed: true,
  },
  debtDetail: {
    hasBalance: false,
    latePayment: false,
  },
  debtType: {
    single: true,
    shared: true,
  },
  portfolioType: {
    installment: true,
    lineOfCredit: true,
    mortgage: true,
    open: true,
    revolving: true,
  },
};

const debtSummaryFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEBT_SUMMARY_FILTERS:
      return {
        initialState,
        updatedState: action.filters,
      };
    default:
      return state;
  }
};

export default debtSummaryFilterReducer;
