import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  getCreditCounselingAgencyFullName,
} from '../../../../helpers/creditCounselingAgencyHelper';

const HowItWorks = () => {
  const { t: translate } = useTranslation();
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();

  return (
    <div className="how-it-works w-full text-center m-auto py-10 bg-white">
      <div className="w-full container m-auto">
        <h2 className="text-center w-full md:w-1/2 m-auto p-2 px-1">
          {translate('landingpage:renterAdvantage:howItWorks:title')}
        </h2>
        <p className="sm:w-1/2 w-full m-auto px-4">
          <Trans components={{ bold: <span className="font-bold" />, p: <p className="pt-4" /> }} values={{ agency: creditCounselingAgencyFullName }}>
            landingpage:renterAdvantage:howItWorks:description
          </Trans>
        </p>
        <div className="steps">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-8">
            <div>
              <div className="step-number">1</div>
              <div className="step-details">
                <h2 className="pb-4">
                  {translate('landingpage:renterAdvantage:howItWorks:1_title')}
                </h2>
                <Trans components={{ p: <p className="pt-4" /> }}>
                  landingpage:renterAdvantage:howItWorks:1_text
                </Trans>
              </div>
            </div>
            <div>
              <div className="step-number">2</div>
              <div className="step-details">
                <h2 className="pb-4">{translate('landingpage:renterAdvantage:howItWorks:2_title')}</h2>
                <p>
                  {translate('landingpage:renterAdvantage:howItWorks:2_text')}
                </p>
              </div>
            </div>
            <div>
              <div className="step-number">3</div>
              <div className="step-details">
                <h2 className="pb-4">
                  {translate('landingpage:renterAdvantage:howItWorks:3_title')}
                </h2>
                <p>
                  {translate('landingpage:renterAdvantage:howItWorks:3_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
