import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from '../../../components/application/common/ApplicationForm/Form';
import ReasonForNotConnectingAccountComponent
  from '../../../components/application/ReasonForNotConnectingAccountComponent/ReasonForNotConnectingAccountComponent';
import { saveDecision, updateDecision } from '../../../redux/actions/decisionActions';
import { saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import SubmitInput from '../../../components/application/common/SubmitInput/SubmitInput';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';

const ReasonForNotConnectingAccount = () => {
  const borrowerIsQualified = useSelector((state) => state.decision.data.isQualified);
  const dispatch = useDispatch();
  const errorMessage = 'There was a problem saving your information. Please try again.';
  const getDecisionFailed = useSelector((state) => state.decision.getDecisionFailed);
  const getDecisionSucceeded = useSelector((state) => state.decision.getDecisionSucceeded);
  const getRiskScoresFailed = useSelector((state) => state.risk.getRiskScoresFailed);
  const {
    errors,
    onApplicationChange,
    onApplicationTextInputBlur,
    onSubmit,
  } = useForm({ validate });
  const history = useHistory();
  const isEnvironmentFreeTool = process.env.REACT_APP_ENVIRONMENT === 'freetool';
  const pullCreditFailed = useSelector((state) => state.transunion.hasPulledCredit === false);
  const saveApplicationFailed = useSelector((state) => state.application.saveFailed);
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);

  useEffect(() => {
    if (!saveApplicationSucceeded) {
      return;
    }

    if (pullCreditFailed || getRiskScoresFailed || getDecisionFailed) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'ReasonForNotConnectingAccount', lastViewedPage: 'Pending' }));

      history.push('/offers/pending');

      return;
    }

    if (getDecisionSucceeded && borrowerIsQualified) {
      if (isEnvironmentFreeTool) {
        dispatch(saveLastCompletedAndLastViewedPage({
          lastCompletedPage: 'ReasonForNotConnectingAccount',
          lastViewedPage: 'ProposedPaymentSummary',
        }));

        history.push('/offers/proposed-payment-summary');
      } else {
        dispatch(updateDecision({ propertyName: 'wasPresentedToBorrower', value: true }));
        dispatch(saveDecision());
        dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'ReasonForNotConnectingAccount', lastViewedPage: 'Success' }));

        history.push('/offers/success');
      }

      return;
    }

    if (getDecisionSucceeded && !borrowerIsQualified) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'ReasonForNotConnectingAccount', lastViewedPage: 'Decline' }));

      history.push('/offers/decline');
    }
  });

  return (
    <div className="leading-normal tracking-normal pb-20">
      <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
        <h2 className="text-xl sm:text-2xl w-full m-auto text-center leading-normal">
          Reason For Not Connecting Your Account
        </h2>
        <p className="text-center pt-2 md:w-1/2 w-full m-auto">
          We would like to get an understanding of why you chose to skip connecting your account. Please provide the primary reason below.
        </p>
        <div className="leading-normal tracking-normal">
          <div className="container form-width mx-auto m-8">
            <Form onSubmit={onSubmit}>
              <ReasonForNotConnectingAccountComponent
                errors={errors}
                onApplicationTextInputBlur={onApplicationTextInputBlur}
                onChange={onApplicationChange}
              />
              <SubmitInput />
            </Form>
          </div>
        </div>
        {saveApplicationFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ReasonForNotConnectingAccount;
