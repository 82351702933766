import React from 'react';
import Checkbox from '../../application/common/CheckboxInput/CheckboxInput';

const SearchFilterCheckbox = ({
  id, label, filterCategory, name, onChange, tempFilterState,
}) => {
  const onInputValueChange = (event) => {
    const valueOverride = event.target.checked;

    onChange(event, valueOverride, filterCategory);
  };

  return (
    <div>
      <Checkbox
        checked={tempFilterState[name] || false}
        className="w-1/2 h-12"
        id={id}
        label={label}
        name={name}
        onChange={onInputValueChange}
        required={false}
      />
    </div>
  );
};

export default SearchFilterCheckbox;
