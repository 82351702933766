/**
 * Converts a range of 2 numbers to another range, maintaining its ratio
 */

const convertLinearRange = ({
  oldValue,
  oldMin = 0,
  oldMax,
  newMin = 0,
  newMax,
}) => {
  if (oldValue === 0) {
    return 0;
  }

  const newValue = (((Math.abs(oldValue) - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;

  return newValue;
};

export default convertLinearRange;
