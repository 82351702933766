import {
  GET_CREDIT_SCORES_SUCCESS,
} from '../actions/creditScoresActions';

const intitialState = {
  data: [
    {
      applicationId: undefined,
      borrowerId: undefined,
      createdAt: undefined,
      owner: undefined,
      spouseId: undefined,
      updatedAt: undefined,
      vantageScore: 0,
    },
  ],
  getCreditScoresFailed: undefined,
  getCreditScoresSucceeded: undefined,
  isGettingCreditScores: undefined,
};

const creditScoresReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_CREDIT_SCORES_SUCCESS:
      return {
        ...state,
        data: action.creditScores || [],
        getCreditScoresFailed: false,
        getCreditScoresSucceeded: true,
        isGettingCreditScores: false,
      };

    default:
      return state;
  }
};

export default creditScoresReducer;
