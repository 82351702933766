import React from 'react';
import { useSelector } from 'react-redux';
import CheckmarkIcon from '../../../assets/icons/checkmark-green_icon.svg';
import phoneIcon from '../../../assets/icons/phone_icon.svg';
import {
  getCreditCounselingAgencyDisclosureName,
  getCreditCounselingAgencyFullName,
  getCreditCounselingAgencyPhoneNumber,
} from '../../../helpers/creditCounselingAgencyHelper';

const CallUs = () => {
  const creditCounselingAgencyDisclosureName = getCreditCounselingAgencyDisclosureName();
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();
  const router = useSelector((state) => state.application.data.router);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });

  return (
    <div className="pending">
      <div className="leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pt-10 pb-56 text-center w-full">
          <h2 className="text-xl sm:text-2xl w-full m-auto">
            You are all set
          </h2>
          <div>
            <img src={CheckmarkIcon} alt="Checkmark icon" className="m-auto" />
            <p className="w-full sm:w-1/2 m-auto sm:py-4">
              Congratulations! This part is done! We&apos;re now connecting you to a certified credit counselor from
              {' '}
              {creditCounselingAgencyFullName}
              <sup>*</sup>
            </p>
            <p className="w-full sm:w-1/2 m-auto sm:py-4">
              Your counselor will explain the Debt Management Plan, answer all of your questions, and discuss next steps.
              Be on the look out for their call!
            </p>
            <div className="credit-counseling-section">
              <p className="w-full sm:w-1/3 m-auto sm:pt-12 pt-4 text-md sm:text-xl">
                <span className="font-bold">
                  Your credit counselor from
                  {' '}
                  {creditCounselingAgencyFullName}
                  <sup>*</sup>
                  {' '}
                  will contact you shortly via phone.
                </span>
              </p>
              <p className="w-full sm:w-1/3 m-auto sm:py-12 pb-4 text-md sm:text-xl">
                <img src={phoneIcon} alt="Phone icon" className="m-auto pb-2" />
                <span className="font-bold">
                  You can also call us directly at:
                </span>
                {' '}
                <span>
                  <a href={`tel:${phoneNumber}`} className="offer-phone-numbers">{phoneNumber}</a>
                </span>
              </p>
              <p className="text-xs pt-2">
                <sup>*</sup>
                {`${creditCounselingAgencyDisclosureName} is a certified member agency of the NFCC.`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallUs;
