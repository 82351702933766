import React from 'react';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../common/ToolTip';
import YesNoRadioButtonInput from '../common/RadioButtonInput';

const AreYouCurrentlyMarriedInput = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="radio-tooltip-adjusments-spouse w-50">
      <ToolTip tooltipDescription={translate('rentalinformation:areYouCurrentlyMarried:tooltip')} />
      <YesNoRadioButtonInput
        errors={errors}
        id="are-you-currently-married-input"
        loadFrom="application"
        name="hasSpouse"
        onChange={onChange}
        question={translate('rentalinformation:areYouCurrentlyMarried:label')}
      />
    </div>
  );
};

export default AreYouCurrentlyMarriedInput;
