import React from 'react';

const AddressFields = ({
  addressLine1TextInput, addressLine2TextInput, cityTextInput, stateDropdownInput, zipCodeInput,
}) => (
  <div className="double-field-adjustment -mb-4">
    <div className="w-full">
      {addressLine1TextInput}
      {addressLine2TextInput}
      {cityTextInput}
    </div>
    <div className="w-full">
      <div className="w-5/12 inline-block">
        {stateDropdownInput}
      </div>
      <div className="w-3/6 inline-block float-right">
        {zipCodeInput}
      </div>
    </div>
  </div>
);

export default AddressFields;
