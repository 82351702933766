import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ pageName, key }) => {
  const { t: translate } = useTranslation();
  const sections = [
    {
      name: translate('global:progressBar:personalInformation'),
      pages: ['aboutYou', 'creditHistory', 'lifeEvents', 'personalInformation', 'rentalInformation', 'yourContact'],
      icon1: 'visited-personal-information',
      icon2: '',
      icon3: '',
      key: 'personal-information',
    },
    {
      name: translate('global:progressBar:incomeAndExpenses'),
      pages: ['expenses', 'provideMonthlyIncome', 'verifyIncome', 'incomeSummary'],
      icon1: 'visited-personal-information',
      icon2: 'visited-verify-income-and-expenses',
      icon3: '',
      key: 'verify-income-and-expenses',
    },
    {
      name: translate('global:progressBar:connectBankAccount'),
      pages: ['bankConnection'],
      icon1: 'visited-personal-information',
      icon2: 'visited-verify-income-and-expenses',
      icon3: 'visited-connect-bank-account',
      key: 'connect-bank-accounts',
    },
  ];

  const currentSection = sections.find((section) => section.pages.includes(pageName));

  const sectionTitle = sections.map((section) => {
    const className = section === currentSection ? 'inline-block' : 'hidden';

    return (<div className={className} key={section.key}>{`${section.name}`}</div>);
  });

  return (
    <section className="progress-bar-contain container sm:py-8 py-5 max-w-5xl m-auto">
      <div className="w-full m-auto">
        <div className="w-full md:w-1/2 m-auto flex">
          <ul className="progress-bar">
            <li key={key}>
              <div className={`personal-information ${currentSection.icon1}`} />
            </li>
            <li key={key}>
              <div className={`verify-income-and-expenses ${currentSection.icon2}`} />
            </li>
            <li key={key}>
              <div className={`connect-bank-account ${currentSection.icon3}`} />
            </li>
          </ul>
        </div>
        <div className="progress-title w-full md:w-1/2 m-auto">
          <div className="text-center text-sm">
            {sectionTitle}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgressBar;
