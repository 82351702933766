import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AgencyCoBranding from './AgencyCoBranding';
import { getCreditCounselingAgencyPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';

const AgencyHeader = () => {
  const isPreLead = useSelector((state) => state.borrower.data.id === undefined);
  const router = useSelector((state) => state.application.data.router);
  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ isPreLead, router });
  const { t: translate } = useTranslation();

  return (
    <div className="agencies agency-header">
      <nav className="container flex items-center justify-between flex-wrap pt-3 mx-auto">
        <div className="items-center sm:mr-6 sm:m-0 m-auto sm:w-full w-auto">
          <AgencyCoBranding />
          <div className="sm:inline-block sm:float-right float-none sm:text-right text-center sm:pr-0 sm:mt-4 mt-1 mb-2 sm:mb-0">
            <p>
              {translate('global:header:getHelpNowAt')}
              {' '}
              <a href={`tel:${phoneNumber}`} className="font-bold">{phoneNumber}</a>
            </p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AgencyHeader;
