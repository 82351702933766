import React from 'react';
import { useSelector } from 'react-redux';

const CheckboxInput = ({
  ariaLabel,
  checked,
  id,
  label,
  labelClass,
  loadFrom,
  name,
  onChange,
  required,
  valueIsFromKeyValuePairCollection = false,
}) => {
  const keyValuePair = useSelector((state) => valueIsFromKeyValuePairCollection && state[loadFrom].data.find((kvPair) => kvPair.key === name));

  const value = useSelector((state) => (
    loadFrom
    && name
    && (valueIsFromKeyValuePairCollection
      ? (keyValuePair && keyValuePair.value) || false
      : state[loadFrom].data[name])) || false);

  const onInputValueChange = (event) => {
    const valueOverride = event.target.checked;

    onChange(event, valueOverride);
  };

  return (
    <section>
      <div className="checkbox pb-5">
        <label htmlFor={id} className="container">
          <input
            aria-label={ariaLabel}
            checked={checked || value}
            id={id}
            name={name}
            onChange={onInputValueChange}
            required={required}
            type="checkbox"
          />
          <span className="checkmark" />
          <span className={labelClass}>{label}</span>
        </label>
      </div>
    </section>
  );
};

export default CheckboxInput;
