import React from 'react';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';

const FooterCallToAction = () => (
  <div className="w-full bg-primary-blue px-2">
    <div className="container inner-width m-auto py-20 text-center">
      <h2 className="font-bold pb-4 text-white">
        Take the first steps to getting out of debt!
      </h2>
      <CallToActionButton />
    </div>
  </div>
);

export default FooterCallToAction;
