import { getNumericValue } from '../helpers/numberHelper';

const formatCognitoPhoneNumberForPortal = ({ cognitoPhoneNumber }) => {
  if (!cognitoPhoneNumber) {
    return undefined;
  }

  // Cognito phone numbers are in the format +19999999999
  const formattedPhoneNumber = `${cognitoPhoneNumber.substring(2, 5)}-${cognitoPhoneNumber.substring(5, 8)}-${cognitoPhoneNumber.substring(8, 12)}`;

  return formattedPhoneNumber;
};

const formatPhoneNumberForBorrowerAndSpouse = ({ phoneNumber }) => {
  if (!phoneNumber) {
    return undefined;
  }

  const digits = phoneNumber.replace(/[^0-9]/g, '');

  const formattedPhoneNumber = `${digits.substring(0, 3)}-${digits.substring(3, 6)}-${digits.substring(6, 10)}`;

  return formattedPhoneNumber;
};

const formatPhoneNumberForCognito = ({ phoneNumber }) => {
  const formattedPhoneNumber = `+1${getNumericValue({ nonNumericValue: phoneNumber })}`;

  return formattedPhoneNumber;
};

export {
  formatCognitoPhoneNumberForPortal,
  formatPhoneNumberForBorrowerAndSpouse,
  formatPhoneNumberForCognito,
};
