const getRouteForBorrower = ({ lastViewedPage }) => {
  const showCustomerPortal = process.env.REACT_APP_SHOW_CUSTOMER_PORTAL === 'true';

  switch (lastViewedPage) {
    case 'AboutYou':
      return '/application/about-you';

    case 'AdditionalIncome':
      return '/application/income/provide-monthly-income';

    case 'BankConnection':
      return '/application/bank-connection';

    case 'BankConnectionSuccess':
      return '/application/income/provide-monthly-income';

    case 'CallOrConnectAccount':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/call-or-connect-account';

    case 'CallScheduled':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/pending';

    case 'CallUs':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/call-us';

    case 'CreditHistory':
      return '/application/credit-history';

    case 'Decline':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/decline';

    case 'Expenses':
      return '/application/expenses';

    case 'HardshipOffer':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/success';

    case 'IncomeAdjustment':
      return '/application/income/provide-monthly-income';

    case 'IncomeSummary':
      return '/application/income/provide-monthly-income';

    case 'LifeEvents':
      return '/application/life-events';

    case 'MonthlyIncome':
      return '/application/income/provide-monthly-income';

    case 'OfferStatus':
      return '/offers/status';

    case 'Pending':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/pending';

    case 'ProvideMonthlyIncome':
      return '/application/income/provide-monthly-income';

    case 'ReasonForNotConnectingAccount':
      return '/application/income/reason-for-not-connecting-account';

    case 'SignContract':
      return '/contract/sign';

    case 'Success':
      return showCustomerPortal ? '/customer-portal/summary' : '/offers/success';

    case 'VerifyIncome':
      return '/application/income/provide-monthly-income';

    case 'YourContact':
      return '/application/your-contact';

    default:
      return '/application/about-you';
  }
};

const getRouteForCustomerServiceUser = () => '/summary';

export {
  getRouteForBorrower,
  getRouteForCustomerServiceUser,
};
