import React from 'react';
import { DateTime } from 'luxon';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const PaymentHistoryDetail = ({ value, date }) => {
  const paymentDateToString = DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat('MM-dd-yyyy');

  return (
    <div className="payment-history">
      <div className="details w-full pt-4">
        <ul className="leaders">
          <li className="text-left pb-4">
            <span className="green font-bold">
              {formatNumberAsCurrency({ number: value })}
            </span>
            <span>{paymentDateToString}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PaymentHistoryDetail;
