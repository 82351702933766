import {
  CLEAR_SAVE_SPOUSE_SUCCEEDED, CLEAR_SPOUSE_ADDRESS,
  SAVE_SPOUSE, SAVE_SPOUSE_FAIL, SAVE_SPOUSE_SUCCESS,
  SET_SAVE_SPOUSE_SUCCEEDED,
  SET_SPOUSE,
  SET_SPOUSE_ADDRESS_TO_BORROWER_ADDRESS,
  SET_SPOUSE_CONSENTED_TO_CREDIT_POLICY,
  UPDATE_SPOUSE,
} from '../actions/spouseActions';
import { formatAwsDateForApplication } from '../../formatters/dateFormatter';

const initialState = {
  consentedToCreditPolicy: false,
  data: {
    // unique identifier
    id: undefined,

    // the owner of the data
    owner: undefined,

    // Spouse properties
    city: undefined,
    dateOfBirth: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    state: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    zipCode: undefined,

    // Spouse -> Application relationship link
    spouseApplicationId: undefined,

    // Spouse -> Spouse relationship link
    spouseSpouseId: undefined,
  },
  hasGottenSpouse: undefined,
  hasSameAddressAsBorrower: undefined,
  isGettingSpouse: undefined,
  isSaving: undefined,
  saveSuccess: undefined,
};

const spouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SAVE_SPOUSE_SUCCEEDED:
      return { ...state, saveSuccess: undefined };

    case CLEAR_SPOUSE_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          addressLine1: undefined,
          addressLine2: undefined,
          city: undefined,
          state: undefined,
          zipCode: undefined,
        },
        hasSameAddressAsBorrower: false,
      };

    case SAVE_SPOUSE:
      return { ...state, isSaving: true, saveSuccess: undefined };

    case SAVE_SPOUSE_FAIL:
      return { ...state, isSaving: false, saveSuccess: false };

    case SAVE_SPOUSE_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, id: action.spouseId },
        isSaving: false,
        saveSuccess: true,
      };
    }

    case SET_SAVE_SPOUSE_SUCCEEDED:
      return { ...state, saveSuccess: true };

    case SET_SPOUSE: {
      if (!action.spouse) {
        return {
          ...state,
          data: initialState.data,
          hasGottenSpouse: true,
          isGettingSpouse: false,
        };
      }

      const dateOfBirth = formatAwsDateForApplication({ awsDate: action.spouse.dateOfBirth });

      const propertiesToKeep = Object.keys(initialState.data);

      Object.keys(action.spouse).forEach((property) => {
        if (!propertiesToKeep.includes(property)) {
          // delete the relational objects the database provided (e.g. application, borrower, etc.)
          // eslint-disable-next-line no-param-reassign
          delete action.spouse[property];
        }
      });

      return {
        ...state,
        data: { ...action.spouse, dateOfBirth },
        hasGottenSpouse: true,
        isGettingSpouse: false,
      };
    }

    case SET_SPOUSE_ADDRESS_TO_BORROWER_ADDRESS: {
      const {
        addressLine1, addressLine2, city, zipCode,
      } = action.borrower;

      return {
        ...state,
        data: {
          ...state.data, addressLine1, addressLine2, city, state: action.borrower.state, zipCode,
        },
        hasSameAddressAsBorrower: true,
      };
    }

    case SET_SPOUSE_CONSENTED_TO_CREDIT_POLICY:
      return { ...state, consentedToCreditPolicy: action.consentedToCreditPolicy };

    case UPDATE_SPOUSE:
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };

    default:
      return state;
  }
};

export default spouseReducer;
