import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownInput from '../common/DropdownInput/DropdownInput';
import { setOtherReasonForNotConnectingAccount } from '../../../redux/actions/applicationActions';

const ReasonForNotConnectingAccountDropdownInput = ({ errors, onChange }) => {
  const dispatch = useDispatch();
  const valueOverride = useSelector((state) => state.application.otherReasonForNotConnectingAccount && 'Other');

  const options = [
    { label: 'I didn\'t feel comfortable providing my credentials', value: 'Not comfortable providing credentials' },
    { label: 'I don\'t do online banking', value: 'Not an online banker' },
    { label: 'I don\'t have my bank credentials', value: 'Does not have bank credentials' },
    { label: 'I can\'t find my bank listed', value: 'Bank is not listed' },
    { label: 'Other', value: 'Other' },
  ];

  const onSelectValueChange = (event) => {
    const { value } = event.target;

    if (value !== 'Other') {
      dispatch(setOtherReasonForNotConnectingAccount({ value: undefined }));
    }

    onChange(event);
  };

  return (
    <div className="pb-8">
      <DropdownInput
        errors={errors}
        id="reason-for-not-connecting-account-select"
        loadFrom="application"
        name="reasonForNotConnectingBank"
        onChange={onSelectValueChange}
        options={options}
        question="Reason for not connecting an account"
        valueOverride={valueOverride}
      />
    </div>
  );
};

export default ReasonForNotConnectingAccountDropdownInput;
