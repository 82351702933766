import React from 'react';

const DebtScoreMinimum = ({ debtScores }) => {
  const newestDebtScore = debtScores.length && debtScores[0].value;
  const debtScoreAnimationEndTime = newestDebtScore === 0 ? 0.1 : (newestDebtScore / 100) * 3;
  let debtScoreTextX = 110;
  const debtScoreTextY = 111;
  const circleCX = 0;
  const circleCY = 17;

  if (newestDebtScore === 100) {
    debtScoreTextX = 100;
  }

  if (newestDebtScore < 10) {
    debtScoreTextX = 120;
  }

  const debtScoreIntegers = newestDebtScore.toString().split('').map((s) => parseInt(s, 10));

  const stat = debtScoreIntegers.map((debtScoreInteger, index) => (
    <tspan
      data-value={debtScoreInteger}
      // eslint-disable-next-line react/no-array-index-key
      key={`integer-${index}`}
      x={debtScoreTextX + ((index + 1) * 25)}
      className="block h-1"
    >
      <tspan y={debtScoreTextY}>
        <animate
          attributeName="y"
          begin="1s"
          values={`${debtScoreTextY};${debtScoreTextY - 40}`}
          dur="500ms"
          repeatCount="1"
          fill="freeze"
        />
        &ndash;
      </tspan>
      {Array(debtScoreInteger + 1)
        .join(0)
        .split(0)
        .map(
          (x, j) => (
            <tspan
              // eslint-disable-next-line react/no-array-index-key
              key={`num-${j}`}
              id={`num-${j}`}
              x={newestDebtScore === 100 && debtScoreInteger === 1 ? debtScoreTextX + ((index + 1) * 30) : debtScoreTextX + ((index + 1) * 25)}
              y={debtScoreTextY + ((j + 1) * 40)}
            >
              <animate
                attributeName="y"
                begin={`${1000 + j * 50}ms`}
                values={j === debtScoreInteger
                  ? `${debtScoreTextY + ((j + 1) * 40)};${debtScoreTextY}`
                  : `${debtScoreTextY + ((j + 1) * 40)};${debtScoreTextY - 40}`}
                dur={`${500 + (j * 100)}ms`}
                repeatCount="1"
                fill="freeze"
              />
              {j}
            </tspan>
          ),
        )}
    </tspan>
  ));

  return (
    <div className="pt-5">
      <div className="debt-score m-auto">
        <svg className="m-auto svg" viewBox="0 0 313 184">
          <g className="g-container" fillRule="evenodd">
            <g transform="translate(-56.000000, -67.000000)">
              <g transform="translate(56.000000, 67.000000)">
                <g transform="translate(5.000000, 0.000000)" strokeWidth="8">
                  <g className="debt-graphic-container" transform="translate(0.000000, 17.000000)">

                    <path
                      id="debt-score-path"
                      d="M0.956217545,147.702073 C17.8695235,48.105534 67.3211012,-0.86518876 149.310951,0.78990531 C231.3008,2.44499938 280.752378,51.4157221 297.665684,147.702073"
                      stroke="#132741"
                      fill="none"
                    />

                    <path className="red" d="M20,78 C7.33931241,97.3430351 0,120.33401 0,145" />
                    <path className="orange" d="M82,19 C60.2041756,28.7105977 41.7991911,44.4326646 29,64" />
                    <path className="yellow" d="M199,11.5806875 C183.552962,4.13771875 166.439434,0 148.430563,0 C130.086493,0 112.671349,4.29299436 97,12" />
                    <path className="green" d="M267,64 C253.981262,44.4018847 235.615826,28.6995992 214,19" />
                    <path className="light-blue" d="M296,146 C296,120.474957 288.266115,96.7448419 275,77" />
                  </g>

                  <circle cx={circleCX} cy={circleCY} r="13">
                    <animate
                      attributeName="stroke"
                      values="#E30051;#FF9B00;#0079DB;#247B4A;#4DDD8C"
                      dur="3s"
                      repeatCount="1"
                      fill="freeze"
                      end={`${debtScoreAnimationEndTime}s`}
                    />
                    <animateMotion
                      dur="3s"
                      repeatCount="1"
                      fill="freeze"
                      end={`${debtScoreAnimationEndTime}s`}
                      path="M0.956217545,147.702073 C17.8695235,48.105534 67.3211012,-0.86518876 149.310951,0.78990531 C231.3008,2.44499938 280.752378,51.4157221 297.665684,147.702073"
                    >
                      <mpath xlinkHref="#debt-score-path" />
                    </animateMotion>
                  </circle>
                </g>

                <svg>
                  <defs>
                    <rect id="rect" width="50%" height="21%" x="75" y="75" />
                    <clipPath id="clip">
                      <use xlinkHref="#rect" />
                    </clipPath>
                  </defs>
                  <use xlinkHref="#rect" />
                  <g clipPath="url(#clip)">
                    <text className="debt-score-value block h-1">
                      <tspan
                        data-value={newestDebtScore}
                        x={debtScoreTextX}
                        y={debtScoreTextY}
                      >
                        {stat}
                      </tspan>
                    </text>
                  </g>
                </svg>

                <text className="debt-score-label">
                  <tspan x="112" y="131">Debt Score</tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DebtScoreMinimum;
