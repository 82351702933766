import React from 'react';
import { useSelector } from 'react-redux';
import Expenses from '../../../components/common/Expenses/Expenses';
import { getExpenseCategoryTotals } from '../../../redux/actions/expensesActions';
import ValueDetail from '../../offers/OfferDetail/ValueDetail';

const CustomerExpenseSummary = () => {
  const expenses = useSelector((state) => state.expenses.list);

  const { totalVerifiedExpenses } = getExpenseCategoryTotals({ expenses });

  return (
    <div className="customer-expense-summary leading-normal tracking-normal grey-bg pb-20">
      <section>
        <div className="w-full">
          <div className="customer-portal">
            <div className="expense-summary">
              <h1 className="text-center">Expense Summary</h1>
              <div className="lender-tile-animation-1">
                <div>
                  <div className="amount text-center">
                    <ValueDetail value={totalVerifiedExpenses} />
                  </div>
                  <div className="text-center pb-4">
                    Total Monthly Expenses
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="income-summary container mx-auto grey-bg">
            <div className="box">
              <div className="verify-income">
                <div className="mx-auto pl-2 pr-2 pb-20 text-center w-full">
                  <h2 className="text-xl sm:text-2xl w-full m-auto">
                    Verified Expenses
                  </h2>
                  <div className="w-full pt-5 pb-10">
                    <div className="expense-tile-container">
                      <Expenses noExpensesMessage="No expenses were provided." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerExpenseSummary;
