import React from 'react';
import WhoWeAre from '../../components/common/WhoWeAre/WhoWeAre';
import { Hero, HowItWorks } from '../../components/landing-pages/Referral';
import { FooterCTA } from '../../components/landing-pages';

const Referral = () => (
  <div className="nfcc">
    <div className="referral">
      <Hero />
      <WhoWeAre />
      <HowItWorks />
      <FooterCTA />
    </div>
  </div>
);

export default Referral;
