import React from 'react';

const OfferDetail = ({ format, name, value }) => (
  <div className="w-full">
    <ul className="leaders">
      <li className="text-left pb-4">
        <span>
          {name}
          :
        </span>
        <span>{value ? format.getFormattedValue(value) : 'N/A'}</span>
      </li>
    </ul>
  </div>
);

export default OfferDetail;
