import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import CGLogo from '../../../../assets/logos/creditgenie_logo_blue.svg';
import RenterAdvantageCoBrand from '../../../../assets/logos/renter-advantage-co-brand_logo.svg';

const RenterAdvantageHero = () => {
  const { t: translate } = useTranslation();
  const showTranslationOption = process.env.REACT_APP_SHOW_TRANSLATION_OPTION === 'true';

  const onLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="renter-advantage-hero md:bg-top bg-white" id="top-of-page">
      {showTranslationOption
        && (
        <div className="text-left ml-10 pt-10">
          <p className="text-sm w-">
            Select Language:
          </p>
          <select name="language" onChange={onLanguageChange}>
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        )}
      <div className="w-full lg:w-3/5 m-auto">
        <div className="container sm:pt-15 pt-5 m-auto">
          <img className="logo mt-4 mx-auto block" src={RenterAdvantageCoBrand} alt="NFCC HPN and Renter Advantage Logo" />
          <h1 className="text-black text-center pt-4 sm:pb-12 pb-2 sm:text-6xl text-3xl font-bold md:px-10">
            {translate('landingpage:renterAdvantage:hero:title')}
          </h1>
          <p className="sm:pb-10 pb-10 w-3/4 m-auto">
            {translate('landingpage:renterAdvantage:hero:subtitle')}
          </p>
          <CallToActionButton buttonLabel={translate('global:cta:callToActionButtonDefault')} />
          <div className="powered-by pb-4 text-black">
            <p>{translate('landingpage:renterAdvantage:hero:poweredBy')}</p>
            <img src={CGLogo} alt="Credit Genie Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenterAdvantageHero;
