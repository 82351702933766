import React from 'react';

const BudgetBarGraph = () => (
  <div className="budget-bar-graph">
    <svg width="338px" height="294px" viewBox="0 0 345 294">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Action-Plan" transform="translate(-27.000000, -192.000000)">
          <rect id="Rectangle" fill="#132741" x="0" y="0" width="414" height="874" />
          <text id="Mar-Apr-May-Jun-Jul" fontFamily="Helvetica" fontSize="12" fontWeight="normal" fill="#FFFFFF">
            <tspan x="64.3242188" y="483">Mar</tspan>
            <tspan x="124.5" y="483">Apr</tspan>
            <tspan x="181.5" y="483">May</tspan>
            <tspan x="235.5" y="483">Jun</tspan>
            <tspan x="291.5" y="483">Jul</tspan>
            <tspan x="345" y="483">Aug</tspan>
          </text>
          <text id="action-plan-values" fontFamily="Helvetica" fontSize="12" fontWeight="normal" line-spacing="23" fill="#FFFFFF">
            <tspan x="27.6523438" y="198">$5k</tspan>
            <tspan x="47" y="221" />
            <tspan x="43.0039062" y="244">-</tspan>
            <tspan x="47" y="267" />
            <tspan x="43.0039062" y="290">-</tspan>
            <tspan x="47" y="313" />
            <tspan x="43.0039062" y="336">-</tspan>
            <tspan x="47" y="359" />
            <tspan x="43.0039062" y="382">-</tspan>
            <tspan x="47" y="405" />
            <tspan x="43.0039062" y="428">-</tspan>
            <tspan x="47" y="451" />
            <tspan x="33.6523438" y="474">$0</tspan>
          </text>
          <rect id="Rectangle" fill="#6BE39A" x="67.5" y="202" width="12" height="259" />
          <rect id="Rectangle" fill="#6BE39A" x="124.5" y="213" width="12" height="248" />
          <rect id="Rectangle" fill="#6BE39A" x="181.5" y="255" width="12" height="206" />
          <rect id="Rectangle" fill="#6BE39A" x="235.5" y="346" width="12" height="115" />
          <rect id="Rectangle" fill="#6BE39A" x="291.5" y="363" width="12" height="98" />
          <rect id="Rectangle" fill="#6BE39A" x="345" y="362" width="12" height="98" />
          <rect id="Rectangle" fill="#6BE39A" x="181.5" y="255" width="12" height="206" />
        </g>
      </g>
    </svg>
  </div>
);

export default BudgetBarGraph;
