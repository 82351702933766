const formatNumberAsPercentage = ({
  allowZero = true, locale = 'en-US', number, numberOfDecimalPoints = 2,
}) => {
  if (!number && !(allowZero && number === 0)) {
    return undefined;
  }

  const numberAsPercentage = new Intl.NumberFormat(locale, {
    minimumFractionDigits: numberOfDecimalPoints,
    maximumFractionDigits: numberOfDecimalPoints,
    style: 'percent',
  }).format(number);

  return numberAsPercentage;
};

export default formatNumberAsPercentage;
