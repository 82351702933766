import React from 'react';
import { withRouter } from 'react-router-dom';
import BackArrow from '../../../../assets/icons/back-arrow_icon_KO.svg';

const BackButtonKO = ({ history }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
    <img
      src={BackArrow}
      className="cursor-pointer"
      data-cy="backbutton"
      onClick={() => history.goBack()}
      onKeyDown={() => history.goBack()}
      alt="Go back"
    />
  </>
);

export default withRouter(BackButtonKO);
