import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import {
  Alerts,
  ContactSummary,
  DebtSummary,
  OfferSummary,
  PlanEnrollmentSummary,
} from '../../pages/customer-service-portal';
import AlertBadge from '../../components/portal/Alerts/AlertBadge';
import {
  ApplicationSummaryReadOnlyComponent,
  IncomeAndExpenseSummaryComponent,
  RentalApplicationSummary,
} from '../../components/customer-service-portal';
import { updateDefaultSummaryTabIndex } from '../../redux/actions/portalActions';

const BorrowerSubNavigation = () => {
  const alerts = useSelector((state) => state.alerts.data);
  const defaultIndex = useSelector((state) => state.portal.summaryTabIndex);
  const dispatch = useDispatch();
  const isRentalApplication = useSelector((state) => state.application.data.type === 'rental');
  const numberOfUnseenAlerts = (alerts.filter((alert) => !alert.hasBeenSeenByCustomerServiceRepresentative)).length;
  const showAlertsTab = process.env.REACT_APP_SHOW_COUNSELOR_ALERTS === 'true';

  const onSelect = (newIndex) => {
    dispatch(updateDefaultSummaryTabIndex(newIndex));
  };

  return (
    <div className="bg-white w-full box">
      <Tabs defaultIndex={defaultIndex || 0} onSelect={onSelect}>
        <TabList>
          <Tab data-cy="application-sumary">
            Application
          </Tab>
          <Tab data-cy="income-and-expenses">
            Income &amp; Expenses
          </Tab>
          <Tab data-cy="debt-summary">
            Debts
          </Tab>
          <Tab data-cy="offer-summary">
            Decision
          </Tab>
          {
            showAlertsTab
            && (
            <Tab>
              Alerts
              { numberOfUnseenAlerts > 0 && (<AlertBadge numberOfAlerts={numberOfUnseenAlerts} />) }
            </Tab>
            )
          }
          <Tab data-cy="contact-summary">
            Contact History
          </Tab>
          <Tab data-cy="plan-enrollment-summary">
            {isRentalApplication ? 'Rental Relief Plan' : 'DMP'}
          </Tab>
        </TabList>
        <TabPanel>
          {isRentalApplication ? <RentalApplicationSummary /> : <ApplicationSummaryReadOnlyComponent />}
        </TabPanel>
        <TabPanel>
          <IncomeAndExpenseSummaryComponent />
        </TabPanel>
        <TabPanel>
          <DebtSummary />
        </TabPanel>
        <TabPanel>
          <OfferSummary />
        </TabPanel>
        {
          showAlertsTab
          && (
          <TabPanel>
            <Alerts />
          </TabPanel>
          )
        }
        <TabPanel>
          <ContactSummary />
        </TabPanel>
        <TabPanel>
          <PlanEnrollmentSummary />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BorrowerSubNavigation;
