import { API, graphqlOperation } from 'aws-amplify';
import { createContactAttempt } from '../../graphql/customMutations';

export const CLEAR_CONTACT_ATTEMPT = 'CLEAR_CONTACT_ATTEMPT';
export const CLEAR_RECEIVED_OFFER = 'CLEAR_RECEIVED_OFFERS';
export const CLEAR_SAVE_CONTACT_ATTEMPT_SUCCEEDED = 'CLEAR_SAVE_CONTACT_ATTEMPT_SUCCEEDED';
export const GET_CONTACT_ATTEMPTS_SUCCESS = 'GET_CONTACT_ATTEMPTS_SUCCESS';
export const SAVE_CONTACT_ATTEMPT = 'SAVE_CONTACT_ATTEMPT';
export const SAVE_CONTACT_ATTEMPT_SUCCESS = 'SAVE_CONTACT_ATTEMPT_SUCCESS';
export const SAVE_CONTACT_ATTEMPT_FAIL = 'SAVE_CONTACT_ATTEMPT_FAIL';
export const SET_LIST_CONTACT_ATTEMPTS = 'SET_LIST_CONTACT_ATTEMPTS';
export const UPDATE_CONTACT_ATTEMPT = 'UPDATE_CONTACT_ATTEMPT';
export const UPDATE_CONTACT_ATTEMPT_LIST = 'UPDATE_CONTACT_ATTEMPT_LIST';

const clearContactAttempt = () => ({ type: CLEAR_CONTACT_ATTEMPT });

const clearReceivedOffer = () => ({ type: CLEAR_RECEIVED_OFFER });

const clearSaveContactAttemptSucceeded = () => ({ type: CLEAR_SAVE_CONTACT_ATTEMPT_SUCCEEDED });

const saveContactAttempt = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_CONTACT_ATTEMPT });

  const state = getState();

  const contactAttempt = state.contactAttempt.data;

  const {
    whatWasTheMethodOfContact: methodOfContact,
    wasACounselingSessionConducted: counselingSessionConducted,
  } = contactAttempt;

  const method = methodOfContact
    || (counselingSessionConducted
      ? 'Counseling Session'
      : 'No session conducted');

  const outcome = contactAttempt.whatTypeOfOfferDidTheyReceive || contactAttempt.whatWasTheOutcome;

  const input = {
    applicationId: state.application.data.id,
    contactedBy: state.user.data.username,
    method,
    outcome,
  };

  try {
    await API.graphql(graphqlOperation(createContactAttempt, { input }));

    dispatch({ type: SAVE_CONTACT_ATTEMPT_SUCCESS });
  } catch (error) {
    console.log('saveContactAttemptFailure was called with:', error);

    dispatch({ contactAttempt, type: SAVE_CONTACT_ATTEMPT_FAIL });
  }
};

const updateContactAttempt = ({ propertyName, value }) => ({ propertyName, value, type: UPDATE_CONTACT_ATTEMPT });

const updateContactAttemptList = () => async (dispatch, getState) => {
  const state = getState();

  const contactAttempt = state.contactAttempt.data;

  const {
    contactedBy,
    createdAt,
    wasACounselingSessionConducted: counselingSessionConducted,
    whatTypeOfOfferDidTheyReceive: offerTypeReceived,
    whatWasTheMethodOfContact: methodOfContact,
    whatWasTheOutcome: outcome,
  } = contactAttempt;

  dispatch({
    contactAttempt: {
      contactedBy,
      createdAt,
      method: methodOfContact || (counselingSessionConducted ? 'Counseling Session' : 'No session conducted'),
      outcome: offerTypeReceived || outcome,
    },
    type: UPDATE_CONTACT_ATTEMPT_LIST,
  });
};

export {
  clearContactAttempt,
  clearReceivedOffer,
  clearSaveContactAttemptSucceeded,
  saveContactAttempt,
  updateContactAttempt,
  updateContactAttemptList,
};
