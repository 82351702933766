import React from 'react';
import { useSelector } from 'react-redux';
import { calculateTotalInterestCharges, calculateTotalUnsecuredDebt, getUniqueUnsecuredDebts } from '../../../redux/actions/debtsActions';
import DebtSummaryComponent from '../../../components/common/DebtSummaryComponent/DebtSummaryComponent';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import formatNumberAsPercentage from '../../../formatters/percentageFormatter';

const CustomerDebtAnalysis = () => {
  const debts = useSelector((state) => state.debts.data);

  const estimatedInterestRate = 0.24;
  const estimatedTotalInterestCharges = calculateTotalInterestCharges({ debts });
  const nationalAverageAmountOfDebt = 27000;
  const totalUnsecuredDebt = calculateTotalUnsecuredDebt({ debts });
  const unsecuredDebts = getUniqueUnsecuredDebts({ debts });

  const amountOfDebtComparedToNationalAverage = totalUnsecuredDebt / nationalAverageAmountOfDebt;

  return (
    <div className="customer-debt-analysis w-full">
      <div className="customer-summary container m-auto pb-10">
        <div className="cashflow-debts-expenses">
          <h1 className="text-center">Your Debt Analysis</h1>
          <div className="flex lender-tile-animation-1">
            <div className={`value m-auto ${totalUnsecuredDebt === 0 ? 'color-green' : 'color-red'}`}>
              <div className={`arrows ${totalUnsecuredDebt === 0 ? 'green-up-arrow' : 'red-down-arrow'}`} />
              {formatNumberAsCurrency({ number: totalUnsecuredDebt })}
            </div>
          </div>
          <div className="lender-tile-animation-1">
            <div>Total Unsecured Debt</div>
            <div className="label-text">
              <span className={`font-bold ${amountOfDebtComparedToNationalAverage <= 1 ? 'color-green' : 'color-red'}`} />
            </div>
            <div className="sub-values flex">
              <div className="w-1/2">
                <div className={`value m-auto ${estimatedTotalInterestCharges === 0 ? 'color-green' : 'color-red'}`}>
                  <div className={`est-total-interest-charges arrows ${estimatedTotalInterestCharges === 0 ? 'green-up-arrow' : 'red-down-arrow'}`} />
                  {formatNumberAsCurrency({ number: estimatedTotalInterestCharges })}
                </div>
                <span className="label-text">
                  Estimated Total Interest Charges
                </span>
              </div>
              <div className="w-1/2">
                <div className={`value m-auto ${estimatedInterestRate === 0 ? 'color-green' : 'color-red'}`}>
                  {formatNumberAsPercentage({ number: estimatedInterestRate, numberOfDecimalPoints: 0 })}
                </div>
                <span className="label-text">
                  Estimated Interest Rate
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />
      <div className="w-full grey-bg">
        <div className="debt-summary m-auto">
          <div className="dynamic-info">
            <div className="bg-white w-full box">
              <h1 className="text-center color-black">Debts By Lender</h1>
              <DebtSummaryComponent debts={unsecuredDebts} showFilter={false} showSearchBar={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDebtAnalysis;
