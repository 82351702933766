import React from 'react';
import TrophyGraphic from '../../../common/TrophyGraphic/TrophyGraphic';

const Step4 = () => (
  <div className="step-4">
    <h3 className="pb-4 font-normal">Step 4</h3>
    <h1>
      See how much you can save
    </h1>
    <TrophyGraphic />
    <p className="pt-6">
      We&apos;ll show you how much you can save with our free Debt Resolution tool. This will have everything you need
      to confidently negotiate with your creditors and reduce your debt.
    </p>
    <p className="pt-6">
      We&apos;ll also add you to the waiting list for Credit Genie&apos;s done-for-you, fully-digital and completely free service.
    </p>
    <div className="w-full">
      <ul className="slider-dots text-center">
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot active-dot">&bull;</li>
      </ul>
    </div>
  </div>
);

export default Step4;
