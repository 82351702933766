/* eslint-disable max-len */
import React from 'react';

const ShieldIcon = () => (
  <div>
    <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1">
      <g id="Mobile---Application-/-NFCC" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="shield-stroke" id="app-page-5-alt" transform="translate(-27.000000, -610.000000)">
          <path d="M36.3155977,611 C36.3155977,611 30.7999983,613.830683 28.1341034,614.196217 C28.1341034,614.196217 26.222881,624.059101 36.830791,631 C36.830791,631 45.6921824,625.885791 44.9566154,614.470122 C44.9566154,614.470122 40.5442018,613.19157 36.3155977,611 Z" id="shield_icon" />
        </g>
      </g>
    </svg>
  </div>
);

export default ShieldIcon;
