import {
  PULL_CREDIT, PULL_CREDIT_FAIL, PULL_CREDIT_SUCCESS,
  SET_BORROWER_CREDIT_REPORT_ID,
  SET_HAS_PULLED_CREDIT,
  SET_SPOUSE_CREDIT_REPORT_ID,
} from '../actions/transunionActions';

const initialState = {
  data: {
    borrowerCreditReportId: undefined,
    spouseCreditReportId: undefined,
  },
  hasPulledCredit: undefined,
  isPullingCredit: undefined,
};

const transunionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PULL_CREDIT:
      return { ...state, hasPulledCredit: undefined, isPullingCredit: true };

    case PULL_CREDIT_FAIL:
      return { ...state, hasPulledCredit: false, isPullingCredit: false };

    case PULL_CREDIT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          borrowerCreditReportId: action.borrowerCreditReportId,
          spouseCreditReportId: action.spouseCreditReportId,
        },
        hasPulledCredit: true,
        isPullingCredit: false,
      };

    case SET_BORROWER_CREDIT_REPORT_ID:
      return { ...state, borrowerCreditReportId: action.borrowerCreditReportId };

    case SET_HAS_PULLED_CREDIT:
      return { ...state, hasPulledCredit: action.hasPulledCredit };

    case SET_SPOUSE_CREDIT_REPORT_ID:
      return { ...state, spouseCreditReportId: action.spouseCreditReportId };

    default:
      return state;
  }
};

export default transunionReducer;
