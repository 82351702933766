import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const OtherHardshipCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <CheckboxInput
      id="other-hardship-checkbox"
      label={translate('lifeevents:other')}
      loadFrom="hardships"
      name="hasOtherHardship"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default OtherHardshipCheckbox;
