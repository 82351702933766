import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpouse } from '../../../redux/actions/spouseActions';
import AddressLine2TextInput from '../common/AddressFields/AddressLine2TextInput/AddressLine2TextInput';

const BorrowerAddressLine2TextInput = ({ errors, onBlur, onChange }) => {
  const dispatch = useDispatch();
  const spouseAddressIsSameAsBorrowerAddress = useSelector((state) => state.spouse.hasSameAddressAsBorrower);

  const onInputValueChange = (event) => {
    if (spouseAddressIsSameAsBorrowerAddress) {
      dispatch(updateSpouse({ propertyName: event.target.name, value: event.target.value }));
    }

    onChange(event);
  };

  return (
    <AddressLine2TextInput
      errors={errors}
      id="borrowe-address-line-2-input"
      loadFrom="borrower"
      onBlur={onBlur}
      onChange={onInputValueChange}
      validationName="borrowerAddressLine2"
    />
  );
};

export default BorrowerAddressLine2TextInput;
