import { DateTime } from 'luxon';
import PlaidLink from 'react-plaid-link';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import awsconfig from '../../../aws-exports';
import { BankConnectionTile } from '../../../components/customer-portal';
import { plaidConnectionSuccess } from '../../../redux/actions/plaidActions';

const CustomerBankConnections = () => {
  const dispatch = useDispatch();
  const plaidConnections = useSelector((state) => state.plaid.plaidItems);
  const webhook = `${awsconfig.aws_cloud_logic_custom.find((api) => api.name === 'plaid').endpoint}/webhook`;

  const sortAlphabetically = (plaidConnectionA, plaidConnectionB) => {
    if (plaidConnectionA.institutionName < plaidConnectionB.institutionName) {
      return -1;
    }

    if (plaidConnectionA.institutionName > plaidConnectionB.institutionName) {
      return 1;
    }

    return 0;
  };

  const connectedAccounts = plaidConnections.filter((plaidConnection) => plaidConnection.isConnected).sort(sortAlphabetically);
  const nonConnectedAccounts = plaidConnections.filter((plaidConnection) => !plaidConnection.isConnected).sort(sortAlphabetically);

  const onSubmit = (event) => {
    event.preventDefault();
  };

  // eslint-disable-next-line camelcase
  const onSuccess = (public_token, metadata) => {
    dispatch(plaidConnectionSuccess({ metadata, public_token }));
  };

  return (
    <div className="container m-auto pb-10">
      <div className="customer-portal-title">Bank Connections</div>
      {connectedAccounts.map((plaidConnection) => (
        <BankConnectionTile
          connectionDate={DateTime.fromISO(plaidConnection.createdAt).toFormat('MM-dd-yyyy')}
          institutionName={plaidConnection.institutionName}
          isConnected={plaidConnection.isConnected}
          key={`${plaidConnection.institutionName}-bank-tile-connected`}
        />
      ))}
      <p className="text-center text-white px-2 sm:px-10 font-light mx-64 mt-5">
        To get the most accurate assessment of your financial health, we suggest connecting any savings or checking account where you deposit funds.
      </p>
      <p className="text-xs text-center text-white px-2 sm:px-10 font-light mx-64 mt-5">
        Concerned about security? Read our FAQs
        {' '}
        <a href="/customer-portal/faq">here</a>
        .
      </p>
      <div className="text-center mt-8">
        <PlaidLink
          className="m-auto self-center customer-portal-input"
          clientName="Creditly"
          env={process.env.REACT_APP_PLAID_ENV}
          product={['transactions']}
          publicKey="1995cf6fdc8987d2cab0a1242a9d56"
          onSuccess={onSuccess}
          onSubmit={onSubmit}
          style={{ outline: 'none' }}
          webhook={webhook}
        >
          {plaidConnections.length > 0 ? 'Connect another account' : 'Connect account'}
        </PlaidLink>
      </div>
      <div className="customer-portal-title mt-20">Connect Your Credit Accounts</div>
      <p className="text-center text-white px-2 sm:px-10 font-light my-4 mx-64">
        To help us accurately monitor your debts, and provide you with best relief options, please connect to your accounts for the following lenders.
      </p>
      <p className="text-xs text-center text-white px-2 sm:px-10 font-light mx-64 mt-5">
        Concerned about security? Read our FAQs
        {' '}
        <a href="/customer-portal/faq">here</a>
        .
      </p>
      {nonConnectedAccounts.map((plaidConnection) => (
        <BankConnectionTile
          institutionName={plaidConnection.institutionName}
          key={`${plaidConnection.institutionName}-bank-tile-not-connected`}
          onSuccess={onSuccess}
          onSubmit={onSubmit}
          webhook={webhook}
        />
      ))}
    </div>
  );
};

export default CustomerBankConnections;
