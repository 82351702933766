import {
  CLEAR_HAS_DEPENDENTS,
  CLEAR_SAVE_APPLICATION_SUCCEEDED,
  GET_APPLICATION, GET_APPLICATION_FAIL, GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FOR_USER, GET_APPLICATION_FOR_USER_FAIL, GET_APPLICATION_FOR_USER_SUCCESS,
  GET_APPLICATIONS_FOR_SERVICE_PORTAL, GET_APPLICATIONS_FOR_SERVICE_PORTAL_FAILED, GET_APPLICATIONS_FOR_SERVICE_PORTAL_SUCCEEDED,
  SAVE_APPLICATION, SAVE_APPLICATION_FAIL, SAVE_APPLICATION_SUCCESS,
  SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_SUCCESS,
  SET_AGREED_TO_TERMS,
  SET_OTHER_REASON_FOR_NOT_CONNECTING_ACCOUNT,
  SET_HAS_DEPENDENTS,
  SET_HAS_PROVIDED_MONTHLY_INCOME,
  SET_HAS_SUBMITTED_PRIMARY_APPLICATION,
  SET_SAVE_APPLICATION_SUCCEEDED,
  SET_UTM_CAMPAIGN_PARAMETERS,
  UPDATE_APPLICATION,
} from '../actions/applicationActions';

const initialState = {
  agreedToTerms: false,
  data: {
    // unique identifier
    id: undefined,

    // the owner of the data
    owner: undefined,

    // Application properties
    agreedToActionPlan: false,
    assignedTo: undefined,
    assignedToAt: undefined,
    createdAt: undefined,
    hasAdditionalIncome: undefined,
    hasLargeMedicalOrDisabilityExpenses: false,
    hasMortgageEscrow: undefined,
    hasRecentDeathInImmediateFamily: false,
    hasRecentJobLoss: false,
    hasRecentlyBeenFurloughed: false,
    hasReductionInIncome: false,
    isCarOwner: undefined,
    isHomeowner: undefined,
    hasServedInMilitary: undefined,
    hasSpouse: undefined,
    kindOfRelief: undefined,
    lastCompletedPage: undefined,
    lastViewedPage: undefined,
    monthlyRentOrMortgageAmount: undefined,
    numberOfDependentsInHousehold: undefined,
    reasonForNotConnectingBank: undefined,
    router: undefined,
    selfReportedMonthlyCurrentIncome: 0,
    status: 'In Progress',
    totalBackTaxesAmount: 0,
    totalDebtToFriendsAndFamilyAmount: 0,
    totalMedicalDebtAmount: undefined,
    totalOtherDebtAmount: 0,
    type: undefined,
    utmCampaign: undefined,
    utmContent: undefined,
    utmMedium: undefined,
    utmSource: undefined,
    utmTerm: undefined,
    verifiedMonthlyCurrentIncome: 0,

    // Application -> Borrower relationship link
    applicationBorrowerId: undefined,

    // Application -> Spouse relationship link
    applicationSpouseId: undefined,
  },
  getApplicationsForServicePortalFailed: undefined,
  getApplicationsForServicePortalSucceeded: undefined,
  hasDependents: undefined,
  hasGottenApplication: undefined,
  hasProvidedMonthlyIncome: false,
  hasSubmittedPrimaryApplication: false,
  isGettingApplication: undefined,
  isGettingApplicationsForServicePortal: undefined,
  isSaving: undefined,
  list: [],
  otherReasonForNotConnectingAccount: undefined,
  saveFailed: undefined,
  saveSuccess: undefined,
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SAVE_APPLICATION_SUCCEEDED:
      return { ...state, saveSuccess: undefined };

    case CLEAR_HAS_DEPENDENTS:
      return { ...state, data: { ...state.data, numberOfDependentsInHousehold: 0 }, hasDependents: false };

    case GET_APPLICATION:
      return { ...state, hasGottenApplication: undefined, isGettingApplication: true };

    case GET_APPLICATION_FAIL:
      return { ...state, hasGottenApplication: false, isGettingApplication: false };

    case GET_APPLICATION_SUCCESS:
    case GET_APPLICATION_FOR_USER_SUCCESS:
      if (!action.application) {
        return {
          ...state,
          data: initialState.data,
          hasGottenApplication: true,
          isGettingApplication: false,
        };
      }

      // eslint-disable-next-line no-case-declarations
      const propertiesToKeep = Object.keys(initialState.data);

      Object.keys(action.application).forEach((property) => {
        if (!propertiesToKeep.includes(property)) {
          // delete the relational objects the database provided (e.g. borrower, spouse, etc.)
          // eslint-disable-next-line no-param-reassign
          delete action.application[property];
        }
      });

      return {
        ...state,
        data: action.application,
        hasGottenApplication: true,
        isGettingApplication: false,
      };

    case GET_APPLICATION_FOR_USER:
      return { ...state, hasGottenApplication: undefined, isGettingApplication: true };

    case GET_APPLICATION_FOR_USER_FAIL:
      return { ...state, hasGottenApplication: false, isGettingApplication: false };

    case GET_APPLICATIONS_FOR_SERVICE_PORTAL:
      return {
        ...state,
        getApplicationsForServicePortalFailed: undefined,
        getApplicationsForServicePortalSucceeded: undefined,
        isGettingApplicationsForServicePortal: true,
        list: [],
      };

    case GET_APPLICATIONS_FOR_SERVICE_PORTAL_FAILED:
      return {
        ...state,
        getApplicationsForServicePortalFailed: true,
        getApplicationsForServicePortalSucceeded: false,
        isGettingApplicationsForServicePortal: false,
      };

    case GET_APPLICATIONS_FOR_SERVICE_PORTAL_SUCCEEDED:
      return {
        ...state,
        getApplicationsForServicePortalFailed: false,
        getApplicationsForServicePortalSucceeded: true,
        isGettingApplicationsForServicePortal: false,
        list: action.applications,
      };

    case SAVE_APPLICATION:
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
      };

    case SAVE_APPLICATION_FAIL:
      return {
        ...state, isSaving: false, saveFailed: true, saveSuccess: false,
      };

    case SAVE_APPLICATION_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, id: action.applicationId },
        isSaving: false,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          id: action.applicationId,
          lastCompletedPage: action.lastCompletedPage,
          lastViewedPage: action.lastViewedPage,
        },
      };

    case SET_AGREED_TO_TERMS:
      return { ...state, agreedToTerms: action.agreedToTerms };

    case SET_HAS_DEPENDENTS:
      return { ...state, data: { ...state.data, numberOfDependentsInHousehold: 0 }, hasDependents: true };

    case SET_HAS_PROVIDED_MONTHLY_INCOME:
      return { ...state, hasProvidedMonthlyIncome: true };

    case SET_HAS_SUBMITTED_PRIMARY_APPLICATION:
      return { ...state, hasSubmittedPrimaryApplication: true };

    case SET_OTHER_REASON_FOR_NOT_CONNECTING_ACCOUNT:
      return { ...state, otherReasonForNotConnectingAccount: action.value };

    case SET_SAVE_APPLICATION_SUCCEEDED:
      return { ...state, saveSuccess: true };

    case SET_UTM_CAMPAIGN_PARAMETERS:
      return {
        ...state,
        data: {
          ...state.data,
          utmCampaign: action.utmCampaign,
          utmContent: action.utmContent,
          utmMedium: action.utmMedium,
          utmSource: action.utmSource,
          utmTerm: action.utmTerm,
        },
      };

    case UPDATE_APPLICATION:
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };

    default:
      return state;
  }
};

export default applicationReducer;
