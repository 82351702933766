import { API, Auth } from 'aws-amplify';

export const GET_DEBT_SCORE = 'GET_DEBT_SCORE';
export const GET_DEBT_SCORE_FAIL = 'GET_DEBT_SCORE_FAIL';
export const GET_DEBT_SCORE_SUCCESS = 'GET_DEBT_SCORE_SUCCESS';
export const GET_DEBT_SCORES = 'GET_DEBT_SCORES';
export const GET_DEBT_SCORES_FAIL = 'GET_DEBT_SCORES_FAIL';
export const GET_DEBT_SCORES_SUCCESS = 'GET_DEBT_SCORES_SUCCESS';
export const SET_DEBT_SCORES = 'SET_DEBT_SCORES';

const getDebtScore = ({ borrowerRiskScoreId, spouseRiskScoreId }) => async (dispatch) => {
  const currentSession = await Auth.currentSession();
  const jwt = currentSession.getIdToken().getJwtToken();

  try {
    const debtScore = await API.post('debt', '/debt-score', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: spouseRiskScoreId ? { borrowerRiskScoreId, spouseRiskScoreId } : { borrowerRiskScoreId },
    });

    dispatch({ debtScore, type: GET_DEBT_SCORE_SUCCESS });
  } catch (error) {
    console.log(`error getting debt score for borrowerRiskScoreId ${borrowerRiskScoreId} and spouseRiskScoreId ${spouseRiskScoreId}`, error);

    dispatch({ type: GET_DEBT_SCORE_FAIL });
  }
};

export {
  getDebtScore,
};
