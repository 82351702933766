import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import logo from '../../../../assets/logos/creditgenie_logo_blue.svg';
import { signOut } from '../../../../redux/actions/userActions';

const PlainHeader = () => {
  const dispatch = useDispatch();
  return (
    <div className="container plain-header logo items-center text-white m-auto">
      <div className="float-left inline-block mt-5">
        <Link to="/">
          <img className="h-8 mr-2" src={logo} alt="Credit Genie Logo Blue" />
        </Link>
      </div>
      <div className="float-right inline-block mt-4">
        <form>
          <Link
            to="/sign-in"
            className="inline-block text-sm px-4 py-2 leading-none border rounded border-blue-custom text-blue-custom"
            onClick={() => dispatch(signOut())}
          >
            Sign In
          </Link>
        </form>
      </div>
    </div>
  );
};

export default PlainHeader;
