import React from 'react';
import { useSelector } from 'react-redux';
import DebtSummaryComponent from '../../../components/common/DebtSummaryComponent/DebtSummaryComponent';

const DebtSummary = () => {
  const debts = useSelector((state) => state.debts.data);

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="borrower-summary-components container mx-auto pl-2 pr-2 pt-8 pb-8 lg:flex flex-none">
          <div className="debt-summary dynamic-info">
            <DebtSummaryComponent debts={debts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtSummary;
