import { DateTime } from 'luxon';

export default function validate(
  {
    dateFormats,
    minValues,
    maxValues,
    regularExpressions,
    requiredValues,
    values,
  },
) {
  const errors = {};
  const invalid = 'invalid';
  const required = 'required';
  const minValNotMet = 'minValNotMet';
  const maxValExceeded = 'maxValExceeded';

  Object.keys(values).forEach((valueKey) => {
    if (requiredValues[valueKey] && !values[valueKey]) {
      errors[valueKey] = required;

      return;
    }

    if (regularExpressions[valueKey] && !(new RegExp(regularExpressions[valueKey]).test(values[valueKey]))) {
      errors[valueKey] = invalid;

      return;
    }

    if (dateFormats[valueKey] && !DateTime.fromFormat(values[valueKey], dateFormats[valueKey]).isValid) {
      errors[valueKey] = invalid;

      return;
    }

    if (minValues[valueKey]) {
      if (dateFormats[valueKey]) {
        const date = DateTime.fromFormat(values[valueKey], dateFormats[valueKey]);
        const minDate = DateTime.fromFormat(minValues[valueKey], dateFormats[valueKey]);

        if (date < minDate) {
          errors[valueKey] = minValNotMet;
        }
      } else if (values[valueKey] < minValues[valueKey]) {
        errors[valueKey] = minValNotMet;

        return;
      }
    }

    if (maxValues[valueKey]) {
      if (dateFormats[valueKey]) {
        const date = DateTime.fromFormat(values[valueKey], dateFormats[valueKey]);
        const maxDate = DateTime.fromFormat(maxValues[valueKey], dateFormats[valueKey]);

        if (date > maxDate) {
          errors[valueKey] = maxValExceeded;
        }
      } else if (values[valueKey] > maxValues[valueKey]) {
        errors[valueKey] = maxValExceeded;
      }
    }
  });

  return errors;
}
