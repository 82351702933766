import React from 'react';
import {
  getCreditCounselingAgencyFullName,
} from '../../../helpers/creditCounselingAgencyHelper';
import AFSLogo from '../../../assets/logos/afs_logo.svg';
import ApprisenLogo from '../../../assets/logos/apprisen_logo.svg';
import CambridgeLogo from '../../../assets/logos/cambridge_logo.svg';
import CesiLogo from '../../../assets/logos/cesi_logo.svg';
import CreditlyLogo from '../../../assets/logos/creditly_logo.svg';
import GreenPathLogo from '../../../assets/logos/greenpath_logo.svg';
import NavicoreLogo from '../../../assets/logos/navicore_logo.svg';
import NFCCLogo from '../../../assets/logos/NFCC_logo.svg';

const AgencyCoBranding = () => {
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();
  const environmentNameToLogoMap = {
    afs: AFSLogo,
    apprisen: ApprisenLogo,
    cambridge: CambridgeLogo,
    cesi: CesiLogo,
    main: CreditlyLogo,
    greenpath: GreenPathLogo,
    navicore: NavicoreLogo,
    staging: CreditlyLogo,
  };

  return (
    <div className="sm:inline-block block m-auto sm:pb-0 pb-2">
      <div className="sm:flex flex-none m-auto">
        <img className="pr-4 inline-block" src={NFCCLogo} alt="NFCC Logo" />
        <img
          className="border-left pl-4 inline-block logo"
          src={environmentNameToLogoMap[process.env.REACT_APP_ENVIRONMENT]}
          alt={creditCounselingAgencyFullName}
        />
      </div>
    </div>
  );
};

export default AgencyCoBranding;
