import React from 'react';
import CreditCardsIcon from '../../../assets/icons/landing-pages/credit-cards_icon.svg';
import CreditCounselingIcon from '../../../assets/icons/landing-pages/phone-call_icon.svg';
import FastForwardIcon from '../../../assets/icons/landing-pages/faster_icon.svg';
import PercentageIcon from '../../../assets/icons/landing-pages/percentage_icon.svg';

const WhatWeDo = () => (
  <div className="what-we-do nfcc-dark-blue-bg text-white">
    <div className="container text-center m-auto py-10">
      <p className="section-header text-2xl md:text-4xl text-center w-full md:w-1/2 m-auto p-2 px-1">
        What we do
      </p>
      <div className="how-we-can-help py-10">
        <div className="w-3/4 md:flex flex-none m-auto">
          <div className="lg:w-1/2 w-full px-4 m-auto">
            <img src={CreditCardsIcon} alt="Credit Cards Icon" />
            <p className="m-auto">
              We work with you and your creditors to reduce or eliminate your credit card debt
            </p>
          </div>
          <div className="lg:w-1/2 w-full px-4 m-auto sm:mt-0 mt-8">
            <img src={CreditCounselingIcon} alt="Credit Counseling Icon" />
            <p className="m-auto">
              We provide advice and tools to improve your financial health
            </p>
          </div>
          <div className="lg:w-1/2 w-full px-4 m-auto sm:mt-0 mt-8">
            <img src={PercentageIcon} alt="Percentage Icon" />
            <p className="m-auto">
              We negotiate lower interest rates for you regardless of your credit score
            </p>
          </div>
          <div className="lg:w-1/2 w-full px-4 m-auto sm:mt-0 mt-8">
            <img src={FastForwardIcon} alt="Fast Forward Icon" />
            <p className="m-auto">
              We provide comprehensive debt management plans to help you pay off your debt faster
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WhatWeDo;
