import React from 'react';
import { BudgetBarGraph, BudgetCategories } from '../../../components/customer-portal';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const CustomerBudget = () => {
  const monthlyExpense = 100;
  return (
    <div className="customer-budget container m-auto pb-10 l">
      <h2 className="text-center">Your Budget</h2>
      <div className="text-center lender-tile-animation-1 mb-12">
        <div className="value rift-it green text-4xl">
          {formatNumberAsCurrency({ number: monthlyExpense })}
        </div>
        <span className="sub-text -mt-2">August Target Spend</span>
      </div>

      <BudgetBarGraph />

      <BudgetCategories />
    </div>
  );
};

export default CustomerBudget;
