/* eslint-disable max-len */
/* eslint-disable no-useless-concat */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EmailAddressInput = ({
  disabled = false,
  errors = {},
  id,
  label = '',
  loadFrom,
  name = 'email',
  onBlur = () => {},
  onChange,
  placeholder = ' ',
  required = true,
  validationName,
  valueOverride = '',
}) => {
  const { t: translate } = useTranslation();
  const acceptableTopLevelDomains = 'biz|BIZ|com|COM|edu|EDU|gov|GOV|info|INFO|name|NAME|net|NET|mil|MIL|org|ORG|us|US';
  const value = useSelector((state) => (loadFrom && name && state[loadFrom].data[name]) || '');

  const emailRegex = '[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+(' + `${acceptableTopLevelDomains})\\b`;

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label htmlFor={id} className="input-container">
          <input
            disabled={disabled}
            id={id}
            maxLength={100}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            regex={emailRegex}
            required={required}
            type="email"
            validationname={validationName}
            value={valueOverride || value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isInvalid')}
      </p>
      )}
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default EmailAddressInput;
