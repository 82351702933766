import {
  CANCEL_EXPENSE_VALUE_CHANGE,
  CHANGE_EXPENSE_VALUE,
  GET_EXPENSE_ESTIMATES, GET_EXPENSE_ESTIMATES_FAIL, GET_EXPENSE_ESTIMATES_SUCCESS,
  GET_EXPENSES_BY_APPLICATION_ID, GET_EXPENSES_BY_APPLICATION_ID_FAIL, GET_EXPENSES_BY_APPLICATION_ID_SUCCESS,
  SAVE_EXPENSES, SAVE_EXPENSES_FAIL, SAVE_EXPENSES_SUCCESS,
  SUBMIT_EXPENSE_VALUE_CHANGE,
  UPDATE_EXPENSE,
} from '../actions/expensesActions';

const initialState = {
  data: {
    expenseEstimates: {
      food: {
        away: { id: undefined, lastSubmittedValue: 0, value: 0 },
        home: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      healthcare: {
        drugs: { id: undefined, lastSubmittedValue: 0, value: 0 },
        healthInsurance: { id: undefined, lastSubmittedValue: 0, value: 0 },
        lifeInsurance: { id: undefined, lastSubmittedValue: 0, value: 0 },
        medicalServices: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      housing: {
        hoa: { id: undefined, lastSubmittedValue: 0, value: 0 },
        insurance: { id: undefined, lastSubmittedValue: 0, value: 0 },
        maintenanceImprovements: { id: undefined, lastSubmittedValue: 0, value: 0 },
        mortgage: { id: undefined, lastSubmittedValue: 0, value: 0 },
        rent: { id: undefined, lastSubmittedValue: 0, value: 0 },
        taxes: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      miscellaneous: {
        alimony: { id: undefined, lastSubmittedValue: 0, value: 0 },
        childcare: { id: undefined, lastSubmittedValue: 0, value: 0 },
        childSupport: { id: undefined, lastSubmittedValue: 0, value: 0 },
        education: { id: undefined, lastSubmittedValue: 0, value: 0 },
        other: { id: undefined, lastSubmittedValue: 0, value: 0 },
        pets: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      otherDebts: {
        backtaxes: { id: undefined, lastSubmittedValue: 0, value: 0 },
        friendsFamily: { id: undefined, lastSubmittedValue: 0, value: 0 },
        medical: { id: undefined, lastSubmittedValue: 0, value: 0 },
        other: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      personal: {
        apparel: { id: undefined, lastSubmittedValue: 0, value: 0 },
        entertainment: { id: undefined, lastSubmittedValue: 0, value: 0 },
        personalCare: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      savings: {
        donations: { id: undefined, lastSubmittedValue: 0, value: 0 },
        savings: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      studentLoans: {
        studentLoans: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      transportation: {
        carPayment: { id: undefined, lastSubmittedValue: 0, value: 0 },
        gas: { id: undefined, lastSubmittedValue: 0, value: 0 },
        insurance: { id: undefined, lastSubmittedValue: 0, value: 0 },
        maintenanceRepairs: { id: undefined, lastSubmittedValue: 0, value: 0 },
        publicTransportation: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
      utilities: {
        cableInternetLandline: { id: undefined, lastSubmittedValue: 0, value: 0 },
        electricity: { id: undefined, lastSubmittedValue: 0, value: 0 },
        gas: { id: undefined, lastSubmittedValue: 0, value: 0 },
        water: { id: undefined, lastSubmittedValue: 0, value: 0 },
        wireless: { id: undefined, lastSubmittedValue: 0, value: 0 },
      },
    },
  },
  getExpenseEstimatesFailed: undefined,
  getExpenseEstimatesSucceeded: undefined,
  getExpensesFailed: undefined,
  hasGottenExpenseEstimates: undefined,
  isGettingExpenseEstimates: undefined,
  isGettingExpenses: undefined,
  isSavingExpenses: undefined,
  list: {
    food: {
      away: 0,
      home: 0,
    },
    healthcare: {
      drugs: 0,
      healthInsurance: 0,
      lifeInsurance: 0,
      medicalServices: 0,
    },
    housing: {
      hoa: 0,
      insurance: 0,
      maintenanceImprovements: 0,
      mortgage: 0,
      rent: 0,
      taxes: 0,
    },
    miscellaneous: {
      alimony: 0,
      childcare: 0,
      childSupport: 0,
      education: 0,
      other: 0,
      pets: 0,
    },
    otherDebts: {
      backtaxes: 0,
      friendsFamily: 0,
      medical: 0,
      other: 0,
    },
    personal: {
      apparel: 0,
      entertainment: 0,
      personalCare: 0,
    },
    savings: {
      donations: 0,
      savings: 0,
    },
    studentLoans: {
      studentLoans: 0,
    },
    transportation: {
      carPayment: 0,
      gas: 0,
      insurance: 0,
      maintenanceRepairs: 0,
      publicTransportation: 0,
    },
    utilities: {
      cableInternetLandline: 0,
      electricity: 0,
      gas: 0,
      water: 0,
      wireless: 0,
    },
  },
  saveExpensesFailed: undefined,
  saveExpensesSucceeded: undefined,
};

const expensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_EXPENSE_VALUE_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          expenseEstimates: {
            ...state.data.expenseEstimates,
            [action.category]: {
              ...state.data.expenseEstimates[action.category],
              [action.subCategory]: {
                ...state.data.expenseEstimates[action.category][action.subCategory],
                value: state.data.expenseEstimates[action.category][action.subCategory].lastSubmittedValue,
              },
            },
          },
        },
      };

    case CHANGE_EXPENSE_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          expenseEstimates: {
            ...state.data.expenseEstimates,
            [action.category]: {
              ...state.data.expenseEstimates[action.category],
              [action.subCategory]: {
                ...state.data.expenseEstimates[action.category][action.subCategory],
                value: action.value,
              },
            },
          },
        },
      };

    case GET_EXPENSE_ESTIMATES:
      return {
        ...state,
        getExpenseEstimatesFailed: undefined,
        getExpenseEstimatesSucceeded: undefined,
        hasGottenExpenseEstimates: undefined,
        isGettingExpenseEstimates: true,
      };

    case GET_EXPENSE_ESTIMATES_FAIL:
      return {
        ...state,
        getExpenseEstimatesFailed: true,
        getExpenseEstimatesSucceeded: false,
        hasGottenExpenseEstimates: false,
        isGettingExpenseEstimates: false,
      };

    case GET_EXPENSE_ESTIMATES_SUCCESS: {
      const expenseEstimatesObject = {};

      action.expenseEstimates.forEach((expenseEstimate) => {
        const {
          id,
          category,
          estimatedValue,
          selfReportedValue,
          subCategory,
        } = expenseEstimate;

        if (expenseEstimatesObject[category] === undefined) {
          expenseEstimatesObject[category] = {};
        }

        expenseEstimatesObject[category][subCategory] = {
          id,
          lastSubmittedValue: selfReportedValue !== null ? selfReportedValue : estimatedValue,
          value: selfReportedValue !== null ? selfReportedValue : estimatedValue,
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          expenseEstimates: expenseEstimatesObject,
        },
        getExpenseEstimatesFailed: false,
        getExpenseEstimatesSucceeded: true,
        hasGottenExpenseEstimates: true,
        isGettingExpenseEstimates: false,
      };
    }

    case GET_EXPENSES_BY_APPLICATION_ID:
      return {
        ...state,
        getExpensesFailed: undefined,
        isGettingExpenses: true,
        list: initialState.list,
      };

    case GET_EXPENSES_BY_APPLICATION_ID_FAIL:
      return {
        ...state,
        getExpensesFailed: true,
        isGettingExpenses: false,
      };

    case GET_EXPENSES_BY_APPLICATION_ID_SUCCESS:
      return {
        ...state,
        getExpensesFailed: false,
        isGettingExpenses: false,
        list: action.expenses,
      };

    case SAVE_EXPENSES:
      return {
        ...state,
        isSavingExpenses: true,
        saveExpensesFailed: undefined,
        saveExpensesSucceeded: undefined,
      };

    case SAVE_EXPENSES_FAIL:
      return {
        ...state,
        isSavingExpenses: false,
        saveExpensesFailed: true,
        saveExpensesSucceeded: false,
      };

    case SAVE_EXPENSES_SUCCESS:
      return {
        ...state,
        isSavingExpenses: false,
        saveExpensesFailed: false,
        saveExpensesSucceeded: true,
      };

    case SUBMIT_EXPENSE_VALUE_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          expenseEstimates: {
            ...state.data.expenseEstimates,
            [action.category]: {
              ...state.data.expenseEstimates[action.category],
              [action.subCategory]: {
                ...state.data.expenseEstimates[action.category][action.subCategory],
                lastSubmittedValue: action.value,
              },
            },
          },
        },
      };

    case UPDATE_EXPENSE:
      return {
        ...state,
        data: {
          ...state.data,
          expenseEstimates: {
            ...state.data.expenseEstimates,
            [action.category]: {
              ...state.data.expenseEstimates[action.category],
              [action.subCategory]: {
                ...state.data.expenseEstimates[action.category][action.subCategory],
                value: action.value,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default expensesReducer;
