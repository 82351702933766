import { API, Auth, graphqlOperation } from 'aws-amplify';
import getLenderFriendlyNameMap from '../../helpers/lenderFriendlyNameHelper';
import sendOutreachText from './outreachActions';
import { updateDecision } from '../../graphql/customMutations';
import { SET_OFFERS } from './offersActions';

export const GET_DECISION = 'GET_DECISION';
export const GET_DECISION_LIST = 'GET_DECISION_LIST';
export const GET_DECISION_FAIL = 'GET_DECISION_FAIL';
export const GET_DECISION_LIST_FAIL = 'GET_DECISION_LIST_FAIL';
export const GET_DECISION_SUCCESS = 'GET_DECISION_SUCCESS';
export const GET_DECISION_LIST_SUCCESS = 'GET_DECISION_LIST_SUCCESS';
export const SAVE_DECISION = 'SAVE_DECISION';
export const SAVE_DECISION_FAIL = 'SAVE_DECISION_FAIL';
export const SAVE_DECISION_SUCCESS = 'SAVE_DECISION_SUCCESS';
export const SET_DECISION = 'SET_DECISION';
export const UPDATE_DECISION = 'UPDATE_DECISION';

const getDecision = () => async (dispatch, getState) => {
  dispatch({ type: GET_DECISION });

  const state = getState();

  try {
    const applicationId = state.application.data.id;
    const borrowerId = state.borrower.data.id;

    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();

    const decision = await API.post('decision', '/decision', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId,
        environment: process.env.REACT_APP_ENVIRONMENT,
      },
    });

    decision.offers = decision.isQualified ? decision.offers.items : [];
    decision.disqualificationReasons = !decision.isQualified ? decision.disqualificationReasons.items : [];

    const lenderFriendlyNameMap = await getLenderFriendlyNameMap();

    const isCreditApplication = state.application.data.type === 'credit';
    const isRentalApplication = state.application.data.type === 'rental';

    if (isCreditApplication && decision.isQualified) {
      dispatch(sendOutreachText({ borrowerId, eventType: 'obtainedOffer' }));
    }

    if (isRentalApplication) {
      dispatch(sendOutreachText({ borrowerId, eventType: 'completedRentalApplication' }));
    }

    if (decision.isQualified) {
      decision.offers.forEach((offer, offerIndex) => {
        const { debt, collection } = offer;
        const debtType = debt || collection;
        const debtTypeName = debt ? 'debt' : 'collection';
        const lenderName = debt ? debt.lenderName : collection.subscriberName;

        if (lenderFriendlyNameMap[lenderName]) {
          decision.offers[offerIndex][debtTypeName] = { ...debtType, lenderFriendlyName: lenderFriendlyNameMap[lenderName] };
        }
      });
    }

    dispatch({ offers: decision.offers, type: SET_OFFERS });
    dispatch({ decision, type: GET_DECISION_SUCCESS });
  } catch (error) {
    console.log('error getting decision:', error);

    dispatch({ type: GET_DECISION_FAIL });
  }
};

const saveDecision = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_DECISION });

  const state = getState();

  const { id, wasAcceptedByBorrower, wasPresentedToBorrower } = state.decision.data;

  try {
    await API.graphql(graphqlOperation(updateDecision, { input: { id, wasAcceptedByBorrower, wasPresentedToBorrower } }));

    dispatch({ type: SAVE_DECISION_SUCCESS });
  } catch (error) {
    console.log('error saving decision:', error);

    dispatch({ type: SAVE_DECISION_FAIL });
  }
};

const setDecisionToNewestDecision = ({ decisions, lenderFriendlyNameMap }) => async (dispatch) => {
  const newestDecision = decisions.sort((decisionA, decisionB) => new Date(decisionB.createdAt) - new Date(decisionA.createdAt))[0];

  const offers = newestDecision && newestDecision.isQualified && newestDecision.offers.items;

  if (newestDecision) {
    newestDecision.offers = offers || [];
    newestDecision.disqualificationReasons = !newestDecision.isQualified ? newestDecision.disqualificationReasons.items : [];
  }

  if (newestDecision && newestDecision.isQualified) {
    newestDecision.offers.forEach((offer, offerIndex) => {
      const { debt, collection } = offer;
      const debtType = debt || collection;
      const debtTypeName = debt ? 'debt' : 'collection';
      const lenderName = debt ? debt.lenderName : collection.subscriberName;

      if (lenderFriendlyNameMap[lenderName]) {
        newestDecision.offers[offerIndex][debtTypeName] = { ...debtType, lenderFriendlyName: lenderFriendlyNameMap[lenderName] };
      }
    });
  }

  dispatch({ offers, type: SET_OFFERS });
  dispatch({ decision: newestDecision, type: SET_DECISION });
};

const updateDecisionInformation = ({ propertyName, value }) => ({ propertyName, type: UPDATE_DECISION, value });

export {
  getDecision,
  saveDecision,
  setDecisionToNewestDecision,
  updateDecisionInformation as updateDecision,
};
