import React from 'react';
import PaymentHistoryDetail from '../../../pages/offers/OfferDetail/PaymentHistoryDetail';

const PaymentHistory = () => (
  <div className="w-full sm:pl-8 pl-0">
    <h3 className="pt-3">Payment History</h3>
    <div>Next Payment: 10/1/2020</div>
    <PaymentHistoryDetail
      value="50"
      date="10/10/2020"
    />
    <PaymentHistoryDetail
      value="50"
      date="10/10/2020"
    />
    <PaymentHistoryDetail
      value="50"
      date="10/10/2020"
    />
    <PaymentHistoryDetail
      value="50"
      date="10/10/2020"
    />
  </div>
);

export default PaymentHistory;
