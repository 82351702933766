import React from 'react';
import { BorrowerInformationTile } from '../../../components/customer-service-portal';
import { BorrowerSubNavigation } from '../../../navigation';

const BorrowerSummary = () => (
  <div>
    <div className="leading-normal tracking-normal grey-bg pb-20">
      <div className="container mx-auto pl-2 pr-2 pt-8 pb-8 lg:flex flex-none">
        <div className="borrower-info">
          <BorrowerInformationTile />
        </div>
        <div className="dynamic-info">
          <BorrowerSubNavigation />
        </div>
      </div>
    </div>
  </div>
);

export default BorrowerSummary;
