import { UPDATE_SUMMARY_TAB_INDEX } from '../actions/portalActions';

const initialState = { summaryTabIndex: 0 };

const portalReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUMMARY_TAB_INDEX:
      return { ...state, summaryTabIndex: action.value };

    default:
      return state;
  }
};

export default portalReducer;
