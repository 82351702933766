import React from 'react';
import CustomerRepTestPic from '../../../../assets/images/no-profile-pic.svg';

const CustomerRepresentativeInformationTile = ({
  email, name, phoneNumber, numberAssigned, numberContacted,
}) => (
  <div>
    <div className="box bg-white text-center">
      <div className="profile-photo-box mt-5 mb-5">
        <img src={CustomerRepTestPic} alt="customer rep" className="profile-photo mx-auto" />
      </div>
      <h4>{name}</h4>
      <p><a href="#!" data-cy="customer-rep-email">{email}</a></p>
      <p><a href="#!">{phoneNumber}</a></p>
      <div className="divider" />
      <div className="flex flex-row">
        <div className="detail-box flex items-center">
          <div className="w-full self-center px-2">
            <h1 className="heading-assigned">{numberAssigned}</h1>
            <p className="text-xs mt-6">Number of customers assigned</p>
          </div>
        </div>
        <div className="vertical-divider" />
        <div className="detail-box flex items-center">
          <div className="w-full self-center px-2">
            <h1 className="heading-contacted">{numberContacted}</h1>
            <p className="text-xs mt-6">Number of customers contacted</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomerRepresentativeInformationTile;
