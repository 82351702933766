import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from '../../common/Carousel/Carousel';
import ProposedPaymentTile from './ProposedPaymentTile';

const ProposedPaymentsCarousel = () => {
  const offers = useSelector((state) => state.offers.data);

  const proposedPaymentTiles = offers.map((offer, offerIndex) => {
    const estimatedMonthlyCollectionPayment = (offer.collection && offer.collection.currentBalance * 0.03) || 0;
    const estimatedMonthlyDebtPayment = (offer.debt && (offer.debt.scheduledMonthlyPayment || offer.debt.estimatedMonthlyPayment)) || 0;

    const estimatedCurrentMonthlyPayment = estimatedMonthlyCollectionPayment || estimatedMonthlyDebtPayment;
    const recommendedMonthlyPayment = offer.payment;

    return (
      <ProposedPaymentTile
        estimatedCurrentMonthlyPayment={estimatedCurrentMonthlyPayment}
        key={offer.id || offerIndex}
        lenderFriendlyName={(offer.debt && offer.debt.lenderFriendlyName) || (offer.collection && offer.collection.lenderFriendlyName)}
        lenderName={(offer.debt && offer.debt.lenderName) || (offer.collection && offer.collection.subscriberName)}
        recommendedMonthlyPayment={recommendedMonthlyPayment}
      />
    );
  });

  return <Carousel components={proposedPaymentTiles} name="ProposedPayments" />;
};

export default ProposedPaymentsCarousel;
