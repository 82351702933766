import React from 'react';
import { useTranslation } from 'react-i18next';

import DropdownInput from '../../application/common/DropdownInput/DropdownInput';

const WhatIsYourRace = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  const options = [
    { label: translate('personalinformation:whatIsYourRace:americanIndianOrAlaskaNative'), value: 'American Indian or Alaska Native' },
    { label: translate('personalinformation:whatIsYourRace:asian'), value: 'Asian' },
    { label: translate('personalinformation:whatIsYourRace:blackOrAfricanAmerican'), value: 'Black or African American' },
    {
      label: translate('personalinformation:whatIsYourRace:nativeHawaiianOrOtherPacificIslander'),
      value: 'Native Hawaiian or Other Pacific Islander',
    },
    { label: translate('personalinformation:whatIsYourRace:white'), value: 'White' },
    { label: translate('personalinformation:whatIsYourRace:other'), value: 'Other' },
  ];

  return (
    <DropdownInput
      errors={errors}
      id="what-is-your-race-select"
      loadFrom="rentalApplication"
      name="race"
      onChange={onChange}
      options={options}
      question={translate('personalinformation:whatIsYourRace:question')}
      required={false}
    />
  );
};

export default WhatIsYourRace;
