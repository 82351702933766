import React from 'react';
import { useDispatch } from 'react-redux';
import { updateContactAttempt } from '../../../../../redux/actions/contactAttemptActions';
import DropdownInput from '../../../../application/common/DropdownInput/DropdownInput';

const WhatWasTheOutcome = () => {
  const dispatch = useDispatch();
  const options = [
    { label: 'Counseling session was conducted', value: 'Counseling session was conducted' },
    { label: 'Wrong number', value: 'Wrong number' },
    { label: 'Left a voicemail', value: 'Left a voicemail' },
    { label: 'Voicemail was not set up', value: 'Voicemail was not set up' },
    { label: 'Session scheduled', value: 'Session scheduled' },
    { label: 'Client was not interested', value: 'Client was not interested' },
    { label: 'Client did not qualify', value: 'Client did not qualify' },
  ];

  const onSelectValueChange = (event) => {
    dispatch(updateContactAttempt({ propertyName: event.target.name, value: event.target.value }));
  };

  return (
    <DropdownInput
      errors="none"
      id="what-was-the-outcome"
      loadFrom="contactAttempt"
      name="whatWasTheOutcome"
      onChange={onSelectValueChange}
      options={options}
      question="What was the outcome?"
    />
  );
};

export default WhatWasTheOutcome;
