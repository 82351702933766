import {
  GET_DECISION, GET_DECISION_FAIL, GET_DECISION_SUCCESS,
  GET_DECISION_LIST, GET_DECISION_LIST_FAIL, GET_DECISION_LIST_SUCCESS,
  SET_DECISION,
  UPDATE_DECISION,
} from '../actions/decisionActions';

const intitialState = {
  data: {
    applicationId: undefined,
    createdAt: undefined,
    currentDisposableIncome: 0,
    currentExpenses: 0,
    currentGrossIncome: 0,
    currentNetCashflow: 0,
    currentUnsecuredDebtPayment: 0,
    disqualificationReasons: [],
    id: undefined,
    isQualified: undefined,
    numberOfUnsecuredDebts: 0,
    offers: [],
    owner: undefined,
    proposedDisposableIncome: 0,
    proposedExpenses: 0,
    proposedUnsecuredDebtPayment: 0,
    proposedNetCashflow: 0,
    totalUnsecuredDebtBalance: 0,
    updatedAt: undefined,
    wasAcceptedByBorrower: undefined,
    wasPresentedToBorrower: undefined,
  },
  list: [],
  getDecisionFailed: undefined,
  getDecisionSucceeded: undefined,
  isGettingDecision: undefined,
  isGettingDecisionList: undefined,
  getDecisionListSucceeded: undefined,
};

const decisionReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_DECISION:
      return {
        ...state,
        getDecisionFailed: undefined,
        getDecisionSucceeded: undefined,
        isGettingDecision: true,
      };

    case GET_DECISION_FAIL:
      return {
        ...state,
        getDecisionFailed: true,
        getDecisionSucceeded: false,
        isGettingDecision: false,
      };

    case GET_DECISION_SUCCESS:
      return {
        ...state,
        data: action.decision,
        getDecisionFailed: false,
        getDecisionSucceeded: true,
        isGettingDecision: false,
      };

    case GET_DECISION_LIST:
      return {
        ...state, getDecisionListSucceeded: undefined, isGettingDecisionList: true, list: [],
      };

    case GET_DECISION_LIST_FAIL:
      return { ...state, getDecisionListSucceeded: false, isGettingDecisionList: false };

    case GET_DECISION_LIST_SUCCESS:
      return {
        ...state, getDecisionListSucceeded: true, isGettingDecisionList: false, list: action.decisions,
      };

    case SET_DECISION:
      return {
        ...state,
        data: action.decision || {},
      };

    case UPDATE_DECISION:
      return {
        ...state,
        data: { ...state.data, [action.propertyName]: action.value },
      };

    default:
      return state;
  }
};

export default decisionReducer;
