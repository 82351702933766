/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router-dom';
import ApplicationLayout from './ApplicationLayout';
import {
  RentalAboutYou,
  Expenses,
  LifeEvents,
  PersonalInformation,
  ProvideMonthlyIncome,
  RentalApplicationCallUs,
  RentalInformation,
  YourContact,
  Pending,
  Decline,
} from '../../pages';
import { RentalFooter, RentalHeader } from '../../components';

const RentalApplicationLayout = ({ agencySpecificHeader, agencySpecificFooter } = {}) => {
  const rentalApplicationRoutes = [
    <Route component={RentalAboutYou} key="about-you-page" path="/application/about-you" />,
    <Route key="life-events-page" path="/application/life-events" render={() => <LifeEvents nextPage="RentalInformation" nextPageRoute="/application/rental-information" />} />,
    <Route component={RentalInformation} key="rental-information-page" path="/application/rental-information" />,
    <Route component={PersonalInformation} key="personal-information-page" path="/application/personal-information" />,
    <Route component={YourContact} key="your-contact-page" path="/application/your-contact" />,
    <Route component={ProvideMonthlyIncome} key="provide-monthly-income-page" path="/application/income/provide-monthly-income" />,
    <Route key="expenses-page" path="/application/expenses" render={() => <Expenses errorPage="CallUs" errorPageRoute="/offers/call-us" nextPage="CallUs" nextPageRoute="/offers/call-us" />} />,
    <Route component={RentalApplicationCallUs} key="call-us-page" path="/offers/call-us" />,
    <Route component={Pending} key="pending-page" path="/offers/pending" />,
    <Route component={Decline} key="decline-page" path="/offers/decline" />,
  ];

  const rentalApplicationHeader = agencySpecificHeader || <RentalHeader />;

  const rentalApplicationFooter = agencySpecificFooter || <RentalFooter />;

  return (
    <ApplicationLayout
      environmentSpecificClass="nfcc"
      environmentSpecificRoutes={rentalApplicationRoutes}
      environmentSpecificHeader={rentalApplicationHeader}
      environmentSpecificFooter={rentalApplicationFooter}
    />
  );
};

export default RentalApplicationLayout;
