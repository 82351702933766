import React from 'react';
import DropdownInput from '../common/DropdownInput';

const KindOfReliefDropdownInput = ({
  errors,
  onChange,
}) => {
  const options = [
    { label: 'Credit card help', value: 'Credit card help' },
    { label: 'Student loan help', value: 'Student loan help' },
    { label: 'First-time homebuyer assistance', value: 'First-time homebuyer assistance' },
    { label: 'Small business owner financial guidance', value: 'Small business owner financial guidance' },
    { label: 'Foreclosure prevention', value: 'Foreclosure prevention' },
    { label: 'Bankruptcy guide', value: 'Bankruptcy guide' },
    { label: 'A review of my overall budget and finances', value: 'A review of my overall budget and finances' },
  ];
  return (
    <DropdownInput
      errors={errors}
      id="kind-of-relief-select"
      loadFrom="application"
      name="kindOfRelief"
      onChange={onChange}
      options={options}
      question="What kind of relief are you looking for?"
    />
  );
};

export default KindOfReliefDropdownInput;
