import React from 'react';
import IntegerInput from '../../../application/common/IntegerInput/IntegerInput';

const DmpTermInMonths = ({
  errors, onChange,
}) => (
  <div className="mt-2">
    <IntegerInput
      errors={errors}
      id="term-in-months"
      label="DMP term (in months)"
      loadFrom="dmpEnrollment"
      maxNumberOfDigits={3}
      name="termInMonths"
      onChange={onChange}
    />
  </div>
);

export default DmpTermInMonths;
