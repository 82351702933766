import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BorrowerEmailAddressInput,
  BorrowerPhoneNumberInput,
  BorrowerFirstNameTextInput,
  BorrowerLastNameTextInput,
} from '../../../components/application/BorrowerInputs';
import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import Form from '../../../components/application/common/ApplicationForm/Form';
import KindOfReliefDropdownInput from '../../../components/application/KindOfReliefDropdownInput/KindOfReliefDropdownInput';
import ProgressBar from '../../../components/application/common/ProgressBar';
import { clearSaveUpdatedUserAttributesSucceeded, saveUpdatedUserAttributes, sendInitialEmail } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import TermsAgreementCheckbox from '../../../components/application/TermsAgreementCheckbox';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';

const CreditGenieAboutYou = ({ history }) => {
  const borrowerEmail = useSelector((state) => state.borrower.data.email);
  const borrowerFullName = useSelector((state) => `${state.borrower.data.firstName} ${state.borrower.data.lastName}`);
  const borrowerId = useSelector((state) => state.borrower.data.id);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.user.errorMessage || 'There was a problem saving your information. Please try again.');
  const initialEmailWasSent = useSelector((state) => state.user.initialEmailWasSent);
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);
  const saveBorrowerSucceeded = useSelector((state) => state.borrower.saveSuccess);
  const saveFailed = useSelector((state) => state.application.saveFailed
    || state.borrower.saveFailed
    || state.twilio.verifyPhoneNumberFailed
    || state.user.saveFailed);
  const saveUpdatedUserAttributesSucceeded = useSelector((state) => state.user.saveUpdatedUserAttributesSucceeded);
  const shouldSendInvitationEmail = process.env.REACT_APP_ENVIRONMENT !== 'freetool';

  const {
    errors,
    onApplicationChange,
    onBorrowerChange,
    onBorrowerTextInputBlur,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    if (saveApplicationSucceeded && saveBorrowerSucceeded) {
      if (saveUpdatedUserAttributesSucceeded === undefined) {
        dispatch(saveUpdatedUserAttributes());

        return;
      }

      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(clearSaveUpdatedUserAttributesSucceeded());

      if (shouldSendInvitationEmail && !initialEmailWasSent) {
        dispatch(sendInitialEmail());
      }

      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'AboutYou', lastViewedPage: 'LifeEvents' }));

      if (window.FS) {
        window.FS.identify(borrowerId, {
          displayName: borrowerFullName,
          email: borrowerEmail,
        });
      }

      history.push('/application/life-events');
    }
  });

  return (
    <div>
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 sm:pt-8 pt-0 pb-8">
          <ProgressBar pageName="aboutYou" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Get Your Free Analysis
          </h2>
          <p className="text-center w-full md:w-1/2 m-auto">
            Filling out the debt analysis is
            {' '}
            <span className="font-bold">
              fast and free
            </span>
            {' '}
            — no commitments or contracts. You will be given a free guide at the end of the process to help you negotiate your payments with your creditors.
          </p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto sm:m-8 sm:mx-auto m-4">
              <Form onSubmit={onSubmit}>
                <BorrowerFirstNameTextInput errors={errors} onBlur={onBorrowerTextInputBlur} onChange={onBorrowerChange} label="First name" />
                <BorrowerLastNameTextInput errors={errors} onBlur={onBorrowerTextInputBlur} onChange={onBorrowerChange} label="Last name" />
                <BorrowerEmailAddressInput errors={errors} onChange={onBorrowerChange} label="Email address" />
                <BorrowerPhoneNumberInput errors={errors} onChange={onBorrowerChange} label="Phone number" />
                <KindOfReliefDropdownInput errors={errors} onChange={onApplicationChange} />
                <TermsAgreementCheckbox errors={errors} />
                <SubmitInput />
              </Form>
            </div>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreditGenieAboutYou;
