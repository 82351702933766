import { API, graphqlOperation } from 'aws-amplify';
import { createHardship, updateHardship } from '../../graphql/customMutations';

export const CLEAR_SAVE_HARDSHIPS_SUCCEEDED = 'CLEAR_SAVE_HARDSHIPS_SUCCEEDED';
export const SAVE_HARDSHIPS = 'SAVE_HARDSHIPS';
export const SAVE_HARDSHIPS_FAILED = 'SAVE_HARDSHIPS_FAILED';
export const SAVE_HARDSHIPS_SUCCEEDED = 'SAVE_HARDSHIPS_SUCCEEDED';
export const SET_HARDSHIPS = 'SET_HARDSHIPS';
export const SET_SAVE_HARDSHIPS_SUCCEEDED = 'SET_SAVE_HARDSHIPS_SUCCEEDED';
export const SET_SHOULD_SAVE_HARDSHIPS = 'SET_SHOULD_SAVE_HARDSHIPS';
export const UPDATE_HARDSHIP = 'UPDATE_HARDSHIP';

const clearSaveHardshipsSucceeded = () => ({ type: CLEAR_SAVE_HARDSHIPS_SUCCEEDED });

const saveHardships = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_HARDSHIPS });

  const state = getState();

  const applicationId = state.application.data.id;
  const hardships = state.hardships.data;
  const savePromises = [];

  try {
    hardships.forEach((hardship) => {
      const saveOperation = hardship.id ? updateHardship : createHardship;

      savePromises.push(API.graphql(graphqlOperation(saveOperation, { input: { ...hardship, applicationId } })));
    });

    const promiseResponses = await Promise.all(savePromises);

    const savedHardships = promiseResponses.map((promiseResponse) => promiseResponse.data.createHardship || promiseResponse.data.updateHardship);

    dispatch({ hardships: savedHardships, type: SAVE_HARDSHIPS_SUCCEEDED });
  } catch (error) {
    console.log('error saving hardships:', error);

    dispatch({ type: SAVE_HARDSHIPS_FAILED });
  }
};

const setSaveHardshipsSucceeded = () => ({ type: SET_SAVE_HARDSHIPS_SUCCEEDED });

const setShouldSaveHardships = () => ({ type: SET_SHOULD_SAVE_HARDSHIPS });

const updateHardshipValue = ({ key, value }) => ({ key, type: UPDATE_HARDSHIP, value });

export {
  clearSaveHardshipsSucceeded,
  saveHardships,
  setSaveHardshipsSucceeded,
  setShouldSaveHardships,
  updateHardshipValue as updateHardship,
};
