import React from 'react';
import DateOfBirthInput from '../common/DateOfBirthInput';

const SpouseDateOfBirthInput = ({ errors, onChange }) => (
  <DateOfBirthInput
    errors={errors}
    id="spouse-date-of-birth-input"
    loadFrom="spouse"
    onChange={onChange}
    validationName="spouseDateOfBirth"
  />
);

export default SpouseDateOfBirthInput;
