import React from 'react';
import step1Graphic from '../../../../assets/icons/landing-pages/step-1_graphic.svg';

const Step1 = () => (
  <div>
    <h3 className="pb-4 font-normal">Step 1</h3>
    <h1>
      Complete a quick application
    </h1>
    <img className="block m-auto pb-4" src={step1Graphic} alt="Application Process" />
    <p>
      We&apos;ll gather your personal details and verify your email to create your account.
    </p>
    <div className="w-full">
      <ul className="slider-dots text-center">
        <li className="dot active-dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
        <li className="dot">&bull;</li>
      </ul>
    </div>
  </div>
);

export default Step1;
