import { UPDATE_SUMMARY_FILTERS } from '../actions/summaryFilterActions';

const initialState = {
  applicationStatus: {
    inProgress: true,
    submitted: true,
    callScheduled: true,
    contacted: true,
    transferred: true,
    archived: false,
    transferredAndArchived: false,
  },
  applicationType: {
    credit: true,
    rental: true,
  },
  claimedStatus: {
    claimed: true,
    unclaimed: true,
  },
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
  offerStatus: {
    hasOffers: true,
    noOffers: true,
  },
  planEnrollmentStatus: {
    isEnrolledInAPlan: true,
    isNotEnrolledInAPlan: true,
  },
};

const summaryFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SUMMARY_FILTERS:
      return {
        initialState,
        updatedState: action.filters,
      };

    default:
      return state;
  }
};

export default summaryFilterReducer;
