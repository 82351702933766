import React from 'react';
import AddressFields from '../common/AddressFields';
import BorrowerCityTextInput from './BorrowerCityTextInput';
import BorrowerStateDropdownInput from './BorrowerStateDropdownInput';
import BorrowerZipCodeInput from './BorrowerZipCodeInput';
import BorrowerAddressLine1TextInput from './BorrowerAddressLine1Input';
import BorrowerAddressLine2TextInput from './BorrowerAddressLine2Input';

const BorrowerAddressFields = ({ errors, onBlur, onChange }) => {
  const borrowerAddressLine1TextInput = <BorrowerAddressLine1TextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const borrowerAddressLine2TextInput = <BorrowerAddressLine2TextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const borrowerCityTextInput = <BorrowerCityTextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const borrowerStateDropdownInput = <BorrowerStateDropdownInput errors={errors} onChange={onChange} />;
  const borrowerZipCodeInput = <BorrowerZipCodeInput errors={errors} onChange={onChange} />;

  return (
    <AddressFields
      addressLine1TextInput={borrowerAddressLine1TextInput}
      addressLine2TextInput={borrowerAddressLine2TextInput}
      cityTextInput={borrowerCityTextInput}
      onChange={onChange}
      stateDropdownInput={borrowerStateDropdownInput}
      zipCodeInput={borrowerZipCodeInput}
    />
  );
};

export default BorrowerAddressFields;
