/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { useDispatch } from 'react-redux';
import ChatIcon from '../../../assets/icons/chat_icon.svg';
import OpenFolderIcon from '../../../assets/icons/open-folder_icon.svg';
import { clearContactAttempt } from '../../../redux/actions/contactAttemptActions';
import { saveApplication, updateApplication } from '../../../redux/actions/applicationActions';

const TransferApplicationTile = ({
  showContactedQuestions,
  showContactSummaryTable,
  toggleContactedQuestions,
  toggleContactSummaryTable,
}) => {
  const dispatch = useDispatch();

  const onArchiveSubmit = () => {
    dispatch(updateApplication({ propertyName: 'status', value: 'Archived' }));
    dispatch(saveApplication());
  };

  return (
    <div className="py-2 w-full mt-2 mb-4 text-center inline-flex">
      <div
        role="button"
        className="call-to-action orange-bg text-center inline-flex items-center justify-center"
        data-cy="contact-borrower"
        onClick={() => {
          dispatch(clearContactAttempt());
          toggleContactedQuestions(!showContactedQuestions);
          toggleContactSummaryTable(!showContactSummaryTable);
        }}
        onKeyDown={() => {
          dispatch(clearContactAttempt());
          toggleContactedQuestions(!showContactedQuestions);
          toggleContactSummaryTable(!showContactSummaryTable);
        }}
      >
        <img src={ChatIcon} alt="right arrow" className="pr-2" />
        Contact Borrower
      </div>
      <div
        role="button"
        className="call-to-action archive-cta text-center inline-flex items-center justify-center"
        data-cy="archive-borrower"
        onClick={() => onArchiveSubmit()}
        onKeyDown={() => onArchiveSubmit()}
      >
        Archive Borrower
        <img src={OpenFolderIcon} alt="right arrow" className="pl-2" />
      </div>
    </div>
  );
};

export default TransferApplicationTile;
