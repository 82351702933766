import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ButtonInput from '../../common/ButtonInput/ButtonInput';
import { getPlaidAccountBalances } from '../../../redux/actions/plaidActions';
import FinancialSummaryCalander from '../FinancialSummaryCalendar/FinancialSummaryCalendar';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const IncomeAndCheckingBalanceCalendar = () => {
  const application = useSelector((state) => state.application.data);
  const dispatch = useDispatch();
  const hasGottenPlaidAccountBalances = useSelector((state) => state.plaid.hasGottenPlaidAccountBalances);
  const history = useHistory();
  const incomeSnapshots = useSelector((state) => state.income.incomeSnapshots);
  const isGettingPlaidAccountBalances = useSelector((state) => state.plaid.isGettingPlaidAccountBalances);
  const plaidAccountBalances = useSelector((state) => state.plaid.plaidAccountBalances);
  const plaidItems = useSelector((state) => state.plaid.plaidItems);

  const isBlurred = plaidItems.length === 0;

  const balances = hasGottenPlaidAccountBalances ? plaidAccountBalances.map((balance) => balance.available) : [];
  const totalAvailableCheckingAccountBalance = balances.length && balances.reduce((sum, total) => sum + total, 0);
  const averageCheckingAccountBalance = (totalAvailableCheckingAccountBalance / balances.length) || 0;

  const filterStartDate = DateTime.fromISO(DateTime.local().minus({ months: 5 }).startOf('month')).toISO();
  const filterEndDate = DateTime.fromISO(DateTime.local().endOf('month')).toISO();
  const filterQueryBetweenLastSixMonths = { createdAt: { between: [filterStartDate, filterEndDate] } };

  const incomeSnapshotsSortedByDate = incomeSnapshots.sort((snapshotA, snapshotB) => new Date(snapshotB.createdAt) - new Date(snapshotA.createdAt));
  const lastSixIncomeSnapshots = incomeSnapshotsSortedByDate.slice(0, 6);
  const mostRecentMonthlyIncome = incomeSnapshotsSortedByDate[0]
    ? incomeSnapshotsSortedByDate[0].monthlyIncome
    : application.selfReportedMonthlyCurrentIncome;

  const averageMonthlyCheckingBalanceDataSet = plaidAccountBalances.map((balance) => ({ date: balance.createdAt, value: balance.available }));
  const monthlyIncomeDataSet = lastSixIncomeSnapshots.map((income) => ({ date: income.createdAt, value: income.monthlyIncome }));

  useEffect(() => {
    if (isGettingPlaidAccountBalances === undefined) {
      dispatch(getPlaidAccountBalances({
        accountSubType: 'checking',
        balancesFilter: filterQueryBetweenLastSixMonths,
      }));
    }
  }, [
    dispatch,
    isGettingPlaidAccountBalances,
    filterQueryBetweenLastSixMonths,
  ]);

  return (
    <div className="relative">
      <div className="flex justify-between w-1/2 mx-auto">
        <div className="pt-4 pb-12">
          <div className={`value financial-summary-value ${isBlurred && 'blurry-text'}`}>
            {formatNumberAsCurrency({ allowNegative: true, allowZero: true, number: Math.round(averageCheckingAccountBalance) })}
          </div>
          <div className={`text-sm ${isBlurred && 'blurry-text'}`}>
            Avg. Monthly
            <br />
            Checking Balance
          </div>
        </div>
        <div className="pt-4 pb-12">
          <div className={isBlurred ? 'value financial-summary-value blurry-text' : 'value financial-summary-value color-green'}>
            {formatNumberAsCurrency({ allowZero: true, number: Math.round(mostRecentMonthlyIncome) })}
          </div>
          <div className={`text-sm ${isBlurred && 'blurry-text'}`}>
            Monthly Income
          </div>
        </div>
      </div>
      <FinancialSummaryCalander
        dataSet1={averageMonthlyCheckingBalanceDataSet}
        dataSet2={monthlyIncomeDataSet}
        dataSet1Color="white"
        dataSet2Color="green"
        isBlurred={isBlurred}
      />
      {
        isBlurred && (
          <div className="absolute inset-0 flex flex-col justify-center text-white">
            <div className="w-full">
              <p className="w-5/6 lg:w-2/3 m-auto">
                Connect your primary checking account to get a better picture of your financial health.
              </p>
            </div>
            <div className="pt-5 w-full">
              <ButtonInput
                className="customer-portal-input m-auto"
                text="Connect account"
                onClick={() => history.push('/customer-portal/bank-connections')}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default IncomeAndCheckingBalanceCalendar;
