import { API, Auth } from 'aws-amplify';

export const CREATE_DOCUSIGN_CONTRACT = 'CREATE_DOCUSIGN_CONTRACT';
export const CREATE_DOCUSIGN_CONTRACT_FAIL = 'CREATE_DOCUSIGN_CONTRACT_FAIL';
export const CREATE_DOCUSIGN_CONTRACT_SUCCESS = 'CREATE_DOCUSIGN_CONTRACT_SUCCESS';

const createDocuSignContract = () => async (dispatch, getState) => {
  dispatch({ type: CREATE_DOCUSIGN_CONTRACT });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const state = getState();

    const response = await API.post('docusign', '/contracts', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: state.application.data.id,
      },
    });

    dispatch({ type: CREATE_DOCUSIGN_CONTRACT_SUCCESS, url: response.url });
  } catch (error) {
    console.error('error creating DocuSign contract:', error);

    dispatch({ error, type: CREATE_DOCUSIGN_CONTRACT_FAIL });
  }
};

export {
  createDocuSignContract,
};
