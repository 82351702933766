/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BasicLoading from '../../components/common/BasicLoading/BasicLoading';
import { captureApplicationQueryStringParameters } from '../../helpers/queryStringHelper';

const ApplicationLayout = ({
  environmentSpecificClass = undefined,
  environmentSpecificRoutes = [],
  environmentSpecificHeader = undefined,
  environmentSpecificFooter = undefined,
}) => {
  const dispatch = useDispatch();
  const hasProvidedMonthlyIncome = useSelector((state) => state.application.hasProvidedMonthlyIncome);
  const history = useHistory();
  const isCreatingDocuSignContract = useSelector((state) => state.docusign.isCreatingDocuSignContract);
  const isGettingDecision = useSelector((state) => state.decision.isGettingDecision);
  const isGettingExpenseEstimates = useSelector((state) => state.expenses.isGettingExpenseEstimates);
  const isGettingRiskScores = useSelector((state) => state.risk.isGettingRiskScores);
  const isPullingCredit = useSelector((state) => state.transunion.isPullingCredit);
  const isVerifyingPhoneNumber = useSelector((state) => state.twilio.isVerifyingPhoneNumber);
  const { t: translate } = useTranslation();

  const commonRoutes = [];

  const isSaving = useSelector((state) => state.application.isSaving
    || state.borrower.isSaving
    || state.expenses.isSavingExpenses
    || state.hardships.isSaving
    || state.income.isSavingIncomePredictions
    || state.plaid.isSavingPlaidItem
    || state.rentalApplication.isSaving
    || state.spouse.isSaving
    || state.user.isSaving);

  useEffect(() => {
    captureApplicationQueryStringParameters({ dispatch, history });
  }, [dispatch, history]);

  return (
    <section className={environmentSpecificClass}>
      {isCreatingDocuSignContract && <BasicLoading message={translate('modal:loading:message:pleaseWait')} subText={translate('modal:loading:subtext:creatingDocuSignContract')} />}
      {
        (isGettingExpenseEstimates || (hasProvidedMonthlyIncome && isPullingCredit))
        && <BasicLoading message={translate('modal:loading:message:pleaseWait')} subText={translate('modal:loading:subtext:gettingExpenseEstimates')} />
      }
      {
        (isGettingRiskScores || isGettingDecision)
        && <BasicLoading message={translate('modal:loading:message:pleaseWait')} subText={translate('modal:loading:subtext:gettingDecision')} />
      }
      {isSaving && <BasicLoading message={translate('modal:loading:message:saving')} />}
      {isVerifyingPhoneNumber && <BasicLoading message={translate('modal:loading:message:saving')} />}
      <div>
        {environmentSpecificHeader}
        <div className="body-container">
          {commonRoutes}
          {environmentSpecificRoutes}
        </div>
        {environmentSpecificFooter}
      </div>
    </section>
  );
};

export default ApplicationLayout;
