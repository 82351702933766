import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BorrowerEmailAddressInput,
  BorrowerPhoneNumberInput,
  BorrowerFirstNameTextInput,
  BorrowerLastNameTextInput,
} from '../../../components/application/BorrowerInputs';
import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveRentalApplicationSucceeded } from '../../../redux/actions/rentalApplicationActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import Form from '../../../components/application/common/ApplicationForm/Form';
import KindOfReliefDropdownInput from '../../../components/application/KindOfReliefDropdownInput/KindOfReliefDropdownInput';
import { clearSaveUpdatedUserAttributesSucceeded, saveUpdatedUserAttributes, sendInitialEmail } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import TermsAgreementCheckbox from '../../../components/application/TermsAgreementCheckbox';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';
import logo from '../../../assets/logos/creditgenie_logo_KO.svg';
import WhoWeAre from '../../../components/common/WhoWeAre/WhoWeAre';
import { WhatWeDo } from '../../../components';

const AboutYou = ({ nextPage = 'LifeEvents', nextPageRoute = '/application/life-events' }) => {
  const borrowerEmail = useSelector((state) => state.borrower.data.email);
  const borrowerFullName = useSelector((state) => `${state.borrower.data.firstName} ${state.borrower.data.lastName}`);
  const borrowerId = useSelector((state) => state.borrower.data.id);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.user.errorMessage || 'There was a problem saving your information. Please try again.');
  const history = useHistory();
  const initialEmailWasSent = useSelector((state) => state.user.initialEmailWasSent);
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);
  const saveBorrowerSucceeded = useSelector((state) => state.borrower.saveSuccess);
  const saveFailed = useSelector((state) => state.application.saveFailed
    || state.borrower.saveFailed
    || state.twilio.verifyPhoneNumberFailed
    || state.user.saveFailed);
  const saveUpdatedUserAttributesSucceeded = useSelector((state) => state.user.saveUpdatedUserAttributesSucceeded);

  const {
    errors,
    onApplicationChange,
    onBorrowerChange,
    onBorrowerEmailAddressInputBlur,
    onBorrowerTextInputBlur,
    onSubmit,
  } = useForm({ validate });

  useEffect(() => {
    if (saveApplicationSucceeded && saveBorrowerSucceeded) {
      if (saveUpdatedUserAttributesSucceeded === undefined) {
        dispatch(saveUpdatedUserAttributes());

        return;
      }

      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveRentalApplicationSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(clearSaveUpdatedUserAttributesSucceeded());

      if (!initialEmailWasSent) {
        dispatch(sendInitialEmail());
      }

      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'AboutYou', lastViewedPage: nextPage }));

      if (window.FS) {
        window.FS.identify(borrowerId, {
          displayName: borrowerFullName,
          email: borrowerEmail,
        });
      }

      history.push(nextPageRoute);
    }
  });

  return (
    <div>
      <noscript>Your browser does not support JavaScript!</noscript>
      <div className="about-you leading-normal tracking-normal">
        <div className="container mx-auto pl-2 pr-2 sm:pt-8 pt-0 pb-8">
          <h1 className="headline text-xl sm:text-2xl w-full m-auto text-center">
            Immediate debt relief. Get started with a free online analysis.
          </h1>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto sm:m-8 sm:mx-auto m-4">
              <div className="text-white text-center">
                <p className="free-online-analysis">
                  This online analysis is confidential and takes less than 5 minutes to complete.
                </p>
                <div className="powered-by pb-4 pt-2">
                  <p>Powered by</p>
                  <img src={logo} alt="Credit Genie Logo" />
                </div>
              </div>
              <div className="mt-2">
                <Form onSubmit={onSubmit}>
                  <BorrowerFirstNameTextInput errors={errors} onBlur={onBorrowerTextInputBlur} onChange={onBorrowerChange} label="First Name" />
                  <BorrowerLastNameTextInput errors={errors} onBlur={onBorrowerTextInputBlur} onChange={onBorrowerChange} label="Last Name" />
                  <BorrowerEmailAddressInput
                    errors={errors}
                    label="Email address"
                    onBlur={onBorrowerEmailAddressInputBlur}
                    onChange={onBorrowerChange}
                  />
                  <BorrowerPhoneNumberInput errors={errors} onChange={onBorrowerChange} label="Phone number" />
                  <KindOfReliefDropdownInput errors={errors} onChange={onApplicationChange} />
                  <TermsAgreementCheckbox errors={errors} />
                  <SubmitInput />
                </Form>
              </div>
            </div>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
        <div className="who-we-are w-full bg-white">
          <WhoWeAre />
        </div>
        <WhatWeDo />
      </div>
    </div>
  );
};

export default AboutYou;
