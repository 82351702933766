/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Close from '../../../../assets/icons/landing-pages/x_icon.svg';
import { setShowHowItWorksModal } from '../../../../redux/actions/howItWorksModalActions';
import CallToActionButton from '../../../common/CallToActionButton/CallToActionButton';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import ButtonInput from '../../../common/ButtonInput/ButtonInput';

const HowItWorksModal = () => {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(setShowHowItWorksModal({ value: false }));
  };

  const steps = [<Step1 />, <Step2 />, <Step3 />, <Step4 />];

  const [pageIndex, setPageIndex] = useState(0);

  return (
    <div className="how-it-works-modal w-full">
      <div className="sm:mt-10 mt-4 sm:mr-10 mr-4">
        <img onClick={onCloseClick} onKeyDown={onCloseClick} tabIndex="0" role="button" className="block float-right pb-4" src={Close} alt="Close" />
        <div className="clearfix" />
      </div>
      <div className="step container m-auto lg:w-1/4 w-full px-2">
        {steps[pageIndex]}
        <div className="clearfix" />
        {pageIndex !== (steps.length - 1)
          ? (
            <ButtonInput className="call-to-action text-center m-auto border-none" onClick={() => setPageIndex(pageIndex + 1)} text="Next Steps" />
          )
          : <CallToActionButton />}
      </div>
    </div>
  );
};

export default HowItWorksModal;
