import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CityTextInput from '../common/AddressFields/CityTextInput';
import { updateSpouse } from '../../../redux/actions/spouseActions';

const BorrowerCityTextInput = ({ errors, onBlur, onChange }) => {
  const dispatch = useDispatch();
  const spouseAddressIsSameAsBorrowerAddress = useSelector((state) => state.spouse.hasSameAddressAsBorrower);

  const onInputValueChange = (event) => {
    if (spouseAddressIsSameAsBorrowerAddress) {
      dispatch(updateSpouse({ propertyName: event.target.name, value: event.target.value }));
    }

    onChange(event);
  };

  return (
    <CityTextInput
      errors={errors}
      id="borrower-city-input"
      loadFrom="borrower"
      onBlur={onBlur}
      onChange={onInputValueChange}
      validationName="borrowerCity"
    />
  );
};

export default BorrowerCityTextInput;
