import React from 'react';
import { useTranslation } from 'react-i18next';
import CallToActionButton from '../../common/CallToActionButton/CallToActionButton';

const FooterCTA = () => {
  const { t: translate } = useTranslation();

  return (
    <section className="nfcc-footer border-b py-8">
      <div className="container max-w-5xl mx-auto m-8">
        <h2 className="text-3xl md:text-4xl text-center text-white pb-10">
          {translate('landingpage:renterAdvantage:footerCta:title')}
        </h2>
        <CallToActionButton buttonLabel={translate('global:cta:callToActionButtonDefault')} />
      </div>
    </section>
  );
};

export default FooterCTA;
