import React from 'react';

const Loading = ({ message = 'Loading...', subText = '' } = {}) => (
  <section className="leading-normal tracking-normal loading-bg w-full h-auto m-auto">
    <div className="logo-container m-auto">
      <svg
        data-name="Credit Genie G Logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 112.75 193.4"
        width="100"
        height="100"
      >
        {/* eslint-disable-next-line max-len */}
        <path id="Fill-3" className="g-icon g-logo-animation" d="M117.78,156.59c10.22-3.28,18.11-9.92,18.11-21.53,0-10-6.07-15.53-14.85-19.13-12.85-5.27-23.86-4.21-36.86-6.94A54.44,54.44,0,0,1,61,98.35C48.51,88.23,43.25,73.42,43.25,57.58,43.25,25.12,69,4.41,99.85,4.41c9.34,0,21.7,2.39,29.71,7.6l7.55-9,13.78,10.92-8.26,9.51c9,10.35,13.37,20,13.37,34.15,0,14.78-6.07,29.17-18.37,37.84l-.3.23h0A67,67,0,0,0,118,89.31,34.68,34.68,0,0,0,137.18,57.8c0-22-16.11-35.62-37.33-35.62-20.78,0-37.56,13.53-37.56,35.39.33,18.8,11.26,31.08,29.56,34.27A107.3,107.3,0,0,0,105,93.94c25.49,2.07,49,12.24,50.39,40.92,0,22.66-18.12,35.73-38.53,40.22A57.21,57.21,0,0,1,115.3,181a58.86,58.86,0,0,1-3.52,8.37l-.45.87a15.91,15.91,0,0,1-3.18,4.67l0,0a6,6,0,0,1-7.19.52,6.52,6.52,0,0,1-2.33-2.61,6.33,6.33,0,0,1-.48-4.14l0-.14a11.22,11.22,0,0,1,1.26-2.72,16.28,16.28,0,0,0,1-2l.08-.12a44.57,44.57,0,0,0,2.51-6.54l.12-.34c-1.26.06-2.48.08-3.75.08-19.29,0-40.52-6.43-50.12-24.58C43.4,141.27,42.8,130.12,51,120A29,29,0,0,1,67.14,110c.67-.15,1.3-.3,2-.39a27.64,27.64,0,0,1,2.85-.33c1.15-.07,2.26-.09,3.41,0a30.52,30.52,0,0,1,3.41.34A38,38,0,0,1,90,113.09a44.48,44.48,0,0,1,10.26,7,47,47,0,0,1,7.19,8.07A59.56,59.56,0,0,1,117.78,156.59ZM104.44,159c-1.11-10.33-5.45-21.43-13.63-28.29l0,0c-6.55-5.61-22.48-11.82-26.59-.22-1.41,3.88-.48,8.05,1.19,11.68C71.22,155,86.78,159,99.63,159.17c1.63,0,3.22-.06,4.81-.17Z" transform="translate(-43.25 -3)" />
      </svg>
      <div>
        <h2 className="text-center text-xl">{message}</h2>
        <p className="text text-center">{subText}</p>
      </div>
    </div>
  </section>
);

export default Loading;
