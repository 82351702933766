import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import GreenCheckmark from '../../../assets/icons/checkmark-green_icon.svg';
import { getUserByUsername } from '../../../redux/actions/userActions';
import TransferApplicationTile from '../TransferApplicationTile/TransferApplicationTile';
import OpenFolderIcon from '../../../assets/icons/open-folder_icon.svg';
import { saveApplication, updateApplication } from '../../../redux/actions/applicationActions';

const ContactSummaryTable = (
  {
    showContactedQuestions,
    showContactSummaryTable,
    toggleContactedQuestions,
    toggleContactSummaryTable,
  },
) => {
  const applicationStatus = useSelector((state) => state.application.data.status);
  const contactAttempts = useSelector((state) => state.contactAttempt.listContactAttempts);
  const dispatch = useDispatch();
  const lastCompletedPage = useSelector((state) => state.application.data.lastCompletedPage);
  const offers = useSelector((state) => state.offers.data);
  const user = useSelector((state) => state.user.data);

  const sortedContactAttempts = contactAttempts
    .sort((contactAttempt1, contactAttempt2) => new Date(contactAttempt2.createdAt) - new Date(contactAttempt1.createdAt));
  const lastContactAttempt = sortedContactAttempts[0];
  const lastContactedBy = lastContactAttempt && (user.userAttributes && user.userAttributes.name);

  const onUnarchiveSubmit = () => {
    let previousApplicationStatus;

    if (applicationStatus === 'Transferred And Archived') {
      previousApplicationStatus = 'Transferred';
    } else if (contactAttempts.length > 0 && applicationStatus === 'Archived') {
      previousApplicationStatus = 'Contacted';
    } else if (offers.length > 0 && lastCompletedPage !== 'Expenses') {
      previousApplicationStatus = 'Submitted';
    } else {
      previousApplicationStatus = 'In Progress';
    }

    dispatch(updateApplication({ propertyName: 'status', value: previousApplicationStatus }));
    dispatch(saveApplication());
  };

  const formatDateTimeObject = (datetime) => DateTime
    .fromISO(datetime)
    .toLocaleString(
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      },
    );

  useEffect(() => {
    if (lastContactAttempt) {
      dispatch(getUserByUsername({ userId: lastContactAttempt.contactedBy }));
    }
  }, [
    dispatch,
    lastContactAttempt,
  ]);

  const contactAttemptsTables = sortedContactAttempts.map((contactAttempt, index) => (
    <table className={`table-auto ${index % 2 === 0 ? 'bg-gray-100' : ' '} w-full`} key={`contact-attempt-${contactAttempt.id || index}`}>
      <thead>
        <tr className="text-left">
          <th className="w-1/3 detail pt-2 pl-2">Date/Time Contacted:</th>
          <th className="w-1/3 detail pl-16 pt-2">Method of Contact:</th>
          <th className="w-1/3 detail pl-16 pt-2">Contact Outcome:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="w-1/3 pl-2">
            {formatDateTimeObject(contactAttempt.createdAt)}
          </td>
          <td className="w-1/3 pl-16 pb-4">{contactAttempt.method}</td>
          <td className="w-1/3 pl-16 pb-4">{contactAttempt.outcome}</td>
        </tr>
      </tbody>
    </table>
  ));

  return (
    <div className="transfer-application-tile lender-tile-animation-1">
      {(applicationStatus === 'Archived' || applicationStatus === 'Transferred And Archived')
        ? (
          <div className="mb-4 mt-2">
            <img src={GreenCheckmark} alt={applicationStatus.toLowerCase()} className="inline-block" />
            <span className="confirmation font-bold inline-block ml-2">
              {applicationStatus}
            </span>
            {(applicationStatus === 'Archived' || applicationStatus === 'Transferred And Archived') && (
              /* eslint-disable jsx-a11y/interactive-supports-focus */
              <div
                role="button"
                className="call-to-action archive-cta text-center inline-flex items-center justify-center"
                data-cy="unarchive-borrower"
                onClick={() => onUnarchiveSubmit()}
                onKeyDown={() => onUnarchiveSubmit()}
              >
                Unarchive Borrower
                <img src={OpenFolderIcon} alt="right arrow" className="pl-2" />
              </div>
            )}
          </div>
        ) : (
          <TransferApplicationTile
            showContactedQuestions={showContactedQuestions}
            showContactSummaryTable={showContactSummaryTable}
            toggleContactedQuestions={toggleContactedQuestions}
            toggleContactSummaryTable={toggleContactSummaryTable}
          />
        )}
      <div className="contact-table w-full">
        <table className="table-auto w-full py-2 mb-4">
          <thead>
            <tr className="text-left">
              <th className="detail w-1/3 pl-2">Last Date/Time Contacted:</th>
              <th className="detail w-1/3 pl-16">Last Contacted by:</th>
              <th className="detail w-1/3 pl-16">Number of Times Contacted:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-1/3 pl-2">
                {lastContactAttempt && formatDateTimeObject(lastContactAttempt.createdAt)}
              </td>
              <td className="w-1/3 pl-16">
                {lastContactAttempt && lastContactedBy}
              </td>
              <td className="w-1/3 pl-16">{contactAttempts.length}</td>
            </tr>
          </tbody>
        </table>
        <div className="divider mb-2" />
        {contactAttemptsTables}
      </div>
    </div>
  );
};

export default ContactSummaryTable;
