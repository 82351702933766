/* eslint-disable max-len */
import React from 'react';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import LenderLogo from '../../common/LenderLogo/LenderLogo';

const ProposedPaymentTile = ({
  estimatedCurrentMonthlyPayment,
  lenderFriendlyName,
  lenderName,
  recommendedMonthlyPayment,
}) => {
  const roundedRecommendedMonthlyPayment = Math.round(recommendedMonthlyPayment > estimatedCurrentMonthlyPayment ? estimatedCurrentMonthlyPayment : recommendedMonthlyPayment);

  return (
    <div className="w-full">
      <div className="proposed-payment-tile m-auto pb-6">
        <div className="lender-logo object-scale-down">
          <div className="pl-4 mt-2 font-bold">
            <LenderLogo lenderFriendlyName={lenderFriendlyName} lenderName={lenderName} />
          </div>
        </div>
        <div className="flex proposed-payment-tile-container m-auto">
          <div className="w-1/2 text-left estimated-payment-amount">
            <div className="name-detail">
              Est. Current Monthly Payment
            </div>
            <div className="value" data-cy="est-current-monthly-payment">
              {estimatedCurrentMonthlyPayment ? formatNumberAsCurrency({ allowZero: true, number: Math.round(estimatedCurrentMonthlyPayment) }) : 'NA'}
            </div>
          </div>
          <div className="w-1/2 text-right new-payment-amount">
            <div className="name-detail">
              Recommended Monthly Payment
            </div>
            <div className="value" data-cy="recommended-monthly-payment">
              {formatNumberAsCurrency({ allowZero: true, number: roundedRecommendedMonthlyPayment })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposedPaymentTile;
