import { API, Auth } from 'aws-amplify';

export const PULL_CREDIT = 'PULL_CREDIT';
export const PULL_CREDIT_FAIL = 'PULL_CREDIT_FAIL';
export const PULL_CREDIT_SUCCESS = 'PULL_CREDIT_SUCCESS';
export const SET_BORROWER_CREDIT_REPORT_ID = 'SET_BORROWER_CREDIT_REPORT_ID';
export const SET_HAS_PULLED_CREDIT = 'SET_HAS_PULLED_CREDIT';
export const SET_SPOUSE_CREDIT_REPORT_ID = 'SET_SPOUSE_CREDIT_REPORT_ID';

const pullCredit = () => async (dispatch, getState) => {
  dispatch({ type: PULL_CREDIT });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const state = getState();

    const transunionReportsResponse = await API.post('transunion', '/reports', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: state.application.data.id,
      },
    });

    const { borrowerPull, spousePull } = transunionReportsResponse;

    if (!borrowerPull.wasSuccessful) {
      dispatch({ type: PULL_CREDIT_FAIL });

      return;
    }

    dispatch({
      borrowerCreditReportId: borrowerPull.creditReportId,
      spouseCreditReportId: spousePull && spousePull.creditReportId,
      type: PULL_CREDIT_SUCCESS,
    });
  } catch (error) {
    console.error('error getting credit report:', error);

    dispatch({ error, type: PULL_CREDIT_FAIL });
  }
};

const setBorrowerCreditReportId = ({ borrowerCreditReportId }) => ({ borrowerCreditReportId, type: SET_BORROWER_CREDIT_REPORT_ID });

const setHasPulledCredit = ({ hasPulledCredit }) => ({ type: SET_HAS_PULLED_CREDIT, hasPulledCredit });

const setSpouseCreditReportId = ({ spouseCreditReportId }) => ({ spouseCreditReportId, type: SET_SPOUSE_CREDIT_REPORT_ID });

export {
  pullCredit,
  setBorrowerCreditReportId,
  setHasPulledCredit,
  setSpouseCreditReportId,
};
