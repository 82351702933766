import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../TextInput';

const CityTextInput = ({
  errors, id, loadFrom, onBlur, onChange, validationName,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div>
      <TextInput
        errors={errors}
        id={id}
        label={translate('yourcontact:addressFields:city')}
        loadFrom={loadFrom}
        name="city"
        onBlur={onBlur}
        onChange={onChange}
        validationName={validationName}
      />
    </div>
  );
};

export default CityTextInput;
