import { setUtmCampaignParameters, updateApplication } from '../redux/actions/applicationActions';
import { updateBorrower } from '../redux/actions/borrowerActions';

const captureApplicationQueryStringParameters = ({ dispatch, history }) => {
  if (!dispatch || !history || !history.location || !history.location.search) {
    return;
  }

  const queryString = new URLSearchParams(history.location.search);

  const applicationType = queryString.get('applicationType');
  const referralId = queryString.get('referral_id');
  const router = queryString.get('router');
  const utmCampaign = queryString.get('utm_campaign');
  const utmContent = queryString.get('utm_content');
  const utmMedium = queryString.get('utm_medium');
  const utmSource = queryString.get('utm_source');
  const utmTerm = queryString.get('utm_term');

  dispatch(updateApplication({ propertyName: 'type', value: applicationType }));
  dispatch(updateApplication({ propertyName: 'router', value: router }));
  dispatch(updateBorrower({ propertyName: 'referralId', value: referralId }));
  dispatch(setUtmCampaignParameters({
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
  }));
};

export {
  // eslint-disable-next-line import/prefer-default-export
  captureApplicationQueryStringParameters,
};
