import PlaidLink from 'react-plaid-link';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import awsconfig from '../../../aws-exports';
import BankIcon from '../../../assets/icons/bank-connection_icon.svg';
import {
  getCreditCounselingAgencyDisclosureName,
  getCreditCounselingAgencyFullName,
  getCreditCounselingAgencyPhoneNumber,
} from '../../../helpers/creditCounselingAgencyHelper';
import PhoneIcon from '../../../assets/icons/phone_icon.svg';
import { plaidConnectionSuccess } from '../../../redux/actions/actions';
import { saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';

const CallOrConnectAccount = () => {
  const creditCounselingAgencyDisclosureName = getCreditCounselingAgencyDisclosureName();
  const creditCounselingAgencyFullName = getCreditCounselingAgencyFullName();
  const dispatch = useDispatch();
  const history = useHistory();
  const isConnectedToPlaid = useSelector((state) => state.plaid.isConnected);
  const router = useSelector((state) => state.application.data.router);

  const phoneNumber = getCreditCounselingAgencyPhoneNumber({ router });
  const webhook = `${awsconfig.aws_cloud_logic_custom.find((api) => api.name === 'plaid').endpoint}/webhook`;

  const onSubmit = (event) => {
    event.preventDefault();
  };

  // eslint-disable-next-line camelcase
  const onSuccess = (public_token, metadata) => {
    dispatch(plaidConnectionSuccess({ metadata, public_token }));
  };

  useEffect(() => {
    if (isConnectedToPlaid) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'CallOrConnectAccount', lastViewedPage: 'CallUs' }));

      history.push('/offers/call-us');
    }
  }, [
    dispatch,
    history,
    isConnectedToPlaid,
  ]);

  return (
    <div className="pending">
      <div className="leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pt-10 text-center w-full">
          <h2 className="text-xl sm:text-2xl w-full m-auto">
            We Need To Verify Your Information
          </h2>
          <img className="block m-auto pt-5" src={BankIcon} alt="Bank Connection Icon" />
          <div>
            <p className="w-full sm:w-1/2 m-auto pt-4 text-md sm:text-md">
              To lock in your savings, we still need to verify some information.
              You can do so securely by connecting your primary checking account.
            </p>
            <div className="leading-normal tracking-normal">
              <div className="mx-auto m-8 bank-connection plaid-call-to-action">
                <PlaidLink
                  className="border-none data-cy-connect-bank-account uppercase"
                  clientName="Creditly"
                  env={process.env.REACT_APP_PLAID_ENV}
                  product={['transactions']}
                  publicKey="1995cf6fdc8987d2cab0a1242a9d56"
                  onSuccess={onSuccess}
                  onSubmit={onSubmit}
                  webhook={webhook}
                >
                  Connect Account
                </PlaidLink>
              </div>
            </div>
            <p className="w-full sm:w-1/2 m-auto sm:py-12 pt-4 text-md sm:text-md">
              If you are still unable to connect your account, you can speak to a certified credit counselor from
              {` ${creditCounselingAgencyFullName}`}
              <sup>*</sup>
              {' '}
              to manually verify your information.
            </p>
            <hr />
          </div>
        </div>
        <div className="bg-gray-200 py-8 text-center">
          <div className="font-bold text-blue-custom">
            Your credit counselor from
            {` ${creditCounselingAgencyFullName}`}
            <sup>*</sup>
            {' '}
            will be reaching out to you shortly.
            <img alt="Phone Icon" className="center py-4" src={PhoneIcon} />
            Or call directly at:
            <p className="w-full sm:w-1/3 m-auto text-md sm:text-xl font-bold pt-2"><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
          </div>
          <br />
          <p className="text-xs pt-2">
            <sup>*</sup>
            {`${creditCounselingAgencyDisclosureName} is a certified member agency of the NFCC.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CallOrConnectAccount;
