import React from 'react';
import CopyIcon from '../../../assets/icons/copy_icon.svg';

const ApplicationSummaryInput = ({ inputValue, inputRef }) => {
  const copyTextToClipboard = (selectedRef) => {
    selectedRef.select();
    document.execCommand('copy');
  };

  return (
    <div>
      <input
        type="text"
        rows="1"
        readOnly
        value={(inputValue === 0 || inputValue) ? inputValue : ''}
        ref={inputRef}
      />
      <button
        onClick={() => copyTextToClipboard(inputRef.current)}
        type="button"
      >
        <img src={CopyIcon} alt="Copy Text" />
      </button>
    </div>
  );
};

export default ApplicationSummaryInput;
