import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCognitoPhoneNumberForPortal } from '../../../formatters/phoneNumberFormatter';
import { getCurrentAuthenticatedUser } from '../../../helpers/authHelper';
import profilePic from '../../../assets/icons/profile-default_icon.svg';

const CustomerSettings = () => {
  const defaultUser = { attributes: { email: undefined, name: undefined, phone_number: undefined } };
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const getCurrentUser = async () => {
      const currentUser = await getCurrentAuthenticatedUser();

      setUser(currentUser);
    };

    if (user === defaultUser) {
      getCurrentUser();
    }
  });

  return (
    <div>
      <div className="customer-settings leading-normal tracking-normal grey-bg pb-20 h-screen">
        <h1 className="text-center">Settings</h1>
        <div className="customer-settings-container m-auto mt-5 p-5">
          <img className="m-auto block pt-5" src={profilePic} alt="Profile" />
          <div className="text-black font-bold text-center py-2">
            {user.attributes.name}
          </div>
          <div className="text-center sub-info pb-5">
            <div>{user.attributes.email}</div>
            <div>{formatCognitoPhoneNumberForPortal({ cognitoPhoneNumber: user.attributes.phone_number })}</div>
          </div>
          <div className="divider" />
          <div className="inline-block mt-5 w-full">
            <div>
              <Link className="color-blue ctas-KO float-left text-center" to="/change-email">Change email</Link>
            </div>
            <div>
              <Link className="color-blue ctas-KO float-right" to="/change-password">Change password</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSettings;
