import React from 'react';

const TrustBar = () => (
  <div className="w-full">
    <div className="container m-auto">
      <div className="py-8">
        <a
          className="bbb-logo"
          href="https://www.bbb.org/us/pa/radnor/profile/legal-information-services/creditly-corporation-0241-236047315/#sealclick"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img
            className="block sm:m-0 m-auto"
            src="https://seal-dc-easternpa.bbb.org/seals/blue-seal-120-61-bbb-236047315.png"
            alt="Creditly Corporation BBB Business Review"
          />
        </a>
      </div>
    </div>
  </div>
);

export default TrustBar;
