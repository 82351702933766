import {
  CLEAR_SAVE_RENTAL_APPLICATION_SUCCEEDED,
  SAVE_RENTAL_APPLICATION,
  SAVE_RENTAL_APPLICATION_FAIL,
  SAVE_RENTAL_APPLICATION_SUCCESS,
  SET_RENTAL_APPLICATION,
  SET_SAVE_RENTAL_APPLICATION_SUCCEEDED,
  UPDATE_RENTAL_APPLICATION,
} from '../actions/rentalApplicationActions';

const initialState = {
  data: {
    id: undefined,

    ethnicity: undefined,
    backRentAmount: undefined,
    hasBackRent: undefined,
    hasReceivedRentalSupportInPast: undefined,
    hasUsedCreditCardToPayRent: undefined,
    monthlyRentPayment: undefined,
    numberOfPeopleLivingWithBorrower: undefined,
    race: undefined,
  },
  hasGottenRentalApplication: undefined,
  isGettingRentalApplication: undefined,
  isSaving: undefined,
  saveFailed: undefined,
  saveSucceeded: undefined,
};

const rentalApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SAVE_RENTAL_APPLICATION_SUCCEEDED: {
      return { ...state, saveSucceeded: undefined };
    }

    case SAVE_RENTAL_APPLICATION: {
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSucceeded: undefined,
      };
    }

    case SAVE_RENTAL_APPLICATION_FAIL: {
      return {
        ...state, isSaving: false, saveFailed: true, saveSucceeded: false,
      };
    }

    case SAVE_RENTAL_APPLICATION_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, ...action.rentalApplication },
        isSaving: false,
        saveFailed: false,
        saveSucceeded: true,
      };
    }

    case SET_RENTAL_APPLICATION: {
      return {
        ...state,
        data: action.rentalApplication || {},
        hasGottenRentalApplication: true,
        isGettingRentalApplication: false,
      };
    }

    case SET_SAVE_RENTAL_APPLICATION_SUCCEEDED: {
      return { ...state, saveSucceeded: true };
    }

    case UPDATE_RENTAL_APPLICATION: {
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };
    }

    default:
      return state;
  }
};

export default rentalApplicationReducer;
