import { API, graphqlOperation } from 'aws-amplify';
import { createSpouse, updateSpouse } from '../../graphql/customMutations';
import { formatApplicationDateForAws } from '../../formatters/dateFormatter';
import { formatPhoneNumberForBorrowerAndSpouse } from '../../formatters/phoneNumberFormatter';

export const CLEAR_SAVE_SPOUSE_SUCCEEDED = 'CLEAR_SAVE_SPOUSE_SUCCEEDED';
export const CLEAR_SPOUSE_ADDRESS = 'CLEAR_SPOUSE_ADDRESS';
export const SAVE_SPOUSE = 'SAVE_SPOUSE';
export const SAVE_SPOUSE_FAIL = 'SAVE_SPOUSE_FAIL';
export const SAVE_SPOUSE_SUCCESS = 'SAVE_SPOUSE_SUCCESS';
export const SET_SAVE_SPOUSE_SUCCEEDED = 'SET_SAVE_SPOUSE_SUCCEEDED';
export const SET_SPOUSE = 'SET_SPOUSE';
export const SET_SPOUSE_ADDRESS_TO_BORROWER_ADDRESS = 'SET_SPOUSE_ADDRESS_TO_BORROWER_ADDRESS';
export const SET_SPOUSE_CONSENTED_TO_CREDIT_POLICY = 'SET_SPOUSE_CONSENTED_TO_CREDIT_POLICY';
export const UPDATE_SPOUSE = 'UPDATE_SPOUSE';

const clearSaveSpouseSucceeded = () => ({ type: CLEAR_SAVE_SPOUSE_SUCCEEDED });

const clearSpouseAddress = () => ({ type: CLEAR_SPOUSE_ADDRESS });

const saveSpouse = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_SPOUSE });

  const state = getState();

  const applicationId = state.application.data.id;
  const borrowerId = state.borrower.data.id;
  const spouse = state.spouse.data;

  const formattedDateOfBirth = formatApplicationDateForAws({ applicationDate: spouse.dateOfBirth });
  const formattedPhoneNumber = formatPhoneNumberForBorrowerAndSpouse({ phoneNumber: spouse.phoneNumber });

  const input = {
    addressLine1: spouse.addressLine1,
    addressLine2: spouse.addressLine2,
    city: spouse.city,
    dateOfBirth: formattedDateOfBirth,
    email: spouse.email,
    firstName: spouse.firstName,
    id: spouse.id,
    lastName: spouse.lastName,
    phoneNumber: formattedPhoneNumber,
    spouseApplicationId: applicationId,
    spouseBorrowerId: borrowerId,
    state: spouse.state,
    zipCode: spouse.zipCode,
  };

  const saveOperation = spouse.id ? updateSpouse : createSpouse;

  try {
    const saveSpouseResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const spouseId = spouse.id || saveSpouseResponse.data.createSpouse.id;

    dispatch({ spouseId, type: SAVE_SPOUSE_SUCCESS });
  } catch (error) {
    console.log('saveSpouseFail was called with:', error);

    dispatch({ type: SAVE_SPOUSE_FAIL });
  }
};

const setSaveSpouseSucceeded = () => ({ type: SET_SAVE_SPOUSE_SUCCEEDED });

const setConsentedToCreditPolicy = ({ consentedToCreditPolicy }) => ({ consentedToCreditPolicy, type: SET_SPOUSE_CONSENTED_TO_CREDIT_POLICY });

const setSpouseAddressToBorrowerAddress = () => async (dispatch, getState) => {
  const state = getState();

  dispatch({ borrower: state.borrower.data, type: SET_SPOUSE_ADDRESS_TO_BORROWER_ADDRESS });
};

const updateSpouseInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_SPOUSE,
  value,
});

export {
  clearSaveSpouseSucceeded,
  clearSpouseAddress,
  saveSpouse,
  setSaveSpouseSucceeded,
  setConsentedToCreditPolicy,
  setSpouseAddressToBorrowerAddress,
  updateSpouseInformation as updateSpouse,
};
