import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import YesNoRadioButtonInput, { noValue, yesValue } from '../../application/common/RadioButtonInput/YesNoRadioButtonInput';

const HaveYouUsedYourCreditCardToPayRent = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  const checkedValue = useSelector((state) => {
    const { hasUsedCreditCardToPayRent } = state.rentalApplication.data;

    if (hasUsedCreditCardToPayRent) {
      return yesValue;
    }

    if (hasUsedCreditCardToPayRent === false) {
      return noValue;
    }

    return undefined;
  });

  return (
    <div>
      <YesNoRadioButtonInput
        checkedValue={checkedValue}
        errors={errors}
        id="have-you-used-your-credit-card-to-pay-rent-radio-button"
        name="hasUsedCreditCardToPayRent"
        onChange={onChange}
        question={translate('rentalinformation:haveYouUsedYourCreditCardToPayRent')}
      />
    </div>
  );
};

export default HaveYouUsedYourCreditCardToPayRent;
