import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const IncreasedExpensesCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();
  return (
    <CheckboxInput
      id="increased-expenses-checkbox"
      label={translate('lifeevents:significantIncreaseInExpenses')}
      loadFrom="hardships"
      name="hasIncreasedExpenses"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default IncreasedExpensesCheckbox;
