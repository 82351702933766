import { GET_PROPOSED_BUDGETS, GET_PROPOSED_BUDGETS_FAIL, GET_PROPOSED_BUDGETS_SUCCESS } from '../actions/budgetsActions';

const initialState = {
  data: [
    {
      agreedTo: undefined,
      applicationId: undefined,
      borrowerId: undefined,
      budgetExpenses: [
        {
          budgetId: undefined,
          expenseId: undefined,
          proposedValue: 0,
        },
      ],
      description: undefined,
    },
  ],
  getProposedBudgetsFailed: undefined,
  getProposedBudgetsSucceeded: undefined,
  isGettingProposedBudgets: undefined,
};

const budgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPOSED_BUDGETS:
      return {
        ...state,
        data: [],
        isGettingProposedBudgets: true,
        getProposedBudgetsFailed: undefined,
        getProposedBudgetsSucceeded: undefined,
      };

    case GET_PROPOSED_BUDGETS_FAIL:
      return {
        ...state,
        isGettingProposedBudgets: false,
        getProposedBudgetsFailed: true,
        getProposedBudgetsSucceeded: false,
      };

    case GET_PROPOSED_BUDGETS_SUCCESS:
      return {
        ...state,
        data: action.proposedBudgets,
        isGettingProposedBudgets: false,
        getProposedBudgetsFailed: false,
        getProposedBudgetsSucceeded: true,
      };

    default:
      return state;
  }
};

export default budgetsReducer;
