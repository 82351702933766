import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ApplicationSummaryInput from '../ApplicationSummaryReadOnlyComponents/ApplicationSummaryInput';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const RentalApplicationSummary = () => {
  const application = useSelector((state) => state.application.data);
  const borrower = useSelector((state) => state.borrower.data);
  const hardships = useSelector((state) => state.hardships.data || []);
  const rentalApplication = useSelector((state) => state.rentalApplication.data);
  const spouse = useSelector((state) => state.spouse.data);

  const {
    hasServedInMilitary,
    hasSpouse,
    numberOfDependentsInHousehold,
  } = application;

  const {
    addressLine1: borrowerAddressLine1,
    addressLine2: borrowerAddressLine2,
    city: borrowerCity,
    dateOfBirth: borrowerDateOfBirth,
    email: borrowerEmailAddress,
    firstName: borrowerFirstName,
    lastName: borrowerLastName,
    phoneNumber: borrowerPhoneNumber,
    state: borrowerState,
    zipCode: borrowerZipCode,
  } = borrower;

  const hardshipsObject = hardships.reduce((newObject, currentHardship) => ({ ...newObject, [currentHardship.key]: currentHardship.value }), {});

  const {
    hasCovidSpecificHardship,
    hasIncreasedExpenses,
    hasJobLossOrReducedIncome,
    hasMedicalDebtOrExpenses,
    hasOtherHardship,
    hasStudentLoans,
    hasTroubleMakingRentPayments,
    hasUtilityPayments,
  } = hardshipsObject;

  const {
    backRentAmount,
    ethnicity,
    hasBackRent,
    hasReceivedRentalSupportInPast,
    hasUsedCreditCardToPayRent,
    monthlyRentPayment,
    numberOfPeopleLivingWithBorrower,
    race,
  } = rentalApplication;

  const {
    addressLine1: spouseAddressLine1,
    addressLine2: spouseAddressLine2,
    city: spouseCity,
    dateOfBirth: spouseDateOfBirth,
    email: spouseEmailAddress,
    firstName: spouseFirstName,
    lastName: spouseLastName,
    phoneNumber: spousePhoneNumber,
    state: spouseState,
    zipCode: spouseZipCode,
  } = spouse;

  const backRentAmountRef = useRef();
  const borrowerAddressLine1Ref = useRef();
  const borrowerAddressLine2Ref = useRef();
  const borrowerCityRef = useRef();
  const borrowerDateOfBirthRef = useRef();
  const borrowerEmailAddressRef = useRef();
  const borrowerFirstNameRef = useRef();
  const borrowerLastNameRef = useRef();
  const borrowerPhoneNumberRef = useRef();
  const borrowerStateRef = useRef();
  const borrowerZipCodeRef = useRef();
  const ethnicityRef = useRef();
  const monthlyRentPaymentRef = useRef();
  const numberOfDependentsInHouseholdRef = useRef();
  const numberOfPeopleLivingWithBorrowerRef = useRef();
  const hasCovidSpecificHardshipRef = useRef();
  const hasIncreasedExpensesRef = useRef();
  const hasJobLossOrReducedIncomeRef = useRef();
  const hasMedicalDebtOrExpensesRef = useRef();
  const hasOtherHardshipRef = useRef();
  const hasReceivedRentalSupportInPastRef = useRef();
  const hasServedInMilitaryRef = useRef();
  const hasSpouseRef = useRef();
  const hasStudentLoansRef = useRef();
  const hasTroubleMakingRentPaymentsRef = useRef();
  const hasUsedCreditCardToPayRentRef = useRef();
  const hasUtilityPaymentsRef = useRef();
  const raceRef = useRef();
  const spouseEmailAddressRef = useRef();
  const spouseFirstNameRef = useRef();
  const spouseAddressLine1Ref = useRef();
  const spouseAddressLine2Ref = useRef();
  const spouseCityRef = useRef();
  const spouseLastNameRef = useRef();
  const spousePhoneNumberRef = useRef();
  const spouseStateRef = useRef();
  const spouseZipCodeRef = useRef();
  const spouseDateOfBirthRef = useRef();

  const getBooleanValueOrNotProvided = ({ booleanValue }) => {
    if (booleanValue) {
      return 'Yes';
    }

    if (booleanValue === false) {
      return 'No';
    }

    return 'Not Provided';
  };

  const getCurrencyValueOrNotProvided = ({ currencyValue }) => (
    (currencyValue || currencyValue === 0) ? formatNumberAsCurrency({ allowZero: true, number: currencyValue }) : 'Not Provided'
  );

  const getBackRentValueOrNotProvided = ({ backRentValue }) => {
    if (hasBackRent || hasBackRent === false) {
      return formatNumberAsCurrency({ allowZero: true, number: backRentValue || 0 });
    }

    return 'Not Provided';
  };

  const getMaritalStatusOrNotProvided = ({ isMarried }) => {
    if (isMarried) {
      return 'Married';
    }

    if (isMarried === false) {
      return 'Single';
    }

    return 'Not Provided';
  };

  const getNumberValueOrNotProvided = ({ numberValue }) => ((numberValue || numberValue === 0) ? numberValue : 'Not Provided');

  const getStringValueOrNotProvided = ({ stringValue }) => (stringValue || 'Not Provided');

  const getYesOrNo = ({ booleanValue }) => (booleanValue ? 'Yes' : 'No');

  return (
    <section>
      <div className="application-summary-read-only-components">
        <div className="lender-tile-animation-1">
          <h1>Application page 1</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">First name:</div>
              <ApplicationSummaryInput inputValue={borrowerFirstName} inputRef={borrowerFirstNameRef} />

              <div className="application-label pt-4">Email address:</div>
              <ApplicationSummaryInput inputValue={borrowerEmailAddress} inputRef={borrowerEmailAddressRef} />
            </div>
            <div className="w-full sm:w-1/2 ">
              <div className="application-label pt-4">Last name:</div>
              <ApplicationSummaryInput inputValue={borrowerLastName} inputRef={borrowerLastNameRef} />

              <div className="application-label pt-4">Phone number:</div>
              <ApplicationSummaryInput inputValue={borrowerPhoneNumber} inputRef={borrowerPhoneNumberRef} />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 2</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Trouble making rent payments:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasTroubleMakingRentPayments })}
                inputRef={hasTroubleMakingRentPaymentsRef}
              />

              <div className="application-label pt-4">Job loss or reduced income:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasJobLossOrReducedIncome })}
                inputRef={hasJobLossOrReducedIncomeRef}
              />

              <div className="application-label pt-4">Significant increase in expenses:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasIncreasedExpenses })}
                inputRef={hasIncreasedExpensesRef}
              />

              <div className="application-label pt-4">COVID-specific hardship</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasCovidSpecificHardship })}
                inputRef={hasCovidSpecificHardshipRef}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Medical debt or expenses:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasMedicalDebtOrExpenses })}
                inputRef={hasMedicalDebtOrExpensesRef}
              />

              <div className="application-label pt-4">Student loan debt:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasStudentLoans })}
                inputRef={hasStudentLoansRef}
              />

              <div className="application-label pt-4">Utility payments:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasUtilityPayments })}
                inputRef={hasUtilityPaymentsRef}
              />

              <div className="application-label pt-4">Other hardship:</div>
              <ApplicationSummaryInput
                inputValue={getYesOrNo({ booleanValue: hasOtherHardship })}
                inputRef={hasOtherHardshipRef}
              />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 3</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Monthly rent payment:</div>
              <ApplicationSummaryInput
                inputValue={getCurrencyValueOrNotProvided({ currencyValue: monthlyRentPayment })}
                inputRef={monthlyRentPaymentRef}
              />

              <div className="application-label pt-4">Has used credit card to pay rent:</div>
              <ApplicationSummaryInput
                inputValue={getBooleanValueOrNotProvided({ booleanValue: hasUsedCreditCardToPayRent })}
                inputRef={hasUsedCreditCardToPayRentRef}
              />

              <div className="application-label pt-4">Marital status:</div>
              <ApplicationSummaryInput
                inputValue={getMaritalStatusOrNotProvided({ isMarried: hasSpouse })}
                inputRef={hasSpouseRef}
              />

              <div className="application-label pt-4">Number of people in unit:</div>
              <ApplicationSummaryInput
                inputValue={getNumberValueOrNotProvided({ numberValue: numberOfPeopleLivingWithBorrower })}
                inputRef={numberOfPeopleLivingWithBorrowerRef}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Amount of back rent owed:</div>
              <ApplicationSummaryInput
                inputValue={getBackRentValueOrNotProvided({ backRentValue: backRentAmount })}
                inputRef={backRentAmountRef}
              />

              <div className="application-label pt-4">Has rental subsidy:</div>
              <ApplicationSummaryInput
                inputValue={getBooleanValueOrNotProvided({ booleanValue: hasReceivedRentalSupportInPast })}
                inputRef={hasReceivedRentalSupportInPastRef}
              />

              <div className="application-label pt-4">Number of dependents:</div>
              <ApplicationSummaryInput
                inputValue={numberOfDependentsInHousehold || 0}
                inputRef={numberOfDependentsInHouseholdRef}
              />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 4</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Race:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: race })}
                inputRef={raceRef}
              />

              <div className="application-label pt-4">Military service:</div>
              <ApplicationSummaryInput
                inputValue={getBooleanValueOrNotProvided({ booleanValue: hasServedInMilitary })}
                inputRef={hasServedInMilitaryRef}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Ethnicity:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: ethnicity })}
                inputRef={ethnicityRef}
              />
            </div>
          </div>
        </div>
        <div className="pt-5 lender-tile-animation-1">
          <h1>Application page 5</h1>
          <div className="information-bg sm:flex flex-none">
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Address line 1:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: borrowerAddressLine1 })}
                inputRef={borrowerAddressLine1Ref}
              />

              <div className="application-label pt-4">City:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: borrowerCity })}
                inputRef={borrowerCityRef}
              />

              <div className="application-label pt-4">Zip code:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: borrowerZipCode })}
                inputRef={borrowerZipCodeRef}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <div className="application-label pt-4">Address line 2:</div>
              <ApplicationSummaryInput
                inputValue={borrowerAddressLine2}
                inputRef={borrowerAddressLine2Ref}
              />

              <div className="application-label pt-4">State:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: borrowerState })}
                inputRef={borrowerStateRef}
              />

              <div className="application-label pt-4">Date of birth:</div>
              <ApplicationSummaryInput
                inputValue={getStringValueOrNotProvided({ stringValue: borrowerDateOfBirth })}
                inputRef={borrowerDateOfBirthRef}
              />
            </div>
          </div>
          {
            hasSpouse
            && (
              <div>
                <div className="divider" />
                <div className="information-bg">
                  <div className="font-bold pt-4">Spouse information</div>
                  <div className="flex">
                    <div className="w-full sm:w-1/2">
                      <div className="application-label pt-4">First name:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseFirstName })}
                        inputRef={spouseFirstNameRef}
                      />

                      <div className="application-label pt-4">Email address:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseEmailAddress })}
                        inputRef={spouseEmailAddressRef}
                      />

                      <div className="application-label pt-4">Address line 1:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseAddressLine1 })}
                        inputRef={spouseAddressLine1Ref}
                      />

                      <div className="application-label pt-4">City:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseCity })}
                        inputRef={spouseCityRef}
                      />

                      <div className="application-label pt-4">Zip code:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseZipCode })}
                        inputRef={spouseZipCodeRef}
                      />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <div className="application-label pt-4">Last name:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseLastName })}
                        inputRef={spouseLastNameRef}
                      />

                      <div className="application-label pt-4">Phone number:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spousePhoneNumber })}
                        inputRef={spousePhoneNumberRef}
                      />

                      <div className="application-label pt-4">Address line 2:</div>
                      <ApplicationSummaryInput
                        inputValue={spouseAddressLine2}
                        inputRef={spouseAddressLine2Ref}
                      />

                      <div className="application-label pt-4">State:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseState })}
                        inputRef={spouseStateRef}
                      />

                      <div className="application-label pt-4">Date of birth:</div>
                      <ApplicationSummaryInput
                        inputValue={getStringValueOrNotProvided({ stringValue: spouseDateOfBirth })}
                        inputRef={spouseDateOfBirthRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default RentalApplicationSummary;
