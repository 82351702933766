import React from 'react';
import TextInput from '../common/TextInput/TextInput';

const FirstNameTextInput = ({
  disabled, errors, id, label, loadFrom, onBlur, onChange, validationName, valueOverride,
}) => (
  <div>
    <TextInput
      disabled={disabled}
      errors={errors}
      id={id}
      label={label}
      loadFrom={loadFrom}
      maxLength={40}
      name="firstName"
      onBlur={onBlur}
      onChange={onChange}
      required
      validationName={validationName}
      valueOverride={valueOverride}
    />
  </div>
);

export default FirstNameTextInput;
