import React from 'react';
import { useSelector } from 'react-redux';
import AddressFields from '../common/AddressFields';
import SameAddressAsAboveInput from './SameAddressAsAboveInput';
import SpouseCityTextInput from './SpouseCityTextInput';
import SpouseStateDropdownInput from './SpouseStateDropdownInput';
import SpouseZipCodeInput from './SpouseZipCodeInput';
import SpouseAddressLine1TextInput from './SpouseAddressLine1TextInput';
import SpouseAddressLine2TextInput from './SpouseAddressLine2TextInput';

const SpouseAddressFields = ({ errors, onBlur, onChange }) => {
  const spouseAddressIsSameAsBorrowerAddress = useSelector((state) => state.spouse.hasSameAddressAsBorrower);
  const spouseAddressLine1TextInput = <SpouseAddressLine1TextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const spouseAddressLine2TextInput = <SpouseAddressLine2TextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const spouseCityTextInput = <SpouseCityTextInput errors={errors} onBlur={onBlur} onChange={onChange} />;
  const spouseStateDropdownInput = <SpouseStateDropdownInput errors={errors} onChange={onChange} />;
  const spouseZipCodeInput = <SpouseZipCodeInput errors={errors} onChange={onChange} />;

  return (
    <div>
      <div className="mb-4">
        <SameAddressAsAboveInput errors={errors} />
      </div>
      {
        spouseAddressIsSameAsBorrowerAddress === false
        && (
        <AddressFields
          addressLine1TextInput={spouseAddressLine1TextInput}
          addressLine2TextInput={spouseAddressLine2TextInput}
          cityTextInput={spouseCityTextInput}
          stateDropdownInput={spouseStateDropdownInput}
          onChange={onChange}
          zipCodeInput={spouseZipCodeInput}
        />
        )
      }
    </div>
  );
};

export default SpouseAddressFields;
