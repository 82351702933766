import React from 'react';
import { useSelector } from 'react-redux';
import {
  CreditGenieApplicationLayout,
  NfccApplicationLayout,
  RentalApplicationLayout,
} from '../layouts';

const ApplicationLayoutFactory = () => {
  const environmentName = process.env.REACT_APP_ENVIRONMENT;
  const isRentalApplication = useSelector((state) => state.application.data.type === 'rental');

  if (isRentalApplication) {
    return <RentalApplicationLayout />;
  }

  if (['afs', 'apprisen', 'cambridge', 'cesi', 'greenpath', 'navicore', 'main', 'staging'].includes(environmentName)) {
    return <NfccApplicationLayout />;
  }

  return <CreditGenieApplicationLayout />;
};

export default ApplicationLayoutFactory;
