/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router-dom';
import ApplicationLayout from './ApplicationLayout';
import {
  AboutYou,
  BankConnection,
  CallOrConnectAccount,
  CallUs,
  CreditHistory,
  Decline,
  Expenses,
  LifeEvents,
  Pending,
  ProvideMonthlyIncome,
  ReasonForNotConnectingAccount,
  Success,
  YourContact,
} from '../../pages';
import { AgencyHeader, AgencyFooter } from '../../components';

const NfccApplicationLayout = ({ agencySpecificHeader, agencySpecificFooter } = {}) => {
  const nfccRoutes = [
    <Route component={AboutYou} key="about-you-page" path="/application/about-you" />,
    <Route component={LifeEvents} key="life-events-page" path="/application/life-events" />,
    <Route component={CreditHistory} key="credit-history-page" path="/application/credit-history" />,
    <Route component={YourContact} key="your-contact-page" path="/application/your-contact" />,
    <Route component={ProvideMonthlyIncome} key="provide-monthly-income-page" path="/application/income/provide-monthly-income" />,
    <Route component={Expenses} key="expenses-page" path="/application/expenses" />,
    <Route component={BankConnection} key="bank-connection-page" path="/application/bank-connection" />,
    <Route component={Pending} key="pending-page" path="/offers/pending" />,
    <Route component={Decline} key="decline-page" path="/offers/decline" />,
    <Route component={Success} key="success-page" path="/offers/success" />,
    <Route component={ReasonForNotConnectingAccount} key="reason-for-not-connecting-account-page" path="/application/income/reason-for-not-connecting-account" />,
    <Route component={CallOrConnectAccount} key="call-or-connect-account-page" path="/offers/call-or-connect-account" />,
    <Route component={CallUs} key="call-us-page" path="/offers/call-us" />,
  ];

  const nfccHeader = agencySpecificHeader || <AgencyHeader />;

  const nfccFooter = agencySpecificFooter || <AgencyFooter />;

  return <ApplicationLayout environmentSpecificClass="nfcc" environmentSpecificRoutes={nfccRoutes} environmentSpecificHeader={nfccHeader} environmentSpecificFooter={nfccFooter} />;
};

export default NfccApplicationLayout;
