import React from 'react';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import convertLinearRange from '../../../helpers/linearConversionHelper';
import { convertToKFormat, roundNumberUp } from '../../../helpers/numberHelper';

const FinancialSummaryCalendar = (
  {
    dataSet1,
    dataSet2,
    dataSet1Color,
    dataSet2Color,
    isBlurred,
  },
) => {
  const cxValues = [25, 80, 135, 190, 245, 300];
  const lastSixMonths = [...Array(6)].map((x, i) => DateTime.local().minus({ months: i }).toFormat('MMM')).reverse();

  const yAxisRangeDataSet = dataSet2.length ? dataSet2 : dataSet1;
  const yAxisRangeValues = yAxisRangeDataSet.map((data) => data.value);
  const yAxisMaxValue = roundNumberUp({ number: Math.max(...yAxisRangeValues) });

  const blurFilter = isBlurred ? 'url(#blurMe)' : undefined;

  const dataSet1ValuesPerMonth = {};
  const dataSet2ValuesPerMonth = {};

  lastSixMonths.forEach((month, monthIndex) => {
    dataSet1ValuesPerMonth[monthIndex] = [];
    dataSet2ValuesPerMonth[monthIndex] = [];

    dataSet1.forEach((data) => {
      const { date, value } = data;

      const monthCreated = DateTime.fromISO(date).toFormat('MMM');
      if (monthCreated === month) {
        dataSet1ValuesPerMonth[monthIndex].push(value);
      }
    });

    dataSet2.forEach((data) => {
      const { date, value } = data;

      const monthCreated = DateTime.fromISO(date).toFormat('MMM');
      if (monthCreated === month) {
        dataSet2ValuesPerMonth[monthIndex].push(value);
      }
    });

    const dataSet1MonthlyValues = dataSet1ValuesPerMonth[monthIndex];
    const dataSet2MonthlyValues = dataSet2ValuesPerMonth[monthIndex];

    dataSet1ValuesPerMonth[monthIndex] = dataSet1MonthlyValues.length
      ? dataSet1MonthlyValues.reduce((a, b) => a + b, 0) / dataSet1MonthlyValues.length
      : undefined;

    dataSet2ValuesPerMonth[monthIndex] = dataSet2MonthlyValues.length
      ? dataSet2MonthlyValues.reduce((a, b) => a + b, 0)
      : undefined;
  });

  const dataSet1Points = Object.values(dataSet1ValuesPerMonth)
    .map((dataSet1Value, index) => {
      const cx = cxValues[index];
      const cy = 100 - convertLinearRange({ oldValue: dataSet1Value, oldMax: yAxisMaxValue, newMax: 100 });

      return (
        dataSet1Value && (
          <svg key={`${lastSixMonths[index]}-dataSet1-point`}>
            <circle
              className={dataSet1Color}
              style={{ animation: `opacity-transition ${0.15 + (0.10 * index)} linear` }}
              cx={cx}
              cy={cy}
              r="4"
              filter={blurFilter}
            />
          </svg>
        )
      );
    });

  const dataSet1Lines = Object.values(dataSet1ValuesPerMonth)
    .slice(0, Object.values(dataSet1ValuesPerMonth).length - 1)
    .map((dataSet1Value, index) => {
      const y1 = 100 - convertLinearRange({ oldValue: dataSet1Value, oldMax: yAxisMaxValue, newMax: 100 });
      const y2 = 100 - convertLinearRange({ oldValue: dataSet1ValuesPerMonth[index + 1], oldMax: yAxisMaxValue, newMax: 100 });

      return (
        dataSet1Value && (
          <svg key={`${lastSixMonths[index]}-dataSet1-line`}>
            <line
              className={dataSet1Color}
              style={{ animation: `dash ${0.1 + (0.1 * index)} linear` }}
              x1={cxValues[index]}
              x2={cxValues[index + 1]}
              y1={y1}
              y2={y2}
              filter={blurFilter}
            />
          </svg>
        )
      );
    });

  const dataSet2Points = Object.values(dataSet2ValuesPerMonth)
    .map((dataSet2Value, index) => {
      const cx = cxValues[index];
      const cy = 100 - convertLinearRange({ oldValue: dataSet2Value, oldMax: yAxisMaxValue, newMax: 100 });

      return (
        dataSet2Value && (
          <svg key={`${lastSixMonths[index]}-dataSet2-point`}>
            <circle
              className={dataSet2Color}
              style={{ animation: `opacity-transition ${0.15 + (0.10 * index)} linear` }}
              cx={cx}
              cy={cy}
              r="4"
              filter={blurFilter}
            />
          </svg>
        )
      );
    });

  const dataSet2Lines = Object.values(dataSet2ValuesPerMonth)
    .slice(0, Object.values(dataSet2ValuesPerMonth).length - 1)
    .map((dataSet2Value, index) => {
      const y1 = 100 - convertLinearRange({ oldValue: dataSet2Value, oldMax: yAxisMaxValue, newMax: 100 });
      const y2 = 100 - convertLinearRange({ oldValue: dataSet2ValuesPerMonth[index + 1], oldMax: yAxisMaxValue, newMax: 100 });

      return (
        dataSet2Value && (
          <svg key={`${lastSixMonths[index]}-dataSet2-line`}>
            <line
              className={dataSet2Color}
              style={{ animation: `dash ${0.1 + (0.1 * index)} linear` }}
              x1={cxValues[index]}
              x2={cxValues[index + 1]}
              y1={y1}
              y2={y2}
              filter={blurFilter}
            />
          </svg>
        )
      );
    });

  return (
    <div className="financial-summary-calendar">
      <svg
        viewBox="0 0 359 124"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <filter id="blurMe">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
        </filter>
        <g className="path">
          <g className="path-container" id="Portal-Summary" transform="translate(-27.000000, -269.000000)">
            <g transform="translate(26.000000, 263.000000)">
              <text className="x-axis">
                {lastSixMonths.map((month, index) => (
                  <tspan key={month} x={(15 + (55 * index)).toString()} y="127" filter={blurFilter}>{month}</tspan>
                ))}
              </text>
              <text className="y-axis">
                <tspan x="0" y="0" alignmentBaseline="middle" filter={blurFilter}>{`$${convertToKFormat({ number: yAxisMaxValue })}`}</tspan>
                <tspan x="0" y="25" alignmentBaseline="middle" filter={blurFilter}>{`$${convertToKFormat({ number: yAxisMaxValue * 0.75 })}`}</tspan>
                <tspan x="0" y="50" alignmentBaseline="middle" filter={blurFilter}>{`$${convertToKFormat({ number: yAxisMaxValue * 0.5 })}`}</tspan>
                <tspan x="0" y="75" alignmentBaseline="middle" filter={blurFilter}>{`$${convertToKFormat({ number: yAxisMaxValue * 0.25 })}`}</tspan>
                <tspan x="0" y="100" alignmentBaseline="middle" filter={blurFilter}>$0</tspan>
              </text>
              {dataSet1Points}
              {dataSet1Lines}
              {dataSet2Points}
              {dataSet2Lines}
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default withRouter(FinancialSummaryCalendar);
