import React from 'react';
import LastNameTextInput from '../LastNameTextInput';

const UserInformationLastNameInput = (
  {
    errors,
    onBlur,
    onChange,
    valueOverride,
  },
) => (
  <LastNameTextInput
    errors={errors}
    id="user-information-last-name"
    label="Last name"
    onBlur={onBlur}
    onChange={onChange}
    validationName="userLastName"
    valueOverride={valueOverride}
  />
);

export default UserInformationLastNameInput;
