import React from 'react';
import CurrencyOfferDetail from '../../../pages/offers/OfferDetail/CurrencyOfferDetail';

const LenderBalance = () => (
  <div className="lender-balance m-auto md:w-1/2 w-full py-8">
    <h3>Lender Balances as of 10/1/2020</h3>
    <div className="details w-full pt-4">
      <ul className="custom-counter">
        <li>
          <CurrencyOfferDetail
            name="Wells Fargo"
            value="750"
          />
        </li>
        <li>
          <CurrencyOfferDetail
            name="Chase"
            value="750"
          />
        </li>
        <li>
          <CurrencyOfferDetail
            name="Citi"
            value="750"
          />
        </li>
      </ul>
    </div>
  </div>
);

export default LenderBalance;
