import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../../DropdownInput';

const StateDropdownInput = ({
  errors, id, loadFrom, name = 'state', onChange, question = 'yourcontact:addressFields:state', validationName,
}) => {
  const { t: translate } = useTranslation();
  const options = [
    { label: 'AK', value: 'Alaska' },
    { label: 'AL', value: 'Alabama' },
    { label: 'AR', value: 'Arkansas' },
    { label: 'AS', value: 'American Samoa' },
    { label: 'AZ', value: 'Arizona' },
    { label: 'CA', value: 'California' },
    { label: 'CO', value: 'Colorado' },
    { label: 'CT', value: 'Connecticut' },
    { label: 'DC', value: 'District of Columbia' },
    { label: 'DE', value: 'Delaware' },
    { label: 'FL', value: 'Florida' },
    { label: 'GA', value: 'Georgia' },
    { label: 'GU', value: 'Guam' },
    { label: 'HI', value: 'Hawaii' },
    { label: 'IA', value: 'Iowa' },
    { label: 'ID', value: 'Idaho' },
    { label: 'IL', value: 'Illinois' },
    { label: 'IN', value: 'Indiana' },
    { label: 'KS', value: 'Kansas' },
    { label: 'KY', value: 'Kentucky' },
    { label: 'LA', value: 'Louisiana' },
    { label: 'MA', value: 'Massachusetts' },
    { label: 'MD', value: 'Maryland' },
    { label: 'ME', value: 'Maine' },
    { label: 'MI', value: 'Michigan' },
    { label: 'MN', value: 'Minnesota' },
    { label: 'MO', value: 'Missouri' },
    { label: 'MP', value: 'North Mariana Islands' },
    { label: 'MS', value: 'Mississippi' },
    { label: 'MT', value: 'Montana' },
    { label: 'NC', value: 'North Carolina' },
    { label: 'ND', value: 'North Dakota' },
    { label: 'NE', value: 'Nebraska' },
    { label: 'NH', value: 'New Hampshire' },
    { label: 'NJ', value: 'New Jersey' },
    { label: 'NM', value: 'New Mexico' },
    { label: 'NV', value: 'Nevada' },
    { label: 'NY', value: 'New York' },
    { label: 'OH', value: 'Ohio' },
    { label: 'OK', value: 'Oklahoma' },
    { label: 'OR', value: 'Oregon' },
    { label: 'PA', value: 'Pennsylvania' },
    { label: 'PR', value: 'Puerto Rico' },
    { label: 'RI', value: 'Rhode Island' },
    { label: 'SC', value: 'South Carolina' },
    { label: 'SD', value: 'South Dakota' },
    { label: 'TN', value: 'Tennessee' },
    { label: 'TX', value: 'Texas' },
    { label: 'UT', value: 'Utah' },
    { label: 'VA', value: 'Virginia' },
    { label: 'VI', value: 'Virgin Islands' },
    { label: 'VT', value: 'Vermont' },
    { label: 'WA', value: 'Washington' },
    { label: 'WI', value: 'Wisconsin' },
    { label: 'WV', value: 'West Virginia' },
    { label: 'WY', value: 'Wyoming' },
  ];

  return (
    <DropdownInput
      errors={errors}
      id={id}
      label={translate('yourcontact:addressFields:state')}
      loadFrom={loadFrom}
      name={name}
      onChange={onChange}
      options={options}
      question={translate(question)}
      required
      validationName={validationName}
    />
  );
};

export default StateDropdownInput;
