import { uniqBy } from 'lodash';

export const SET_CREDIT_REPORTS = 'SET_CREDIT_REPORTS';
export const SET_DEBTS = 'SET_DEBTS';

const getUniqueUnsecuredDebts = ({ debts }) => {
  const unsecuredDebtAccountTypes = ['CC', 'CH', 'FE', 'FX', 'LC', 'US'];

  const unsecuredDebts = uniqBy(
    debts.filter((debt) => unsecuredDebtAccountTypes.includes(debt.accountType) && debt.currentBalance > 0 && !debt.dateClosed),
    'accountNumber',
  );

  return unsecuredDebts;
};

const calculateTotalUnsecuredDebt = ({ debts }) => {
  const unsecuredDebts = getUniqueUnsecuredDebts({ debts });

  const totalUnsecuredDebt = unsecuredDebts
    .map((debt) => debt.currentBalance)
    .reduce((acc, unsecuredDebtBalance) => acc + unsecuredDebtBalance, 0);

  return totalUnsecuredDebt;
};

const calculateTotalInterestCharges = ({ debts }) => {
  const totalInterestOfCurrentPlan = 0.6644;
  const totalUnsecuredDebt = calculateTotalUnsecuredDebt({ debts });

  const totalInterestOfUnsecuredDebt = Math.round(totalUnsecuredDebt * totalInterestOfCurrentPlan);

  return totalInterestOfUnsecuredDebt;
};

const setDebts = ({ borrowerDebts, spouseDebts, lenderFriendlyNameMap }) => async (dispatch) => {
  const updatedDebts = borrowerDebts.map((debt) => {
    const lenderFriendlyNameMatch = Object.keys(lenderFriendlyNameMap).includes(debt.lenderName) && lenderFriendlyNameMap[debt.lenderName] !== '';
    const isShared = spouseDebts
      && borrowerDebts.some((borrowerDebt) => borrowerDebt.accountNumber === debt.accountNumber)
      && spouseDebts.some((spouseDebt) => spouseDebt.accountNumber === debt.accountNumber);

    return {
      ...debt,
      lenderFriendlyName: lenderFriendlyNameMatch ? lenderFriendlyNameMap[debt.lenderName] : undefined,
      shared: isShared,
    };
  });

  const uniqueDebts = uniqBy(updatedDebts, 'accountNumber');

  dispatch({ debts: uniqueDebts, type: SET_DEBTS });
};

export {
  calculateTotalInterestCharges,
  calculateTotalUnsecuredDebt,
  getUniqueUnsecuredDebts,
  setDebts,
};
