import React from 'react';
import { useSelector } from 'react-redux';
import { DmpEnrollment, RentalReliefPlanEnrollment } from '../../../components/customer-service-portal';

const PlanEnrollmentSummary = () => {
  const isEnrolledInADmp = useSelector((state) => state.dmpEnrollment.isEnrolledInADmp);
  const isEnrolledInARentalReliefPlan = useSelector((state) => state.rentalReliefPlan.isEnrolledInARentalReliefPlan);

  return (
    <div>
      <div className="leading-normal tracking-normal">
        <div className="borrower-summary-components container mx-auto pl-2 pr-2 pt-8 pb-8 lg:flex flex-none">
          {isEnrolledInADmp && <DmpEnrollment />}
          {isEnrolledInARentalReliefPlan && <RentalReliefPlanEnrollment />}
          {(!isEnrolledInADmp && !isEnrolledInARentalReliefPlan) && (
            <div>
              <p className="font-bold">
                This borrower is not currently enrolled in a plan
              </p>
              <p className="pt-2">
                To enroll the borrower click on the &quot;Enroll in a plan&quot; button in the borrower information tile and answer a few questions.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanEnrollmentSummary;
