import React from 'react';
import FirstNameTextInput from '../FirstNameTextInput';

const UserInformationFirstNameInput = (
  {
    errors,
    onBlur,
    onChange,
    valueOverride,
  },
) => (
  <FirstNameTextInput
    errors={errors}
    id="user-information-first-name"
    label="First name"
    onBlur={onBlur}
    onChange={onChange}
    validationName="userFirstName"
    valueOverride={valueOverride}
  />
);

export default UserInformationFirstNameInput;
