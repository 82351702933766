import React from 'react';

const SubmitInput = ({ cypressId = 'continue', onChange = () => { }, text = 'Continue' }) => (
  <input
    className="call-to-action py-3 mt-5 ml-auto mr-auto text-center block cursor-pointer"
    data-cy={cypressId}
    onChange={onChange}
    type="Submit"
    value={text}
  />
);

export default SubmitInput;
