import React from 'react';
import PaymentSummaryGraphic from './PaymentSummaryGraphic/PaymentSummaryGraphic';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const PaymentSummary = () => (
  <div>
    <div className="m-auto text-center pb-8">
      <div className="text-4xl font-bold rift-it">
        <ValueDetail
          value="2325"
        />
      </div>
      <div className="-mt-1 sub-text">
        Total Balance
      </div>
    </div>
    <PaymentSummaryGraphic />
  </div>
);

export default PaymentSummary;
