/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyOfferDetail from '../OfferDetail/CurrencyOfferDetail';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import GradientArrow from '../../../components/application/common/GradientArrow/GradientArrow';
import LenderOfferTileCarousel from '../../../components/offers/LenderOfferTileCarousel/LenderOfferTileCarousel';
import OfferDetailNoFormat from '../OfferDetail/OfferDetailNoFormat';
import { saveDecision, updateDecision } from '../../../redux/actions/decisionActions';
import { saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import ValueDetail from '../OfferDetail/ValueDetail';
import TrophyGraphic from '../../../components/common/TrophyGraphic/TrophyGraphic';

const Success = ({ history }) => {
  const decision = useSelector((state) => state.decision.data);
  const dispatch = useDispatch();
  const isConnectedToPlaid = useSelector((state) => state.plaid.isConnected);
  const offers = useSelector((state) => state.offers.data);
  const totalMonthlySavings = Math.round(decision.currentUnsecuredDebtPayment - decision.proposedUnsecuredDebtPayment);

  const onReduceMyPaymentsClick = () => {
    dispatch(updateDecision({ propertyName: 'wasAcceptedByBorrower', value: true }));
    dispatch(saveDecision());

    if (isConnectedToPlaid) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'Success', lastViewedPage: 'CallUs' }));

      history.push('/offers/call-us');

      return;
    }

    dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'Success', lastViewedPage: 'CallOrConnectAccount' }));

    history.push('/offers/call-or-connect-account');
  };

  return (
    <div className="leader-normal tracking-normal pb-20 nfcc">
      <div className="w-full m-auto bg-white">
        <div className="container mx-auto pl-2 pr-2 pt-4 text-center w-full">
          <h2 className="text-xl sm:text-2xl w-full mx-auto mb-4">
            Congratulations
          </h2>
          <div className="text-center">
            <TrophyGraphic />
            <span className="monthly-savings-amount pt-5 nfcc-green">
              {formatNumberAsCurrency({ number: totalMonthlySavings })}
            </span>
            <p className="w-full sm:w-1/2 m-auto monthly-savings-amount-subtext nfcc-green">
              Potential Monthly Savings
            </p>
            <p className="w-full sm:w-1/2 m-auto sm:py-4">
              Based on our initial assessment, you are eligible for a debt management plan
              <sup>*</sup>
              {' '}
              that can get you significant savings while paying off your debt.
            </p>
          </div>
          <div className="divider sm:my-0 my-4" />
        </div>
      </div>
      <div className="px-2">
        <p className="font-bold mt-8 pb-2 text-center">
          Debt management plan estimates:
        </p>
        <div className="new-monthly-payment-tile">
          <div className="flex justify-between">
            <p className="mb-1 sm:text-sm text-xs grey-text">Min. Monthly Payment</p>
            <p className="mb-1 sm:text-sm text-xs nfcc-green">
              New Min. Monthly Payment
              <sup>1</sup>
            </p>
          </div>
          <div className="flex justify-between">
            <h2 className="grey-text">
              <ValueDetail
                value={Math.round(decision.currentUnsecuredDebtPayment)}
              />
            </h2>
            <GradientArrow
              endColor="#9AC03F"
              startColor="#000"
            />
            <h2 className="nfcc-green">
              <ValueDetail
                value={Math.round(decision.proposedUnsecuredDebtPayment)}
              />
            </h2>
          </div>
          <div className="py-2">
            <div className="pt-6 success-details">
              <div className="w-full details active lender-tile-animation-1">
                <OfferDetailNoFormat name="Plan Length" value="60 Months" />
                <CurrencyOfferDetail name="Monthly Savings" value={totalMonthlySavings} />
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <div className="lender-carousel container mx-auto pl-2 pr-2 text-center w-full">
          <p className="font-bold my-8">
            Your plan details for each creditor:
          </p>
          {offers.length && <LenderOfferTileCarousel offers={offers} />}
        </div>
        <input
          type="button"
          className="call-to-action px-20 py-3 ml-auto mr-auto text-center block uppercase"
          data-cy="reduce-payments"
          onClick={onReduceMyPaymentsClick}
          value="Reduce My Payments"
        />
        <div className="container m-auto pt-2 md:w-1/4 w-full text-center">
          <div className="font-bold pt-2">
            Acting now to reduce your debt payments will prevent additional fees and interest charges from your exisiting debts.
          </div>
        </div>
        <div className="container m-auto md:w-1/2 w-full text-center">
          <div className="disclosure">
            <sup>*</sup>
            The details of your debt management plan are based on our initial assessment of your current situation. The final terms
            of your debt management plan are subject to change and require you to consult with a credit counselor.
          </div>
          <div className="disclosure">
            <sup>1</sup>
            This includes all fees associated with a debt management plan
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
