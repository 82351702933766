import React from 'react';
import CreditGenieLogo from '../../../../assets/logos/creditgenie_logo_KO.svg';
import NFCCLogo from '../../../../assets/logos/NFCC_logo_white.svg';

const HardshipHero = () => (
  <div className="hardship-hero bg-cover bg-center bg-no-repeat md:bg-top">
    <div>
      <h1 className="text-white text-center text-5xl md:text-6xl">
        Helping People navigate through uncertain Financial times
        <br />
        {' '}
      </h1>
      <p className="text-center text-white px-2 sm:px-10">
        CreditGenie along with the National Foundation for Credit Counseling are partnering to work with your lenders to get you
        immediate debt relief.
      </p>
      <div className="flex img-container">
        <div className="w-1/3 m-auto">
          <img className="credit-genie mx-auto text-center" src={CreditGenieLogo} alt="Credit Genie Knockout Logo" />
        </div>
        <div className="pipe w-1/3 m-auto" />
        <div className="w-1/3 m-auto">
          <img className="mx-auto text-center" src={NFCCLogo} alt="NFCC Knockout Logo" />
        </div>
      </div>
    </div>
  </div>
);

export default HardshipHero;
