/* eslint-disable max-len */
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { startCase } from 'lodash';
import { createApplication, updateApplication, updateBorrower } from '../../graphql/customMutations';
import { getApplication, listApplicationsByOwnerWithIds, listApplicationsByStatusSortedByCreatedAt } from '../../graphql/customQueries';
import {
  convertExpenseArrayToExpensesObject,
  GET_EXPENSE_ESTIMATES_SUCCESS,
  GET_EXPENSES_BY_APPLICATION_ID_SUCCESS,
} from './expensesActions';
import { getAlertEventDate, SET_ALERTS } from './alertActions';
import { GET_CREDIT_SCORES_SUCCESS } from './creditScoresActions';
import getLenderFriendlyNameMap from '../../helpers/lenderFriendlyNameHelper';
import { setAssignedBorrowerCount, SET_BORROWER, setContactedBorrowerCount } from './borrowerActions';
import { setBorrowerCreditReportId, setHasPulledCredit, setSpouseCreditReportId } from './transunionActions';
import { SET_DEBT_SCORES } from './debtScoreActions';
import { setDebts } from './debtsActions';
import { setDecisionToNewestDecision } from './decisionActions';
import { SET_DMP_ENROLLMENT } from './dmpEnrollmentActions';
import { SET_HARDSHIPS } from './hardshipActions';
import { SET_INCOME_PREDICTIONS } from './incomeActions';
import { SET_LIST_CONTACT_ATTEMPTS } from './contactAttemptActions';
import { SET_RENTAL_APPLICATION } from './rentalApplicationActions';
import { SET_RENTAL_RELIEF_PLAN } from './rentalReliefPlanActions';
import { SET_SPOUSE } from './spouseActions';

export const CLEAR_SAVE_APPLICATION_SUCCEEDED = 'CLEAR_SAVE_APPLICATION_SUCCEEDED';
export const CLEAR_HAS_DEPENDENTS = 'CLEAR_HAS_DEPENDENTS';
export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_FAIL = 'GET_APPLICATION_FAIL';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FOR_USER = 'GET_APPLICATION_FOR_USER';
export const GET_APPLICATION_FOR_USER_FAIL = 'GET_APPLICATION_FOR_USER_FAIL';
export const GET_APPLICATION_FOR_USER_SUCCESS = 'GET_APPLICATION_FOR_USER_SUCCESS';
export const GET_APPLICATIONS_FOR_SERVICE_PORTAL = 'GET_APPLICATIONS_FOR_SERVICE_PORTAL';
export const GET_APPLICATIONS_FOR_SERVICE_PORTAL_FAILED = 'GET_APPLICATIONS_FOR_SERVICE_PORTAL_FAILED';
export const GET_APPLICATIONS_FOR_SERVICE_PORTAL_SUCCEEDED = 'GET_APPLICATIONS_FOR_SERVICE_PORTAL_SUCCEEDED';
export const SAVE_APPLICATION = 'SAVE_APPLICATION';
export const SAVE_APPLICATION_FAIL = 'SAVE_APPLICATION_FAIL';
export const SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS';
export const SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE = 'SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE';
export const SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_FAIL = 'SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_FAIL';
export const SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_SUCCESS = 'SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_SUCCESS';
export const SET_AGREED_TO_TERMS = 'SET_AGREED_TO_TERMS';
export const SET_HAS_DEPENDENTS = 'SET_HAS_CHILDREN';
export const SET_HAS_PROVIDED_MONTHLY_INCOME = 'SET_HAS_PROVIDED_MONTHLY_INCOME';
export const SET_HAS_SUBMITTED_PRIMARY_APPLICATION = 'SET_HAS_SUBMITTED_PRIMARY_APPLICATION';
export const SET_LAST_VIEWED_PAGE = 'SET_LAST_VIEWED_PAGE';
export const SET_OTHER_REASON_FOR_NOT_CONNECTING_ACCOUNT = 'SET_OTHER_REASON_FOR_NOT_CONNECTING_ACCOUNT';
export const SET_SAVE_APPLICATION_SUCCEEDED = 'SET_SAVE_APPLICATION_SUCCEEDED';
export const SET_UTM_CAMPAIGN_PARAMETERS = 'SET_UTM_CAMPAIGN_PARAMETERS';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

const setApplication = ({ application }) => async (dispatch) => {
  const { borrower, dmpEnrollment, spouse } = application;
  const lenderFriendlyNameMap = await getLenderFriendlyNameMap();

  const alerts = application.borrower.alerts.items;
  const borrowerCreditReports = borrower.creditReports.items;
  const borrowerDebts = borrowerCreditReports.flatMap((creditReport) => creditReport.debts.items);
  const contactAttempts = application.contactAttempts.items;
  const debtScores = borrower.debtScores.items;
  const decisions = application.decisions.items;
  const expenses = application.expenses.items;
  const hardships = application.hardships.items;
  const plaidItems = application.plaidItems.items;
  const plaidAccounts = plaidItems.flatMap((plaidItem) => plaidItem.plaidAccounts.items);
  const plaidTransactions = plaidAccounts.flatMap((plaidAccount) => plaidAccount.plaidTransactions.items);
  const { rentalApplication } = application.borrower;
  const rentalReliefPlan = rentalApplication && rentalApplication.rentalReliefPlan;
  const spouseCreditReports = spouse ? spouse.creditReports.items : [];
  const spouseDebts = spouseCreditReports.flatMap((spouseCreditReport) => spouseCreditReport.debts.items);

  const creditReports = [...borrowerCreditReports, ...spouseCreditReports];

  if (borrowerCreditReports.length > 0) {
    const mostRecentBorrowerCreditReport = borrowerCreditReports.sort(
      (creditReportOne, creditReportTwo) => new Date(creditReportTwo.createdAt) - new Date(creditReportOne).createdAt,
    )[0];

    dispatch(setBorrowerCreditReportId({ borrowerCreditReportId: mostRecentBorrowerCreditReport.id }));
  }

  if (creditReports.length > 0) {
    dispatch(setHasPulledCredit({ hasPulledCredit: true }));
  }

  if (expenses && expenses.length) {
    const expensesObject = convertExpenseArrayToExpensesObject({ expenseArray: expenses });

    dispatch({ expenses: expensesObject, type: GET_EXPENSES_BY_APPLICATION_ID_SUCCESS });
    dispatch({ expenseEstimates: expenses, type: GET_EXPENSE_ESTIMATES_SUCCESS });
  }

  if (spouseCreditReports.length > 0) {
    const mostRecentSpouseCreditReport = spouseCreditReports.sort(
      (creditReportOne, creditReportTwo) => new Date(creditReportTwo.createdAt) - new Date(creditReportOne).createdAt,
    )[0];

    dispatch(setSpouseCreditReportId({ spouseCreditReportId: mostRecentSpouseCreditReport.id }));
  }

  dispatch({ alerts, type: SET_ALERTS });
  dispatch({ borrower, type: SET_BORROWER });
  dispatch({ contactAttempts, type: SET_LIST_CONTACT_ATTEMPTS });
  dispatch({ creditScores: creditReports, type: GET_CREDIT_SCORES_SUCCESS });
  dispatch(setDebts({ borrowerDebts, spouseDebts, lenderFriendlyNameMap }));
  dispatch({ debtScores, type: SET_DEBT_SCORES });
  dispatch(setDecisionToNewestDecision({ decisions, lenderFriendlyNameMap }));
  dispatch({ dmpEnrollment, type: SET_DMP_ENROLLMENT });
  dispatch({ hardships, type: SET_HARDSHIPS });
  dispatch({ incomePredictions: plaidTransactions, type: SET_INCOME_PREDICTIONS });
  dispatch({ rentalApplication, type: SET_RENTAL_APPLICATION });
  dispatch({ rentalReliefPlan, type: SET_RENTAL_RELIEF_PLAN });
  dispatch({ spouse, type: SET_SPOUSE });

  alerts.forEach((alert) => dispatch(getAlertEventDate({ alert })));

  dispatch({ application, type: GET_APPLICATION_SUCCESS });
};

const assignApplicationToUser = ({ applicationId, userId }) => async () => {
  const currentTimeAndDate = DateTime.local();
  try {
    await API.graphql(graphqlOperation(updateApplication, {
      input: {
        assignedTo: userId,
        assignedToAt: currentTimeAndDate,
        id: applicationId,
      },
    }));
  } catch (error) {
    console.log(`failed to assign Application ID ${applicationId} to User ID ${userId}:`, error);
  }
};

const clearSaveApplicationSucceeded = () => ({ type: CLEAR_SAVE_APPLICATION_SUCCEEDED });

const clearHasDependents = () => ({ type: CLEAR_HAS_DEPENDENTS });

const getApplicationInformation = ({ applicationId }) => async (dispatch) => {
  dispatch({ type: GET_APPLICATION });

  if (!applicationId) {
    dispatch({ application: undefined, type: GET_APPLICATION_SUCCESS });

    return;
  }

  try {
    const getApplicationResponse = await API.graphql(graphqlOperation(getApplication, { applicationId }));

    const application = getApplicationResponse.data.getApplication;

    dispatch(setApplication({ application }));
  } catch (error) {
    console.log('failed to get application:', error);

    dispatch({ type: GET_APPLICATION_FAIL });
  }
};

const getApplicationInformationForUser = () => async (dispatch, getState) => {
  dispatch({ type: GET_APPLICATION_FOR_USER });

  const state = getState();
  const owner = state.user.data.username;

  const getAllApplicationsIncrementally = async ({ limit = 10, nextToken } = {}) => {
    const nextIncrementResponse = await API.graphql(graphqlOperation(listApplicationsByOwnerWithIds, { limit, nextToken, owner }));

    const { items: applications, nextToken: nextIncrementNextToken } = nextIncrementResponse.data.listApplicationsByOwner;

    if (nextIncrementNextToken) {
      return [...applications, ...await getAllApplicationsIncrementally({ limit, nextToken: nextIncrementNextToken })];
    }

    return applications;
  };

  try {
    const applications = await getAllApplicationsIncrementally({ limit: 1000 });

    const getApplicationResponse = await API.graphql(graphqlOperation(getApplication, { applicationId: applications[0].id }));

    const application = getApplicationResponse.data.getApplication;

    dispatch(setApplication({ application }));
  } catch (error) {
    console.log('failed to get application:', error);

    dispatch({ type: GET_APPLICATION_FOR_USER_FAIL });
  }
};

const getApplicationsForServicePortal = ({ shouldFilterByUserId = false, userId } = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_APPLICATIONS_FOR_SERVICE_PORTAL });

  const createdAtFilter = {};
  const filter = (shouldFilterByUserId && userId) ? { assignedTo: { eq: userId } } : undefined;
  const seventyTwoHoursAgo = DateTime.local().minus({ hours: 72 }).toFormat('yyyy-MM-dd');
  const state = getState();

  const filters = state.summaryFilters.updatedState || state.summaryFilters;

  const { applicationStatus: statusesToFilterBy, dateRange } = filters;

  if (!dateRange.startDate && !dateRange.endDate) {
    createdAtFilter.ge = seventyTwoHoursAgo;
  }

  if (dateRange.startDate && !dateRange.endDate) {
    createdAtFilter.between = [dateRange.startDate, dateRange.startDate];
  }

  if (dateRange.startDate && dateRange.endDate) {
    createdAtFilter.between = [dateRange.startDate, dateRange.endDate];
  }

  const listAllServicePortalApplicationsIncrementally = async ({ limit = 1000, nextToken, statusToFilterBy } = {}) => {
    const nextIncrementResponse = await API.graphql(graphqlOperation(listApplicationsByStatusSortedByCreatedAt, {
      createdAt: createdAtFilter,
      filter,
      limit,
      nextToken,
      sortDirection: 'DESC',
      status: startCase(statusToFilterBy),
    }));

    const { items: applications, nextToken: nextIncrementNextToken } = nextIncrementResponse.data.listApplicationsByStatusSortedByCreatedAt;

    if (nextIncrementNextToken) {
      return [...applications, ...await listAllServicePortalApplicationsIncrementally({ limit, nextToken: nextIncrementNextToken, statusToFilterBy })];
    }

    return applications;
  };

  try {
    const listApplicationPromises = Object.keys(statusesToFilterBy)
      .filter((statusKey) => statusesToFilterBy[statusKey])
      .map((statusToFilterBy) => listAllServicePortalApplicationsIncrementally({ statusToFilterBy }));

    const listApplicationsResponses = await Promise.all(listApplicationPromises);

    const applications = listApplicationsResponses
      .flatMap((application) => application)
      .sort((applicationOne, applicationTwo) => new Date(applicationTwo.createdAt) - new Date(applicationOne.createdAt));

    if (shouldFilterByUserId) {
      const numberOfAssignedBorrowers = applications.length;
      const numberOfContactedBorrowers = applications.filter((application) => application.status === 'Contacted').length;

      dispatch(setAssignedBorrowerCount({ numberOfAssignedBorrowers }));
      dispatch(setContactedBorrowerCount({ numberOfContactedBorrowers }));
    }

    applications.forEach((application, applicationIndex) => {
      const { borrower, plaidItems } = application;
      const mostRecentDecision = application.decisions.items.sort((decisionA, decisionB) => new Date(decisionB.createdAt) - new Date(decisionA.createdAt))[0];

      if (mostRecentDecision) {
        mostRecentDecision.offers = mostRecentDecision.isQualified ? mostRecentDecision.offers.items : [];
      }

      applications[applicationIndex].borrower.alerts = borrower.alerts.items;
      applications[applicationIndex].decision = mostRecentDecision || {};

      // plaidItem.isConnected === null signifies that the user connected a bank account before the isConnected field was added. We are assuming the
      // account is still connected unless we are notified by the Plaid Error Webhook.
      applications[applicationIndex].hasConnectedAccount = plaidItems.items.some((plaidItem) => plaidItem.isConnected || plaidItem.isConnected === null);

      // We don't need these, and we can only store so much, so we should just delete them
      delete applications[applicationIndex].decisions;
      delete applications[applicationIndex].plaidItems;
    });

    dispatch({ applications, type: GET_APPLICATIONS_FOR_SERVICE_PORTAL_SUCCEEDED });
  } catch (error) {
    console.log('error getting applications for service portal:', error);

    dispatch({ type: GET_APPLICATIONS_FOR_SERVICE_PORTAL_FAILED });
  }
};

const saveApplication = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_APPLICATION });

  const state = getState();

  const application = state.application.data;
  const borrowerId = state.borrower.data.id;
  const spouseId = state.spouse.data.id;

  if (state.application.hasDependents && !application.numberOfDependentsInHousehold) {
    application.numberOfDependentsInHousehold = 0;
  }

  if (state.application.data.isHomeowner === false) {
    application.hasMortgageEscrow = false;
  }

  const input = {
    applicationBorrowerId: borrowerId,
    applicationSpouseId: spouseId,
    agreedToActionPlan: application.agreedToActionPlan,
    assignedTo: application.assignedTo,
    assignedToAt: application.assignedToAt,
    docusignEnvelopeId: application.docusignEnvelopeId,
    id: application.id,
    isCarOwner: application.isCarOwner,
    isHomeowner: application.isHomeowner,
    hasServedInMilitary: application.hasServedInMilitary,
    hasSpouse: application.hasSpouse,
    hasLargeMedicalOrDisabilityExpenses: application.hasLargeMedicalOrDisabilityExpenses,
    hasMortgageEscrow: application.hasMortgageEscrow,
    hasRecentDeathInImmediateFamily: application.hasRecentDeathInImmediateFamily,
    hasRecentJobLoss: application.hasRecentJobLoss,
    hasRecentlyBeenFurloughed: application.hasRecentlyBeenFurloughed,
    hasReductionInIncome: application.hasReductionInIncome,
    kindOfRelief: application.kindOfRelief,
    lastCompletedPage: application.lastCompletedPage,
    lastViewedPage: application.lastViewedPage,
    monthlyRentOrMortgageAmount: application.monthlyRentOrMortgageAmount,
    numberOfDependentsInHousehold: application.numberOfDependentsInHousehold,
    reasonForNotConnectingBank: application.reasonForNotConnectingBank,
    router: application.router,
    selfReportedMonthlyCurrentIncome: application.selfReportedMonthlyCurrentIncome,
    status: application.status,
    totalBackTaxesAmount: application.totalBackTaxesAmount,
    totalDebtToFriendsAndFamilyAmount: application.totalDebtToFriendsAndFamilyAmount,
    totalMedicalDebtAmount: application.totalMedicalDebtAmount,
    totalOtherDebtAmount: application.totalOtherDebtAmount,
    type: application.type,
    utmCampaign: application.utmCampaign,
    utmContent: application.utmContent,
    utmMedium: application.utmMedium,
    utmSource: application.utmSource,
    utmTerm: application.utmTerm,
    verifiedMonthlyCurrentIncome: application.verifiedMonthlyCurrentIncome,
  };

  const saveOperation = application.id ? updateApplication : createApplication;

  try {
    const saveApplicationResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const applicationId = application.id || saveApplicationResponse.data.createApplication.id;

    dispatch({ applicationId, type: SAVE_APPLICATION_SUCCESS });

    if (saveOperation === createApplication) {
      try {
        await API.graphql(graphqlOperation(updateBorrower, { input: { id: borrowerId, borrowerApplicationId: applicationId } }));
      } catch (error) {
        console.log('error saving application to borrower', error);
      }
    }
  } catch (error) {
    console.log('saveApplicationFailure was called with:', error);

    dispatch({ type: SAVE_APPLICATION_FAIL });
  }
};

const saveLastCompletedAndLastViewedPage = ({ lastCompletedPage, lastViewedPage }) => async (dispatch, getState) => {
  dispatch({ type: SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE });

  const state = getState();

  const application = state.application.data;
  const borrowerId = state.borrower.data.id;
  const spouseId = state.spouse.data.id;

  const input = {
    applicationBorrowerId: borrowerId,
    applicationSpouseId: spouseId,
    id: application.id,
    lastCompletedPage,
    lastViewedPage,
    router: application.router,
    type: application.type,
  };

  const saveOperation = application.id ? updateApplication : createApplication;

  try {
    const saveLastCompletedPageResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const applicationId = application.id || saveLastCompletedPageResponse.data.createApplication.id;

    dispatch({ applicationId, lastCompletedPage, type: SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_SUCCESS });
  } catch (error) {
    console.log('failed to save lastCompletedPage and lastViewedPage:', error);

    dispatch({ type: SAVE_LAST_COMPLETED_AND_LAST_VIEWED_PAGE_FAIL });
  }
};

const setAgreedToTerms = ({ agreedToTerms }) => ({ agreedToTerms, type: SET_AGREED_TO_TERMS });

const setHasDependents = () => ({ type: SET_HAS_DEPENDENTS });

const setHasProvidedMonthlyIncome = () => ({ type: SET_HAS_PROVIDED_MONTHLY_INCOME });

const setHasSubmittedPrimaryApplication = () => ({ type: SET_HAS_SUBMITTED_PRIMARY_APPLICATION });

const setOtherReasonForNotConnectingAccount = ({ value }) => ({ type: SET_OTHER_REASON_FOR_NOT_CONNECTING_ACCOUNT, value });

const setSaveApplicationSucceeded = () => ({ type: SET_SAVE_APPLICATION_SUCCEEDED });

const setUtmCampaignParameters = ({
  utmCampaign,
  utmContent,
  utmMedium,
  utmSource,
  utmTerm,
}) => ({
  type: SET_UTM_CAMPAIGN_PARAMETERS,
  utmCampaign,
  utmContent,
  utmMedium,
  utmSource,
  utmTerm,
});

const unassignApplicationFromUser = ({ applicationId }) => async () => {
  try {
    await API.graphql(graphqlOperation(updateApplication, {
      input: {
        assignedTo: null,
        assignedToAt: null,
        id: applicationId,
      },
    }));
  } catch (error) {
    console.log(`failed to unassign Application ID ${applicationId}`, error);
  }
};

const updateApplicationInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_APPLICATION,
  value,
});

export {
  assignApplicationToUser,
  clearHasDependents,
  clearSaveApplicationSucceeded,
  getApplicationInformation,
  getApplicationInformationForUser as getApplicationForUser,
  getApplicationsForServicePortal,
  saveApplication,
  saveLastCompletedAndLastViewedPage,
  setAgreedToTerms,
  setHasDependents,
  setHasProvidedMonthlyIncome,
  setHasSubmittedPrimaryApplication,
  setOtherReasonForNotConnectingAccount,
  setSaveApplicationSucceeded,
  setUtmCampaignParameters,
  unassignApplicationFromUser,
  updateApplicationInformation as updateApplication,
};
