import React from 'react';
import AddressLine1TextInput from '../common/AddressFields/AddressLine1TextInput';

const SpouseAddressLine1TextInput = ({ errors, onBlur, onChange }) => (
  <AddressLine1TextInput
    errors={errors}
    id="spouse-address-line-1-input"
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseAddressLine1TextInput"
  />
);

export default SpouseAddressLine1TextInput;
