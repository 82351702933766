import React from 'react';
import { useSelector } from 'react-redux';
import {
  calculateTotalUnsecuredDebt,
  getUniqueUnsecuredDebts,
} from '../../../redux/actions/debtsActions';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import FinancialSummaryCalander from '../FinancialSummaryCalendar/FinancialSummaryCalendar';

const MonthlyAndTotalDebtCalendar = () => {
  const creditReports = useSelector((state) => state.debts.creditReports);
  const decision = useSelector((state) => state.decision.data);
  const mostRecentCreditReport = creditReports.sort((creditReportA, creditReportB) => new Date(creditReportB) - new Date(creditReportA))[0];
  const debts = mostRecentCreditReport && mostRecentCreditReport.debts;
  const mostRecentTotalUnsecuredDebt = debts ? calculateTotalUnsecuredDebt({ debts: mostRecentCreditReport.debts }) : 0;
  const monthlyDebtPayment = decision
    ? formatNumberAsCurrency({ allowZero: true, number: Math.round(decision.proposedUnsecuredDebtPayment) })
    : 'N/A';

  const monthlyDebtsDataSet = creditReports.map((creditReport) => ({ date: creditReport.createdAt, value: decision.proposedUnsecuredDebtPayment }));
  const totalDebtsDataSet = creditReports.map((creditReport) => {
    const creditReportDebts = getUniqueUnsecuredDebts({ debts: creditReport.debts });
    const totalUnsecuredDebtForCreditReport = calculateTotalUnsecuredDebt({ debts: creditReportDebts });

    return { date: creditReport.createdAt, value: totalUnsecuredDebtForCreditReport };
  });

  return (
    <div>
      <div className="flex justify-between w-1/2 mx-auto">
        <div className="pt-4 pb-12">
          <div className="value financial-summary-value color-white">
            {monthlyDebtPayment}
          </div>
          <div className="text-sm">
            Monthly Debt Payment
          </div>
        </div>
        <div className="pt-4 pb-12">
          <div className="value financial-summary-value color-red">
            {formatNumberAsCurrency({ allowZero: true, number: mostRecentTotalUnsecuredDebt })}
          </div>
          <div className="text-sm">
            Total Debts
          </div>
        </div>
      </div>
      <div className="pt-6">
        <FinancialSummaryCalander
          dataSet1={monthlyDebtsDataSet}
          dataSet2={totalDebtsDataSet}
          dataSet1Color="white"
          dataSet2Color="red"
        />
      </div>
    </div>
  );
};

export default MonthlyAndTotalDebtCalendar;
