import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveLastCompletedAndLastViewedPage, setHasProvidedMonthlyIncome } from '../../../../redux/actions/applicationActions';
import CurrentMonthlyIncomeInput from './CurrentMonthlyIncomeInput';
import Form from '../../../../components/application/common/ApplicationForm/Form';
import ProgressBar from '../../../../components/application/common/ProgressBar';
import SubmitInput from '../../../../components/application/common/SubmitInput';
import useForm from '../../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../../components/application/common/ApplicationForm/validator';
import { updateCurrentPhoneNumber } from '../../../../redux/actions/customerServiceActions';
import { getCreditCounselingAgencyPostLeadPhoneNumber } from '../../../../helpers/creditCounselingAgencyHelper';
import { getExpenseEstimates } from '../../../../redux/actions/expensesActions';

const ProvideMonthlyIncome = () => {
  const { t: translate } = useTranslation();
  const applicationType = useSelector((state) => state.application.data.type);

  const noteMessage = applicationType === 'rental'
    ? `${translate('providemonthlyincome:noteMessage')}`
    : 'Note: If you are currently receiving unemployment, social security, or disability benefits, please include them as part of your income.';

  const dispatch = useDispatch();
  const { errors, onApplicationChange, onSubmit } = useForm({ validate });
  const hasGottenExpenseEstimates = useSelector((state) => state.expenses.hasGottenExpenseEstimates);
  const history = useHistory();
  const isPullingCredit = useSelector((state) => state.transunion.isPullingCredit);
  const isSaving = useSelector((state) => state.application.isSaving);
  const router = useSelector((state) => state.application.data.router);
  const saveApplicationSucceeded = useSelector((state) => state.application.saveSuccess);

  const postLeadPhoneNumber = getCreditCounselingAgencyPostLeadPhoneNumber({ router });

  useEffect(() => {
    dispatch(updateCurrentPhoneNumber(postLeadPhoneNumber));
  }, [
    dispatch,
    postLeadPhoneNumber,
  ]);

  useEffect(() => {
    if (isSaving) {
      return;
    }

    if (saveApplicationSucceeded) {
      dispatch(setHasProvidedMonthlyIncome());

      if (hasGottenExpenseEstimates === undefined) {
        if (isPullingCredit) {
          return;
        }

        dispatch(getExpenseEstimates());
      }

      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'ProvideMonthlyIncome', lastViewedPage: 'Expenses' }));

      history.push('/application/expenses');
    }
  });

  return (
    <div>
      <div className="provide-monthly-income leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pb-20 text-center w-full">
          <ProgressBar pageName="provideMonthlyIncome" />
          <h2 className="header-line-height text-xl sm:text-2xl w-full sm:w-1/2 px-8 sm:px-0 m-auto pt-5">
            {translate('providemonthlyincome:title')}
          </h2>
          <div>
            <div>
              <Form onSubmit={onSubmit}>
                <div className="text-left form-width mx-auto income-summary">
                  <div className="amount">
                    <CurrentMonthlyIncomeInput
                      errors={errors}
                      onChange={onApplicationChange}
                      amountDescription={translate('providemonthlyincome:amountDescription')}
                    />
                  </div>
                </div>
                <SubmitInput text={translate('global:submit:text')} />
              </Form>
            </div>
            <p className="sm:w-1/2 w-full form-width mt-6 mx-auto">
              <span className="font-bold">{noteMessage}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvideMonthlyIncome;
