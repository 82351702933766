import React, { useRef, useState } from 'react';
import { startCase } from 'lodash';
import CurrencyOfferDetail from '../../../../pages/offers/OfferDetail/CurrencyOfferDetail';
import LenderLogo from '../../LenderLogo/LenderLogo';
import OfferDetailNoFormat from '../../../../pages/offers/OfferDetail/OfferDetailNoFormat';
import useOutsideClick from '../../../../hooks/useOutsideClick/useOutsideClick';
import ValueDetail from '../../../../pages/offers/OfferDetail/ValueDetail';
import sharedDebtIcon from '../../../../assets/icons/shared-debt_icon.svg';

const DebtSummaryTile = (
  {
    borrowerName,
    debt,
    spouseName,
  },
) => {
  const showDetailsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const debtIsSharedIcon = debt.shared ? sharedDebtIcon : undefined;
  const minimumMonthlyPayment = Math.round(debt.scheduledMonthlyPayment || debt.estimatedMonthlyPayment);
  const sharedDebtPrimaryOwner = debt.ecoaDesignator === 'jointContractLiability' ? `${borrowerName} & ${spouseName}` : borrowerName;

  useOutsideClick(showDetailsRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="leading-normal tracking-normal">
      <div className="debts-container lender-tile-animation-1">
        <div className="debt-summary-tile mb-2">
          <div className="pl-2 w-1/2 inline-block">
            <LenderLogo
              lenderFriendlyName={debt.lenderFriendlyName}
              lenderName={debt.lenderName}
            />
            <div className="account-number-position">
              <div>
                <OfferDetailNoFormat
                  name="Account Number"
                  value={((debt.accountNumber) || 'N/A')}
                />
              </div>
            </div>
          </div>
          <div className="covid-offers w-1/2 pr-2 inline-block">
            <div className="name-detail">
              Current Balance
            </div>
            <div className="value">
              <ValueDetail
                icon={debtIsSharedIcon}
                name="Current Balance"
                value={((debt.currentBalance === 0 || debt.currentBalance) ? debt.currentBalance : 'N/A')}
              />
            </div>
          </div>
          <div className="lender-details pb-2 -mt-2">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p className="cursor-pointer text-right details-toggle color-blue pr-2" onClick={toggle} onKeyDown={toggle} ref={showDetailsRef}>
              {isOpen ? <span>Hide details</span> : <span>Show details</span>}
            </p>
            <div role="button" tabIndex="0" className={isOpen ? 'details active lender-tile-animation-1' : 'details'}>
              <div className="w-full pt-2">
                <OfferDetailNoFormat
                  name="Portfolio Type"
                  value={(startCase(debt.portfolioType) || 'N/A')}
                />
                {debt.shared && (debt.ecoaDesignator === 'primary' || debt.ecoaDesignator === 'jointContractLiability') && (
                  <OfferDetailNoFormat
                    name="Primary Owner"
                    value={sharedDebtPrimaryOwner}
                  />
                )}
                <OfferDetailNoFormat
                  name="Date Opened"
                  value={((debt.dateOpened === 0 || debt.dateOpened) ? debt.dateOpened : 'N/A')}
                />
                <OfferDetailNoFormat
                  name="Date Closed"
                  value={((debt.dateClosed === 0 || debt.dateClosed) ? debt.dateClosed : 'N/A')}
                />
                <CurrencyOfferDetail
                  name="High Credit"
                  value={((debt.highCredit === 0 || debt.highCredit) ? debt.highCredit : 'N/A')}
                />
                <CurrencyOfferDetail
                  name="Past Due"
                  value={((debt.pastDue === 0 || debt.pastDue) ? debt.pastDue : 'N/A')}
                />
                <CurrencyOfferDetail
                  name="Min. Monthly Payment"
                  value={((minimumMonthlyPayment === 0 || minimumMonthlyPayment) ? minimumMonthlyPayment : 'N/A')}
                />
                <OfferDetailNoFormat
                  name="Most Recent Payment Date"
                  value={debt.mostRecentPayment || 'N/A'}
                />
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtSummaryTile;
