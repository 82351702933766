import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getIncomePredictions } from './incomeActions';
import {
  getBorrowerWithPlaidItems,
  getBorrowerWithPlaidAccountBalances,
} from '../../graphql/customQueries';
import { onUpdatePlaidItem } from '../../graphql/subscriptions';

export const CONNECT_SUCCESS = 'CONNECT_SUCCESS';
export const GET_PLAID_ACCOUNT_BALANCES = 'GET_PLAID_ACCOUNT_BALANCES';
export const GET_PLAID_ACCOUNT_BALANCES_FAIL = 'GET_PLAID_ACCOUNT_BALANCES_FAIL';
export const GET_PLAID_ACCOUNT_BALANCES_SUCCESS = 'GET_PLAID_ACCOUNT_BALANCES_SUCCESS';
export const GET_PLAID_ITEMS = 'GET_PLAID_ITEMS';
export const GET_PLAID_ITEMS_FAIL = 'GET_PLAID_ITEMS_FAIL';
export const GET_PLAID_ITEMS_SUCCESS = 'GET_PLAID_ITEMS_SUCCESS';
export const GET_ALL_PLAID_TRANSACTIONS_FOR_USER = 'GET_ALL_PLAID_TRANSACTIONS_FOR_USER';
export const GET_ALL_PLAID_TRANSACTIONS_FOR_USER_FAIL = 'GET_ALL_PLAID_TRANSACTIONS_FOR_USER_FAIL';
export const GET_ALL_PLAID_TRANSACTIONS_FOR_USER_SUCCESS = 'GET_ALL_PLAID_TRANSACTIONS_FOR_USER_SUCCESS';
export const SAVE_PLAID_ITEM = 'SAVE_PLAID_ITEM';
export const SAVE_PLAID_ITEM_FAIL = 'SAVE_PLAID_ITEM_FAIL';
export const SAVE_PLAID_ITEM_SUCCESS = 'SAVE_PLAID_ITEM_SUCCESS';
export const SET_SHOW_CONNECT_ACCOUNT_MODAL = 'SET_SHOW_CONNECT_ACCOUNT_MODAL';
export const SET_PLAID_ITEMS = 'SET_PLAID_ITEMS';
export const SUBSCRIBING = 'SUBSCRIBING';
export const SUBSCRIBING_FAIL = 'SUBSCRIBING_FAIL';
export const SUBSCRIBING_SUCCESS = 'SUBSCRIBING_SUCCESS';
export const UPDATE_PLAID_LIST = 'UPDATE_PLAID_LIST';
export const UPDATE_PLAID_ITEM_SUBSCRIPTIONS = 'UPDATE_PLAID_ITEM_SUBSCRIPTIONS';

const getPlaidItems = () => async (dispatch, getState) => {
  dispatch({ type: GET_PLAID_ITEMS });

  const state = getState();
  const borrowerId = state.borrower.data.id;

  try {
    const plaidItemsResponse = await API.graphql(graphqlOperation(getBorrowerWithPlaidItems, { borrowerId }));
    const plaidItems = plaidItemsResponse.data.getBorrower.plaidItems.items;

    dispatch({ plaidItems, type: GET_PLAID_ITEMS_SUCCESS });
  } catch (getPlaidItemsError) {
    console.log('getPlaidItemsError:', getPlaidItemsError);

    dispatch({ type: GET_PLAID_ITEMS_FAIL });
  }
};

const getPlaidAccountBalances = ({ accountSubType, balancesFilter = {} } = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_PLAID_ACCOUNT_BALANCES });

  const state = getState();
  const borrowerId = state.borrower.data.id;

  try {
    const plaidAccountBalancesResponse = await API.graphql(graphqlOperation(getBorrowerWithPlaidAccountBalances, { borrowerId, balancesFilter }));
    const plaidItems = plaidAccountBalancesResponse.data.getBorrower.plaidItems.items;

    const plaidAccounts = accountSubType
      ? plaidItems
        .flatMap((plaidItem) => plaidItem.plaidAccounts.items)
        .filter((plaidAccount) => plaidAccount.subType === accountSubType)
      : plaidItems.flatMap((plaidItem) => plaidItem.plaidAccounts.items);

    const plaidAccountBalances = plaidAccounts.flatMap((plaidAccount) => plaidAccount.balances.items);

    dispatch({ plaidAccountBalances, type: GET_PLAID_ACCOUNT_BALANCES_SUCCESS });
  } catch (getPlaidAccountBalancesError) {
    console.log('getPlaidAccountBalancesError:', getPlaidAccountBalancesError);

    dispatch({ type: GET_PLAID_ACCOUNT_BALANCES_FAIL });
  }
};

const savePlaidItemFail = ({ error }) => {
  console.log('savePlaidItemFail was called with:', error);

  return {
    type: SAVE_PLAID_ITEM_FAIL,
  };
};

const savePlaidItemSuccess = ({ response }) => async (dispatch, getState) => {
  dispatch({ type: SAVE_PLAID_ITEM_SUCCESS });

  if (!response || !response[0]) {
    console.log('could not subscribe: Plaid Item response was empty');

    dispatch({ type: SUBSCRIBING_FAIL });

    return;
  }

  const { plaidItemId } = response[0];
  const state = getState();
  const { username } = state.user.data;

  dispatch({ type: SUBSCRIBING });

  const subscription = await API.graphql(graphqlOperation(onUpdatePlaidItem, { id: plaidItemId, owner: username }))
    .subscribe({
      next: (data) => {
        if (data.value.data.onUpdatePlaidItem.isFinishedHistoricalUpdate) {
          dispatch(getIncomePredictions());

          const plaidItemSubscription = getState().plaid.plaidItemSubscriptions[plaidItemId];

          plaidItemSubscription.unsubscribe();

          dispatch({ type: SUBSCRIBING_SUCCESS });
        }
      },
      error: (subscriptionErrorResponse) => {
        if (subscriptionErrorResponse && subscriptionErrorResponse.error && subscriptionErrorResponse.error.errors) {
          console.log('subscription errors:', subscriptionErrorResponse.error.errors.map((error) => error.message));
        } else {
          console.log('subscription error:', (subscriptionErrorResponse && subscriptionErrorResponse.error) || subscriptionErrorResponse);
        }

        dispatch({ type: SUBSCRIBING_FAIL });
      },
    });

  dispatch({ plaidItemId, subscription, type: UPDATE_PLAID_ITEM_SUBSCRIPTIONS });
};

const updatePlaidList = (institution) => ({ institution, type: UPDATE_PLAID_LIST });

const savePlaidItem = ({ metadata }) => async (dispatch, getState) => {
  dispatch({ type: SAVE_PLAID_ITEM });

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();
    const state = getState();

    const plaidItemInstitutionIds = state.plaid.plaidItems.map((plaidItem) => plaidItem.institutionId);
    if (plaidItemInstitutionIds.includes(metadata.institution.institution_id)) {
      return;
    }

    dispatch(updatePlaidList(metadata.institution));

    const response = await API.post('plaid', '/items', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: state.application.data.id,
        institutionId: metadata.institution.institution_id,
        institutionName: metadata.institution.name,
        publicToken: metadata.public_token,
      },
    });

    dispatch(savePlaidItemSuccess({ response }));
  } catch (error) {
    dispatch(savePlaidItemFail({ error }));
  }
};

// eslint-disable-next-line camelcase
const plaidConnectionSuccess = ({ metadata, public_token }) => async (dispatch) => {
  dispatch({ metadata, public_token, type: CONNECT_SUCCESS });

  dispatch(savePlaidItem({ metadata }));
};

const setShowConnectAccountModal = ({ showConnectAccountModal }) => ({ showConnectAccountModal, type: SET_SHOW_CONNECT_ACCOUNT_MODAL });

export {
  getPlaidItems,
  getPlaidAccountBalances,
  plaidConnectionSuccess,
  setShowConnectAccountModal,
  updatePlaidList,
};
