import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const StudentLoanDebtCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <CheckboxInput
      id="student-loan-debt-checkbox"
      label={translate('lifeevents:studentLoanDebt')}
      loadFrom="hardships"
      name="hasStudentLoans"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default StudentLoanDebtCheckbox;
