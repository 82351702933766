import React from 'react';
import { Link } from 'react-router-dom';

const CallToActionButton = ({ buttonLabel = 'Get Started Now', dataCy = 'app-about-you', to = '/application/about-you' }) => (
  <Link data-cy={dataCy} to={to} className="call-to-action-button px-20 py-3 ml-auto mr-auto text-center block">
    {buttonLabel}
  </Link>
);

export default CallToActionButton;
