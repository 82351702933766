import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NameDetail from '../../../pages/offers/OfferDetail/NameDetail';
import TextInputDetail from '../common/TextInputDetail/TextInputDetail';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const SubCategory = ({
  errors,
  id,
  label,
  name,
  onCancel,
  onChange,
  value,
}) => {
  const { t: translate } = useTranslation();
  const [showEditInput, toggleEditInput] = useState(true);
  const [isAlsoHidden, setIsAlsoHidden] = useState(false);

  return (
    <div className="pb-2">
      <div className="details">
        <ul className="leaders">
          <li className="text-left pb-2">
            <span className="float-left">
              <NameDetail name={name} />
            </span>
            {!isAlsoHidden && (
            <span className="float-right">
              <ValueDetail value={value} />
            </span>
            )}
            {isAlsoHidden && (
              <span>
                <TextInputDetail
                  ariaLabel={`${name}-currency-input`}
                  errors={errors}
                  id={id}
                  label={label}
                  name={name}
                  onChange={onChange}
                  value={value}
                />
              </span>
            )}
          </li>
        </ul>
      </div>
      <div
        role="button"
        tabIndex="0"
        onClick={() => { toggleEditInput(!showEditInput); setIsAlsoHidden(!isAlsoHidden); }}
        onKeyDown={() => { toggleEditInput(!showEditInput); setIsAlsoHidden(!isAlsoHidden); }}
      >
        {!isAlsoHidden && (
          <span
            aria-label={`${name}-currency-input-edit`}
            className="edit-value"
            data-cy="edit-lineitem"
          >
            {translate('global:edit')}
          </span>
        )}
        {isAlsoHidden && (
          <span
            aria-label={`${name}-currency-input-cancel`}
            className="edit-value cancel"
            data-cy="cancel-lineitem-edit"
            onClick={onCancel}
            onKeyDown={onCancel}
            role="button"
            tabIndex="0"
          >
            {translate('global:cancel')}
          </span>
        )}
      </div>
    </div>
  );
};

export default SubCategory;
