import React from 'react';

const GradientArrow = ({
  startColor,
  endColor,
}) => (
  <div className="w-full relative">
    <svg viewBox="0 0 350 50" className="absolute" height="100%" width="100%" preserveAspectRatio="none">
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
          fill={endColor}
        >
          <polygon points="0 0, 10 3.5, 0 7" />
        </marker>
        <linearGradient id="linear-gradient" x1="0%" y1="25" x2="240" y2="25" gradientUnits="userSpaceOnUse">
          <stop stopColor={startColor} offset="0" />
          <stop stopColor={startColor} offset=".5" />
          <stop stopColor={endColor} offset="1" />
        </linearGradient>
      </defs>
      <line
        x1="5%"
        y1="55%"
        x2="90%"
        y2="55%"
        stroke="url(#linear-gradient)"
        strokeWidth="2"
        markerEnd="url(#arrowhead)"
      />
    </svg>
  </div>
);

export default GradientArrow;
