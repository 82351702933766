import {
  SET_CREDIT_REPORTS,
  SET_DEBTS,
} from '../actions/debtsActions';

const intitialState = {
  creditReports: [],
  data: [
    {
      accountNumber: undefined,
      currentBalance: 0,
      dateClosed: undefined,
      dateOpened: undefined,
      estimatedMonthlyPayment: 0,
      highCredit: 0,
      lenderName: undefined,
      portfolioType: undefined,
      pastDue: 0,
      scheduledMonthlyPayment: 0,
    },
  ],
  hasGottenBorrowerCreditReportsWithDebts: undefined,
  hasGottenBorrowerDebts: undefined,
  isGettingBorrowerCreditReportsWithDebts: undefined,
  isGettingBorrowerDebts: undefined,
};

const debtsReducer = (state = intitialState, action) => {
  switch (action.type) {
    case SET_CREDIT_REPORTS:
      return { ...state, creditReports: action.creditReports || [] };

    case SET_DEBTS:
      return { ...state, data: action.debts || [] };

    default:
      return state;
  }
};

export default debtsReducer;
