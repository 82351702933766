import React from 'react';
import { useDispatch } from 'react-redux';
import { updateContactAttempt } from '../../../../../redux/actions/contactAttemptActions';
import DropdownInput from '../../../../application/common/DropdownInput/DropdownInput';

const WhatWasTheMethodOfContact = () => {
  const dispatch = useDispatch();
  const options = [
    { label: 'Email', value: 'Email' },
    { label: 'Phone', value: 'Phone' },
  ];

  const onSelectValueChange = (event) => {
    dispatch(updateContactAttempt({ propertyName: event.target.name, value: event.target.value }));
  };

  return (
    <DropdownInput
      errors="none"
      id="what-was-the-method-of-contact"
      loadFrom="contactAttempt"
      name="whatWasTheMethodOfContact"
      onChange={onSelectValueChange}
      options={options}
      question="What was the method of contact?"
    />
  );
};

export default WhatWasTheMethodOfContact;
