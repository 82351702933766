import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/ButtonInput/ButtonInput';
import {
  cancelExpenseValueChange,
  changeExpenseValue,
  saveExpenses,
  submitExpenseValueChange,
} from '../../../redux/actions/expensesActions';
import { getBorrowerAndSpouseRiskScores } from '../../../redux/actions/riskActions';
import { getDecision } from '../../../redux/actions/decisionActions';
import { getProposedBudgets } from '../../../redux/actions/budgetsActions';
import ProgressBar from '../../../components/application/common/ProgressBar';
import { pullCredit } from '../../../redux/actions/transunionActions';
import ReviewTile from '../../../components/application/ReviewTile';
import { saveApplication, saveLastCompletedAndLastViewedPage, updateApplication } from '../../../redux/actions/applicationActions';
import SubCategory from '../../../components/application/SubCategory';

const Expenses = (
  {
    errorPage = 'Pending',
    errorPageRoute = '/offers/pending',
    nextPage = 'BankConnection',
    nextPageRoute = '/application/bank-connection',
  },
) => {
  const { t: translate } = useTranslation();
  const application = useSelector((state) => state.application.data);
  const borrowerCreditReportId = useSelector((state) => state.transunion.data.borrowerCreditReportId);
  const dispatch = useDispatch();
  const expenseEstimates = useSelector((state) => state.expenses.data.expenseEstimates);
  const getDecisionFailed = useSelector((state) => state.decision.getDecisionFailed);
  const getDecisionSucceeded = useSelector((state) => state.decision.getDecisionSucceeded);
  const getExpenseEstimatesFailed = useSelector((state) => state.expenses.getExpenseEstimatesFailed);
  const getRiskScoresFailed = useSelector((state) => state.risk.getRiskScoresFailed);
  const getRiskScoresSucceeded = useSelector((state) => state.risk.getRiskScoresSucceeded);
  const hasPulledCredit = useSelector((state) => state.transunion.hasPulledCredit);
  const history = useHistory();
  const isGettingDecision = useSelector((state) => state.decision.isGettingDecision);
  const isGettingRiskScores = useSelector((state) => state.risk.isGettingRiskScores);
  const isPullingCredit = useSelector((state) => state.transunion.isPullingCredit);
  const pullCreditFailed = useSelector((state) => state.transunion.hasPulledCredit === false);
  const pullCreditSucceeded = useSelector((state) => state.transunion.hasPulledCredit === true);
  const saveExpensesSucceeded = useSelector((state) => state.expenses.saveExpensesSucceeded);
  const saveExpensesFailed = useSelector((state) => state.expenses.saveExpensesFailed);
  const spouseCreditReportId = useSelector((state) => state.transunion.data.spouseCreditReportId);

  const alimony = expenseEstimates.miscellaneous.alimony.value;
  const backTaxes = expenseEstimates.otherDebts.backtaxes.value;
  const cableInternetLandline = expenseEstimates.utilities.cableInternetLandline.value;
  const carInsurance = expenseEstimates.transportation.insurance.value;
  const carPayment = expenseEstimates.transportation.carPayment.value;
  const carRepairs = expenseEstimates.transportation.maintenanceRepairs.value;
  const childcare = expenseEstimates.miscellaneous.childcare.value;
  const childSupport = expenseEstimates.miscellaneous.childSupport.value;
  const clothing = expenseEstimates.personal.apparel.value;
  const donations = expenseEstimates.savings.donations.value;
  const education = expenseEstimates.miscellaneous.education.value;
  const electricity = expenseEstimates.utilities.electricity.value;
  const entertainment = expenseEstimates.personal.entertainment.value;
  const friendsAndFamily = expenseEstimates.otherDebts.friendsFamily.value;
  const gas = expenseEstimates.utilities.gas.value;
  const gasAndTolls = expenseEstimates.transportation.gas.value;
  const groceries = expenseEstimates.food.home.value;
  const healthInsurance = expenseEstimates.healthcare.healthInsurance.value;
  const hoaFees = expenseEstimates.housing.hoa.value;
  const homeownersInsurance = expenseEstimates.housing.insurance.value;
  const homeRepairs = expenseEstimates.housing.maintenanceImprovements.value;
  const lifeInsurance = expenseEstimates.healthcare.lifeInsurance.value;
  const medicalDebt = expenseEstimates.otherDebts.medical.value;
  const mortgage = expenseEstimates.housing.mortgage.value;
  const otherDebt = expenseEstimates.otherDebts.other.value;
  const otherMiscellaneous = expenseEstimates.miscellaneous.other.value;
  const personalCare = expenseEstimates.personal.personalCare.value;
  const petcare = expenseEstimates.miscellaneous.pets.value;
  const prescriptionDrugs = expenseEstimates.healthcare.drugs.value;
  const propertyTaxes = expenseEstimates.housing.taxes.value;
  const publicTransportation = expenseEstimates.transportation.publicTransportation.value;
  const rent = expenseEstimates.housing.rent.value;
  const rentersInsurance = expenseEstimates.housing.insurance.value;
  const restaurants = expenseEstimates.food.away.value;
  const savings = expenseEstimates.savings.savings.value;
  const servicesAndAppointments = expenseEstimates.healthcare.medicalServices.value;
  const studentLoanPayment = expenseEstimates.studentLoans.studentLoans.value;
  const water = expenseEstimates.utilities.water.value;
  const wirelessPhone = expenseEstimates.utilities.wireless.value;

  const onCancelExpenseChange = ({ category, subCategory }) => {
    dispatch(cancelExpenseValueChange({ category, subCategory }));
  };

  const onCancelFoodExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'food', subCategory });
  };

  const onCancelHousingExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'housing', subCategory });
  };

  const onCancelMedicalExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'healthcare', subCategory });
  };

  const onCancelMiscellaneousExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'miscellaneous', subCategory });
  };

  const onCancelOtherDebtsExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'otherDebts', subCategory });
  };

  const onCancelPersonalExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'personal', subCategory });
  };

  const onCancelSavingsAndDonationsExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'savings', subCategory });
  };

  const onCancelStudentLoanPaymentExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'studentLoans', subCategory });
  };

  const onCancelTransportationExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'transportation', subCategory });
  };

  const onCancelUtilitiesExpenseChange = ({ subCategory }) => {
    onCancelExpenseChange({ category: 'utilities', subCategory });
  };

  const onCancelAllFoodExpensesChanges = () => {
    onCancelFoodExpenseChange({ subCategory: 'away' });
    onCancelFoodExpenseChange({ subCategory: 'home' });
  };

  const onCancelAllHousingExpensesChanges = () => {
    if (application.isHomeowner) {
      onCancelHousingExpenseChange({ subCategory: 'hoa' });
      onCancelHousingExpenseChange({ subCategory: 'insurance' });
      onCancelHousingExpenseChange({ subCategory: 'maintenanceImprovements' });
      onCancelHousingExpenseChange({ subCategory: 'mortgage' });
      onCancelHousingExpenseChange({ subCategory: 'taxes' });
    } else {
      onCancelHousingExpenseChange({ subCategory: 'insurance' });
      onCancelHousingExpenseChange({ subCategory: 'rent' });
    }
  };

  const onCancelAllMedicalExpensesChanges = () => {
    onCancelMedicalExpenseChange({ subCategory: 'drugs' });
    onCancelMedicalExpenseChange({ subCategory: 'healthInsurance' });
    onCancelMedicalExpenseChange({ subCategory: 'lifeInsurance' });
    onCancelMedicalExpenseChange({ subCategory: 'medicalServices' });
  };

  const onCancelAllMiscellaneousExpensesChanges = () => {
    onCancelMiscellaneousExpenseChange({ subCategory: 'alimony' });
    onCancelMiscellaneousExpenseChange({ subCategory: 'childcare' });
    onCancelMiscellaneousExpenseChange({ subCategory: 'childSupport' });
    onCancelMiscellaneousExpenseChange({ subCategory: 'education' });
    onCancelMiscellaneousExpenseChange({ subCategory: 'other' });
    onCancelMiscellaneousExpenseChange({ subCategory: 'pets' });
  };

  const onCancelAllOtherDebtsExpensesChanges = () => {
    onCancelOtherDebtsExpenseChange({ subCategory: 'backtaxes' });
    onCancelOtherDebtsExpenseChange({ subCategory: 'friendsFamily' });
    onCancelOtherDebtsExpenseChange({ subCategory: 'medical' });
    onCancelOtherDebtsExpenseChange({ subCategory: 'other' });
  };

  const onCancelAllPersonalExpensesChanges = () => {
    onCancelPersonalExpenseChange({ subCategory: 'entertainment' });
    onCancelPersonalExpenseChange({ subCategory: 'apparel' });
    onCancelPersonalExpenseChange({ subCategory: 'personalCare' });
  };

  const onCancelAllSavingsAndDonationsExpensesChanges = () => {
    onCancelSavingsAndDonationsExpenseChange({ subCategory: 'savings' });
    onCancelSavingsAndDonationsExpenseChange({ subCategory: 'donations' });
  };

  const onCancelAllStudentLoanPaymentExpensesChanges = () => {
    onCancelStudentLoanPaymentExpenseChange({ subCategory: 'studentLoans' });
  };

  const onCancelAllTransportationExpensesChanges = () => {
    onCancelTransportationExpenseChange({ subCategory: 'carPayment' });
    onCancelTransportationExpenseChange({ subCategory: 'gas' });
    onCancelTransportationExpenseChange({ subCategory: 'insurance' });
    onCancelTransportationExpenseChange({ subCategory: 'maintenanceRepairs' });
    onCancelTransportationExpenseChange({ subCategory: 'publicTransportation' });
  };

  const onCancelAllUtilitiesExpensesChanges = () => {
    onCancelUtilitiesExpenseChange({ subCategory: 'cableInternetLandline' });
    onCancelUtilitiesExpenseChange({ subCategory: 'electricity' });
    onCancelUtilitiesExpenseChange({ subCategory: 'gas' });
    onCancelUtilitiesExpenseChange({ subCategory: 'water' });
    onCancelUtilitiesExpenseChange({ subCategory: 'wireless' });
  };

  const onChangeExpense = ({ category, subCategory, value }) => {
    dispatch(changeExpenseValue({ category, subCategory, value }));
  };

  const onChangeFoodExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'food', subCategory, value });
  };

  const onChangeHousingExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'housing', subCategory, value });
  };

  const onChangeMedicalExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'healthcare', subCategory, value });
  };

  const onChangeMiscellaneousExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'miscellaneous', subCategory, value });
  };

  const onChangeOtherDebtsExpense = ({ subCategory, value }) => {
    const subCategoryToApplicationPropertyNameMap = {
      backtaxes: 'totalBackTaxesAmount',
      friendsFamily: 'totalDebtToFriendsAndFamilyAmount',
      medical: 'totalMedicalDebtAmount',
      other: 'totalOtherDebtAmount',
    };

    dispatch(updateApplication({ propertyName: subCategoryToApplicationPropertyNameMap[subCategory], value }));

    onChangeExpense({ category: 'otherDebts', subCategory, value });
  };

  const onChangePersonalExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'personal', subCategory, value });
  };

  const onChangeSavingsAndDonationsExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'savings', subCategory, value });
  };

  const onChangeStudentLoanPaymentExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'studentLoans', subCategory, value });
  };

  const onChangeTransportationExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'transportation', subCategory, value });
  };

  const onChangeUtilitiesExpense = ({ subCategory, value }) => {
    onChangeExpense({ category: 'utilities', subCategory, value });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(saveExpenses());
  };

  const onSubmitFoodExpensesChanges = () => {
    const category = 'food';

    dispatch(submitExpenseValueChange({ category, subCategory: 'away', value: restaurants }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'home', value: groceries }));
  };

  const onSubmitHousingExpenseChanges = () => {
    const category = 'housing';

    if (application.isHomeowner) {
      dispatch(submitExpenseValueChange({ category, subCategory: 'hoa', value: hoaFees }));
      dispatch(submitExpenseValueChange({ category, subCategory: 'insurance', value: homeownersInsurance }));
      dispatch(submitExpenseValueChange({ category, subCategory: 'maintenanceImprovements', value: homeRepairs }));
      dispatch(submitExpenseValueChange({ category, subCategory: 'mortgage', value: mortgage }));
      dispatch(submitExpenseValueChange({ category, subCategory: 'taxes', value: propertyTaxes }));
    } else {
      dispatch(submitExpenseValueChange({ category, subCategory: 'insurance', value: rentersInsurance }));
      dispatch(submitExpenseValueChange({ category, subCategory: 'rent', value: rent }));
    }
  };

  const onSubmitMedicalExpensesChanges = () => {
    const category = 'healthcare';

    dispatch(submitExpenseValueChange({ category, subCategory: 'drugs', value: prescriptionDrugs }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'healthInsurance', value: healthInsurance }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'lifeInsurance', value: lifeInsurance }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'medicalServices', value: servicesAndAppointments }));
  };

  const onSubmitMiscellaneousExpenses = () => {
    const category = 'miscellaneous';

    dispatch(submitExpenseValueChange({ category, subCategory: 'childcare', value: childcare }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'education', value: education }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'pets', value: petcare }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'childSupport', value: childSupport }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'alimony', value: alimony }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'other', value: otherMiscellaneous }));
  };

  const onSubmitOtherDebtsChanges = () => {
    const category = 'otherDebts';

    dispatch(submitExpenseValueChange({ category, subCategory: 'backtaxes', value: backTaxes }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'friendsFamily', value: friendsAndFamily }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'medical', value: medicalDebt }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'other', value: otherDebt }));
  };

  const onSubmitPersonalExpensesChanges = () => {
    const category = 'personal';

    dispatch(submitExpenseValueChange({ category, subCategory: 'entertainment', value: entertainment }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'apparel', value: clothing }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'personalCare', value: personalCare }));
  };

  const onSubmitSavingsAndDonationsChanges = () => {
    const category = 'savings';

    dispatch(submitExpenseValueChange({ category, subCategory: 'savings', value: savings }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'donations', value: donations }));
  };

  const onSubmitStudentLoanPaymentChanges = () => {
    dispatch(submitExpenseValueChange({ category: 'studentLoans', subCategory: 'studentLoans', value: studentLoanPayment }));
  };

  const onSubmitTransportationExpensesChanges = () => {
    const category = 'transportation';

    dispatch(submitExpenseValueChange({ category, subCategory: 'carPayment', value: carPayment }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'gas', value: gasAndTolls }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'insurance', value: carInsurance }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'maintenanceRepairs', value: carRepairs }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'publicTransportation', value: publicTransportation }));
  };

  const onSubmitUtilitiesChanges = () => {
    const category = 'utilities';

    dispatch(submitExpenseValueChange({ category, subCategory: 'cableInternetLandline', value: cableInternetLandline }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'electricity', value: electricity }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'gas', value: gas }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'water', value: water }));
    dispatch(submitExpenseValueChange({ category, subCategory: 'wireless', value: wirelessPhone }));
  };

  useEffect(() => {
    if (hasPulledCredit === undefined && !isPullingCredit) {
      dispatch(pullCredit());

      return;
    }

    if (getExpenseEstimatesFailed || saveExpensesFailed) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'Expenses', lastViewedPage: errorPage }));

      history.push(errorPageRoute);

      return;
    }

    if (saveExpensesSucceeded && (pullCreditSucceeded || pullCreditFailed)) {
      dispatch(updateApplication({ propertyName: 'lastCompletedPage', value: 'Expenses' }));
      dispatch(updateApplication({ propertyName: 'lastViewedPage', value: nextPage }));
      dispatch(updateApplication({ propertyName: 'status', value: 'Submitted' }));
      dispatch(saveApplication());

      if (pullCreditSucceeded) {
        dispatch(getBorrowerAndSpouseRiskScores({ borrowerCreditReportId, spouseCreditReportId }));
        dispatch(getProposedBudgets());
      }

      if (pullCreditFailed) {
        history.push(nextPageRoute);
      }
    }
  }, [
    borrowerCreditReportId,
    dispatch,
    errorPage,
    errorPageRoute,
    getExpenseEstimatesFailed,
    hasPulledCredit,
    history,
    isPullingCredit,
    nextPage,
    nextPageRoute,
    pullCreditFailed,
    pullCreditSucceeded,
    saveExpensesFailed,
    saveExpensesSucceeded,
    spouseCreditReportId,
  ]);

  useEffect(() => {
    if (isGettingRiskScores) {
      return;
    }

    if (getRiskScoresFailed) {
      history.push(nextPageRoute);

      return;
    }

    if (getRiskScoresSucceeded && isGettingDecision === undefined) {
      dispatch(getDecision());

      return;
    }

    if (getDecisionFailed || getDecisionSucceeded) {
      history.push(nextPageRoute);
    }
  }, [
    dispatch,
    history,
    getDecisionFailed, getDecisionSucceeded, getRiskScoresFailed, getRiskScoresSucceeded,
    isGettingDecision, isGettingRiskScores,
    nextPageRoute,
  ]);

  const homeownerReviewTile = (
    <ReviewTile
      onCancel={onCancelAllHousingExpensesChanges}
      onSubmit={onSubmitHousingExpenseChanges}
      summaryType="Housing Expenses"
      summaryAmount={hoaFees + homeownersInsurance + homeRepairs + mortgage + propertyTaxes}
      tooltipDescription="This includes your Mortgage, Homeowners Insurance, HOA Fees and Property Taxes"
      lineItems={
        [
          <SubCategory
            id="mortgage"
            key="mortgage"
            name="Mortgage"
            onCancel={() => onCancelHousingExpenseChange({ subCategory: 'mortgage' })}
            onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'mortgage', value: valueOverride })}
            value={mortgage}
          />,
          <SubCategory
            id="hoa-fees"
            key="hoa-fees"
            name="HOA Fees"
            onCancel={() => onCancelHousingExpenseChange({ subCategory: 'hoa' })}
            onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'hoa', value: valueOverride })}
            value={hoaFees}
          />,
          !application.hasMortgageEscrow ? (
            <SubCategory
              id="homeowners-insurance"
              key="homeowners-insurance"
              name="Homeowners Insurance"
              onCancel={() => onCancelHousingExpenseChange({ subCategory: 'insurance' })}
              onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'insurance', value: valueOverride })}
              value={homeownersInsurance}
            />
          )
            : undefined,
          !application.hasMortgageEscrow ? (
            <SubCategory
              id="property-taxes"
              key="property-taxes"
              name="Property Taxes"
              onCancel={() => onCancelHousingExpenseChange({ subCategory: 'taxes' })}
              onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'taxes', value: valueOverride })}
              value={propertyTaxes}
            />
          )
            : undefined,
          <SubCategory
            id="maintenance-and-improvements"
            key="maintenance-and-improvements"
            name="Maintenance &amp; Improvements"
            onCancel={() => onCancelHousingExpenseChange({ subCategory: 'maintenanceImprovements' })}
            onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'maintenanceImprovements', value: valueOverride })}
            value={homeRepairs}
          />,
        ]
      }
    />
  );

  const renterReviewTile = (
    <ReviewTile
      onCancel={onCancelAllHousingExpensesChanges}
      onSubmit={onSubmitHousingExpenseChanges}
      summaryType={translate('expenses:housing:title')}
      summaryAmount={rent + rentersInsurance}
      tooltipDescription={translate('expenses:housing:tooltip')}
      lineItems={
        [
          <SubCategory
            id="rent"
            key="rent"
            name={translate('expenses:housing:rent')}
            onCancel={() => onCancelHousingExpenseChange({ subCategory: 'rent' })}
            onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'rent', value: valueOverride })}
            value={rent}
          />,
          <SubCategory
            id="renters-insurance"
            key="renters-insurance"
            name={translate('expenses:housing:rentersInsurance')}
            onCancel={() => onCancelHousingExpenseChange({ subCategory: 'insurance' })}
            onChange={(event, valueOverride) => onChangeHousingExpense({ subCategory: 'insurance', value: valueOverride })}
            value={rentersInsurance}
          />,
        ]
      }
    />
  );

  return (
    <div className="review-application">
      <div className="leading-normal tracking-normal grey-bg">
        <div className="container mx-auto pl-2 pr-2 pb-20 text-center w-full">
          <ProgressBar pageName="expenses" />
          <h2 className="text-xl sm:text-2xl w-full m-auto">
            {translate('expenses:title')}
          </h2>
          <div>
            {translate('expenses:text')}
          </div>
          <div className="review-tile-container application-component-container">
            <div className="lender-tile-animation-1 flex-none lg:flex">
              <div className="lg:w-1/2 z-10">
                {application.isHomeowner ? homeownerReviewTile : renterReviewTile}
                <div className="triple-line">
                  <ReviewTile
                    onCancel={onCancelAllTransportationExpensesChanges}
                    onSubmit={onSubmitTransportationExpensesChanges}
                    summaryType={translate('expenses:transportation:title')}
                    summaryAmount={carPayment + gasAndTolls + carInsurance + carRepairs + publicTransportation}
                    tooltipDescription={translate('expenses:transportation:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="car-payment"
                          key="car-payment"
                          name={translate('expenses:transportation:carPayment')}
                          onCancel={() => onCancelTransportationExpenseChange({ subCategory: 'carPayment' })}
                          onChange={(event, valueOverride) => onChangeTransportationExpense({ subCategory: 'carPayment', value: valueOverride })}
                          value={carPayment}
                        />,
                        <SubCategory
                          id="gas-and-tolls"
                          key="gas-and-tolls"
                          name={translate('expenses:transportation:gasAndTolls')}
                          onCancel={() => onCancelTransportationExpenseChange({ subCategory: 'gas' })}
                          onChange={(event, valueOverride) => onChangeTransportationExpense({ subCategory: 'gas', value: valueOverride })}
                          value={gasAndTolls}
                        />,
                        <SubCategory
                          id="car-insurance"
                          key="car-insurance"
                          name={translate('expenses:transportation:carInsurance')}
                          onCancel={() => onCancelTransportationExpenseChange({ subCategory: 'insurance' })}
                          onChange={(event, valueOverride) => onChangeTransportationExpense({ subCategory: 'insurance', value: valueOverride })}
                          value={carInsurance}
                        />,
                        <SubCategory
                          id="car-maintenance-and-repairs"
                          key="car-maintenance-and-repairs"
                          name={translate('expenses:transportation:carMaintenanceAndRepairs')}
                          onCancel={() => onCancelTransportationExpenseChange({ subCategory: 'maintenanceRepairs' })}
                          onChange={
                            (event, valueOverride) => onChangeTransportationExpense({ subCategory: 'maintenanceRepairs', value: valueOverride })
                          }
                          value={carRepairs}
                        />,
                        <SubCategory
                          id="bus-train-tickets-and-taxi-fares"
                          key="bus-train-tickets-and-taxi-fares"
                          name={translate('expenses:transportation:ticketsAndFares')}
                          onCancel={() => onCancelTransportationExpenseChange({ subCategory: 'publicTransportation' })}
                          onChange={
                            (event, valueOverride) => onChangeTransportationExpense({ subCategory: 'publicTransportation', value: valueOverride })
                          }
                          value={publicTransportation}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="triple-line">
                  <ReviewTile
                    onCancel={onCancelAllUtilitiesExpensesChanges}
                    onSubmit={onSubmitUtilitiesChanges}
                    summaryType={translate('expenses:utilities:title')}
                    summaryAmount={cableInternetLandline + electricity + gas + water + wirelessPhone}
                    tooltipDescription={translate('expenses:utilities:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="cable-internet-and-landline"
                          key="cable-internet-and-landline"
                          name={translate('expenses:utilities:cableInternetAndLandline')}
                          onCancel={() => onCancelUtilitiesExpenseChange({ subCategory: 'cableInternetLandline' })}
                          onChange={
                            (event, valueOverride) => onChangeUtilitiesExpense({ subCategory: 'cableInternetLandline', value: valueOverride })
                          }
                          value={cableInternetLandline}
                        />,
                        <SubCategory
                          id="electricity"
                          key="electricity"
                          name={translate('expenses:utilities:electricity')}
                          onCancel={() => onCancelUtilitiesExpenseChange({ subCategory: 'electricity' })}
                          onChange={(event, valueOverride) => onChangeUtilitiesExpense({ subCategory: 'electricity', value: valueOverride })}
                          value={electricity}
                        />,
                        <SubCategory
                          id="gas"
                          key="gas"
                          name={translate('expenses:utilities:gas')}
                          onCancel={() => onCancelUtilitiesExpenseChange({ subCategory: 'gas' })}
                          onChange={(event, valueOverride) => onChangeUtilitiesExpense({ subCategory: 'gas', value: valueOverride })}
                          value={gas}
                        />,
                        <SubCategory
                          id="water"
                          key="water"
                          name={translate('expenses:utilities:water')}
                          onCancel={() => onCancelUtilitiesExpenseChange({ subCategory: 'water' })}
                          onChange={(event, valueOverride) => onChangeUtilitiesExpense({ subCategory: 'water', value: valueOverride })}
                          value={water}
                        />,
                        <SubCategory
                          id="wireless-phone"
                          key="wireless-phone"
                          name={translate('expenses:utilities:wirelessPhone')}
                          onCancel={() => onCancelUtilitiesExpenseChange({ subCategory: 'wireless' })}
                          onChange={(event, valueOverride) => onChangeUtilitiesExpense({ subCategory: 'wireless', value: valueOverride })}
                          value={wirelessPhone}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="double-line">
                  <ReviewTile
                    onCancel={onCancelAllFoodExpensesChanges}
                    onSubmit={onSubmitFoodExpensesChanges}
                    summaryType={translate('expenses:food:title')}
                    summaryAmount={groceries + restaurants}
                    tooltipDescription={translate('expenses:food:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="groceries"
                          key="groceries"
                          name={translate('expenses:food:groceries')}
                          onCancel={() => onCancelFoodExpenseChange({ subCategory: 'home' })}
                          onChange={(event, valueOverride) => onChangeFoodExpense({ subCategory: 'home', value: valueOverride })}
                          value={groceries}
                        />,
                        <SubCategory
                          id="restaurants-including-delivery"
                          key="restaurants-including-delivery"
                          name={translate('expenses:food:restaurants')}
                          onCancel={() => onCancelFoodExpenseChange({ subCategory: 'away' })}
                          onChange={(event, valueOverride) => onChangeFoodExpense({ subCategory: 'away', value: valueOverride })}
                          value={restaurants}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="triple-line medical-expenses">
                  <ReviewTile
                    onCancel={onCancelAllMedicalExpensesChanges}
                    onSubmit={onSubmitMedicalExpensesChanges}
                    summaryType={translate('expenses:medical:title')}
                    summaryAmount={prescriptionDrugs + healthInsurance + lifeInsurance + servicesAndAppointments}
                    tooltipDescription={translate('expenses:medical:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="prescription-drugs"
                          key="prescription-drugs"
                          name={translate('expenses:medical:perscriptionDrugs')}
                          onCancel={() => onCancelMedicalExpenseChange({ subCategory: 'drugs' })}
                          onChange={(event, valueOverride) => onChangeMedicalExpense({ subCategory: 'drugs', value: valueOverride })}
                          value={prescriptionDrugs}
                        />,
                        <SubCategory
                          id="health-insurance"
                          key="health-insurance"
                          name={translate('expenses:medical:healthInsurance')}
                          onCancel={() => onCancelMedicalExpenseChange({ subCategory: 'healthInsurance' })}
                          onChange={(event, valueOverride) => onChangeMedicalExpense({ subCategory: 'healthInsurance', value: valueOverride })}
                          value={healthInsurance}
                        />,
                        <SubCategory
                          id="life-insurance"
                          key="life-insurance"
                          name={translate('expenses:medical:lifeInsurance')}
                          onCancel={() => onCancelMedicalExpenseChange({ subCategory: 'lifeInsurance' })}
                          onChange={(event, valueOverride) => onChangeMedicalExpense({ subCategory: 'lifeInsurance', value: valueOverride })}
                          value={lifeInsurance}
                        />,
                        <SubCategory
                          id="services-and-appointments"
                          key="services-and-appointments"
                          name={translate('expenses:medical:servicesAndAppointments')}
                          onCancel={() => onCancelMedicalExpenseChange({ subCategory: 'medicalServices' })}
                          onChange={(event, valueOverride) => onChangeMedicalExpense({ subCategory: 'medicalServices', value: valueOverride })}
                          value={servicesAndAppointments}
                        />,
                      ]
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/2">
                <div className="double-line">
                  <ReviewTile
                    onCancel={onCancelAllPersonalExpensesChanges}
                    onSubmit={onSubmitPersonalExpensesChanges}
                    summaryType={translate('expenses:personal:title')}
                    summaryAmount={entertainment + clothing + personalCare}
                    tooltipDescription={translate('expenses:personal:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="entertainment"
                          key="entertainment"
                          name={translate('expenses:personal:entertainment')}
                          onCancel={() => onCancelPersonalExpenseChange({ subCategory: 'entertainment' })}
                          onChange={(event, valueOverride) => onChangePersonalExpense({ subCategory: 'entertainment', value: valueOverride })}
                          value={entertainment}
                        />,
                        <SubCategory
                          id="clothing"
                          key="clothing"
                          name={translate('expenses:personal:clothing')}
                          onCancel={() => onCancelPersonalExpenseChange({ subCategory: 'apparel' })}
                          onChange={(event, valueOverride) => onChangePersonalExpense({ subCategory: 'apparel', value: valueOverride })}
                          value={clothing}
                        />,
                        <SubCategory
                          id="personal-care"
                          key="personal-care"
                          name={translate('expenses:personal:personalCare')}
                          onCancel={() => onCancelPersonalExpenseChange({ subCategory: 'personalCare' })}
                          onChange={(event, valueOverride) => onChangePersonalExpense({ subCategory: 'personalCare', value: valueOverride })}
                          value={personalCare}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="double-line">
                  <ReviewTile
                    onCancel={onCancelAllStudentLoanPaymentExpensesChanges}
                    onSubmit={onSubmitStudentLoanPaymentChanges}
                    summaryType={translate('expenses:studentLoan:title')}
                    summaryAmount={studentLoanPayment}
                    tooltipDescription={translate('expenses:studentLoan:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="minimum-student-loan-payment"
                          key="minimum-student-loan-payment"
                          name={translate('expenses:studentLoan:minimumPayment')}
                          onCancel={() => onCancelStudentLoanPaymentExpenseChange({ subCategory: 'studentLoans' })}
                          onChange={(
                            event,
                            valueOverride,
                          ) => onChangeStudentLoanPaymentExpense({ subCategory: 'studentLoans', value: valueOverride })}
                          value={studentLoanPayment}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="triple-line">
                  <ReviewTile
                    onCancel={onCancelAllOtherDebtsExpensesChanges}
                    onSubmit={onSubmitOtherDebtsChanges}
                    summaryType={translate('expenses:other:title')}
                    summaryAmount={medicalDebt + friendsAndFamily + backTaxes + otherDebt}
                    tooltipDescription={translate('expenses:other:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="medical-debt"
                          key="medical-debt"
                          name={translate('expenses:other:totalMedicalDebt')}
                          onCancel={() => onCancelOtherDebtsExpenseChange({ subCategory: 'medical' })}
                          onChange={(event, valueOverride) => onChangeOtherDebtsExpense({ subCategory: 'medical', value: valueOverride })}
                          value={medicalDebt}
                        />,
                        <SubCategory
                          id="debt-to-friends-and-family"
                          key="debt-to-friends-and-family"
                          name={translate('expenses:other:totalDebtToFriendsAndFamily')}
                          onCancel={() => onCancelOtherDebtsExpenseChange({ subCategory: 'friendsFamily' })}
                          onChange={(event, valueOverride) => onChangeOtherDebtsExpense({ subCategory: 'friendsFamily', value: valueOverride })}
                          value={friendsAndFamily}
                        />,
                        <SubCategory
                          id="back-taxes"
                          key="back-taxes"
                          name={translate('expenses:other:totalBackTaxes')}
                          onCancel={() => onCancelOtherDebtsExpenseChange({ subCategory: 'backtaxes' })}
                          onChange={(event, valueOverride) => onChangeOtherDebtsExpense({ subCategory: 'backtaxes', value: valueOverride })}
                          value={backTaxes}
                        />,
                        <SubCategory
                          id="other-debt"
                          key="other-debt"
                          name={translate('expenses:other:totalOtherDebt')}
                          onCancel={() => onCancelOtherDebtsExpenseChange({ subCategory: 'other' })}
                          onChange={(event, valueOverride) => onChangeOtherDebtsExpense({ subCategory: 'other', value: valueOverride })}
                          value={otherDebt}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="double-line">
                  <ReviewTile
                    onCancel={onCancelAllSavingsAndDonationsExpensesChanges}
                    onSubmit={onSubmitSavingsAndDonationsChanges}
                    summaryType={translate('expenses:savingsAndDonations:title')}
                    summaryAmount={savings + donations}
                    tooltipDescription={translate('expenses:savingsAndDonations:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="savings"
                          key="savings"
                          name={translate('expenses:savingsAndDonations:savings')}
                          onCancel={() => onCancelSavingsAndDonationsExpenseChange({ subCategory: 'savings' })}
                          onChange={(event, valueOverride) => onChangeSavingsAndDonationsExpense({ subCategory: 'savings', value: valueOverride })}
                          value={savings}
                        />,
                        <SubCategory
                          id="donations"
                          key="donations"
                          name={translate('expenses:savingsAndDonations:donations')}
                          onCancel={() => onCancelSavingsAndDonationsExpenseChange({ subCategory: 'donations' })}
                          onChange={(event, valueOverride) => onChangeSavingsAndDonationsExpense({ subCategory: 'donations', value: valueOverride })}
                          value={donations}
                        />,
                      ]
                    }
                  />
                </div>
                <div className="double-line">
                  <ReviewTile
                    onCancel={onCancelAllMiscellaneousExpensesChanges}
                    onSubmit={onSubmitMiscellaneousExpenses}
                    summaryType={translate('expenses:miscellaneous:title')}
                    summaryAmount={childcare + education + petcare + childSupport + alimony + otherMiscellaneous}
                    tooltipDescription={translate('expenses:miscellaneous:tooltip')}
                    lineItems={
                      [
                        <SubCategory
                          id="childcare"
                          key="childcare"
                          name={translate('expenses:miscellaneous:childcare')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'childcare' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'childcare', value: valueOverride })}
                          value={childcare}
                        />,
                        <SubCategory
                          id="education"
                          key="education"
                          name={translate('expenses:miscellaneous:education')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'education' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'education', value: valueOverride })}
                          value={education}
                        />,
                        <SubCategory
                          id="petcare"
                          key="petcare"
                          name={translate('expenses:miscellaneous:petcare')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'pets' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'pets', value: valueOverride })}
                          value={petcare}
                        />,
                        <SubCategory
                          id="child-support"
                          key="child-support"
                          name={translate('expenses:miscellaneous:childSupport')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'childSupport' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'childSupport', value: valueOverride })}
                          value={childSupport}
                        />,
                        <SubCategory
                          id="alimony"
                          key="alimony"
                          name={translate('expenses:miscellaneous:alimony')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'alimony' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'alimony', value: valueOverride })}
                          value={alimony}
                        />,
                        <SubCategory
                          id="other-miscellaneous"
                          key="other-miscellaneous"
                          name={translate('expenses:miscellaneous:other')}
                          onCancel={() => onCancelMiscellaneousExpenseChange({ subCategory: 'other' })}
                          onChange={(event, valueOverride) => onChangeMiscellaneousExpense({ subCategory: 'other', value: valueOverride })}
                          value={otherMiscellaneous}
                        />,
                      ]
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <Button id="save-and-continue-button-input" onClick={onSubmit} text={translate('global:submit:saveAndContinue')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
