import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checkmark from '../../../../assets/icons/checkmark-green_icon.svg';
import LinkButton from '../../../common/LinkButton/LinkButton';
import NameDetail from '../../../../pages/offers/OfferDetail/NameDetail';
import { setShowRentalReliefPlanForm } from '../../../../redux/actions/rentalReliefPlanActions';
import ValueDetail from '../../../../pages/offers/OfferDetail/ValueDetail';

const RentalReliefPlanEnrollment = () => {
  const dispatch = useDispatch();
  const rentalReliefPlan = useSelector((state) => state.rentalReliefPlan.data);

  const onEditClick = () => {
    dispatch(setShowRentalReliefPlanForm({ showRentalReliefPlanForm: true }));
  };

  return (
    <div className="lender-tile-animation-1 w-full">
      <div className="plan-enrollment-summary">
        <div className="flex">
          <div className="enrolled pr-5">
            <img className="inline-block" src={checkmark} alt="Checkmark" />
            <div className="inline-block pl-2">Enrolled in a Rental Relief Plan</div>
          </div>
          <LinkButton additionalClasses="edit pl-5" onClick={onEditClick} text="Edit" />
        </div>
        <div className="w-full text-center pt-8">
          Monthly Rental Relief Plan rent amount:
          <div className="payment-amount -mt-2">
            <ValueDetail value={rentalReliefPlan.monthlyPlanRent} />
          </div>
          <div className="w-1/2">
            <div className="sub-text">
              Date of signed Rental Relief Plan agreement:
            </div>
            <div className="-mt-2">
              <NameDetail name={rentalReliefPlan.dateSigned} />
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="flex text-center pt-5">
          <div className="w-1/2">
            <div className="sub-text">
              Planned start date:
            </div>
            <div className="-mt-2">
              <NameDetail name={rentalReliefPlan.plannedStartDate} />
            </div>
          </div>
          <div className="w-1/2">
            <div className="sub-text">
              Planned end date:
            </div>
            <div className="-mt-2">
              <NameDetail name={rentalReliefPlan.plannedEndDate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalReliefPlanEnrollment;
