import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearDefaultSearchValue } from '../../../redux/actions/searchBarActions';
import FilterIcon from '../../../assets/icons/filter_icon.svg';

const SearchBar = ({ defaultValue = '', onChange, onClick }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue);

  const onInputValueChange = (event) => {
    setValue(event.target.value);

    onChange(event);
  };

  useEffect(() => {
    dispatch(clearDefaultSearchValue());
  }, [dispatch]);

  return (
    <div>
      <div className="search-bar mx-auto">
        <div className="relative text-gray-600">
          <button data-cy="filter-button" type="button" className="filter-icon" onClick={onClick}>
            <img src={FilterIcon} alt="Filter Icon" />
          </button>
          <input
            data-cy="search-input"
            type="search"
            name="search"
            placeholder="Search"
            className="w-full bg-white h-10 px-5 rounded-full text-sm focus:outline-none"
            onChange={onInputValueChange}
            value={value}
          />
        </div>
      </div>
      <div className="clear" />
    </div>
  );
};

export default SearchBar;
