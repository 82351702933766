import { combineReducers } from 'redux';
import alertsReducer from './alertsReducer';
import applicationReducer from './applicationReducer';
import borrowerReducer from './borrowerReducer';
import budgetsReducer from './budgetsReducer';
import contactAttemptReducer from './contactAttemptReducer';
import creditScoresReducer from './creditScoresReducer';
import customerServiceReducer from './customerServiceReducer';
import debtsReducer from './debtsReducer';
import debtScoreReducer from './debtScoreReducer';
import debtSummaryFilterReducer from './debtSummaryFilterReducer';
import decisionReducer from './decisionReducer';
import dmpEnrollmentReducer from './dmpEnrollmentReducer';
import docusignReducer from './docusignReducer';
import expensesReducer from './expensesReducer';
import hardshipsReducer from './hardshipsReducer';
import incomeReducer from './incomeReducer';
import offersReducer from './offersReducer';
import plaidReducer from './plaidReducer';
import portalReducer from './portalReducer';
import rentalApplicationReducer from './rentalApplicationReducer';
import rentalReliefPlanReducer from './rentalReliefPlanReducer';
import riskReducer from './riskReducer';
import searchBarReducer from './searchBarReducer';
import spouseReducer from './spouseReducer';
import summaryFilterReducer from './summaryFilterReducer';
import transunionReducer from './transunionReducer';
import twilioReducer from './twilioReducer';
import userReducer from './userReducer';
import videoReducer from './videoReducer';
import howItWorksModalReducer from './howItWorksModalReducer';

const combinedReducers = combineReducers({
  alerts: alertsReducer,
  application: applicationReducer,
  borrower: borrowerReducer,
  budgets: budgetsReducer,
  contactAttempt: contactAttemptReducer,
  customerService: customerServiceReducer,
  creditScores: creditScoresReducer,
  debts: debtsReducer,
  debtScores: debtScoreReducer,
  debtSummaryFilter: debtSummaryFilterReducer,
  decision: decisionReducer,
  dmpEnrollment: dmpEnrollmentReducer,
  docusign: docusignReducer,
  expenses: expensesReducer,
  hardships: hardshipsReducer,
  income: incomeReducer,
  offers: offersReducer,
  plaid: plaidReducer,
  portal: portalReducer,
  rentalApplication: rentalApplicationReducer,
  rentalReliefPlan: rentalReliefPlanReducer,
  risk: riskReducer,
  searchBar: searchBarReducer,
  spouse: spouseReducer,
  summaryFilters: summaryFilterReducer,
  transunion: transunionReducer,
  twilio: twilioReducer,
  user: userReducer,
  video: videoReducer,
  howItWorksModal: howItWorksModalReducer,
});

export default combinedReducers;
