import React from 'react';

const BasicLoading = ({
  showDidYouKnow = false,
  message = 'Loading...',
  subText = '',
  tips = [],
} = {}) => {
  const animatedTips = tips.map((tip) => (
    <span
      className="tips"
      key={tip.split(' ').join('-')}
    >
      {tip}
    </span>
  ));

  return (
    <div className="loading-bg">
      <div className="contain">
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <svg height="80" viewBox="-30 0 255 80" width="255">
          <ellipse cx="25" cy="20" fill="none" rx="10" ry="10" />
        </svg>
        <div
          aria-atomic="true"
          aria-live="polite"
          className="sub-content"
          role="status"
        >
          <h2 className="text headline">{message}</h2>
          <p className="text text-center">{subText}</p>
        </div>
      </div>
      { showDidYouKnow
        && (
        <div className="tips-container mt-20">
          <div className="text text-center">
            <span className="font-bold">
              Did you know?
            </span>
            {' '}
            <div className="tips-box">
              {animatedTips}
            </div>
          </div>
        </div>
        )}
    </div>
  );
};

export default BasicLoading;
