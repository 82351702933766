import React from 'react';
import { useSelector } from 'react-redux';
import DebtScoreMinimum from './DebtScoreMinimum';

const ScoreComponents = () => {
  const debtScores = useSelector((state) => state.debtScores.data);
  const debtScoresSortedByCreatedAt = debtScores
    .sort((debtScoreA, debtScoreB) => new Date(debtScoreB.createdAt) - new Date(debtScoreA.createdAt));

  return (
    <div className="text-center">
      <div className="flex flex-none md:w-1/2 w-full m-auto">
        <div className="m-auto">
          <DebtScoreMinimum
            debtScores={debtScoresSortedByCreatedAt}
          />
        </div>
        {/* TO DO - Add Credit Score component. Add w-1/2 to both divs */}
      </div>
      <div className="text-center pb-8 sub-text">
        Last updated June 10, 2020
      </div>
    </div>
  );
};

export default ScoreComponents;
