import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';
import { setConsentedToCreditPolicy } from '../../../redux/actions/spouseActions';

const SpouseCreditReportPolicyCheckbox = ({ errors }) => {
  const { t: translate } = useTranslation();
  const consentedToCreditPolicy = useSelector((state) => state.spouse.consentedToCreditPolicy);
  const dispatch = useDispatch();
  const label = translate('yourcontact:spouseCreditReportPolicy:label');
  const name = 'spouseConsentedToCreditInquiry';

  const onChange = (event) => {
    dispatch(setConsentedToCreditPolicy({ consentedToCreditPolicy: event.target.checked }));
  };

  return (
    <section>
      <div className="pt-2">
        <CheckboxInput
          checked={consentedToCreditPolicy}
          className="inline-block pb-0"
          errors={errors}
          id="spouse-credit-policy-checkbox"
          label={label}
          labelClass="hidden"
          name={name}
          onChange={onChange}
          required
        />
        <p className="terms consent-terms text-xs pt-2 text-gray-600" data-cy="spousecreditpolicylinks">
          {translate('yourcontact:spouseCreditReportPolicy:text')}
          {' '}
          <Link to="/credit-report-policy" target="_blank" className="font-semibold">{translate('yourcontact:spouseCreditReportPolicy:link')}</Link>
        </p>
        {errors[name] === 'required' && <p className="validation">{translate('yourcontact:spouseCreditReportPolicy:errorMessage')}</p>}
      </div>
    </section>
  );
};

export default SpouseCreditReportPolicyCheckbox;
