import React from 'react';
import LastNameTextInput from '../LastNameTextInput';

const SpouseLastNameTextInput = ({
  errors, label, onBlur, onChange,
}) => (
  <LastNameTextInput
    errors={errors}
    id="spouse-last-name-input"
    label={label}
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseLastName"
  />
);

export default SpouseLastNameTextInput;
