import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const CovidSpecificHardshipCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <CheckboxInput
      id="covid-specific-hardship-checkbox"
      label={translate('lifeevents:covidSpecificHardship')}
      loadFrom="hardships"
      name="hasCovidSpecificHardship"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default CovidSpecificHardshipCheckbox;
