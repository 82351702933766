import React from 'react';
import { FooterCTA } from '../../components/landing-pages';
import {
  HowCanWeHelp,
  HowItWorks,
  RenterAdvantageHero,
  WhoIsYourCounselor,
} from '../../components/landing-pages/RenterAdvantage';

const RenterAdvantage = () => (
  <div className="renter-advantage">
    <RenterAdvantageHero />
    <WhoIsYourCounselor />
    <HowCanWeHelp />
    <HowItWorks />
    <FooterCTA />
  </div>
);

export default RenterAdvantage;
