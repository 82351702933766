import React from 'react';
import { useTranslation } from 'react-i18next';
import UpArrow from '../../../../assets/icons/rental/up-arrow_icon.svg';
import Housing from '../../../../assets/icons/rental/housing_icon.svg';
import CreditCard from '../../../../assets/icons/rental/credit-card_icon.svg';
import Counselor from '../../../../assets/icons/rental/counselor_icon.svg';

const HowCanWeHelp = () => {
  const { t: translate } = useTranslation();

  return (
    <div className="how-can-we-help w-full text-center m-auto py-10 bg-white">
      <div className="sm:w-1/2 w-full container m-auto">
        <h2 className="text-center w-full md:w-1/2 m-auto p-2 px-1">
          {translate('landingpage:renterAdvantage:howCanWeHelp:title')}
        </h2>
        <div className="benefits">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-8">
            <div>
              <div className="benefit-img up-arrow">
                <img src={UpArrow} alt="Up Arrow" />
              </div>
              <div className="arrow-benefit-details">
                <p>
                  {translate('landingpage:renterAdvantage:howCanWeHelp:1')}
                </p>
              </div>
            </div>
            <div>
              <div className="benefit-img housing">
                <img src={Housing} alt="Housing" />
              </div>
              <p>
                {translate('landingpage:renterAdvantage:howCanWeHelp:2')}
              </p>
            </div>
            <div>
              <div className="benefit-img credit-card">
                <img src={CreditCard} alt="Credit card" />
              </div>
              <p className="pt-2">
                {translate('landingpage:renterAdvantage:howCanWeHelp:3')}
              </p>
            </div>
            <div>
              <div className="benefit-img counselor">
                <img src={Counselor} alt="Counselor" />
              </div>
              <p className="pt-2">
                {translate('landingpage:renterAdvantage:howCanWeHelp:4')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowCanWeHelp;
