import {
  SET_SHOW_HOW_IT_WORKS_MODAL,
} from '../actions/howItWorksModalActions';

const initialState = {
  showHowItWorksModal: false,
};

const howItWorksModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_HOW_IT_WORKS_MODAL: {
      return {
        ...state,
        showHowItWorksModal: action.value,
      };
    }

    default:
      return state;
  }
};

export default howItWorksModalReducer;
