import {
  GET_RISK_SCORES,
  GET_RISK_SCORES_FAIL,
  GET_RISK_SCORES_SUCCESS,
} from '../actions/riskActions';

const initialState = {
  data: {
    borrowerRiskScore: {
      creditReportId: undefined,
      id: undefined,
    },
    spouseRiskScore: {
      creditReportId: undefined,
      id: undefined,
    },
  },
  getRiskScoresFailed: undefined,
  getRiskScoresSucceeded: undefined,
  isGettingRiskScores: undefined,
};

const riskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_SCORES:
      return {
        ...state,
        getRiskScoresFailed: undefined,
        getRiskScoresSucceeded: undefined,
        isGettingRiskScores: true,
      };

    case GET_RISK_SCORES_FAIL:
      return {
        ...state,
        getRiskScoresFailed: true,
        getRiskScoresSucceeded: false,
        isGettingRiskScores: false,
      };

    case GET_RISK_SCORES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, borrowerRiskScore: action.borrowerRiskScore, spouseRiskScore: action.spouseRiskScore },
        getRiskScoresFailed: false,
        getRiskScoresSucceeded: true,
        isGettingRiskScores: false,
      };

    default:
      return state;
  }
};

export default riskReducer;
