import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ButtonInput from '../../../components/common/ButtonInput/ButtonInput';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import ProposedPaymentsCarousel from '../../../components/offers/ProposedPaymentsCarousel/ProposedPaymentsCarousel';
import { saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';


const ProposedPaymentSummary = () => {
  const application = useSelector((state) => state.application.data);
  const baseTen = 10;
  const currentDisposableIncome = useSelector((state) => parseInt(state.decision.data.currentDisposableIncome, baseTen));
  const expenseEstimates = useSelector((state) => state.expenses.data.expenseEstimates);
  const history = useHistory();
  const [minMonthlyPaymentsPerDebt, setMinMonthlyPaymentsPerDebt] = useState([]);
  const monthlyIncome = application.selfReportedMonthlyCurrentIncome;
  const offers = useSelector((state) => state.offers.data);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const dispatch = useDispatch();

  const totalMonthlyDebtPayment = parseInt(minMonthlyPaymentsPerDebt.reduce((sum, minMonthlyPayment) => sum + minMonthlyPayment, 0), baseTen);

  const onClick = (event) => {
    event.preventDefault();

    dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'ProposedPaymentSummary', lastViewedPage: 'LenderGuide' }));
    history.push('/offers/lender-guide');
  };

  useEffect(() => {
    const expenseValues = [];

    Object.keys(expenseEstimates)
      .map((category) => Object.keys(expenseEstimates[category])
        .forEach((subCategory) => { expenseValues.push(expenseEstimates[category][subCategory].value); }));

    const totalExpensesAmount = expenseValues.reduce((sum, expenseValue) => sum + expenseValue, 0);

    setTotalExpenses(totalExpensesAmount);
  }, [expenseEstimates]);

  useEffect(() => {
    const collections = offers.map((offer) => offer.collection).filter((collection) => collection);
    const debts = offers.map((offer) => offer.debt).filter((debt) => debt);
    const minimumMonthlyPayments = [];

    collections.forEach((collection) => {
      minimumMonthlyPayments.push(collection.currentBalance * 0.03);
    });

    debts.forEach((debt) => {
      minimumMonthlyPayments.push(debt.scheduledMonthlyPayment || debt.estimatedMonthlyPayment);
    });

    setMinMonthlyPaymentsPerDebt(minimumMonthlyPayments);
  }, [offers]);

  return (
    <div className="proposed-payment-summary">
      <div className="bg-blue w-full text-white">
        <div className="container mx-auto text-center sm:w-1/4 w-full">
          <h2 className="w-full m-auto text-center">Monthly Financial Analysis Summary</h2>
          <div className="flex pt-5 pb-4">
            <div className="w-1/3">
              <div className="value green-text">
                {formatNumberAsCurrency({ allowZero: true, number: monthlyIncome })}
              </div>
              <div className="sub-text">
                Income
              </div>
            </div>
            <div className="w-1/3">
              <div className="value red-text">
                {formatNumberAsCurrency({ allowZero: true, number: totalExpenses })}
              </div>
              <div className="sub-text">
                Expenses
              </div>
            </div>
            <div className="w-1/3">
              <div className="value red-text">
                {formatNumberAsCurrency({ allowZero: true, number: totalMonthlyDebtPayment })}
              </div>
              <div className="sub-text">
                Debt Payments
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="pt-4 pb-4">
            <div className={`value ${currentDisposableIncome < 0 ? 'red-text' : 'green-text'}`}>
              {formatNumberAsCurrency({ allowNegative: true, allowZero: true, number: currentDisposableIncome })}
            </div>
            <div className="sub-text">
              Disposable Income
            </div>
          </div>
        </div>
      </div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 sm:pt-8 pt-0 pb-8">
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Proposed Payment Summary
          </h2>
          <p className="text-center w-full md:w-1/2 m-auto">
            Based on our initial assessment, you are currently unable to afford your monthly
            debt payments and require them to be reduced.
          </p>
          <br />
          <p className="text-center w-full md:w-1/2 m-auto font-bold">
            Here are the monthly payments we recommend
            you negotiate with your creditors:
          </p>
        </div>
        <div className="lender-carousel container mx-auto pl-2 pr-2 text-center w-full mb-1">
          <ProposedPaymentsCarousel />
        </div>
        <br />
        <p className="text-center w-full md:w-1/2 m-auto font-bold">
          Ready to negotiate with your creditors?
        </p>
        <p className="text-center w-full md:w-1/2 m-auto font-bold">
          Get our free guide to help you negotiate our
          recommended payment with each one of your creditors.
        </p>
        <div className="leading-normal tracking-normal">
          <ButtonInput id="get-my-free-guide-button" onClick={onClick} text="Get my free guide" />
        </div>
      </div>
    </div>
  );
};

export default ProposedPaymentSummary;
