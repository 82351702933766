import React from 'react';

const PasswordInput = ({
  id, label, maxLength = 100, onChange, placeholder = ' ', required = true,
}) => (
  <div className="w-full m-auto mb-4">
    <div className="mb-4">
      <label className="input-container" htmlFor={id}>
        <input
          data-cy="password"
          id={id}
          maxLength={maxLength}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type="password"
        />
        <span className="label">{label}</span>
        <span className="border" />
      </label>
    </div>
  </div>
);

export default PasswordInput;
