import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../common/DropdownInput';

const NumberOfDependentsInHouseholdDropdownInput = ({
  errors,
  id,
  onChange,
  options,
}) => {
  const { t: translate } = useTranslation();
  return (
    <DropdownInput
      errors={errors}
      id={id}
      loadFrom="application"
      name="numberOfDependentsInHousehold"
      onChange={onChange}
      options={options}
      question={translate('rentalinformation:numberOfDependentsInHoushold')}
    />
  );
};

export default NumberOfDependentsInHouseholdDropdownInput;
