const createApplication = /* GraphQL */ `
  mutation createApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBorrower = /* GraphQL */ `
  mutation createBorrower(
    $input: CreateBorrowerInput!
    $condition: ModelBorrowerConditionInput
  ) {
    createBorrower(input: $input, condition: $condition) {
      id
    }
  }
`;

const createContactAttempt = /* GraphQL */ `
  mutation CreateContactAttempt(
    $input: CreateContactAttemptInput!
    $condition: ModelContactAttemptConditionInput
  ) {
    createContactAttempt(input: $input, condition: $condition) {
      id
    }
  }
`;

const createDmpEnrollment = /* GraphQL */ `
  mutation CreateDmpEnrollment(
    $input: CreateDmpEnrollmentInput!
    $condition: ModelDmpEnrollmentConditionInput
  ) {
    createDmpEnrollment(input: $input, condition: $condition) {
      id
      application {
        id
        dmpEnrollment {
          id
        }
      }
    }
  }
`;

const createHardship = /* GraphQL */ `
  mutation CreateHardship(
    $input: CreateHardshipInput!
    $condition: ModelHardshipConditionInput
  ) {
    createHardship(input: $input, condition: $condition) {
      id
      applicationId
      key
      value
    }
  }
`;

const createRentalApplication = /* GraphQL */ `
  mutation CreateRentalApplication(
    $input: CreateRentalApplicationInput!
    $condition: ModelRentalApplicationConditionInput
  ) {
    createRentalApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

const createRentalReliefPlan = /* GraphQL */ `
  mutation CreateRentalReliefPlan(
    $input: CreateRentalReliefPlanInput!
    $condition: ModelRentalReliefPlanConditionInput
  ) {
    createRentalReliefPlan(input: $input, condition: $condition) {
      id
      rentalApplication {
        id
        rentalReliefPlan {
          id
        }
      }
    }
  }
`;

const createSpouse = /* GraphQL */ `
  mutation CreateSpouse(
    $input: CreateSpouseInput!
    $condition: ModelSpouseConditionInput
  ) {
    createSpouse(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateApplication = /* GraphQL */ `
  mutation updateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateBorrower = /* GraphQL */ `
  mutation updateBorrower(
    $input: UpdateBorrowerInput!
    $condition: ModelBorrowerConditionInput
  ) {
    updateBorrower(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateDecision = /* GraphQL */ `
  mutation UpdateDecision(
    $input: UpdateDecisionInput!
    $condition: ModelDecisionConditionInput
  ) {
    updateDecision(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateDmpEnrollment = /* GraphQL */ `
  mutation UpdateDmpEnrollment(
    $input: UpdateDmpEnrollmentInput!
    $condition: ModelDmpEnrollmentConditionInput
  ) {
    updateDmpEnrollment(input: $input, condition: $condition) {
      id
      application {
        id
        dmpEnrollment {
          id
        }
      }
    }
  }
`;

const updateExpense = /* GraphQL */ `
  mutation UpdateExpense(
    $input: UpdateExpenseInput!
    $condition: ModelExpenseConditionInput
  ) {
    updateExpense(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateHardship = /* GraphQL */ `
  mutation UpdateHardship(
    $input: UpdateHardshipInput!
    $condition: ModelHardshipConditionInput
  ) {
    updateHardship(input: $input, condition: $condition) {
      id
      applicationId
      key
      value
    }
  }
`;

const updatePlaidTransaction = /* GraphQL */ `
  mutation UpdatePlaidTransaction(
    $input: UpdatePlaidTransactionInput!
    $condition: ModelPlaidTransactionConditionInput
  ) {
    updatePlaidTransaction(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateRentalApplication = /* GraphQL */ `
  mutation UpdateRentalApplication(
    $input: UpdateRentalApplicationInput!
    $condition: ModelRentalApplicationConditionInput
  ) {
    updateRentalApplication(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateRentalReliefPlan = /* GraphQL */ `
  mutation UpdateRentalReliefPlan(
    $input: UpdateRentalReliefPlanInput!
    $condition: ModelRentalReliefPlanConditionInput
  ) {
    updateRentalReliefPlan(input: $input, condition: $condition) {
      id
      rentalApplication {
        id
        rentalReliefPlan {
          id
        }
      }
    }
  }
`;

const updateSpouse = /* GraphQL */ `
  mutation UpdateSpouse(
    $input: UpdateSpouseInput!
    $condition: ModelSpouseConditionInput
  ) {
    updateSpouse(input: $input, condition: $condition) {
      id
    }
  }
`;

export {
  createApplication,
  createBorrower,
  createContactAttempt,
  createDmpEnrollment,
  createHardship,
  createRentalApplication,
  createRentalReliefPlan,
  createSpouse,
  updateAlert,
  updateApplication,
  updateBorrower,
  updateDecision,
  updateDmpEnrollment,
  updateExpense,
  updateHardship,
  updatePlaidTransaction,
  updateRentalApplication,
  updateRentalReliefPlan,
  updateSpouse,
};
