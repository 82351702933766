/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const TermsAndConditions = () => {
  const { t: translate } = useTranslation();
  const showTranslationOption = process.env.REACT_APP_SHOW_TRANSLATION_OPTION === 'true';
  const termsAndConditionsTitle = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <h2 className="text-xl sm:text-2xl w-full m-auto text-center" tabIndex="0">
      {translate('termsandconditions:title')}
    </h2>
  );

  const onLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <section>
      {showTranslationOption
        && (
        <div className="text-left ml-10 pt-10">
          <p className="text-sm w-">
            Select Language:
          </p>
          <select name="language" onChange={onLanguageChange}>
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        )}
      <div className="leading-normal tracking-normal pb-20">
        <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
          <div className="pb-5">
            {termsAndConditionsTitle}
            <p className="text-center p-5">
              {translate('termsandconditions:date')}
            </p>
          </div>
          <div className="pb-5">
            <p className="pb-5">
              {translate('termsandconditions:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:paragraphTwo')}
              {' '}
              <Link className="color-blue" target="_blank" to="/">www.creditgenie.com</Link>
              {translate('termsandconditions:paragraphThree')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:paragraphFour')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:privacy:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:privacy:paragraphOne')}
              {' '}
              <Link className="color-blue" target="_blank" to="/privacy-policy">Privacy Policy</Link>
              {' '}
              {translate('termsandconditions:privacy:paragraphTwo')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:yourUseOfSite:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:yourUseOfSite:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:yourUseOfSite:paragraphTwo')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:yourUseOfSite:paragraphThree')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:yourUseOfSite:paragraphFour')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:yourUseOfSite:paragraphFive')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:accountInformationFromOnlineFinancialServiceProviderSites:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:accountInformationFromOnlineFinancialServiceProviderSites:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:accountInformationFromOnlineFinancialServiceProviderSites:paragraphTwo')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:sharingPersonallyIdentifiableInformationWithThirdParties:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:sharingPersonallyIdentifiableInformationWithThirdParties:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:rightYouGrantUs:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:rightYouGrantUs:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:rightYouGrantUs:paragraphTwo')}
              {' '}
              <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com</a>.
            </p>
            <p className="pb-5">
              {translate('termsandconditions:rightYouGrantUs:paragraphThree')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:personalInformationRetention:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:personalInformationRetention:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:intellectualProperty:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:intellectualProperty:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:intellectualProperty:paragraphTwo')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:communication:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:communication:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:cookies:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:cookies:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:cookies:paragraphTwo')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:cookies:paragraphThree')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:dataUse:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:dataUse:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:linksToOtherWebsitesAndServices:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:linksToOtherWebsitesAndServices:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:siteSecurity:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:siteSecurity:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:developerApplicationsSecurity:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:developerApplicationsSecurity:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:limitationOfLiability:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:limitationOfLiability:paragraphOne')}
            </p>
            <p className="pb-5">
              {translate('termsandconditions:limitationOfLiability:paragraphTwo')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:minors:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:minors:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:severability:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:severability:paragraphOne')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="pb-5">
              {translate('termsandconditions:governingLaw:headline')}
            </h2>
            <p className="pb-5">
              {translate('termsandconditions:governingLaw:paragraphOne')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
