import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DebtSummaryFilter from './DebtSummaryFilter/DebtSummaryFilter';
import DebtSummaryTile from './DebtSummaryTile/DebtSummaryTile';
import FilterResults from '../../customer-service-portal/common/FilterResults/FilterResults';
import objectValueChecker from '../../../helpers/objectValueHelper';
import Paginate from '../Pagination/Paginate';
import SearchBar from '../SearchBar/SearchBar';
import { updateDebtSummaryFilters } from '../../../redux/actions/debtSummaryFilterActions';

const DebtSummaryComponent = ({ debts, showFilter = true, showSearchBar = true }) => {
  const borrower = useSelector((state) => state.borrower.data);
  const dispatch = useDispatch();
  const [filterErrors, setFilterErrors] = useState(undefined);
  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState(false);
  const [filteredDebts, setFilteredDebts] = useState([]);
  const filters = useSelector((state) => state.debtSummaryFilter.updatedState || state.debtSummaryFilter);
  const initialFilterState = useSelector((state) => state.debtSummaryFilter.initialState || state.debtSummaryFilter);
  const [search, setSearch] = useState(undefined);
  const spouse = useSelector((state) => state.spouse.data);
  const [tempFilters, setTempFilters] = useState(filters);

  const [currentPage, setCurrentPage] = useState(1);
  const debtsPerPage = 10;
  const indexOfLastDebt = currentPage * debtsPerPage;
  const indexOfFirstDebt = indexOfLastDebt - debtsPerPage;
  const pageCount = Math.ceil(filteredDebts.length / debtsPerPage);

  const toggleFilterMenu = () => {
    setFilterErrors(undefined);
    setTempFilters(filters);
    setFilterMenuIsOpen(!filterMenuIsOpen);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const onFilterChange = (event, valueOverride, filterCategory) => {
    const { name } = event.target;

    if (filterCategory === 'debtStatus' && name === 'open' && tempFilters.debtStatus.open === true) {
      setTempFilters({
        ...tempFilters,
        debtStatus: { ...tempFilters.debtStatus, open: false },
        debtDetail: { balance: false, late: false },
      });

      return;
    }

    setTempFilters({ ...tempFilters, [filterCategory]: { ...tempFilters[filterCategory], [name]: valueOverride } });
  };

  const onFilterSubmit = (event) => {
    event.preventDefault();

    setFilterErrors(undefined);

    const tempFilterKeys = Object.keys(tempFilters);

    const errors = {};
    tempFilterKeys.forEach((key) => {
      const filterNotSelected = objectValueChecker(tempFilters[key], false);

      if (filterNotSelected && key !== 'debtDetail') {
        errors[key] = 'Please select at least one filter';
      }
    });

    setFilterErrors(errors);

    if (Object.keys(errors).length) {
      return;
    }

    dispatch(updateDebtSummaryFilters(tempFilters));
    toggleFilterMenu();
    setCurrentPage(1);
  };

  const debtSummaryTiles = filteredDebts
    .slice(indexOfFirstDebt, indexOfLastDebt)
    .map((debt) => (
      <DebtSummaryTile
        borrowerName={`${borrower.firstName} ${borrower.lastName}`}
        debt={debt}
        key={debt.id}
        spouseName={`${spouse.firstName} ${spouse.lastName}`}
      />
    ));

  const filterByDebtType = useCallback((debt) => {
    const { debtType } = filters;

    const debtIsShared = debtType.shared && debt.shared;
    const debtIsNotShared = debtType.single && !debt.shared;

    return debtIsShared || debtIsNotShared;
  }, [filters]);

  const filterByDebtStatus = useCallback((debt) => {
    const { debtStatus } = filters;

    const openDebt = debtStatus.open && !debt.dateClosed;
    const closedDebt = debtStatus.closed && debt.dateClosed;

    return openDebt || closedDebt;
  }, [filters]);

  const filterByDebtDetail = useCallback((debt) => {
    const { debtDetail } = filters;
    if (!debtDetail.hasBalance && !debtDetail.latePayment) {
      return true;
    }

    const hasBalance = debtDetail.hasBalance && debt.currentBalance > 0;
    const has30DateLatePayment = debt.late30DaysTotal && debt.late30DaysTotal > 0;
    const has60DateLatePayment = debt.late60DaysTotal && debt.late60DaysTotal > 0;
    const has90DateLatePayment = debt.late90DaysTotal && debt.late90DaysTotal > 0;

    const isLate = debtDetail.latePayment && (has30DateLatePayment || has60DateLatePayment || has90DateLatePayment);

    const hasBalanceAndIsLate = hasBalance && isLate;
    const hasBalanceAndIsNotLate = hasBalance && !debtDetail.latePayment;
    const hasNoBalanceAndIsLate = isLate && !debtDetail.hasBalance;

    return hasBalanceAndIsLate || hasBalanceAndIsNotLate || hasNoBalanceAndIsLate;
  }, [filters]);

  const filterByPortfolioType = useCallback((debt) => {
    const { portfolioType } = filters;

    const enabledPortfolioTypeFilters = Object.keys(filters.portfolioType).filter((key) => (portfolioType[key]));
    const debtFilteredByPortfolioType = enabledPortfolioTypeFilters.includes(debt.portfolioType);

    return debtFilteredByPortfolioType;
  }, [filters]);

  const filterByAccountType = useCallback((debt) => {
    const accountTypeMapping = {
      AU: 'automobile',
      CC: 'creditCard',
      CH: 'chargeAccount',
      CV: 'conventionalRealEstateMortgage',
      FE: 'attorneyFees',
      FX: 'flexibleSpendingCreditCard',
      LC: 'lineOfCredit',
      SM: 'secondMortgage',
      ST: 'studentLoan',
      US: 'unsecured',
    };

    const { accountType } = debt;
    const friendlyAccountType = accountTypeMapping[accountType];
    const enabledAccountTypeFilters = Object.keys(filters.accountType).filter((enabledAccountType) => filters.accountType[enabledAccountType]);
    const filterMatch = enabledAccountTypeFilters.some((enabledAccountTypeFilter) => enabledAccountTypeFilter === friendlyAccountType);

    if (enabledAccountTypeFilters.includes('other') && !filterMatch) {
      const otherFilterMatch = !Object.keys(filters.accountType).includes(friendlyAccountType);

      return otherFilterMatch;
    }

    return filterMatch;
  }, [filters]);

  const filterBySearch = useCallback((debt) => {
    if (!search) {
      return true;
    }

    setCurrentPage(1);
    const { accountNumber, lenderFriendlyName, lenderName } = debt;
    const substring = search.toLowerCase().trim();

    try {
      const matchWasFound = lenderName.toLowerCase().match(substring)
        || (lenderFriendlyName && lenderFriendlyName.toLowerCase().match(substring))
        || accountNumber.toLowerCase().match(substring);

      return matchWasFound;
    } catch (error) {
      return false;
    }
  }, [search]);

  useEffect(() => {
    const getFilteredDebts = debts
      .filter(filterByDebtType)
      .filter(filterByDebtStatus)
      .filter(filterByDebtDetail)
      .filter(filterByPortfolioType)
      .filter(filterByAccountType)
      .filter(filterBySearch)
      .sort((debtOne, debtTwo) => debtTwo.currentBalance - debtOne.currentBalance);

    setFilteredDebts(getFilteredDebts);
  }, [
    debts,
    filterByAccountType,
    filterByDebtDetail,
    filterByDebtStatus,
    filterByDebtType,
    filterByPortfolioType,
    filterBySearch,
    search,
  ]);

  return (
    <div className="debt-summary-component">
      <br />
      {showFilter && filterMenuIsOpen && (
        <DebtSummaryFilter
          isOpen={filterMenuIsOpen}
          filterErrors={filterErrors}
          filterState={filters}
          onFilterChange={onFilterChange}
          onFilterReset={() => setTempFilters(initialFilterState)}
          onSubmit={onFilterSubmit}
          tempFilterState={tempFilters}
          toggle={toggleFilterMenu}
        />
      )}
      {showSearchBar && <SearchBar onChange={(event) => setSearch(event.target.value)} onClick={toggleFilterMenu} />}
      {showFilter && <FilterResults data={filteredDebts} dataName="Debts" />}
      <div className="-mt-5">
        {debts.length > 0 ? debtSummaryTiles : 'There are no debts currently available for this borrower.'}
        {showFilter && debts.length > 0 && filteredDebts.length === 0 && ('There are no debts that match your filters.')}
      </div>
      <div className="container mx-auto pt-5 pl-2 pr-2 pb-3 text-center w-full">
        {
          filteredDebts.length > 0
            ? <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />
            : ''
        }
      </div>
    </div>
  );
};

export default DebtSummaryComponent;
