import { API, Auth } from 'aws-amplify';

const sendOutreachText = ({ borrowerId, eventType }) => async () => {
  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();

    const outreachResponse = await API.post('outreach', '/contact', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        borrowerId,
        eventType,
      },
    });

    return outreachResponse;
  } catch (error) {
    console.log('error sending outreach text:', error);
  }

  return undefined;
};

export default sendOutreachText;
