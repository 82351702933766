import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearSaveApplicationSucceeded, saveLastCompletedAndLastViewedPage } from '../../../redux/actions/applicationActions';
import { clearSaveBorrowerSucceeded } from '../../../redux/actions/borrowerActions';
import { clearSaveHardshipsSucceeded } from '../../../redux/actions/hardshipActions';
import { clearSaveRentalApplicationSucceeded } from '../../../redux/actions/rentalApplicationActions';
import { clearSaveSpouseSucceeded } from '../../../redux/actions/spouseActions';
import {
  CovidSpecificHardshipCheckbox,
  IncreasedExpensesCheckbox,
  JobLossOrReducedIncomeCheckbox,
  MedicalDebtOrExpensesCheckbox,
  OtherHardshipCheckbox,
  StudentLoanDebtCheckbox,
  TroubleMakingRentPaymentsCheckbox,
  UtilityPaymentsCheckbox,
} from '../../../components';
import Form from '../../../components/application/common/ApplicationForm/Form';
import { getCreditCounselingAgencyPreLeadPhoneNumber } from '../../../helpers/creditCounselingAgencyHelper';
import ProgressBar from '../../../components/application/common/ProgressBar/ProgressBar';
import SubmitInput from '../../../components/application/common/SubmitInput/SubmitInput';
import { updateCurrentPhoneNumber } from '../../../redux/actions/customerServiceActions';
import useForm from '../../../components/application/common/ApplicationForm/useForm';
import validate from '../../../components/application/common/ApplicationForm/validator';

const LifeEvents = ({ nextPage = 'CreditHistory', nextPageRoute = '/application/credit-history' }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const errorMessage = 'There was a problem saving your information. Please try again.';
  const history = useHistory();
  const isSaving = useSelector((state) => state.hardships.isSaving);
  const { onHardshipChange, onSubmit } = useForm({ validate });
  const router = useSelector((state) => state.application.data.router);
  const saveHardshipsSucceeded = useSelector((state) => state.hardships.savingSucceeded);
  const saveFailed = useSelector((state) => state.hardships.savingFailed);
  const preLeadPhoneNumber = getCreditCounselingAgencyPreLeadPhoneNumber({ router });

  useEffect(() => {
    dispatch(updateCurrentPhoneNumber(preLeadPhoneNumber));

    if (isSaving) {
      return;
    }

    if (saveHardshipsSucceeded) {
      dispatch(clearSaveApplicationSucceeded());
      dispatch(clearSaveBorrowerSucceeded());
      dispatch(clearSaveHardshipsSucceeded());
      dispatch(clearSaveRentalApplicationSucceeded());
      dispatch(clearSaveSpouseSucceeded());
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'LifeEvents', lastViewedPage: nextPage }));

      history.push(nextPageRoute);
    }
  });

  return (
    <div>
      <div className="life-events leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pb-8">
          <ProgressBar pageName="lifeEvents" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center leading-normal">
            {translate('lifeevents:title')}
          </h2>
          <p className="text-center">{translate('lifeevents:subtitle')}</p>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <Form onSubmit={onSubmit}>
                <div className="md:flex flex-none">
                  <div className="md:w-1/2 w-full">
                    <TroubleMakingRentPaymentsCheckbox onChange={onHardshipChange} />
                    <JobLossOrReducedIncomeCheckbox onChange={onHardshipChange} />
                    <IncreasedExpensesCheckbox onChange={onHardshipChange} />
                    <CovidSpecificHardshipCheckbox onChange={onHardshipChange} />
                  </div>
                  <div className="md:w-1/2 w-full md:pl-8 pl-0">
                    <MedicalDebtOrExpensesCheckbox onChange={onHardshipChange} />
                    <StudentLoanDebtCheckbox onChange={onHardshipChange} />
                    <UtilityPaymentsCheckbox onChange={onHardshipChange} />
                    <OtherHardshipCheckbox onChange={onHardshipChange} />
                  </div>
                </div>
                <SubmitInput text={translate('global:submit:text')} />
              </Form>
            </div>
          </div>
          {saveFailed && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default LifeEvents;
