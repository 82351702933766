import React from 'react';
import Carousel from '../../common/Carousel/Carousel';
import LenderOfferTile from '../LenderOfferTiles/LenderOfferTile';

const LenderOfferTileCarousel = ({ offers }) => {
  const lenderOfferTiles = offers
    .sort((offerA, offerB) => offerB.balance - offerA.balance)
    .map((offer) => (
      <LenderOfferTile key={offer.id} offer={offer} />
    ));

  return <Carousel components={lenderOfferTiles} name="LenderOffers" />;
};

export default LenderOfferTileCarousel;
