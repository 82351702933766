import React from 'react';

const Form = ({ children, errorMessage = '', onSubmit }) => (
  <div>
    <form noValidate onSubmit={onSubmit}>
      {children}
    </form>
    {errorMessage && <p className="form-failure w-full">{errorMessage}</p>}
  </div>
);

export default Form;
