import InputMask from 'react-input-mask';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const IntegerInput = ({
  allowZero = false,
  errors = {},
  id,
  label,
  loadFrom,
  maskChar = null,
  maxNumberOfDigits,
  name,
  onChange,
  placeholder = ' ',
  required = true,
  validationName,
}) => {
  const { t: translate } = useTranslation();
  let mask = '';

  for (let i = 0; i < maxNumberOfDigits; i += 1) {
    mask += '9';
  }

  const regex = maxNumberOfDigits ? String.raw`\d{1,${maxNumberOfDigits}}` : '';

  const value = useSelector((state) => {
    const storedValue = loadFrom && name && state[loadFrom].data[name];

    if (allowZero && storedValue === 0) {
      return storedValue;
    }

    return storedValue || '';
  });

  const onInputValueChange = (event) => {
    const { value: inputValue } = event.target;

    if (!inputValue) {
      onChange(event, '');

      return;
    }

    const baseTen = 10;
    const numericValue = parseInt(inputValue, baseTen);

    onChange(event, numericValue);
  };

  return (
    <div className="w-full m-auto">
      <div className="mb-4">
        <label className="input-container" htmlFor={id}>
          <InputMask
            id={id}
            mask={mask}
            maskChar={maskChar}
            name={name}
            onChange={onInputValueChange}
            placeholder={placeholder}
            regex={regex}
            required={required}
            type="tel"
            validationname={validationName}
            value={value}
          />
          <span className="label">{label}</span>
          <span className="border" />
        </label>
      </div>
      {(validationName ? errors[validationName] === 'invalid' : errors[name] === 'invalid') && (
      <p className="validation">
        {label}
        {' '}
        is invalid
      </p>
      )}
      {(validationName ? errors[validationName] === 'required' : errors[name] === 'required') && (
      <p className="validation">
        {label}
        {' '}
        {translate('global:errorMessages:isRequired')}
      </p>
      )}
    </div>
  );
};

export default IntegerInput;
