import React from 'react';

const LandingPageLayout = ({
  environmentSpecificRoutes = [],
  environmentSpecificFooter = undefined,
}) => (
  <section>
    <div className="landing-pages">
      {environmentSpecificRoutes}
    </div>
    {environmentSpecificFooter}
  </section>
);

export default LandingPageLayout;
