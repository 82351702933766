import React from 'react';
import { useSelector } from 'react-redux';
import GradientArrow from '../../../components/application/common/GradientArrow/GradientArrow';
import LenderOfferTileCarousel from '../../../components/offers/LenderOfferTileCarousel/LenderOfferTileCarousel';
import ValueDetail from '../../offers/OfferDetail/ValueDetail';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';

const OfferSummary = () => {
  const decision = useSelector((state) => state.decision.data);
  const offers = useSelector((state) => state.offers.data);
  const disqualificationReasons = decision && !decision.isQualified && decision.disqualificationReasons;
  const totalMonthlySavings = decision && Math.round(decision.currentUnsecuredDebtPayment - decision.proposedUnsecuredDebtPayment);

  return (
    <div className="offer-summary container mx-auto pl-2 pr-2 pt-4 text-center w-full">
      {decision && decision.isQualified
        ? (
          <div>
            <p className="w-full sm:w-1/2 m-auto font-bold">
              Debt management plan estimates:
            </p>
            <div className="new-monthly-payment-tile pt-3">
              <div className="flex justify-between">
                <p className="mb-1 text-sm grey-text">Min. Monthly Payment</p>
                <p className="mb-1 text-sm green-text">New Min. Monthly Payment</p>
              </div>
              <div className="flex justify-between">
                <h2 className="grey-text">
                  <ValueDetail
                    value={Math.round(decision.currentUnsecuredDebtPayment)}
                  />
                </h2>
                <GradientArrow
                  startColor="#C8CCD4"
                  endColor="#4DDD8C"
                />
                <h2 className="green-text">
                  <ValueDetail
                    value={Math.round(decision.proposedUnsecuredDebtPayment)}
                  />
                </h2>
              </div>
            </div>
            <div className="details offer-details pt-4">
              <ul className="leaders">
                <li className="pb-2">
                  <span className="float-left">Plan Length:</span>
                  <span>60 Months</span>
                </li>
                <li className="pb-2">
                  <span className="float-left">Monthly Savings:</span>
                  <span>{formatNumberAsCurrency({ number: totalMonthlySavings })}</span>
                </li>
              </ul>
            </div>
            <p className="w-full sm:w-1/2 m-auto font-bold py-4">
              Plan details for each creditor:
            </p>
            {offers.length && <LenderOfferTileCarousel offers={offers} />}
          </div>
        )
        : (
          <div>
            {disqualificationReasons
              ? (
                <div>
                  <p className="my-4 font-bold">This borrower did not qualify for the following reason(s):</p>
                  <ul>
                    {disqualificationReasons.map((disqualificationReason) => (
                      <li className="disqualification-reason my-4" key={`${disqualificationReason.reason}`}>{disqualificationReason.reason}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <p className="my-4">There was no decision made for this borrower.</p>
                </div>
              )}
          </div>
        )}
    </div>
  );
};

export default OfferSummary;
