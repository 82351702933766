import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { captureApplicationQueryStringParameters } from '../helpers/queryStringHelper';
import {
  CambridgeLandingPageLayout,
  CesiLandingPageLayout,
  CreditGenieLandingPageLayout,
  NavicoreLandingPageLayout,
  RentalLandingPageLayout,
} from '../layouts';

const LandingPageLayoutFactory = () => {
  const dispatch = useDispatch();
  const environmentName = process.env.REACT_APP_ENVIRONMENT;
  const history = useHistory();

  let applicationType;

  if (history.location && history.location.search) {
    const queryString = new URLSearchParams(history.location.search);

    applicationType = queryString.get('applicationType');
  }

  useEffect(() => {
    captureApplicationQueryStringParameters({ dispatch, history });
  }, [dispatch, history]);

  if (applicationType === 'rental') {
    return <RentalLandingPageLayout />;
  }

  if (environmentName === 'cambridge') {
    return <CambridgeLandingPageLayout />;
  }

  if (environmentName === 'cesi') {
    return <CesiLandingPageLayout />;
  }

  if (environmentName === 'navicore') {
    return <NavicoreLandingPageLayout />;
  }

  return <CreditGenieLandingPageLayout />;
};

export default LandingPageLayoutFactory;
