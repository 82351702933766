import React from 'react';
import NameDetail from '../../../pages/offers/OfferDetail/NameDetail';
import ValueDetail from '../../../pages/offers/OfferDetail/ValueDetail';

const SubCategoryReadOnly = ({ name, value }) => (
  <div className="pb-2">
    <div className="details">
      <ul className="leaders">
        <li className="text-left pb-2">
          <span className="float-left">
            <NameDetail name={name} />
          </span>
          <span className="float-right">
            <ValueDetail value={value} />
          </span>
        </li>
      </ul>
    </div>
  </div>
);


export default SubCategoryReadOnly;
