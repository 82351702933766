import React from 'react';
import PlaidLink from 'react-plaid-link';
import GreenCheckmark from '../../../assets/icons/checkmark-green_icon.svg';
import LenderLogo from '../../common/LenderLogo/LenderLogo';

const BankConnectionTile = ({
  connectionDate,
  institutionName,
  isConnected,
  onSuccess,
  onSubmit,
  webhook,
}) => (
  <div className="bank-connection-tile m-auto p-6">
    <div className="flex justify-between items-center">
      <LenderLogo
        className="self-center text-black"
        lenderFriendlyName={institutionName}
      />
      {isConnected
        ? (
          <div className="account-info text-right">
            <div>
              XXXX XXXX XXXX 5757
            </div>
            {connectionDate && (
              <div>
                {connectionDate}
              </div>
            )}
            <div>
              <img src={GreenCheckmark} alt="" className="inline-block" />
              <span className="confirmation font-bold inline-block ml-2">
                Connected
              </span>
            </div>
          </div>
        )
        : (
          <PlaidLink
            className="m-auto self-center customer-portal-input"
            clientName="Creditly"
            env={process.env.REACT_APP_PLAID_ENV}
            product={['transactions']}
            publicKey="1995cf6fdc8987d2cab0a1242a9d56"
            onSuccess={onSuccess}
            onSubmit={onSubmit}
            style={{ outline: 'none' }}
            webhook={webhook}
          >
            Connect
          </PlaidLink>
        )}
    </div>
  </div>
);

export default BankConnectionTile;
