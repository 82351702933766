import React from 'react';
import { DateTime } from 'luxon';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calender_icon.svg';

const DateIcon = ({ date }) => {
  const formattedDate = date ? DateTime.fromJSDate(date).toFormat('d') : undefined;

  return (
    <div>
      <CalendarIcon />
      <div className="absolute text-center">
        <span className="calendar-icon-value">
          {formattedDate}
        </span>
      </div>
    </div>
  );
};

export default DateIcon;
