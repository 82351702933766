import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomerRepresentativeInformationTile,
  SummaryFilterComponent,
} from '../../../components/customer-service-portal';
import { getApplicationsForServicePortal } from '../../../redux/actions/applicationActions';
import { getCurrentAuthenticatedUser } from '../../../helpers/authHelper';

const BorrowerQueue = () => {
  const applications = useSelector((state) => (Array.isArray(state.application.list) ? state.application.list : []));
  const [applicationsWereRetrieved, setApplicationsWereRetrieved] = useState(false);
  const assignedBorrowerCount = useSelector((state) => state.borrower.assignedBorrowerCount);
  const contactedBorrowerCount = useSelector((state) => state.borrower.contactedBorrowerCount);
  const defaultUser = { attributes: { name: undefined } };
  const dispatch = useDispatch();
  const [user, setUser] = useState(defaultUser);

  const borrowers = applications.map((application) => ({
    application: {
      assignedTo: application.assignedTo,
      createdAt: application.createdAt,
      decision: application.decision,
      dmpEnrollment: application.dmpEnrollment,
      id: application.id,
      lastCompletedPage: application.lastCompletedPage,
      router: application.router,
      status: application.status,
      type: application.type,
    },
    hasConnectedAccount: application.hasConnectedAccount,
    ...application.borrower,
  }));

  useEffect(() => {
    if (!user.username) {
      return;
    }

    if (!applicationsWereRetrieved) {
      setApplicationsWereRetrieved(true);
      dispatch(getApplicationsForServicePortal({ shouldFilterByUserId: true, userId: user.username }));
    }
  }, [applicationsWereRetrieved, defaultUser, dispatch, user]);

  useEffect(() => {
    const setUserToCurrentUser = async () => {
      if (user === defaultUser) {
        const currentUser = await getCurrentAuthenticatedUser();

        setUser(currentUser);
      }
    };

    setUserToCurrentUser();
  }, [defaultUser, dispatch, user]);

  return (
    <section>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="borrower-queue-components container mx-auto pl-2 pr-2 pt-8 pb-8 lg:flex flex-none">
          <div className="customer-rep-info">
            <CustomerRepresentativeInformationTile
              numberAssigned={assignedBorrowerCount}
              numberContacted={contactedBorrowerCount}
              name={user.attributes.name}
              email={user.attributes.email}
              phoneNumber={user.attributes.phone_number}
            />
          </div>
          <div className="dynamic-info">
            <div className="bg-white w-full box">
              <h1 className="mb-2">Borrower Queue</h1>
              <br />
              <SummaryFilterComponent
                borrowers={borrowers}
                filterResultsTextColor="black"
                shouldFilterByUserId
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BorrowerQueue;
