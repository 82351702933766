import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import thunk from 'redux-thunk';
import awsconfig from './aws-exports';
import {
  CreditReportPolicy,
  DebtRelief,
  PrivacyPolicy,
  TermsAndConditions,
  VendorSharing,
} from './pages';
import combinedReducers from './redux/reducers/reducers';
import { loadState, saveState } from './helpers/stateHelper';
import HistoryHelper from './helpers/historyHelper';
import CustomerExpenseSummary from './pages/customer-portal/CustomerExpenseSummary/CustomerExpenseSummary';
import CustomerOffersSummary from './pages/customer-portal/CustomerOffersSummary/CustomerOffersSummary';
import SignIn from './pages/portal/SignIn';
import ForgotPassword from './pages/portal/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/portal/ResetPassword';
import Summary from './pages/customer-service-portal/Summary';
import BorrowerQueue from './pages/customer-service-portal/BorrowerQueue';
import BorrowerSummary from './pages/customer-service-portal/BorrowerSummary/BorrowerSummary';
import UserInformation from './pages/customer-service-portal/UserInformation/UserInformation';
import VerificationCode from './pages/portal/VerificationCode';
import CustomerDebtAnalysis from './pages/customer-portal/CustomerDebtAnalysis/CustomerDebtAnalysis';
import CustomerPortalLayout from './layouts/CustomerPortalLayout';
import LegalDocumentLayout from './layouts/LegalDocumentLayout';
import OnBoardingLayout from './layouts/OnBoardingLayout';
import PortalLayout from './layouts/PortalLayout';
import PleaseCheckYourEmail from './pages/portal/PleaseCheckYourEmail';
import Preferences from './pages/customer-service-portal/Preferences';
import ScrollToTop from './components/common/ScrollToTop';
import ChangePassword from './pages/portal/ChangePassword/ChangePassword';
import ChangeEmail from './pages/portal/ChangeEmail/ChangeEmail';
import HardshipHomePage from './pages/brochure/HardshipHomepage';
import HardshipBrochureLayout from './layouts/HardshipBrochureLayout';
import ContactSummary from './pages/customer-service-portal/ContactSummary';
import CustomerBudget from './pages/customer-portal/CustomerBudget/CustomerBudget';
import CustomerFaq from './pages/customer-portal/CustomerFaq/CustomerFaq';
import CustomerSummary from './pages/customer-portal/CustomerSummary';
import CustomerBankConnections from './pages/customer-portal/CustomerBankConnections/CustomerBankConnections';
import CustomerAlerts from './pages/customer-portal/CustomerAlerts/CustomerAlerts';
import CustomerPayments from './pages/customer-portal/CustomerPayments/CustomerPayments';
import CustomerFinancials from './pages/customer-portal/CustomerFinancials/CustomerFinancials';
import CustomerSettings from './pages/customer-portal/CustomerSettings/CustomerSettings';
import ApplicationLayoutFactory from './factories/ApplicationLayoutFactory';
import LandingPageLayoutFactory from './factories/LandingPageLayoutFactory';
import DTCLandingPageLayout from './layouts/DTCLandingPageLayout';

Amplify.configure(awsconfig);

const persistedState = loadState();

const reduxStore = createStore(combinedReducers, persistedState, applyMiddleware(thunk));

reduxStore.subscribe(() => {
  saveState({ state: reduxStore.getState() });
});

const App = () => (
  <Provider store={reduxStore}>
    <Suspense fallback="Loading...">
      <Router>
        <div>
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path={
              [
                '/',
              ]
            }
            >
              <HardshipBrochureLayout>
                <Route path="/" exact component={HardshipHomePage} />
              </HardshipBrochureLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/debt-relief',
                '/contact-us',
              ]
            }
            >
              <DTCLandingPageLayout>
                <Route path="/debt-relief" component={DebtRelief} />
              </DTCLandingPageLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/application/about-you',
                '/application/credit-history',
                '/application/life-events',
                '/application/your-contact',
                '/application/bank-connection',
                '/application/expenses',
                '/application/income/provide-monthly-income',
                '/application/income/reason-for-not-connecting-account',
                '/application/rental-information',
                '/application/personal-information',
                '/offers/call-or-connect-account',
                '/offers/call-us',
                '/offers/pending',
                '/offers/decline',
                '/offers/success',
                '/offers/proposed-payment-summary',
                '/offers/lender-guide',
                '/contract/sign',
                '/faq',
              ]
            }
            >
              <ApplicationLayoutFactory />
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/customer-portal/bank-connections',
                '/customer-portal/alerts',
                '/customer-portal/debt-analysis',
                '/customer-portal/expense-summary',
                '/customer-portal/faq',
                '/customer-portal/offers-summary',
                '/customer-portal/financials',
                '/customer-portal/payments',
                '/customer-portal/summary',
                '/customer-portal/your-budget',
                '/customer-portal/settings',
              ]
            }
            >
              <CustomerPortalLayout>
                <Route path="/customer-portal/bank-connections" component={CustomerBankConnections} />
                <Route path="/customer-portal/alerts" component={CustomerAlerts} />
                <Route path="/customer-portal/debt-analysis" component={CustomerDebtAnalysis} />
                <Route path="/customer-portal/expense-summary" component={CustomerExpenseSummary} />
                <Route path="/customer-portal/faq" component={CustomerFaq} />
                <Route path="/customer-portal/offers-summary" component={CustomerOffersSummary} />
                <Route path="/customer-portal/financials" component={CustomerFinancials} />
                <Route path="/customer-portal/payments" component={CustomerPayments} />
                <Route path="/customer-portal/summary" component={CustomerSummary} />
                <Route path="/customer-portal/your-budget" component={CustomerBudget} />
                <Route path="/customer-portal/settings" component={CustomerSettings} />
              </CustomerPortalLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/credit-report-policy',
                '/privacy-policy',
                '/terms-and-conditions',
                '/vendor-sharing',
              ]
            }
            >
              <LegalDocumentLayout>
                <Route path="/credit-report-policy" component={CreditReportPolicy} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                <Route path="/vendor-sharing" component={VendorSharing} />
              </LegalDocumentLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/summary',
                '/borrower-summary',
                '/preferences',
                '/borrower-queue',
                '/contact-summary',
              ]
            }
            >
              <PortalLayout>
                <Route path="/summary" component={Summary} />
                <Route path="/borrower-summary" component={BorrowerSummary} />
                <Route path="/preferences" component={Preferences} />
                <Route path="/borrower-queue" component={BorrowerQueue} />
                <Route path="/contact-summary" component={ContactSummary} />
              </PortalLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/sign-in',
                '/forgot-password',
                '/please-check-your-email',
                '/change-password',
                '/change-email',
                '/reset-password',
                '/user-information',
                '/verification-code',
              ]
            }
            >
              <OnBoardingLayout>
                <Route path="/sign-in" component={SignIn} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/please-check-your-email" component={PleaseCheckYourEmail} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/change-email" component={ChangeEmail} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/user-information" component={UserInformation} />
                <Route path="/verification-code" component={VerificationCode} />
              </OnBoardingLayout>
            </Route>
          </Switch>
          <Switch>
            <Route
              exact
              path={
              [
                '/debt-analysis',
                '/referral',
                '/renter-advantage',
              ]
            }
            >
              <LandingPageLayoutFactory />
            </Route>
          </Switch>
          <HistoryHelper />
        </div>
      </Router>
    </Suspense>
  </Provider>
);

export default App;
