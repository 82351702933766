import React, { useState } from 'react';
import Chevron from '../../../assets/icons/chevron.svg';

const Accordion = ({ question, answer, steps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <section className="container m-auto">
      <div className="faq w-full md:w-1/2 m-auto mt-5 block">
        <div>
          <div className="question" onClick={toggle} onKeyDown={toggle} role="button" tabIndex="0">
            {question}
            <img className={isOpen ? 'chevron' : ''} src={Chevron} alt="chevron" />
          </div>
          <div className={isOpen ? 'active lender-tile-animation-1 pt-5 answer-border' : 'answer'}>
            <span>{answer}</span>
            { steps
            && (
            <div>
              <br />
              <br />
              <span>{steps}</span>
            </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accordion;
