import React from 'react';
import ColorIconDetail from '../../common/ColorIconDetail/ColorIconDetail';

const BudgetCategories = () => (
  <div className="mt-12">
    <div className="l:w-3/5 text-center">
      <p className="font-bold mb-4">
        Recommended budget per category
      </p>
    </div>
    <div className="md:w-3/5 m-auto px-1 text-sm lg:text-base">
      <div className="flex flex-wrap -mx-px overflow-hidden lg:-mx-1">

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="lightblue"
            name="housing"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="primaryblue"
            name="personal"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="vibrantpink"
            name="transportation"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="darkorange"
            name="food"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="green"
            name="medical"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="darkgrey"
            name="savings"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="purple"
            name="utilities"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="mediumgrey"
            name="other"
            value={100}
          />
        </div>

        <div className="my-2 px-2 w-1/3 overflow-hidden lg:my-1 lg:px-3 lg:w-1/5">
          <ColorIconDetail
            color="darkgreen"
            name="misc"
            value={100}
          />
        </div>

      </div>
    </div>
  </div>
);

export default BudgetCategories;
