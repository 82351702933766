import React from 'react';
import useLogo from '../../../hooks/useLogo/useLogo';

const LenderLogo = ({ className, lenderName, lenderFriendlyName }) => {
  const lenderLogo = useLogo(lenderFriendlyName);

  return (
    <div>
      {lenderLogo
        ? <img className={`${className} img-container`} src={lenderLogo} alt={lenderFriendlyName} />
        : <span className="text-xs sm:text-lg">{lenderFriendlyName || lenderName}</span>}
    </div>
  );
};

export default LenderLogo;
