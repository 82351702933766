import React from 'react';
import { useSelector } from 'react-redux';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import GradientArrow from '../../../components/application/common/GradientArrow/GradientArrow';
import LenderOfferTileCarousel from '../../../components/offers/LenderOfferTileCarousel/LenderOfferTileCarousel';
import ValueDetail from '../../offers/OfferDetail/ValueDetail';

const CustomerOffersSummary = () => {
  const decision = useSelector((state) => state.decision.data);
  const offers = useSelector((state) => state.offers.data);
  const totalMonthlySavings = decision
    ? Math.round(decision.currentUnsecuredDebtPayment - decision.proposedUnsecuredDebtPayment)
    : 0;

  return (
    <div className="container mx-auto pl-2 pr-2 text-center w-full h-screen">
      <h2 className="text-xl sm:text-2xl w-full m-auto">
        Your Offer
      </h2>
      {offers.length
        ? (
          <div>
            <span className="monthly-savings-amount pt-3">
              {formatNumberAsCurrency({ number: totalMonthlySavings })}
            </span>
            <p className="w-full sm:w-1/2 m-auto text-sm">
              Total Monthly Savings
            </p>
            <div className="new-monthly-payment-tile">
              <div className="flex justify-between">
                <p className="mb-1 text-sm grey-text">Min. Monthly Payment</p>
                <p className="mb-1 text-sm">New Min. Monthly Payment</p>
              </div>
              <div className="flex justify-between">
                <h2 className="grey-text">
                  <ValueDetail
                    value={Math.round(decision.currentUnsecuredDebtPayment)}
                  />
                </h2>
                <GradientArrow
                  endColor="#fff"
                  startColor="#979797"
                />
                <h2>
                  <ValueDetail
                    value={Math.round(decision.proposedUnsecuredDebtPayment)}
                  />
                </h2>
              </div>
            </div>

            <div className="lender-carousel container mx-auto pl-2 pr-2 text-center w-full mt-16 mb-24">
              <LenderOfferTileCarousel offers={offers} />
            </div>
          </div>
        )
        : <p className="my-24">There are currently no offers</p>}
    </div>
  );
};

export default CustomerOffersSummary;
