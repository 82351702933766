import React from 'react';
import AddressLine2TextInput from '../common/AddressFields/AddressLine2TextInput/AddressLine2TextInput';

const SpouseAddressLine2TextInput = ({ errors, onBlur, onChange }) => (
  <AddressLine2TextInput
    errors={errors}
    id="spouse-address-line-2-input"
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseAddressLine2TextInput"
  />
);

export default SpouseAddressLine2TextInput;
