import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const UtilityPaymentsCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <CheckboxInput
      id="utility-payments-checkbox"
      label={translate('lifeevents:utilityPayments')}
      loadFrom="hardships"
      name="hasUtilityPayments"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default UtilityPaymentsCheckbox;
