import React from 'react';
import { Link } from 'react-router-dom';
import ButtonInput from '../../common/ButtonInput/ButtonInput';

const CustomerAlertTile = ({ alert, date, description }) => (
  <div className="customer-alert-tile">
    <div className="flex justify-center items-center py-6">
      <div className="w-1/2">
        <div className="alert-title">
          {alert}
        </div>
        <div className="alert-subtitle">
          {date || description}
        </div>
      </div>
      <div className="w-1/2">
        {
          alert === 'Bank Not Connected'
          && (
          <Link to="/customer-portal/bank-connections">
            <ButtonInput className="customer-portal-input float-right" text="Connect" />
          </Link>
          )
        }
        {
          alert === 'Account Disconnected'
          && (
          <Link to="/customer-portal/bank-connections">
            <ButtonInput className="customer-portal-input float-right" text="Reconnect" />
          </Link>
          )
        }
      </div>
    </div>
    <div className="divider" />
  </div>
);

export default CustomerAlertTile;
