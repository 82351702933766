/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrivacyPoliicy = () => {
  const { t: translate } = useTranslation();
  const privacyPolicyHeadline = (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <h2 className="text-xl sm:text-2xl w-full m-auto text-center" tabIndex="0">
      {translate('privacypolicy:content:headline')}
    </h2>
  );

  return (
    <section>
      <div className="policy-tables leading-normal tracking-normal pb-20">
        <div className="container mx-auto max-w-5xl pl-2 pr-2 pt-8 pb-8">
          <div className="pb-5">
            {privacyPolicyHeadline}
            <p className="text-center p-5">
              {translate('privacypolicy:content:date')}
            </p>
            <p>
              {translate('privacypolicy:content:parargraphOne')}
            </p>
            <br />
            <p>
              {translate('privacypolicy:content:parargraphTwo:sentenceOne')}
              {' '}
              <Link className="color-blue" target="_blank" to="/">creditgenie.com</Link>
              {translate('privacypolicy:content:parargraphTwo:sentenceTwo')}
              {' '}
              <Link className="color-blue" target="_blank" to="/">creditgenie.com</Link>
              {translate('privacypolicy:content:parargraphTwo:sentenceThree')}
            </p>
          </div>
          <div className="pb-5">
            <h2 className="text-xl">
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:headline')}
            </h2>
            <p className="pb-5">
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphOne')}
            </p>
            <p className="font-bold pb-2">
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:howAndWhyWeCollectInformationAboutYou')}
            </p>
            <p className="font-bold pb-2">
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:informationYouProvideUs')}
            </p>
            <p className="font-bold pb-2">
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:personalInformation')}
            </p>
          </div>
          <div className="pb-5">
            <p>
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphTwo')}
            </p>
            <br />
            <p>
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphThree')}
            </p>
            <ul className="list-disc pl-5 pt-2">
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemOne')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemTwo')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemThree')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemFour')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemFive')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemSix')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemSeven')}
              </li>
              <li>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:listOne:itemEight')}
              </li>
            </ul>
            <br />
            <p>
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphFour')}
            </p>
            <br />
            <p>
              {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphFive')}
              {' '}
              <a href="mailto:help@creditgenie.com" className="color-blue">help@creditgenie.com</a>.
            </p>
            <br />
            <div className="pb-5">
              <p className="font-bold pb-2">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:informationWeCollectAboutYou')}
              </p>
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:informationCollected')}
              </p>
              <p>
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphSix')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:cookieInformation')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphSeven')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphEight')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphNine')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphTen')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:contactUsByTelephone')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphEleven')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:informationThirdPartiesProvideAboutYou')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphTwelve')}
              </p>
              <p className="pb-2">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphThirteen')}
              </p>
              <ul className="list-disc pl-5 pb-5">
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemOne')}
                </li>
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemTwo')}
                </li>
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemThree')}
                </li>
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemFour')}
                </li>
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemFive')}
                </li>
                <li>
                  {translate('privacypolicy:content:changesToOurPrivacyPolicy:listTwo:itemSix')}
                </li>
              </ul>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphFourteen')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:changesToOurPrivacyPolicy:paragraphFifteen')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:useOfPersonalInformation:headline')}
              </p>
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:useOfPersonalInformation:howWeUseYourPersonalInformation')}
              </p>
              <p className="pb-2">
                {translate('privacypolicy:content:useOfPersonalInformation:paragraphOne')}
              </p>
              <p className="pb-2">
                {translate('privacypolicy:content:useOfPersonalInformation:paragraphTwo')}
              </p>
              <ul>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listOne:itemOne')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listOne:itemTwo')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listOne:itemThree')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listOne:itemFour')}
                </li>
              </ul>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:useOfPersonalInformation:aggregateData')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:useOfPersonalInformation:paragraphThree')}
              </p>
              <p className="pb-2">
                {translate('privacypolicy:content:useOfPersonalInformation:paragraphFour')}
              </p>
              <ul className="list-disc pl-5">
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listTwo:itemOne')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listTwo:itemTwo')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listTwo:itemThree')}
                </li>
                <li>
                  {translate('privacypolicy:content:useOfPersonalInformation:listTwo:itemFour')}
                </li>
              </ul>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:howWeShareYourPersonalInformation:headline')}
              </p>
              <p className="pb-2">
                {translate('privacypolicy:content:howWeShareYourPersonalInformation:paragraphOne')}
              </p>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">
                    {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemOneBoldHeadline')}
                  </span>
                  {' '}
                  {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemOne')}
                </li>
                <li>
                  <span className="font-bold">
                    {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemTwoBoldHeadline')}
                  </span>
                  {' '}
                  {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemTwo')}
                </li>
                <li>
                  <span className="font-bold">
                    {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemThreeBoldHeadline')}
                  </span>
                  {' '}
                  {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemThree')}
                </li>
                <li>
                  <span className="font-bold">
                    {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemFourBoldHeadline')}
                  </span>
                  {' '}
                  {translate('privacypolicy:content:howWeShareYourPersonalInformation:listOne:itemFour')}
                </li>
              </ul>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:headline')}
              </p>
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:whatWeDoToProtectYourPersonalInformation')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphOne')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphTwo')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphThree')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:stepsYouCanTakeToHelpUsKeepYourInformationSafe')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphFour')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphFive')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphSix')}
                {' '}
                <a href="mailto:help@creditgenie.com" className="color-blue">help@creditgenie.com</a>
                {' '}
                {translate('privacypolicy:content:protectingYourPersonalInformation:paragraphSeven')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-b">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:headline')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphOne')}
              </p>
              <table>
                <thead>
                  <tr>
                    <th>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:tableHeaderOne')}
                    </th>
                    <th>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:tableHeaderTwo')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:col2Row1')}
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:col3Row1')}
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:col4Row1')}
                    </td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:col5Row1')}
                    </td>
                    <td>No</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:yourRightsAsACaliforniaResident')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphTwo')}
              </p>
              <ul className="list-disc pl-5">
                <li>
                  {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:listOne:itemOne')}
                  {' '}
                  <a
                    className="color-blue"
                    href="/vendor-sharing"
                    target="_blank"
                  >
                    https://www.creditgenie.com/vendor-sharing
                  </a>
                  .
                </li>
                <li>
                  {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:listOne:itemTwo')}
                </li>
                <li>
                  {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:listOne:itemThree')}
                </li>
                <li>
                  {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:listOne:itemFour')}
                </li>
                <li>
                  {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:listOne:itemFive')}
                </li>
              </ul>
            </div>
            <div className="pb-5">
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphThree')}
                {' '}
                <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com</a>.
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphFour')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphFive')}
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:changingAndUpdatingYourPersonalInformation')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:yourChoicesRegardingYourPersonalInformation:paragraphSix')}
                {' '}
                <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com</a>.
              </p>
            </div>
            <div className="pb-5">
              <p className="font-bold pb-5">
                {translate('privacypolicy:content:complaintsAndConcerns:headline')}
              </p>
              <p className="pb-5">
                {translate('privacypolicy:content:complaintsAndConcerns:paragraphOne')}
                {' '}
                <a className="color-blue" href="mailto:help@creditgenie.com">help@creditgenie.com</a>.
                {' '}
                {translate('privacypolicy:content:complaintsAndConcerns:paragraphTwo')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPoliicy;
