export const SET_SHOW_VIDEO = 'SET_SHOW_VIDEO';

const setShowVideo = ({ value }) => ({
  type: SET_SHOW_VIDEO,
  value,
});

export {
  setShowVideo,
};
