import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * This event should be fired on every change of the url.
 */
const HistoryHelper = ({ history }) => {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' });
    }
  }, [history.location.key]);
  return null;
};

export default withRouter(HistoryHelper);
