import React from 'react';
import { Link } from 'react-router-dom';
import SadFaceIcon from '../../../assets/icons/sad-face_icon.svg';

const AlertModal = ({ callToAction, hideAlertModal, title }) => {
  const handleSubmitClick = () => {
    hideAlertModal();
  };

  return (
    <div className="fixed inset-0 overflow-y-auto z-20 pt-20 alert-modal-bg">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div
          // eslint-disable-next-line max-len
          className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full alert-modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white text-center px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-black mb-4">
              {title}
            </h3>

            <img src={SadFaceIcon} alt="negative alert" className="mx-auto" />

            {title === 'Bank Account Not Connected' && (
            <div className="text-black mt-6">
              <p className="mb-4">
                It looks like you don&apos;t have a checking account connected.
              </p>
              <p className="mb-4 font-bold">
                To get a better picture of your financial health, connect your primary checking account.
              </p>
            </div>
            )}

            <div className="text-center w-full">
              <Link to="/customer-portal/bank-connections">
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full"
                  data-cy="submit-bank-connection"
                  onClick={() => handleSubmitClick()}
                >
                  {callToAction}
                </button>
              </Link>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default AlertModal;
