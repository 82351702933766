import {
  CHANGE_PASSWORD, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_SUCCESS,
  CHANGE_EMAIL, CHANGE_EMAIL_FAIL, CHANGE_EMAIL_SUCCESS,
  CLEAR_RESET_PASSWORD_ERROR, CLEAR_SET_INITIAL_PASSWORD_STATUS,
  CLEAR_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED,
  CREATE_USER, CREATE_USER_FAIL, CREATE_USER_SUCCESS,
  FORGOT_PASSWORD, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_SUCCESS,
  GET_USER, GET_USER_FAIL, GET_USER_SUCCESS,
  LIST_USERS, LIST_USERS_FAIL, LIST_USERS_SUCCESS,
  RESET_PASSWORD, RESET_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS,
  SAVE_UPDATED_USER_ATTRIBUTES, SAVE_UPDATED_USER_ATTRIBUTES_FAIL, SAVE_UPDATED_USER_ATTRIBUTES_SUCCESS,
  SEND_INITIAL_EMAIL, SEND_INITIAL_EMAIL_FAIL, SEND_INITIAL_EMAIL_SUCCESS,
  SET_INITIAL_PASSWORD, SET_INITIAL_PASSWORD_FAIL, SET_INITIAL_PASSWORD_SUCCESS,
  SET_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED,
  SIGN_IN, SIGN_IN_FAIL, SIGN_IN_SUCCESS,
  SIGN_OUT, SIGN_OUT_FAIL, SIGN_OUT_SUCCESS,
  STORE_FIRST_NAME, STORE_LAST_NAME, STORE_PHONE_NUMBER, STORE_USERNAME, STORE_VERIFICATION_CODE,
} from '../actions/userActions';

const initialState = {
  data: {
    firstName: undefined, // this only exists when creating Customer Service accounts
    lastName: undefined, // this only exists when creating Customer Service accounts
    phoneNumber: undefined, // this only exists when creating Customer Service accounts
    username: undefined,
    verificationCode: undefined,
  },
  changeEmailErrorCode: undefined,
  changeEmailFailed: undefined,
  changePasswordErrorCode: undefined,
  changePasswordFailed: undefined,
  errorMessage: undefined,
  forgotPasswordFailed: undefined,
  hasChangedEmail: undefined,
  hasChangedPassword: undefined,
  hasForgottenPassword: undefined,
  hasResetPassword: undefined,
  hasSetInitialPassword: undefined,
  hasSignedIn: undefined,
  hasSignedOut: undefined,
  initialEmailWasSent: undefined,
  initialPassword: undefined,
  isChangingPassword: undefined,
  isChangingEmail: undefined,
  isProcessingForgotPassword: undefined,
  isResettingPassword: undefined,
  isSaving: undefined,
  isSendingInitialEmail: undefined,
  isSettingInitialPassword: undefined,
  isSigningIn: undefined,
  isSigningOut: undefined,
  list: [],
  mustSetInitialPassword: undefined,
  resetPasswordErrorCode: undefined,
  resetPasswordFailed: undefined,
  saveFailed: undefined,
  saveUpdatedUserAttributesSucceeded: undefined,
  signInErrorCode: undefined,
  signInFailed: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state, hasChangedPassword: undefined, isChangingPassword: true, changePasswordErrorCode: undefined, changePasswordFailed: undefined,
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state, hasChangedPassword: false, isChangingPassword: false, changePasswordErrorCode: action.errorCode, changePasswordFailed: true,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state, hasChangedPassword: true, isChangingPassword: false, changePasswordFailed: false,
      };

    case CHANGE_EMAIL:
      return {
        ...state, hasChangedEmail: undefined, isChangingEmail: true, changeEmailErrorCode: undefined, changeEmailFailed: undefined,
      };

    case CHANGE_EMAIL_FAIL:
      return {
        ...state, hasChangedEmail: false, isChangingEmail: false, changeEmailErrorCode: action.errorCode, changeEmailFailed: true,
      };

    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state, hasChangedEmail: true, isChangingEmail: false, changeEmailFailed: false,
      };

    case CLEAR_RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordErrorCode: undefined, resetPasswordFailed: undefined };

    case CLEAR_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED:
      return { ...state, saveUpdatedUserAttributesSucceeded: undefined };

    case CLEAR_SET_INITIAL_PASSWORD_STATUS:
      return { ...state, hasSetInitialPassword: undefined, isSettingInitialPassword: undefined };

    case CREATE_USER:
      return { ...state, isSaving: true, saveFailed: undefined };

    case CREATE_USER_FAIL: {
      let errorMessage;

      if (action.errorCode) {
        if (action.errorCode === 'UsernameExistsException') {
          errorMessage = 'An account with the given email address already exists. Please use a different email address.';
        }
      }

      return {
        ...state,
        errorMessage,
        isSaving: false,
        saveFailed: true,
      };
    }

    case CREATE_USER_SUCCESS:
      return {
        ...state, data: { ...state.data, ...action.user }, isSaving: false, saveFailed: false,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        data: { ...state.data, verificationCode: undefined },
        hasForgottenPassword: undefined,
        isProcessingForgotPassword: true,
        forgotPasswordFailed: undefined,
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state, hasForgottenPassword: false, isProcessingForgotPassword: false, forgotPasswordFailed: true,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: { ...state.data, username: action.username },
        hasForgottenPassword: true,
        hasResetPassword: undefined,
        isProcessingForgotPassword: false,
        forgotPasswordFailed: false,
      };

    case GET_USER:
      return {
        ...state,
        getUserFailed: undefined,
        isGettingUser: true,
      };

    case GET_USER_FAIL:
      return {
        ...state,
        getUserFailed: true,
        isGettingUser: false,
      };

    case GET_USER_SUCCESS: {
      const userObject = {};

      action.userAttributes.forEach((attribute) => {
        const {
          Name,
          Value,
        } = attribute;

        userObject[Name] = Value;
      });

      return {
        ...state,
        data: {
          ...state.data,
          userAttributes: userObject,
        },
        getUserFailed: false,
        isGettingUser: false,
      };
    }

    case LIST_USERS: {
      return {
        ...state,
        listUsersFailed: undefined,
        isGettingList: true,
      };
    }

    case LIST_USERS_FAIL:
      return {
        ...state,
        listUsersFailed: true,
        isGettingList: false,
      };

    case LIST_USERS_SUCCESS: {
      const list = [];

      action.list.forEach((user) => {
        const nameAttribute = user.Attributes.find((attribute) => attribute.Name === 'name');

        const userObject = {
          id: user.Username,
          name: nameAttribute ? nameAttribute.Value : 'Name Unavailable',
        };

        list.push(userObject);
      });

      return {
        ...state,
        list,
        listUsersFailed: false,
        isGettingList: false,
      };
    }
    case RESET_PASSWORD:
      return {
        ...state, hasResetPassword: undefined, isResettingPassword: true, resetPasswordErrorCode: undefined, resetPasswordFailed: undefined,
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state, hasResetPassword: false, isResettingPassword: false, resetPasswordErrorCode: action.errorCode, resetPasswordFailed: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state, hasResetPassword: true, isResettingPassword: false, resetPasswordFailed: false,
      };

    case SAVE_UPDATED_USER_ATTRIBUTES:
      return { ...state, saveUpdatedUserAttributesSucceeded: undefined };

    case SAVE_UPDATED_USER_ATTRIBUTES_FAIL:
      return { ...state, saveUpdatedUserAttributesSucceeded: false };

    case SAVE_UPDATED_USER_ATTRIBUTES_SUCCESS:
      return { ...state, saveUpdatedUserAttributesSucceeded: true };

    case SEND_INITIAL_EMAIL:
      return { ...state, initialEmailWasSent: undefined, isSendingInitialEmail: true };

    case SEND_INITIAL_EMAIL_FAIL:
      return { ...state, initialEmailWasSent: false, isSendingInitialEmail: false };

    case SEND_INITIAL_EMAIL_SUCCESS:
      return { ...state, initialEmailWasSent: true, isSendingInitialEmail: false };

    case SET_INITIAL_PASSWORD:
      return { ...state, hasSetInitialPassword: undefined, isSettingInitialPassword: true };

    case SET_INITIAL_PASSWORD_FAIL:
      return {
        ...state,
        hasSetInitialPassword: false,
        isSettingInitialPassword: false,
        mustSetInitialPassword: undefined,
      };

    case SET_INITIAL_PASSWORD_SUCCESS:
      return {
        ...state, hasSetInitialPassword: true, isSettingInitialPassword: false, mustSetInitialPassword: false,
      };

    case SET_SAVE_UPDATED_USER_ATTRIBUTES_SUCCEEDED:
      return { ...state, saveUpdatedUserAttributesSucceeded: true };

    case SIGN_IN:
      return {
        ...state, hasSignedIn: undefined, isSigningIn: true, signInErrorCode: undefined, signInFailed: undefined,
      };

    case SIGN_IN_FAIL:
      return {
        ...state, hasSignedIn: false, isSigningIn: false, signInErrorCode: action.errorCode, signInFailed: true,
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        data: { ...state.data, username: action.user.username },
        hasSignedIn: true,
        isSigningIn: false,
        mustSetInitialPassword: action.mustSetInitialPassword,
        signInFailed: false,
      };

    case SIGN_OUT:
      return { ...state, hasSignedOut: undefined, isSigningOut: true };

    case SIGN_OUT_FAIL:
      return { ...state, hasSignedOut: false, isSigningOut: false };

    case SIGN_OUT_SUCCESS:
      return {
        ...state, data: initialState.data, hasSignedIn: false, hasSignedOut: true, isSigningOut: false,
      };

    case STORE_FIRST_NAME:
      return { ...state, data: { ...state.data, firstName: action.firstName } };

    case STORE_LAST_NAME:
      return { ...state, data: { ...state.data, lastName: action.lastName } };

    case STORE_PHONE_NUMBER:
      return { ...state, data: { ...state.data, phoneNumber: action.phoneNumber } };

    case STORE_USERNAME:
      return { ...state, data: { ...state.data, username: action.username } };

    case STORE_VERIFICATION_CODE:
      return { ...state, data: { ...state.data, verificationCode: action.verificationCode } };

    default:
      return state;
  }
};

export default userReducer;
