import React from 'react';
import CurrencyInput from '../common/CurrencyInput';

const MonthlyRentOrMortgagePaymentAmountInput = ({ errors, onChange }) => (
  <div className="pt-2 estimated-housing-cost">
    <CurrencyInput
      allowZero
      ariaLabel="estimated monthly housing cost"
      errors={errors}
      id="monthly-rent-or-mortgage-payment-amount-input"
      label="What is your estimated monthly housing cost?"
      loadFrom="application"
      name="monthlyRentOrMortgageAmount"
      onChange={onChange}
      maxLength={6}
    />
    <p className="input-tool-tip">This includes rent or mortgage expenses, condo fees, insurance, and taxes.</p>
  </div>
);

export default MonthlyRentOrMortgagePaymentAmountInput;
