import React from 'react';
import { capitalize, startCase } from 'lodash';
import SearchFilterCheckBox from '../../SearchFilter/SearchFilterCheckBox';

const DebtSummaryFilter = ({
  filterErrors,
  filterState,
  onFilterChange,
  onFilterReset,
  onSubmit,
  tempFilterState,
  toggle,
}) => {
  const accountTypes = Object.keys(filterState.accountType);
  const debtDetails = Object.keys(filterState.debtDetail);
  const debtStatuses = Object.keys(filterState.debtStatus);
  const debtTypes = Object.keys(filterState.debtType);
  const portfolioTypes = Object.keys(filterState.portfolioType);

  const numberOfFilterErrors = filterErrors ? Object.keys(filterErrors).length : 0;
  const filterContentsClass = `filter-contents${filterErrors ? ` filter-contents-with-error-${numberOfFilterErrors}` : ''}`;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="filter-container">
          <div className={filterContentsClass}>
            <div className="filter-header">
              <h1>FILTER BY</h1>
              <button
                className="text-red-600"
                onClick={onFilterReset}
                type="button"
              >
                Reset to Default
              </button>
              <hr />
            </div>
            <div className="filter-group">
              <div className="filter-title">
                <h2>Shared or Single Debt</h2>
              </div>
              <div className="flex flex-wrap mb-4">
                {debtTypes.map((debtType) => (
                  <div
                    className="w-1/2 h-12"
                    key={`${debtType.replace(/\s+/g, '-')}-filter`}
                  >
                    <SearchFilterCheckBox
                      filterCategory="debtType"
                      id={`${debtType.replace(/\s+/g, '-')}-filter`}
                      label={capitalize(debtType)}
                      filterState={filterState.debtType}
                      name={debtType}
                      onChange={onFilterChange}
                      tempFilterState={tempFilterState.debtType}
                    />
                  </div>
                ))}
                {filterErrors && filterErrors.debtType && (
                  <p className="failure filter-failure w-full">
                    {filterErrors.debtType}
                  </p>
                )}
              </div>
              <div className="filter-title">
                <h2>Debt Status</h2>
              </div>
              <div className="flex flex-wrap mb-4">
                {debtStatuses.map((debtStatus) => (
                  <div
                    className="w-1/2 h-12"
                    key={`${debtStatus.replace(/\s+/g, '-')}-filter`}
                  >
                    <SearchFilterCheckBox
                      filterCategory="debtStatus"
                      id={`${debtStatus.replace(/\s+/g, '-')}-filter`}
                      label={capitalize(debtStatus)}
                      filterState={filterState.debtStatus}
                      name={debtStatus}
                      onChange={onFilterChange}
                      tempFilterState={tempFilterState.debtStatus}
                    />
                  </div>
                ))}
                {filterErrors && filterErrors.debtStatus && (
                  <p className="failure filter-failure w-full">
                    {filterErrors.debtStatus}
                  </p>
                )}
              </div>
              {tempFilterState.debtStatus.open && (
                <div>
                  <div className="filter-title">
                    <h2>Debt Details</h2>
                  </div>
                  <div className="flex flex-wrap mb-4">
                    {debtDetails.map((debtDetail) => (
                      <div
                        className="w-1/2 h-12"
                        key={`${debtDetail.replace(/\s+/g, '-')}-filter`}
                      >
                        <SearchFilterCheckBox
                          filterCategory="debtDetail"
                          id={`${debtDetail.replace(/\s+/g, '-')}-filter`}
                          label={startCase(debtDetail)}
                          filterState={filterState.debtDetail}
                          name={debtDetail}
                          onChange={onFilterChange}
                          tempFilterState={tempFilterState.debtDetail}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="filter-title">
                <h2>Portfolio Types</h2>
              </div>
              <div className="flex flex-wrap mb-4">
                {portfolioTypes.map((portfolioType) => (
                  <div
                    className="w-1/2 h-12"
                    key={`${portfolioType.replace(/\s+/g, '-')}-portfolioType-filter`}
                  >
                    <SearchFilterCheckBox
                      filterCategory="portfolioType"
                      id={`${portfolioType.replace(/\s+/g, '-')}-portfolioType-filter`}
                      label={startCase(portfolioType)}
                      filterState={filterState.portfolioType}
                      name={portfolioType}
                      onChange={onFilterChange}
                      tempFilterState={tempFilterState.portfolioType}
                    />
                  </div>
                ))}
                {filterErrors && filterErrors.portfolioType && (
                  <p className="failure filter-failure w-full">
                    {filterErrors.portfolioType}
                  </p>
                )}
              </div>
            </div>
            <div className="filter-title">
              <h2>Account Types</h2>
            </div>
            <div className="flex flex-wrap mb-4">
              {accountTypes.map((accountType) => (
                <div
                  className="w-1/2 h-12"
                  key={`${accountType.replace(/\s+/g, '-')}-accountType-filter`}
                >
                  <SearchFilterCheckBox
                    filterCategory="accountType"
                    id={`${accountType.replace(/\s+/g, '-')}-accountType-filter`}
                    label={startCase(accountType)}
                    filterState={filterState.accountType}
                    name={accountType}
                    onChange={onFilterChange}
                    tempFilterState={tempFilterState.accountType}
                  />
                </div>
              ))}
              {filterErrors && filterErrors.accountType && (
                <p className="failure filter-failure w-full">
                  {filterErrors.accountType}
                </p>
              )}
            </div>
            <div className="flex py-5">
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className="cancel-value w-1/2 cursor-pointer"
                onClick={toggle}
                onKeyDown={toggle}
              >
                Cancel
              </p>
              <button type="submit" className="call-to-action w-1/2">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DebtSummaryFilter;
