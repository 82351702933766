
import { API, graphqlOperation } from 'aws-amplify';
import { createDmpEnrollment, updateApplication, updateDmpEnrollment } from '../../graphql/customMutations';
import { formatApplicationDateForAws } from '../../formatters/dateFormatter';

export const CANCEL_DMP_ENROLLMENT_CHANGES = 'CANCEL_DMP_ENROLLMENT_CHANGES';
export const SAVE_DMP_ENROLLMENT = 'SAVE_DMP_ENROLLMENT';
export const SAVE_DMP_ENROLLMENT_FAIL = 'SAVE_DMP_ENROLLMENT_FAIL';
export const SAVE_DMP_ENROLLMENT_SUCCESS = 'SAVE_DMP_ENROLLMENT_SUCCESS';
export const SET_DMP_ENROLLMENT = 'SET_DMP_ENROLLMENT';
export const SET_SAVE_DMP_ENROLLMENT_SUCCEEDED = 'SET_SAVE_DMP_ENROLLMENT_SUCCEEDED';
export const SET_SHOW_DMP_ENROLLMENT_FORM = 'SET_SHOW_DMP_ENROLLMENT_FORM';
export const UPDATE_DMP_ENROLLMENT = 'UPDATE_DMP_ENROLLMENT';

const cancelDmpEnrollmentChanges = () => ({ type: CANCEL_DMP_ENROLLMENT_CHANGES });

const saveDmpEnrollment = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_DMP_ENROLLMENT });

  const state = getState();

  const applicationId = state.application.data.id;
  const dmpEnrollment = state.dmpEnrollment.data;

  const formattedActiveAt = formatApplicationDateForAws({ applicationDate: dmpEnrollment.activeAt });
  const formattedDateSigned = formatApplicationDateForAws({ applicationDate: dmpEnrollment.dateSigned });
  const formattedFirstPaymentDate = formatApplicationDateForAws({ applicationDate: dmpEnrollment.firstPaymentDate });
  const formattedInactiveAt = formatApplicationDateForAws({ applicationDate: dmpEnrollment.inactiveAt });

  const input = {
    ...dmpEnrollment,
    activeAt: formattedActiveAt,
    dateSigned: formattedDateSigned,
    dmpEnrollmentApplicationId: applicationId,
    firstPaymentDate: formattedFirstPaymentDate,
    inactiveAt: formattedInactiveAt,
    termInMonths: dmpEnrollment.termInMonths,
  };

  const saveOperation = dmpEnrollment.id ? updateDmpEnrollment : createDmpEnrollment;

  try {
    const saveDmpEnrollmentResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const savedDmpEnrollment = (saveDmpEnrollmentResponse.data.createDmpEnrollment || saveDmpEnrollmentResponse.data.updateDmpEnrollment);

    dispatch({ dmpEnrollment: { ...dmpEnrollment, id: savedDmpEnrollment.id }, type: SAVE_DMP_ENROLLMENT_SUCCESS });

    if (!savedDmpEnrollment.application.dmpEnrollment) {
      try {
        await API.graphql(graphqlOperation(updateApplication, { input: { id: applicationId, applicationDmpEnrollmentId: savedDmpEnrollment.id } }));
      } catch (error) {
        console.log('error saving dmpEnrollment to application', error);
      }
    }
  } catch (error) {
    console.log('saveDmpEnrollment failed:', error);
    dispatch({ type: SAVE_DMP_ENROLLMENT_FAIL });
  }
};

const setDmpEnrollment = ({ dmpEnrollment }) => ({ dmpEnrollment, type: SET_DMP_ENROLLMENT });

const setSaveDmpEnrollmentSucceeded = () => ({ type: SET_SAVE_DMP_ENROLLMENT_SUCCEEDED });

const setShowDmpEnrollmentForm = ({ value }) => ({
  type: SET_SHOW_DMP_ENROLLMENT_FORM,
  value,
});

const updateDmpEnrollmentInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_DMP_ENROLLMENT,
  value,
});

export {
  cancelDmpEnrollmentChanges,
  saveDmpEnrollment,
  setDmpEnrollment,
  setSaveDmpEnrollmentSucceeded,
  setShowDmpEnrollmentForm,
  updateDmpEnrollmentInformation as updateDmpEnrollment,
};
