import React from 'react';
import { DateTime } from 'luxon';
import DateInput from '../../../application/common/DateInput/DateInput';

const DmpDateActiveDateInput = (
  {
    errors = {},
    id,
    onChange,
    validationName,
  },
) => {
  const oneYearAgo = DateTime.local().minus({ years: 1 }).toFormat('MM-dd-yyyy');
  const oneYearFromNow = DateTime.local().plus({ years: 1 }).toFormat('MM-dd-yyyy');

  return (
    <DateInput
      errors={errors}
      id={id}
      label="Date DMP became active"
      loadFrom="dmpEnrollment"
      maxDateAllowed={oneYearFromNow}
      minDateAllowed={oneYearAgo}
      name="activeAt"
      onChange={onChange}
      validationName={validationName}
    />
  );
};

export default DmpDateActiveDateInput;
