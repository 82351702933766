import {
  SAVE_ALERT,
  SAVE_ALERT_FAIL,
  SAVE_ALERT_SUCCESS,
  SET_ALERTS,
  SET_EVENT_DATE,
  UPDATE_ALERT,
} from '../actions/alertActions';

const initialState = {
  data: [
    {
      borrowerId: undefined,
      hasBeenSeenByBorrower: undefined,
      hasBeenSeenByCustomerServiceRepresentative: undefined,
      id: undefined,
      triggeredBy: [
        {
          id: undefined,
          type: undefined,
        },
      ],
      type: undefined,
    },
  ],
  eventDates: {
    // alertId to eventDate mapping
  },
  isSaving: undefined,
  savingFailed: undefined,
  savingSucceeded: undefined,
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ALERT: {
      return {
        ...state,
        isSaving: true,
        savingFailed: undefined,
        savingSucceeded: undefined,
      };
    }

    case SAVE_ALERT_FAIL: {
      return {
        ...state,
        isSaving: false,
        savingFailed: true,
        savingSucceeded: false,
      };
    }

    case SAVE_ALERT_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        savingFailed: false,
        savingSucceeded: true,
      };
    }

    case SET_ALERTS: {
      return { ...state, data: action.alerts };
    }

    case SET_EVENT_DATE: {
      return { ...state, eventDates: { ...state.eventDates, [action.alertId]: action.eventDate } };
    }

    case UPDATE_ALERT: {
      const { alertId, propertyName, value } = action;

      const alerts = [...state.data].map((alert) => {
        if (alert.id === alertId) {
          return { ...alert, [propertyName]: value };
        }

        return alert;
      });

      return { ...state, data: alerts };
    }

    default:
      return state;
  }
};

export default alertsReducer;
