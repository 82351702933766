import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';
import { setAgreedToTerms } from '../../../redux/actions/applicationActions';

const TermsAgreementCheckbox = ({ errors }) => {
  const agreedToTerms = useSelector((state) => state.application.agreedToTerms);
  const dispatch = useDispatch();
  const name = 'agreedToTerms';
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    dispatch(setAgreedToTerms({ agreedToTerms: event.target.checked }));
  };

  return (
    <section>
      <div className="pt-2">
        <CheckboxInput
          ariaLabel="I agree to Credit Genie's Terms and Conditions and Privacy Policy"
          checked={agreedToTerms}
          className="inline-block pb-0"
          errors={errors}
          id="terms-agreement-checkbox"
          name={name}
          label="I agree to Credit Genie's Terms and Conditions"
          labelClass="hidden"
          onChange={onChange}
          required
        />
        <p className="terms text-xs pt-2" data-cy="termscheckboxlinks">
          {translate('rentalaboutyou:aboutYou:checkboxCopy1:text')}
          <Link id="terms-and-conditions-link" to="/terms-and-conditions" target="_blank">
            {' '}
            {translate('rentalaboutyou:aboutYou:termsAndConditions:text')}
          </Link>
          {' '}
          {translate('rentalaboutyou:aboutYou:and:text')}
          {' '}
          <Link id="privacy-policy-link" to="/privacy-policy" target="_blank">
            {' '}
            {translate('rentalaboutyou:aboutYou:privacyPolicy:text')}
          </Link>
          {' '}
          {translate('rentalaboutyou:aboutYou:creditGenie:text')}
        </p>
        {errors[name] === 'required'
        && <p className="mt-4 validation" id="required-error">{translate('global:errorMessages:termsAgreementIsRequired')}</p>}
      </div>
    </section>
  );
};

export default TermsAgreementCheckbox;
