import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import YesNoRadioButtonInput, { noValue, yesValue } from '../../application/common/RadioButtonInput/YesNoRadioButtonInput';

const DoYouOweBackRent = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  const checkedValue = useSelector((state) => {
    const { hasBackRent } = state.rentalApplication.data;

    if (hasBackRent) {
      return yesValue;
    }

    if (hasBackRent === false) {
      return noValue;
    }

    return undefined;
  });

  return (
    <div>
      <YesNoRadioButtonInput
        checkedValue={checkedValue}
        errors={errors}
        id="do-you-owe-back-rent-radio-button-input"
        name="hasBackRent"
        onChange={onChange}
        question={translate('rentalinformation:doYouOweBackRent')}
      />
    </div>
  );
};

export default DoYouOweBackRent;
