import React from 'react';
import YesNoRadioButtonInput from '../../../application/common/RadioButtonInput';

const DmpActiveInput = ({ errors, onChange }) => (
  <YesNoRadioButtonInput
    errors={errors}
    id="is-the-borrower-enrolled-in-this-dmp-input"
    loadFrom="dmpEnrollment"
    name="active"
    onChange={onChange}
    question="Is the borrower enrolled in this DMP?"
  />
);

export default DmpActiveInput;
