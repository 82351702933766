import React from 'react';
import BackButton from '../../../components/application/common/BackButton';
import Checkmark from '../../../assets/images/checkmark.svg';

const PleaseCheckYourEmail = ({ history }) => {
  const message = history.location.state && history.location.state.message;

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <BackButton />
          <img className="block m-auto pt-2" src={Checkmark} alt="Checkmark Icon" />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Please Check Your E-mail
          </h2>
          <p className="text-center">
            Please check your e-mail for your verification code,
            {' '}
            <br />
            which you can use to reset your password.
          </p>
        </div>
        {message && <p className="failure sm:w-1/2 w-full">{message}</p>}
      </div>
    </div>
  );
};

export default PleaseCheckYourEmail;
