import React from 'react';

const FilterResults = ({ data, dataName, textColor = 'black' }) => (
  <div className={`results text-${textColor}`}>
    Results:
    <span className="font-bold">{` ${data.length} `}</span>
    {`${dataName} are displayed.`}
  </div>
);

export default FilterResults;
