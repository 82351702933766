import {
  CANCEL_DMP_ENROLLMENT_CHANGES,
  SAVE_DMP_ENROLLMENT,
  SAVE_DMP_ENROLLMENT_FAIL,
  SAVE_DMP_ENROLLMENT_SUCCESS,
  SET_DMP_ENROLLMENT,
  SET_SAVE_DMP_ENROLLMENT_SUCCEEDED,
  SET_SHOW_DMP_ENROLLMENT_FORM,
  UPDATE_DMP_ENROLLMENT,
} from '../actions/dmpEnrollmentActions';
import { formatAwsDateForApplication } from '../../formatters/dateFormatter';

const initialState = {
  data: {
    active: undefined,
    activeAt: undefined,
    dateSigned: undefined,
    firstPaymentDate: undefined,
    id: undefined,
    inactiveAt: undefined,
    monthlyPaymentAmount: undefined,
    termInMonths: undefined,
  },
  hasGottenDmpEnrollment: undefined,
  isEnrolledInADmp: undefined,
  isGettingDMPEnrollment: undefined,
  isSaving: undefined,
  originalActiveValue: undefined,
  originalActiveAtValue: undefined,
  originalDateSignedValue: undefined,
  originalFirstPaymentDateValue: undefined,
  originalInactiveAtValue: undefined,
  originalMonthlyPaymentAmountValue: undefined,
  originalTermInMonthsValue: undefined,
  saveFailed: undefined,
  saveSuccess: undefined,
  showDmpEnrollmentForm: false,
};

const dmpEnrollmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_DMP_ENROLLMENT_CHANGES: {
      return {
        ...state,
        data: {
          ...state.data,
          active: state.originalActiveValue,
          activeAt: state.originalActiveAtValue,
          dateSigned: state.originalDateSignedValue,
          firstPaymentDate: state.originalFirstPaymentDateValue,
          inactiveAt: state.originalInactiveAtValue,
          monthlyPaymentAmount: state.originalMonthlyPaymentAmountValue,
          termInMonths: state.originalTermInMonthsValue,
        },
      };
    }

    case SAVE_DMP_ENROLLMENT:
      return {
        ...state, isSaving: true, saveFailed: undefined, saveSuccess: undefined,
      };

    case SAVE_DMP_ENROLLMENT_FAIL:
      return {
        ...state, isSaving: false, saveFailed: true, saveSuccess: false,
      };

    case SAVE_DMP_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        data: action.dmpEnrollment,
        isEnrolledInADmp: action.dmpEnrollment.active,
        isSaving: false,
        originalActiveValue: action.dmpEnrollment.active,
        originalActiveAtValue: action.dmpEnrollment.activeAt,
        originalDateSignedValue: action.dmpEnrollment.dateSigned,
        originalFirstPaymentDateValue: action.dmpEnrollment.firstPaymentDate,
        originalInactiveAtValue: action.dmpEnrollment.inactiveAt,
        originalMonthlyPaymentAmountValue: action.dmpEnrollment.monthlyPaymentAmount,
        originalTermInMonthsValue: action.dmpEnrollment.termInMonths,
        saveFailed: false,
        saveSuccess: true,
      };
    }

    case SET_DMP_ENROLLMENT: {
      if (!action.dmpEnrollment) {
        return {
          ...state,
          data: { ...initialState.data },
          isEnrolledInADmp: false,
          originalActiveValue: undefined,
          originalActiveAtValue: undefined,
          originalDateSignedValue: undefined,
          originalFirstPaymentDateValue: undefined,
          originalInactiveAtValue: undefined,
          originalMonthlyPaymentAmountValue: undefined,
          originalTermInMonthsValue: undefined,
        };
      }

      const activeAt = formatAwsDateForApplication({ awsDate: action.dmpEnrollment.activeAt });
      const dateSigned = formatAwsDateForApplication({ awsDate: action.dmpEnrollment.dateSigned });
      const firstPaymentDate = formatAwsDateForApplication({ awsDate: action.dmpEnrollment.firstPaymentDate });
      const inactiveAt = formatAwsDateForApplication({ awsDate: action.dmpEnrollment.inactiveAt });

      return {
        ...state,
        data: {
          ...action.dmpEnrollment,
          activeAt,
          dateSigned,
          firstPaymentDate,
          inactiveAt,
        },
        isEnrolledInADmp: action.dmpEnrollment.active,
        originalActiveValue: action.dmpEnrollment.active,
        originalActiveAtValue: activeAt,
        originalDateSignedValue: dateSigned,
        originalFirstPaymentDateValue: firstPaymentDate,
        originalInactiveAtValue: inactiveAt,
        originalMonthlyPaymentAmountValue: action.dmpEnrollment.monthlyPaymentAmount,
        originalTermInMonthsValue: action.dmpEnrollment.termInMonths,
      };
    }

    case SET_SAVE_DMP_ENROLLMENT_SUCCEEDED: {
      return { ...state, saveSuccess: true };
    }

    case SET_SHOW_DMP_ENROLLMENT_FORM: {
      return {
        ...state,
        saveFailed: undefined,
        saveSuccess: undefined,
        showDmpEnrollmentForm: action.value,
      };
    }

    case UPDATE_DMP_ENROLLMENT: {
      return { ...state, data: { ...state.data, [action.propertyName]: action.value } };
    }

    default:
      return state;
  }
};

export default dmpEnrollmentReducer;
