import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '../../../../assets/icons/close_icon.svg';
import DownArrow from '../../../../assets/icons/down-arrow-white_icon.svg';
import { getCurrentAuthenticatedUser, doesUserNeedToChangePassword, isUserSignedIn } from '../../../../helpers/authHelper';
import Hamburger from '../../../../assets/icons/hamburger_icon-KO.svg';
import logo from '../../../../assets/logos/creditgenie_logo_KO.svg';
import { signOut } from '../../../../redux/actions/userActions';
import useOutsideClick from '../../../../hooks/useOutsideClick/useOutsideClick';

const Header = ({ revealNewApplicationModal }) => {
  const defaultUser = { attributes: { name: undefined } };
  const dispatch = useDispatch();
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const [hideMobileNav] = useState(true);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const nameDropdownRef = useRef();
  const [showMobileNav, toggleMobileNav] = useState(false);
  const [user, setUser] = useState(defaultUser);
  const [userHasClickedSignOutLink, setUserHasClickedSignOutLink] = useState(false);
  const userHasResetPassword = useSelector((state) => state.user.hasResetPassword);

  const shouldShowStartApplicationButton = ['cesi', 'creditorg', 'navicore'].includes(environment);

  const onSignOut = (event) => {
    event.preventDefault();

    setUserHasClickedSignOutLink(true);

    dispatch(signOut());
  };

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (userHasClickedSignOutLink) {
      history.push('/sign-in');

      return;
    }

    const checkIfUserIsSignedIn = async () => {
      const isSignedIn = await isUserSignedIn();

      if (!isSignedIn) {
        history.push('/sign-in');

        return;
      }

      if (!userHasResetPassword) {
        const userNeedsToChangePassword = await doesUserNeedToChangePassword();

        if (userNeedsToChangePassword) {
          console.log('going from header to /please-check-your-email - user needs to change password!');

          history.push('/please-check-your-email');

          return;
        }
      }

      if (user === defaultUser) {
        const currentUser = await getCurrentAuthenticatedUser();

        setUser(currentUser);
      }
    };

    checkIfUserIsSignedIn();

    if (showMobileNav) {
      history.listen(() => {
        toggleMobileNav(!hideMobileNav);
      });
    }
  });

  useOutsideClick(nameDropdownRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="portal-header">
      <nav className="navigation container flex items-center justify-between flex-wrap pt-6 pb-6 m-auto">
        <div
          role="button"
          tabIndex="0"
          className="hamburger sm:ml-4 ml-0 cursor-pointer"
          onKeyDown={() => toggleMobileNav(!showMobileNav)}
          onClick={() => toggleMobileNav(!showMobileNav)}
        >
          <img src={Hamburger} alt="Hamburger Icon" />
        </div>
        {showMobileNav && (
        <div className="mobile-nav">
          <Link to="/summary">
            <img className="h-8 mr-2" src={logo} alt="Credit Genie Logo Blue" />
          </Link>
          <div
            role="button"
            tabIndex="0"
            onClick={() => toggleMobileNav(!hideMobileNav)}
            onKeyDown={() => toggleMobileNav(!hideMobileNav)}
          >
            <img src={CloseIcon} className="close-icon float-right" alt="Close X Icon" />
          </div>
          <div>
            <ul className="list-reset lg:flex justify-end flex-1 items-center">
              {
                shouldShowStartApplicationButton && (
                <li className="mr-3 py-2 px-4">
                  <button type="button" onClick={revealNewApplicationModal} className="inline-block py-2 pr-4 no-underline">
                    Start Application
                  </button>
                </li>
                )
              }
              <li className="mr-3 py-2 px-4">
                <Link to="/borrower-queue" className="inline-block py-2 pr-4 no-underline">
                  Borrower Queue
                </Link>
              </li>
              <li className="mr-3 py-2 px-4">
                <Link to="/summary" className="inline-block no-underline">
                  Summary
                </Link>
              </li>
              <li className="mr-3 py-2 px-4">
                <Link to="/preferences">Preferences</Link>
              </li>
              <li className="mr-3 py-2 px-4">
                <Link onClick={onSignOut} to="/sign-in">Sign Out</Link>
              </li>
            </ul>
          </div>
        </div>
        )}
        <div className="logo flex items-center flex-no-shrink lg:mr-6 mx-auto mt-2">
          <Link to="/summary">
            <img className="h-8 mr-2" src={logo} alt="Credit Genie Logo Blue" />
          </Link>
        </div>
        <div className="
          w-full block lg:items-center lg:w-autow-full flex-grow lg:flex lg:items-center
          lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            {
              shouldShowStartApplicationButton && (
              <li className="mr-3 py-2 px-4">
                <button type="button" onClick={revealNewApplicationModal} className="inline-block py-2 pr-4 no-underline">
                  Start Application
                </button>
              </li>
              )
            }
            <li>
              <Link to="/borrower-queue" className="inline-block py-2 pr-4 no-underline">
                Borrower Queue
              </Link>
            </li>
            <li>
              <Link to="/summary" className="inline-block py-2 px-4 no-underline">
                Summary
              </Link>
            </li>
            <li className="ml-3">
              <ul>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <li className={isOpen ? 'm-menu -active' : 'm-menu '} onClick={toggle} onKeyDown={toggle} ref={nameDropdownRef}>
                  <button type="button" className="dropdown-btn" onClick={toggle}>
                    {user.attributes.name}
                    <img src={DownArrow} alt="Down Arrow Icon" />
                  </button>
                  <ul className="m-menu__list">
                    <li className="m-menu__item">
                      <Link to="/preferences" className="m-menu__link">Preferences</Link>
                    </li>
                    <li className="m-menu__item">
                      <Link onClick={onSignOut} to="/sign-in"><div className="m-menu__link">Sign Out</div></Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
