import { API, graphqlOperation } from 'aws-amplify';
import { createRentalApplication, updateRentalApplication } from '../../graphql/customMutations';

export const CLEAR_SAVE_RENTAL_APPLICATION_SUCCEEDED = 'CLEAR_SAVE_RENTAL_APPLICATION_SUCCEEDED';
export const SAVE_RENTAL_APPLICATION = 'SAVE_RENTAL_APPLICATION';
export const SAVE_RENTAL_APPLICATION_FAIL = 'SAVE_RENTAL_APPLICATION_FAIL';
export const SAVE_RENTAL_APPLICATION_SUCCESS = 'SAVE_RENTAL_APPLICATION_SUCCESS';
export const SET_RENTAL_APPLICATION = 'SET_RENTAL_APPLICATION';
export const SET_SAVE_RENTAL_APPLICATION_SUCCEEDED = 'SET_SAVE_RENTAL_APPLICATION_SUCCEEDED';
export const UPDATE_RENTAL_APPLICATION = 'UPDATE_RENTAL_APPLICATION';

const clearSaveRentalApplicationSucceeded = () => ({ type: CLEAR_SAVE_RENTAL_APPLICATION_SUCCEEDED });

const saveRentalApplication = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_RENTAL_APPLICATION });

  const state = getState();

  const borrowerId = state.borrower.data.id;
  const rentalApplication = state.rentalApplication.data;

  const saveOperation = rentalApplication.id ? updateRentalApplication : createRentalApplication;

  try {
    const input = { ...rentalApplication, rentalApplicationBorrowerId: borrowerId };

    const saveResponse = await API.graphql(graphqlOperation(saveOperation, { input }));

    const rentalApplicationId = rentalApplication.id || saveResponse.data.createRentalApplication.id;

    dispatch({ rentalApplication: { ...rentalApplication, id: rentalApplicationId }, type: SAVE_RENTAL_APPLICATION_SUCCESS });
  } catch (error) {
    console.log('saveRentalApplication failed:', error);

    dispatch({ type: SAVE_RENTAL_APPLICATION_FAIL });
  }
};

const setSaveRentalApplicationSucceeded = () => ({ type: SET_SAVE_RENTAL_APPLICATION_SUCCEEDED });

const updateRentalApplicationInformation = ({ propertyName, value }) => ({
  propertyName,
  type: UPDATE_RENTAL_APPLICATION,
  value,
});

export {
  clearSaveRentalApplicationSucceeded,
  saveRentalApplication,
  setSaveRentalApplicationSucceeded,
  updateRentalApplicationInformation as updateRentalApplication,
};
