import { CREATE_DOCUSIGN_CONTRACT, CREATE_DOCUSIGN_CONTRACT_FAIL, CREATE_DOCUSIGN_CONTRACT_SUCCESS } from '../actions/docusignActions';

const initialState = {
  data: {
    url: undefined,
  },
  getDocuSignFailed: undefined,
  isCreatingDocuSignContract: undefined,
};

const docusignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DOCUSIGN_CONTRACT:
      return {
        ...state,
        data: { ...state.data, url: undefined },
        getDocuSignFailed: undefined,
        isCreatingDocuSignContract: true,
      };

    case CREATE_DOCUSIGN_CONTRACT_FAIL:
      return {
        ...state,
        getDocuSignFailed: true,
        isCreatingDocuSignContract: false,
      };

    case CREATE_DOCUSIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, url: action.url },
        getDocuSignFailed: false,
        isCreatingDocuSignContract: false,
      };

    default:
      return state;
  }
};

export default docusignReducer;
