import {
  SET_SHOW_VIDEO,
} from '../actions/videoActions';

const initialState = {
  showVideo: false,
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_VIDEO: {
      return {
        ...state,
        showVideo: action.value,
      };
    }

    default:
      return state;
  }
};

export default videoReducer;
