import { Link } from 'react-router-dom';
import React from 'react';
import Logo from '../../../assets/logos/creditgenie_logo_KO.svg';

const CreditGenieFooter = () => (
  <section className="credit-genie-footer py-8">
    <div className="container mx-auto mt-8 mb-8">
      <div className="ml-2 sm:ml-0">
        <img src={Logo} alt="NFCC Logo" />
        <div className="pt-4 text-white">
          Creditly Corp. Copyright &copy;
          {' '}
          {(new Date()).getFullYear()}
        </div>
        <div className="divider w-1/4 my-4" />
        <div>
          <Link className="text-white" to="/credit-report-policy" target="_blank">
            Credit report policy
          </Link>
        </div>
        <div className="pt-2">
          <Link className="text-white" to="/terms-and-conditions" target="_blank">
            Terms and conditions
          </Link>
        </div>
        <div className="pt-2">
          <Link className="text-white" to="/privacy-policy" target="_blank">
            Privacy policy
          </Link>
        </div>
      </div>
    </div>
  </section>
);


export default CreditGenieFooter;
