import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearHasDependents, setHasDependents } from '../../../redux/actions/applicationActions';
import NumberOfDependentsInHouseholdDropdownInput from '../NumberOfDependentsInHouseholdDrowdownInput/NumberOfDependentsInHouseholdDropdownInput';
import ToolTip from '../../common/ToolTip';
import YesNoRadioButtonInput, { noValue, yesValue } from '../common/RadioButtonInput/YesNoRadioButtonInput';

const DoYouHaveAnyDependents = ({ errors, onChange }) => {
  const { t: translate } = useTranslation();
  const checkedValue = useSelector((state) => {
    if (state.application.hasDependents === undefined || state.application.hasDependents === null) {
      if (state.application.data.numberOfDependentsInHousehold) {
        return yesValue;
      }

      return state.application.data.numberOfDependentsInHousehold === 0 ? noValue : undefined;
    }

    return state.application.hasDependents ? yesValue : noValue;
  });

  const dispatch = useDispatch();
  const hasDependents = useSelector((state) => state.application.hasDependents || state.application.data.numberOfDependentsInHousehold > 0);
  const numberOfDependentsInHouseholdSelectId = 'number-of-dependents-in-household-select';
  const numberOfDependentsInHouseholdSelectName = 'numberOfDependentsInHousehold';

  const onNumberOfDependentsChange = (event) => {
    const baseTen = 10;
    const numericValue = parseInt(event.target.value, baseTen);

    onChange(event, numericValue || 0);
  };

  const onHasDependentsChange = (event, valueOverride) => {
    if (valueOverride) {
      dispatch(setHasDependents());

      return;
    }

    dispatch(clearHasDependents());

    const numberOfDependentsInHouseholdElement = document.getElementById(numberOfDependentsInHouseholdSelectId);

    if (!numberOfDependentsInHouseholdElement) {
      const temporaryNumberOfDependentsInHouseholdElement = document.createElement('select');

      temporaryNumberOfDependentsInHouseholdElement.name = numberOfDependentsInHouseholdSelectName;
      temporaryNumberOfDependentsInHouseholdElement.value = 0;

      const inputChangeEvent = new Event('change', { bubbles: true, target: temporaryNumberOfDependentsInHouseholdElement });

      temporaryNumberOfDependentsInHouseholdElement.addEventListener('change', onNumberOfDependentsChange);
      temporaryNumberOfDependentsInHouseholdElement.dispatchEvent(inputChangeEvent);
      temporaryNumberOfDependentsInHouseholdElement.removeEventListener('change', onNumberOfDependentsChange);

      return;
    }

    const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set;

    valueSetter.call(numberOfDependentsInHouseholdElement, 0);

    const selectChangeEvent = new Event('change', { bubbles: true });

    numberOfDependentsInHouseholdElement.dispatchEvent(selectChangeEvent);
  };

  const options = [
    { label: translate('global:numbers:one'), value: 1 },
    { label: translate('global:numbers:two'), value: 2 },
    { label: translate('global:numbers:three'), value: 3 },
    { label: `${translate('global:numbers:four')}+`, value: 4 },
  ];

  return (
    <div className="radio-tooltip-adjusments-dependents w-50">
      <div className="img-adjust">
        <ToolTip tooltipDescription={translate('rentalinformation:doYouHaveAnyDependents:tooltip')} />
      </div>
      <YesNoRadioButtonInput
        checkedValue={checkedValue}
        errors={errors}
        id="do-you-have-any-dependents"
        name="hasDependents"
        onChange={onHasDependentsChange}
        question={translate('rentalinformation:doYouHaveAnyDependents:label')}
      />
      {hasDependents && (
        <NumberOfDependentsInHouseholdDropdownInput
          errors={errors}
          id={numberOfDependentsInHouseholdSelectId}
          onChange={onNumberOfDependentsChange}
          options={options}
        />
      )}
    </div>
  );
};

export default DoYouHaveAnyDependents;
