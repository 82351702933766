import React, { useState } from 'react';
import formatNumberAsCurrency from '../../../../formatters/currencyFormatter';

const ExpenseTile = (
  {
    showDetailsByDefault = false,
    lineItems,
    summaryAmount,
    summaryType,
  },
) => {
  const [showDetails, setShowDetails] = useState(showDetailsByDefault);

  return (
    <div className="review-tile m-auto py-2 mb-5">
      <div className="summary-type pl-2">
        {summaryType}
      </div>
      {!showDetails && (
        <>
          <div className="pl-2 lender-tile-animation-1">
            <div className="summary-amount">{formatNumberAsCurrency({ allowZero: true, number: summaryAmount })}</div>
          </div>
          <div className="edit-value pr-2 cursor-pointer">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              onClick={() => {
                setShowDetails(true);
              }}
              onKeyDown={() => {
                setShowDetails(true);
              }}
            >
              Show details
            </p>
          </div>
        </>
      )}
      {showDetails && (
        <div className="px-2 lender-tile-animation-1">
          <div className="summary-amount">{formatNumberAsCurrency({ allowZero: true, number: summaryAmount })}</div>
          <div className="edit-value pr-2 cursor-pointer">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              onClick={() => {
                setShowDetails(false);
              }}
              onKeyDown={() => {
                setShowDetails(false);
              }}
            >
              Hide details
            </p>
          </div>
          <div className="divider mb-5" />
          <div>{lineItems}</div>
        </div>
      )}
    </div>
  );
};

export default ExpenseTile;
