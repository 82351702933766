import React from 'react';
import { useSelector } from 'react-redux';
import ExpenseTile from './ExpenseTile/ExpenseTile';
import { getExpenseCategoryTotals, getIndividualExpenses } from '../../../redux/actions/expensesActions';
import SubCategoryReadOnly from '../../application/SubCategoryReadOnly/SubCategoryReadOnly';

const Expenses = ({ noExpensesMessage, showDetailsByDefault }) => {
  const application = useSelector((state) => state.application.data);
  const expenses = useSelector((state) => state.expenses.list);

  const {
    alimony,
    backTaxes,
    cableInternetLandline,
    carInsurance,
    carPayment,
    carRepairs,
    childcare,
    childSupport,
    clothing,
    donations,
    education,
    electricity,
    entertainment,
    friendsAndFamily,
    gas,
    gasAndTolls,
    groceries,
    healthInsurance,
    hoaFees,
    homeownersInsurance,
    homeRepairs,
    lifeInsurance,
    medicalDebt,
    mortgage,
    otherDebt,
    otherMiscellaneous,
    personalCare,
    petcare,
    prescriptionDrugs,
    propertyTaxes,
    publicTransportation,
    rent,
    rentersInsurance,
    restaurants,
    savings,
    servicesAndAppointments,
    studentLoanPayment,
    water,
    wirelessPhone,
  } = getIndividualExpenses({ expenses });

  const {
    totalFoodExpenses,
    totalHomeOwnerExpenses,
    totalHomeRentalExpenses,
    totalMedicalExpenses,
    totalMiscExpenses,
    totalOtherDebts,
    totalPersonalExpenses,
    totalSavingsAndDonations,
    totalTransportationExpenses,
    totalUtilitiesExpenses,
    totalVerifiedExpenses,
  } = getExpenseCategoryTotals({ expenses });

  const homeownerReviewTile = (
    <div>
      <ExpenseTile
        showDetailsByDefault={showDetailsByDefault}
        summaryAmount={totalHomeOwnerExpenses}
        summaryType="Housing Expenses"
        lineItems={
          [
            <SubCategoryReadOnly
              id="mortgage"
              key="mortgage"
              name="Mortgage"
              value={mortgage}
            />,
            <SubCategoryReadOnly
              id="hoa-fees"
              key="hoa-fees"
              name="HOA Fees"
              value={hoaFees}
            />,
            <SubCategoryReadOnly
              id="homeowners-insurance"
              key="homeowners-insurance"
              name="Homeowners Insurance"
              value={homeownersInsurance}
            />,
            <SubCategoryReadOnly
              id="property-taxes"
              key="property-taxes"
              name="Property Taxes"
              value={propertyTaxes}
            />,
            <SubCategoryReadOnly
              id="maintenance-and-improvements"
              key="maintenance-and-improvements"
              name="Maintenance &amp; Improvements"
              value={homeRepairs}
            />,
          ]
        }
      />
    </div>
  );

  const renterReviewTile = (
    <div>
      <ExpenseTile
        showDetailsByDefault={showDetailsByDefault}
        summaryAmount={totalHomeRentalExpenses}
        summaryType="Housing Expenses"
        lineItems={
          [
            <SubCategoryReadOnly
              id="rent"
              key="rent"
              name="Rent"
              value={rent}
            />,
            <SubCategoryReadOnly
              id="renters-insurance"
              key="renters-insurance"
              name="Renters Insurance"
              value={rentersInsurance}
            />,
          ]
        }
      />
    </div>
  );

  const expensesSection = totalVerifiedExpenses
    ? (
      <div className="mt-5">
        <div className="container review-application mx-auto pb-8 lg:flex flex-none">
          <div className="review-tile-container application-component-container sm:w-1/2 w-full">
            <div className="lender-tile-animation-1">
              {application.isHomeowner ? homeownerReviewTile : renterReviewTile}
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalTransportationExpenses}
                  summaryType="Transportation Expense"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="car-payment"
                        key="car-payment"
                        name="Car Payment"
                        value={carPayment}
                      />,
                      <SubCategoryReadOnly
                        id="gas-and-tolls"
                        key="gas-and-tolls"
                        name="Gas &amp; Tolls"
                        value={gasAndTolls}
                      />,
                      <SubCategoryReadOnly
                        id="car-insurance"
                        key="car-insurance"
                        name="Car Insurance"
                        value={carInsurance}
                      />,
                      <SubCategoryReadOnly
                        id="car-maintenance-and-repairs"
                        key="car-maintenance-and-repairs"
                        name="Car Maintenance &amp; Repairs"
                        value={carRepairs}
                      />,
                      <SubCategoryReadOnly
                        id="bus-train-tickets-and-taxi-fares"
                        key="bus-train-tickets-and-taxi-fares"
                        name="Bus/Train Tickets &amp; Taxi Fares"
                        value={publicTransportation}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalUtilitiesExpenses}
                  summaryType="Utilities"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="cable-internet-and-landline"
                        key="cable-internet-and-landline"
                        name="Cable, Internet &amp; Landline"
                        value={cableInternetLandline}
                      />,
                      <SubCategoryReadOnly
                        id="electricity"
                        key="electricity"
                        name="Electricity"
                        value={electricity}
                      />,
                      <SubCategoryReadOnly
                        id="gas"
                        key="gas"
                        name="Gas"
                        value={gas}
                      />,
                      <SubCategoryReadOnly
                        id="water"
                        key="water"
                        name="Water"
                        value={water}
                      />,
                      <SubCategoryReadOnly
                        id="wireless-phone"
                        key="wireless-phone"
                        name="Wireless Phone"
                        value={wirelessPhone}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalFoodExpenses}
                  summaryType="Food Expenses"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="groceries"
                        key="groceries"
                        name="Groceries"
                        value={groceries}
                      />,
                      <SubCategoryReadOnly
                        id="restaurants-including-delivery"
                        key="restaurants-including-delivery"
                        name="Restaurants (Including Delivery)"
                        value={restaurants}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalMedicalExpenses}
                  summaryType="Medical Expenses"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="prescription-drugs"
                        key="prescription-drugs"
                        name="Prescription Drugs"
                        value={prescriptionDrugs}
                      />,
                      <SubCategoryReadOnly
                        id="health-insurance"
                        key="health-insurance"
                        name="Health Insurance"
                        value={healthInsurance}
                      />,
                      <SubCategoryReadOnly
                        id="life-insurance"
                        key="life-insurance"
                        name="Life Insurance"
                        value={lifeInsurance}
                      />,
                      <SubCategoryReadOnly
                        id="services-and-appointments"
                        key="services-and-appointments"
                        name="Services &amp; Appointments"
                        value={servicesAndAppointments}
                      />,
                    ]
                  }
                />
              </div>
            </div>
          </div>
          <div className="review-tile-container application-component-container sm:w-1/2 w-full">
            <div className="lender-tile-animation-1">
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalPersonalExpenses}
                  summaryType="Personal Expenses"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="entertainment"
                        key="entertainment"
                        name="Entertainment"
                        value={entertainment}
                      />,
                      <SubCategoryReadOnly
                        id="clothing"
                        key="clothing"
                        name="Clothing"
                        value={clothing}
                      />,
                      <SubCategoryReadOnly
                        id="personal-care"
                        key="personal-care"
                        name="Personal Care"
                        value={personalCare}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={studentLoanPayment}
                  summaryType="Student Loan Payments"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="minimum-student-loan-payment"
                        key="minimum-student-loan-payment"
                        name="Minimum Payment"
                        value={studentLoanPayment}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalOtherDebts}
                  summaryType="Other Monthly Debts"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="medical-debt"
                        key="medical-debt"
                        name="Medical Debt"
                        value={medicalDebt}
                      />,
                      <SubCategoryReadOnly
                        id="debt-to-friends-and-family"
                        key="debt-to-friends-and-family"
                        name="Debt to Friends &amp; Family"
                        value={friendsAndFamily}
                      />,
                      <SubCategoryReadOnly
                        id="back-taxes"
                        key="back-taxes"
                        name="Back Taxes"
                        value={backTaxes}
                      />,
                      <SubCategoryReadOnly
                        id="other-debt"
                        key="other-debt"
                        name="Other Monthly Debt"
                        value={otherDebt}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalSavingsAndDonations}
                  summaryType="Savings &amp; Donations"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="savings"
                        key="savings"
                        name="Savings"
                        value={savings}
                      />,
                      <SubCategoryReadOnly
                        id="donations"
                        key="donations"
                        name="Donations"
                        value={donations}
                      />,
                    ]
                  }
                />
              </div>
              <div>
                <ExpenseTile
                  showDetailsByDefault={showDetailsByDefault}
                  summaryAmount={totalMiscExpenses}
                  summaryType="Miscellaneous Expenses"
                  lineItems={
                    [
                      <SubCategoryReadOnly
                        id="childcare"
                        key="childcare"
                        name="Childcare"
                        value={childcare}
                      />,
                      <SubCategoryReadOnly
                        id="education"
                        key="education"
                        name="Education"
                        value={education}
                      />,
                      <SubCategoryReadOnly
                        id="petcare"
                        key="petcare"
                        name="Petcare"
                        value={petcare}
                      />,
                      <SubCategoryReadOnly
                        id="child-support"
                        key="child-support"
                        name="Child Support"
                        value={childSupport}
                      />,
                      <SubCategoryReadOnly
                        id="alimony"
                        key="alimony"
                        name="Alimony"
                        value={alimony}
                      />,
                      <SubCategoryReadOnly
                        id="other-miscellaneous"
                        key="other-miscellaneous"
                        name="Other"
                        value={otherMiscellaneous}
                      />,
                    ]
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    : (
      <div className="mt-2">
        <p>{noExpensesMessage}</p>
      </div>
    );

  return expensesSection;
};

export default Expenses;
