import React from 'react';
import PhoneNumberInput from '../common/PhoneNumberInput';

const SpousePhoneNumberInput = ({ errors, label, onChange }) => (
  <PhoneNumberInput
    errors={errors}
    id="spouse-phone-number-input"
    label={label}
    loadFrom="spouse"
    onChange={onChange}
    validationName="spousePhoneNumber"
  />
);

export default SpousePhoneNumberInput;
