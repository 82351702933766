import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const TroubleMakingRentPaymentsCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <CheckboxInput
      id="trouble-making-rent-payments-checkbox"
      label={translate('lifeevents:troubleMakingRentPayments')}
      loadFrom="hardships"
      name="hasTroubleMakingRentPayments"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default TroubleMakingRentPaymentsCheckbox;
