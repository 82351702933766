import { Link, useHistory } from 'react-router-dom';
import PlaidLink from 'react-plaid-link';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import awsconfig from '../../../aws-exports';
import BankIcon from '../../../assets/icons/bank-connection_icon.svg';
import { plaidConnectionSuccess } from '../../../redux/actions/actions';
import ProgressBar from '../../../components/application/common/ProgressBar/ProgressBar';
import { saveDecision, updateDecision } from '../../../redux/actions/decisionActions';
import { saveLastCompletedAndLastViewedPage, clearSaveApplicationSucceeded } from '../../../redux/actions/applicationActions';
import ShieldIcon from './ShieldIcon';

const BankConnection = () => {
  const borrowerIsQualified = useSelector((state) => state.decision.data.isQualified);
  const dispatch = useDispatch();
  const getDecisionFailed = useSelector((state) => state.decision.getDecisionFailed);
  const getDecisionSucceeded = useSelector((state) => state.decision.getDecisionSucceeded);
  const getRiskScoresFailed = useSelector((state) => state.risk.getRiskScoresFailed);
  const history = useHistory();
  const isConnectedToPlaid = useSelector((state) => state.plaid.isConnected);
  const isEnvironmentFreeTool = process.env.REACT_APP_ENVIRONMENT === 'freetool';
  const isGettingDecision = useSelector((state) => state.decision.isGettingDecision);
  const pullCreditFailed = useSelector((state) => state.transunion.hasPulledCredit === false);
  const webhook = `${awsconfig.aws_cloud_logic_custom.find((api) => api.name === 'plaid').endpoint}/webhook`;

  const onPlaidSkip = () => {
    dispatch(clearSaveApplicationSucceeded());
    dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'BankConnection', lastViewedPage: 'ReasonForNotConnectingAccount' }));
  };

  useEffect(() => {
    if (!isConnectedToPlaid) {
      return;
    }

    if (pullCreditFailed || getRiskScoresFailed || getDecisionFailed) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'BankConnection', lastViewedPage: 'Pending' }));

      history.push('/offers/pending');

      return;
    }

    if (getDecisionSucceeded && borrowerIsQualified) {
      if (isEnvironmentFreeTool) {
        dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'BankConnection', lastViewedPage: 'ProposedPaymentSummary' }));

        history.push('/offers/proposed-payment-summary');
      } else {
        dispatch(updateDecision({ propertyName: 'wasPresentedToBorrower', value: true }));
        dispatch(saveDecision());
        dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'BankConnection', lastViewedPage: 'Success' }));

        history.push('/offers/success');
      }

      return;
    }

    if (getDecisionSucceeded && !borrowerIsQualified) {
      dispatch(saveLastCompletedAndLastViewedPage({ lastCompletedPage: 'BankConnection', lastViewedPage: 'Decline' }));

      history.push('/offers/decline');
    }
  }, [
    borrowerIsQualified,
    dispatch,
    history,
    isConnectedToPlaid,
    isEnvironmentFreeTool,
    getDecisionFailed,
    getDecisionSucceeded,
    getRiskScoresFailed,
    isGettingDecision,
    pullCreditFailed,
  ]);

  const onSubmit = (event) => {
    event.preventDefault();
  };

  // eslint-disable-next-line camelcase
  const onSuccess = (public_token, metadata) => {
    dispatch(plaidConnectionSuccess({ metadata, public_token }));
  };

  return (
    <div>
      <div className="bank-connection leading-normal tracking-normal pb-20">
        <div className="container mx-auto pl-2 pr-2 pb-8 text-center w-full">
          <ProgressBar pageName="bankConnection" />
          <h2 className="text-xl sm:text-2xl w-full m-auto">
            Securely Verify Your Income
          </h2>
          <p className="w-full sm:w-1/2 m-auto">
            We are asking you to verify your income, by
            {' '}
            <span className="font-bold">
              securely connecting your primary checking account.
            </span>
            {' '}
            Verifying your income helps us obtain the best debt relief options for you.
          </p>
          <img className="block m-auto pt-5" src={BankIcon} alt="Bank Connection Icon" />
          <p className="w-full sm:w-1/2 m-auto pt-5">
            Rest assured, when you connect an account with us, we never have access to your username or password. We are only able to view a
            summary of your transactions, which helps us verify your income and we maintain this connection to provide you guidance on maintaining
            good financial habits as you work towards eliminating your debt.
          </p>
          <div className="leading-normal tracking-normal">
            <div className="mx-auto m-8 bank-connection plaid-call-to-action">
              <PlaidLink
                className="border-none data-cy-connect-bank-account"
                clientName="Creditly"
                env={process.env.REACT_APP_PLAID_ENV}
                product={['transactions']}
                publicKey="1995cf6fdc8987d2cab0a1242a9d56"
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                webhook={webhook}
              >
                Verify my income
              </PlaidLink>
            </div>
            <div className="skip-bank-connection container text-black my-4 w-full sm:w-1/2 m-auto">
              <Link onClick={onPlaidSkip} to="/application/income/reason-for-not-connecting-account">
                I&apos;m unable to connect an account
              </Link>
              <div className="note-details text-left pt-4">
                <span className="font-bold text-black">Note:</span>
                {' '}
                We will connect you with a certified credit counselor who will verify your information.
              </div>
            </div>
          </div>
          <div className="bullet-points container pt-4 w-full sm:w-1/2 m-auto">
            <div className="divider mb-10" />
            <h3>We take your privacy &amp; security seriously.</h3>
            <div className="flex pt-2">
              <ShieldIcon />
              <div className="pl-4">
                <p>
                  Credit Genie has partnered with Plaid, Inc. to allow you to quickly connect your bank account
                  and verify your personal information. Plaid partners with most leading banks, and connects
                  millions of consumers with their banks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankConnection;
