import {
  getCreditCounselingAgencyPreLeadPhoneNumber,
  getCreditCounselingAgencyPostLeadPhoneNumber,
} from '../../helpers/creditCounselingAgencyHelper';

export const UPDATE_CURRENT_PHONE_NUMBER = 'UPDATE_CURRENT_PHONE_NUMBER';

const updateCurrentPhoneNumber = (phoneNumber) => ({ currentPhoneNumber: phoneNumber, type: UPDATE_CURRENT_PHONE_NUMBER });

const updateCurrentPhoneNumberAfterSignIn = ({ route, router }) => {
  const preLeadPhoneNumber = getCreditCounselingAgencyPreLeadPhoneNumber({ router });
  const postLeadPhoneNumber = getCreditCounselingAgencyPostLeadPhoneNumber({ router });

  switch (route) {
    case '/application/life-events':
    case '/application/credit-history':
    case '/application/your-contact':
      return { currentPhoneNumber: preLeadPhoneNumber, type: UPDATE_CURRENT_PHONE_NUMBER };

    case '/application/bank-connection':
    case '/application/income/verify-income':
    case '/application/income/income-summary':
    case '/application/income/provide-monthly-income':
    case '/application/income/reason-for-skipping':
    case '/application/expenses':
    case '/offers/pending':
    case '/offers/status':
    case '/offers/success':
    case '/offers/call-us':
    case '/contract/sign':
      return { currentPhoneNumber: postLeadPhoneNumber, type: UPDATE_CURRENT_PHONE_NUMBER };

    default:
      return { currentPhoneNumber: preLeadPhoneNumber, type: UPDATE_CURRENT_PHONE_NUMBER };
  }
};

export {
  updateCurrentPhoneNumber,
  updateCurrentPhoneNumberAfterSignIn,
};
