import React from 'react';
import {
  FooterCallToAction, Hero, GetAnAnalysis, WhyCreditGenie, LegalDisclosure,
} from '../../components/landing-pages/index';

const DebtAnalysis = () => (
  <div className="debt-analysis">
    <Hero />
    <GetAnAnalysis />
    <WhyCreditGenie />
    <FooterCallToAction />
    <LegalDisclosure />
  </div>
);

export default DebtAnalysis;
