import React from 'react';

const ResetPasswordValidation = ({ isValidated } = { isValidated: {} }) => (
  <div className="container mx-auto px-4 mb-4">
    <ul className="reset-password-validators">
      <li className={isValidated.validationNumAndChar ? 'validated' : undefined}>
        <span>Must contain at least one number and one letter.</span>
      </li>
      <li className={isValidated.validationLength ? 'validated' : undefined}>
        <span>Must be between 8 and 20 characters long.</span>
      </li>
      <li className={isValidated.validationInvalidChar ? 'validated' : undefined}>
        <span>
          Cannot contain any characters outside of letters, numbers
          and the following special characters: !.@#$%&*
        </span>
      </li>
    </ul>
  </div>
);

export default ResetPasswordValidation;
