import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StateDropdownInput from '../common/AddressFields/StateDropdownInput';
import { updateSpouse } from '../../../redux/actions/spouseActions';

const BorrowerStateDropdownInput = ({ errors, onChange }) => {
  const dispatch = useDispatch();
  const spouseAddressIsSameAsBorrowerAddress = useSelector((state) => state.spouse.hasSameAddressAsBorrower);

  const onSelectValueChange = (event) => {
    if (spouseAddressIsSameAsBorrowerAddress) {
      dispatch(updateSpouse({ propertyName: event.target.name, value: event.target.value }));
    }

    onChange(event);
  };

  return (
    <StateDropdownInput
      errors={errors}
      id="borrower-state-select"
      loadFrom="borrower"
      onChange={onSelectValueChange}
      validationName="borrowerState"
    />
  );
};

export default BorrowerStateDropdownInput;
