import React from 'react';
import CreditGenieLogo from '../../../assets/logos/creditgenie_logo_blue.svg';

const RentalHeader = () => (
  <div>
    <nav className="sm:pt-6 pt-3 mx-auto">
      <div className="shadow-lg pb-3">
        <div className="container m-auto w-full">
          <div className="block">
            <img className="block px-2 sm:m-0 m-auto" src={CreditGenieLogo} alt="Credit Genie Logo" />
          </div>
        </div>
      </div>
    </nav>
  </div>
);

export default RentalHeader;
