import React from 'react';
import { useDispatch } from 'react-redux';
import YesNoRadioButtonInput, { noValue } from '../common/RadioButtonInput/YesNoRadioButtonInput';
import ToolTip from '../../common/ToolTip';
import { updateApplication } from '../../../redux/actions/applicationActions';

const DoYouOwnYourHomeInput = ({ errors, onChange }) => {
  const dispatch = useDispatch();
  const onInputChange = (event, valueOverride) => {
    const yesNoValue = event.target.value;
    if (yesNoValue === noValue) {
      dispatch(updateApplication({ propertyName: 'hasMortgageEscrow', value: undefined }));
    }
    onChange(event, valueOverride);
  };
  return (
    <div className="radio-tooltip-adjusments-home w-50">
      <ToolTip tooltipDescription="This means you own your home outright or are paying a mortgage." />
      <YesNoRadioButtonInput
        errors={errors}
        id="do-you-own-your-home-input"
        loadFrom="application"
        name="isHomeowner"
        onChange={onInputChange}
        question="Do you own your home?"
      />
    </div>
  );
};

export default DoYouOwnYourHomeInput;
