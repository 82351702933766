/* eslint-disable max-len */
import React, { useState } from 'react';
import ContactSummaryTable from '../../../components/customer-service-portal/ContactSummaryComponents/ContactSummaryTable';
import ContactedQuestions from '../../../components/customer-service-portal/ContactSummaryComponents/QuestionsComponents/ContactedQuestions/ContactedQuestions';

const ContactSummary = () => {
  const [showContactedQuestions, toggleContactedQuestions] = useState(false);
  const [showContactSummaryTable, toggleContactSummaryTable] = useState(true);

  return (
    <div className="transfer-application-tile lender-tile-animation-1">
      {showContactSummaryTable && (
        <ContactSummaryTable
          toggleContactedQuestions={toggleContactedQuestions}
          showContactedQuestions={showContactedQuestions}
          toggleContactSummaryTable={toggleContactSummaryTable}
          showContactSummaryTable={showContactSummaryTable}
        />
      )}
      {showContactedQuestions && (
        <ContactedQuestions
          toggleContactedQuestions={toggleContactedQuestions}
          showContactedQuestions={showContactedQuestions}
          toggleContactSummaryTable={toggleContactSummaryTable}
          showContactSummaryTable={showContactSummaryTable}
        />
      )}
    </div>
  );
};

export default ContactSummary;
