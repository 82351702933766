import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '../common/CheckboxInput';

const JobLossOrReducedIncomeCheckbox = ({ onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <CheckboxInput
      id="job-loss-or-reduced-income-checkbox"
      label={translate('lifeevents:jobLossOrReducedIncome')}
      loadFrom="hardships"
      name="hasJobLossOrReducedIncome"
      onChange={onChange}
      valueIsFromKeyValuePairCollection
    />
  );
};

export default JobLossOrReducedIncomeCheckbox;
