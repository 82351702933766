import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatNumberAsCurrency from '../../../formatters/currencyFormatter';
import ToolTip from '../../common/ToolTip';

const ReviewTile = ({
  lineItems,
  onCancel,
  onSubmit,
  summaryAmount,
  summaryType,
  tooltipDescription,
}) => {
  const { t: translate } = useTranslation();
  const [showEditComponent, toggleEditComponent] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const ariaFriendlyName = summaryType.replace(' ', '-').toLowerCase();

  return (
    <div className="review-tile m-auto py-2 mb-5">
      <div className="summary-type pl-2">
        {summaryType}
        <ToolTip tooltipDescription={tooltipDescription} />
      </div>
      {showEditComponent && (
        <>
          <div className="pl-2 lender-tile-animation-1">
            <div className="summary-amount">{formatNumberAsCurrency({ allowZero: true, number: summaryAmount })}</div>
          </div>
          <div className="edit-value pr-2 cursor-pointer" data-cy="edit-category">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              onClick={() => {
                toggleEditComponent(!showEditComponent);
                setIsHidden(!isHidden);
              }}
              onKeyDown={() => {
                toggleEditComponent(!showEditComponent);
                setIsHidden(!isHidden);
              }}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
            >
              {translate('global:edit')}
            </p>
          </div>
        </>
      )}
      {isHidden && (
        <div className="px-2 lender-tile-animation-1">
          <div className="summary-amount">{formatNumberAsCurrency({ allowZero: true, number: summaryAmount })}</div>
          <div className="divider mb-5" />
          <div>{lineItems}</div>
          <div className="flex pt-5 pb-5 m-auto w-full">
            <button
              aria-label={`${ariaFriendlyName}-cancel-button`}
              className="cancel-value w-1/2 cursor-pointer"
              data-cy="cancel-edit"
              onClick={
                () => {
                  onCancel();
                  toggleEditComponent(!showEditComponent);
                  setIsHidden(!isHidden);
                }
              }
              type="button"
            >
              {translate('global:cancel')}
            </button>
            <button
              aria-label={`${ariaFriendlyName}-submit-button`}
              className="call-to-action w-1/2"
              data-cy="submit-edit"
              onClick={() => {
                onSubmit();
                toggleEditComponent(!showEditComponent);
                setIsHidden(!isHidden);
              }}
              type="button"
            >
              {translate('global:submit:submit')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewTile;
