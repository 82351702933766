import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import { startCase } from 'lodash';
import BackButtonKO from '../../../application/common/BackButtonKO';
import ClaimApplicationLinkButton from '../../common/ClaimApplicationLinkButton/ClaimApplicationLinkButton';
import UnclaimApplicationLinkButton from '../../common/UnclaimApplicationLinkButton/UnclaimApplicationLinkButton';
import formatNumberAsCurrency from '../../../../formatters/currencyFormatter';
import EnrollmentIcon from '../../../../assets/icons/transfer_icon.svg';
import { setShowDmpEnrollmentForm } from '../../../../redux/actions/dmpEnrollmentActions';
import { setShowRentalReliefPlanForm } from '../../../../redux/actions/rentalReliefPlanActions';
import checkmark from '../../../../assets/icons/checkmark-green_icon.svg';

const BorrowerInformationTile = () => {
  const application = useSelector((state) => state.application.data);
  const applicationDateToString = DateTime.fromISO(application.createdAt).toFormat('MM-dd-yyyy hh:mm a');
  const { assignedTo } = application;
  const borrower = useSelector((state) => state.borrower.data);
  const customerServiceUsers = useSelector((state) => state.user.list);
  const decision = useSelector((state) => state.decision.data);
  const [internalAssignedTo, setInternalAssignedTo] = useState(assignedTo);
  const offers = useSelector((state) => state.offers.data);
  const userId = useSelector((state) => state.user.data.username);
  const dispatch = useDispatch();
  const isEnrolledInADmp = useSelector((state) => state.dmpEnrollment.isEnrolledInADmp);
  const isEnrolledInARentalReliefPlan = useSelector((state) => state.rentalReliefPlan.isEnrolledInARentalReliefPlan);

  const currentDisposableIncome = decision
    ? formatNumberAsCurrency({ allowNegative: true, number: Math.round(decision.currentDisposableIncome) })
    : 'N/A';

  const assignedToDetails = assignedTo
    ? customerServiceUsers.filter((user) => user.id === assignedTo)
    : customerServiceUsers.filter((user) => user.id === userId);

  const assignedToValue = internalAssignedTo
    ? (
      <div>
        {assignedToDetails && assignedToDetails[0] && assignedToDetails[0].name}
        {(internalAssignedTo === userId) && ' (You)'}
        <br />
        <UnclaimApplicationLinkButton applicationId={application.id} applicationStatus={application.status} />
      </div>
    ) : (
      <ClaimApplicationLinkButton applicationId={application.id} applicationStatus={application.status} />
    );

  const borrowerInformationMapping = [
    {
      containerClassOverride: 'w-full',
      label: 'ID',
      value: borrower.id,
    },
    {
      containerClassOverride: 'w-full',
      label: 'Email',
      value: borrower.email,
    },
    {
      label: 'Phone',
      value: borrower.phoneNumber,
    },
    {
      label: 'Application Created',
      value: applicationDateToString || 'Unknown',
    },
    {
      label: 'Referred From',
      value: `${startCase(application.router) || 'Unknown'}`,
    },
    {
      label: 'Application Status',
      value: `${application.status || 'Unknown'}`,
    },
    {
      label: 'Offer Type',
      value: offers.length ? 'Has Offers' : 'No Offers',
    },
    {
      label: 'Current Disposable Income',
      value: currentDisposableIncome,
    },
    {
      label: 'Last Page Completed',
      value: `${application.lastCompletedPage || 'Unknown'}`,
      valueClassOverride: 'label-value truncate',
    },
    {
      label: 'Assigned To',
      value: assignedToValue,
    },
  ];

  const borrowerInformation = borrowerInformationMapping.map((borrowerInfo) => (
    <div className={borrowerInfo.containerClassOverride || 'w-1/2'} key={`borrowerInfo-${borrowerInfo.label}`}>
      <div className={borrowerInfo.labelClassOverride || 'label pt-3'}>
        {borrowerInfo.label}
      </div>
      <div className={borrowerInfo.valueClassOverride || 'label-value'}>
        {borrowerInfo.value}
      </div>
    </div>
  ));

  const onEnrollInAPlanClick = () => {
    if (application.type === 'credit') {
      dispatch(setShowDmpEnrollmentForm({ value: true }));
    }

    if (application.type === 'rental') {
      dispatch(setShowRentalReliefPlanForm({ showRentalReliefPlanForm: true }));
    }
  };

  useEffect(() => {
    setInternalAssignedTo(assignedTo);
  }, [assignedTo]);

  return (
    <div>
      <div className="container m-auto back-button">
        <BackButtonKO />
      </div>
      <div className="box bg-white">
        <div>
          <h3 className="font-bold text-black pb-2">{`${borrower.firstName} ${borrower.lastName}`}</h3>
          <div className="divider" />
          <div className="flex flex-wrap">
            {borrowerInformation}
          </div>
          <div className="plan-enrollment-summary">
            {(isEnrolledInADmp || isEnrolledInARentalReliefPlan)
              ? (
                <div className="enrolled pr-5 pt-2">
                  <img className="inline-block" src={checkmark} alt="Checkmark" />
                  <div className="inline-block pl-2">{isEnrolledInADmp ? 'Enrolled in a DMP' : 'Enrolled in a Rental Relief Plan'}</div>
                </div>
              )
              : (
                <div
                  className="call-to-action plan-enrollment-summary-cta mt-5 text-center inline-flex items-center justify-center py-4"
                  role="button"
                  tabIndex="0"
                  onClick={onEnrollInAPlanClick}
                  onKeyDown={onEnrollInAPlanClick}
                >
                  Enroll in a plan
                  <img src={EnrollmentIcon} alt="right arrow" className="pl-2" />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BorrowerInformationTile;
