import React from 'react';
import FirstNameTextInput from '../FirstNameTextInput';

const BorrowerFullNameTextInput = (
  {
    disabled,
    errors,
    label,
    onBlur,
    onChange,
  },
) => (
  <FirstNameTextInput
    disabled={disabled}
    errors={errors}
    id="borrower-first-name"
    label={label}
    loadFrom="borrower"
    onBlur={onBlur}
    onChange={onChange}
    validationName="borrowerFirstName"
  />
);

export default BorrowerFullNameTextInput;
