import React from 'react';
import { DebtReliefHero } from '../../components/landing-pages';

const DebtRelief = () => (
  <div className="debt-relief">
    <DebtReliefHero />
  </div>
);

export default DebtRelief;
