import { API, Auth } from 'aws-amplify';

export const GET_PROPOSED_BUDGETS = 'GET_PROPOSED_BUDGETS';
export const GET_PROPOSED_BUDGETS_FAIL = 'GET_PROPOSED_BUDGETS_FAIL';
export const GET_PROPOSED_BUDGETS_SUCCESS = 'GET_PROPOSED_BUDGETS_SUCCESS';

const getProposedBudgets = () => async (dispatch, getState) => {
  dispatch({ type: GET_PROPOSED_BUDGETS });

  const state = getState();

  try {
    const application = state.application.data;
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();

    const proposedBudgets = await API.post('budget', '/propose-budgets', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: application.id,
      },
    });

    dispatch({ proposedBudgets, type: GET_PROPOSED_BUDGETS_SUCCESS });
  } catch (error) {
    console.log('error getting proposed budgets', error);

    dispatch({ type: GET_PROPOSED_BUDGETS_FAIL });
  }
};

export {
  getProposedBudgets,
};
