import { API, Auth, graphqlOperation } from 'aws-amplify';
import { updatePlaidTransaction } from '../../graphql/customMutations';

export const CLEAR_SAVE_INCOME_PREDICTIONS_SUCCEEDED = 'CLEAR_SAVE_INCOME_PREDICTIONS_SUCCEEDED';
export const GET_INCOME_PREDICTIONS = 'GET_INCOME_PREDICTIONS';
export const GET_INCOME_PREDICTIONS_FAIL = 'GET_INCOME_PREDICTIONS_FAIL';
export const GET_INCOME_PREDICTIONS_SUCCESS = 'GET_INCOME_PREDICTIONS_SUCCESS';
export const SAVE_INCOME_PREDICTIONS = 'SAVE_INCOME_PREDICTIONS';
export const SAVE_INCOME_PREDICTIONS_FAIL = 'SAVE_INCOME_PREDICTIONS_FAIL';
export const SAVE_INCOME_PREDICTIONS_SUCCESS = 'SAVE_INCOME_PREDICTIONS_SUCCESS';
export const SET_INCOME_PREDICTIONS = 'SET_INCOME_PREDICTIONS';
export const SET_INCOME_SNAPSHOTS = 'SET_INCOME_SNAPSHOTS';
export const UNVERIFY_ALL_INCOME_PREDICTIONS = 'UNVERIFY_ALL_INCOME_PREDICTIONS';
export const UNVERIFY_INCOME_PREDICTION = 'UNVERIFY_INCOME_PREDICTION';
export const VERIFY_ALL_INCOME_PREDICTIONS = 'VERIFY_ALL_INCOME_PREDICTIONS';
export const VERIFY_INCOME_PREDICTION = 'VERIFY_INCOME_PREDICTION';

const clearSaveIncomePredictionsSucceeded = () => ({ type: CLEAR_SAVE_INCOME_PREDICTIONS_SUCCEEDED });

const getIncomePredictions = () => async (dispatch, getState) => {
  dispatch({ type: GET_INCOME_PREDICTIONS });

  const state = getState();

  try {
    const currentSession = await Auth.currentSession();
    const jwt = currentSession.getIdToken().getJwtToken();

    const response = await API.post('income', '/income/predict', {
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
      body: {
        applicationId: state.application.data.id,
      },
    });

    dispatch({ incomePredictions: response, type: GET_INCOME_PREDICTIONS_SUCCESS });
  } catch (error) {
    console.error('error getting income predictions:', error);

    dispatch({ type: GET_INCOME_PREDICTIONS_FAIL });
  }
};

const saveIncomePredictions = () => async (dispatch, getState) => {
  dispatch({ type: SAVE_INCOME_PREDICTIONS });

  const state = getState();

  let saveSucceeded = true;

  await Promise.all(Object.keys(state.income.data.incomePredictions).map(async (incomePredictionId) => {
    const incomePrediction = state.income.data.incomePredictions[incomePredictionId];

    try {
      await API.graphql(graphqlOperation(updatePlaidTransaction, { input: { ...incomePrediction } }));
    } catch (error) {
      console.error('error saving income predictions:', error);

      saveSucceeded = false;
    }
  }));

  const successOrFailAction = saveSucceeded ? { type: SAVE_INCOME_PREDICTIONS_SUCCESS } : { type: SAVE_INCOME_PREDICTIONS_FAIL };

  dispatch(successOrFailAction);
};

const setIncomePredictions = ({ incomePredictions }) => ({ incomePredictions, type: SET_INCOME_PREDICTIONS });

const unverifyAllIncomePredictions = () => ({ type: UNVERIFY_ALL_INCOME_PREDICTIONS });

const unverifyIncomePrediction = ({ incomePredictionId }) => ({ incomePredictionId, type: UNVERIFY_INCOME_PREDICTION });

const verifyAllIncomePredictions = () => ({ type: VERIFY_ALL_INCOME_PREDICTIONS });

const verifyIncomePrediction = ({ incomePredictionId }) => ({ incomePredictionId, type: VERIFY_INCOME_PREDICTION });

export {
  clearSaveIncomePredictionsSucceeded,
  getIncomePredictions,
  saveIncomePredictions,
  setIncomePredictions,
  unverifyAllIncomePredictions,
  unverifyIncomePrediction,
  verifyAllIncomePredictions,
  verifyIncomePrediction,
};
