import {
  GET_DEBT_SCORES, GET_DEBT_SCORES_FAIL, GET_DEBT_SCORES_SUCCESS,
  SET_DEBT_SCORES,
} from '../actions/debtScoreActions';

const intitialState = {
  data: [
    {
      borrowerId: undefined,
      borrowerRiskScoreId: undefined,
      discount: 0,
      id: undefined,
      monthlyExpense: 0,
      monthlyIncome: 0,
      monthlyUnsecuredDebt: 0,
      owner: undefined,
      riskScore: 0,
      value: 0,
      version: undefined,
    },
  ],
  getDebtScoresFailed: undefined,
  getDebtScoresSucceeded: undefined,
  isGettingDebtScores: undefined,
};

const debtScoresReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_DEBT_SCORES:
      return {
        ...state,
        getDebtScoresFailed: undefined,
        getDebtScoresSucceeded: undefined,
        isGettingDebtScores: true,
      };

    case GET_DEBT_SCORES_FAIL:
      return {
        ...state,
        data: [],
        getDebtScoresFailed: true,
        getDebtScoresSucceeded: false,
        isGettingDebtScores: false,
      };

    case GET_DEBT_SCORES_SUCCESS:
      return {
        ...state,
        data: action.debtScores || [],
        getDebtScoresFailed: false,
        getDebtScoresSucceeded: true,
        isGettingDebtScores: false,
      };

    case SET_DEBT_SCORES:
      return {
        ...state,
        data: action.debtScores || [],
      };

    default:
      return state;
  }
};

export default debtScoresReducer;
