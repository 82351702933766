import { Auth } from 'aws-amplify';

const doesUserNeedToChangePassword = async () => {
  try {
    const currentUserInfo = await Auth.currentUserInfo();

    const mustChangePassword = currentUserInfo.attributes['custom:must_change_password'];

    return mustChangePassword;
  } catch (error) {
    console.log('failed to get the current user info', error);

    return undefined;
  }
};

const getCurrentAuthenticatedUser = async () => {
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

    return currentAuthenticatedUser;
  } catch (error) {
    console.log('failed to get the current authenticated user:', error);

    return {
      attributes: {
        email: undefined,
        email_verified: undefined,
        name: undefined,
        phone_number: undefined,
        phone_number_verified: undefined,
      },
    };
  }
};

const isCustomerServiceUser = async () => {
  try {
    const currentSession = await Auth.currentSession();

    const userGroups = currentSession.accessToken.payload['cognito:groups'];

    if (userGroups && userGroups.find((userGroup) => userGroup.includes('CustomerService'))) {
      return true;
    }
  } catch (error) {
    console.log('failed to get current session:', error);
  }

  return false;
};

const isUserSignedIn = async () => {
  try {
    await Auth.currentAuthenticatedUser();

    return true;
  } catch (error) {
    return false;
  }
};

export {
  doesUserNeedToChangePassword,
  getCurrentAuthenticatedUser,
  isCustomerServiceUser,
  isUserSignedIn,
};
