import React from 'react';
import EmailAddressInput from '../common/EmailAddressInput';

const SpouseEmailAddressInput = (
  {
    errors,
    label,
    onBlur = () => {},
    onChange,
  },
) => (
  <EmailAddressInput
    errors={errors}
    id="spouse-email-address"
    label={label}
    loadFrom="spouse"
    onBlur={onBlur}
    onChange={onChange}
    validationName="spouseEmail"
  />
);

export default SpouseEmailAddressInput;
