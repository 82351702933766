import React from 'react';
import PhoneNumberInput from '../common/PhoneNumberInput';

const UserInformationPhoneNumber = ({ errors, onChange, valueOverride }) => (
  <PhoneNumberInput
    errors={errors}
    id="user-information-phone-number-input"
    label="Phone number"
    onChange={onChange}
    validationName="userInformationPhoneNumber"
    valueOverride={valueOverride}
  />
);

export default UserInformationPhoneNumber;
