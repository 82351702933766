export const SET_DEFAULT_SEARCH_VALUE = 'SET_DEFAULT_SEARCH_VALUE';

const clearDefaultSearchValue = () => ({ type: SET_DEFAULT_SEARCH_VALUE, value: undefined });

const setDefaultSearchValue = ({ value }) => ({ type: SET_DEFAULT_SEARCH_VALUE, value });

export {
  clearDefaultSearchValue,
  setDefaultSearchValue,
};
