import React from 'react';
import CurrencyInput from '../../../application/common/CurrencyInput';

const DmpMonthlyPaymentAmountTextInput = ({
  errors, onChange, validationName, valueOverride,
}) => (
  <div>
    <CurrencyInput
      errors={errors}
      id="monthly-dmp-payment-amount-input"
      label="Monthly DMP payment amount"
      loadFrom="dmpEnrollment"
      name="monthlyPaymentAmount"
      onChange={onChange}
      maxLength={6}
      validationName={validationName}
      valueOverride={valueOverride}
    />
  </div>
);

export default DmpMonthlyPaymentAmountTextInput;
