import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Header from '../components/customer-service-portal/common/Header';
import Loading from '../components/common/Loading';
import NewApplicationModal from '../components/portal/NewApplicationModal/NewApplicationModal';
import PlanEnrollmentForm from '../components/customer-service-portal/PlanEnrollmentForm/PlanEnrollmentForm';
import { setDefaultSearchValue } from '../redux/actions/searchBarActions';

const PortalLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showNewApplicationModal, setShowNewApplicationModal] = useState(false);

  const hideNewApplicationModal = () => {
    setShowNewApplicationModal(false);
  };

  const revealNewApplicationModal = () => {
    setShowNewApplicationModal(true);
  };

  const isLoading = useSelector((state) => state.application.isGettingApplication
    || state.application.isGettingApplicationsForServicePortal
    || state.borrower.isGettingBorrowerList
    || state.expenses.isGettingExpenses
    || state.offers.isGettingOffers
    || state.spouse.isGettingSpouse);

  const isSaving = useSelector((state) => (state.dmpEnrollment.isSaving || state.rentalReliefPlan.isSaving));
  const isSigningOut = useSelector((state) => state.user.isSigningOut);
  const showDmpEnrollmentForm = useSelector((state) => state.dmpEnrollment.showDmpEnrollmentForm);
  const showRentalReliefPlanForm = useSelector((state) => state.rentalReliefPlan.showRentalReliefPlanForm);

  useEffect(() => {
    if (history.location && history.location.search) {
      const queryString = new URLSearchParams(history.location.search);

      const searchValue = queryString.get('searchValue');

      dispatch(setDefaultSearchValue({ value: searchValue }));
    }
  }, [dispatch, history]);

  return (
    <section className="customer-service-portal">
      <div>
        {isLoading && <Loading message="Loading..." />}
        {isSaving && <Loading message="Saving..." />}
        {isSigningOut && <Loading message="Signing out..." />}
        {(showDmpEnrollmentForm || showRentalReliefPlanForm) && <PlanEnrollmentForm />}
        {showNewApplicationModal && <NewApplicationModal hideNewApplicationModal={hideNewApplicationModal} />}
        <Header revealNewApplicationModal={revealNewApplicationModal} />
        {children}
        <Footer />
      </div>
    </section>
  );
};

export default PortalLayout;
