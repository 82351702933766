import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import BackButton from '../../../components/application/common/BackButton';
import { storeFirstName, storeLastName, storePhoneNumber } from '../../../redux/actions/userActions';
import SubmitInput from '../../../components/application/common/SubmitInput';
import UserInformationFirstNameInput from '../../../components/application/UserInformationInputs/UserInformationFirstNameInput';
import UserInformationLastNameInput from '../../../components/application/UserInformationInputs/UserInformationLastNameInput';
import UserInformationPhoneNumberInput from '../../../components/application/UserInformationInputs/UserInformationPhoneNumberInput';

const UserInformation = ({ history }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const phoneNumberRegex = String.raw`\d{3}-\d{3}-\d{4}`;

  const onSubmit = (event) => {
    event.preventDefault();

    const currentErrors = {};
    const isPhoneNumberValid = new RegExp(phoneNumberRegex).test(phoneNumber);

    if (!firstName) {
      currentErrors.userFirstName = 'required';
    }

    if (!lastName) {
      currentErrors.userLastName = 'required';
    }

    if (!phoneNumber) {
      currentErrors.userInformationPhoneNumber = 'required';
    } else if (!isPhoneNumberValid) {
      currentErrors.userInformationPhoneNumber = 'invalid';
    }

    if (!firstName || !lastName || !phoneNumber || !isPhoneNumberValid) {
      setErrors(currentErrors);

      return;
    }

    dispatch(storeFirstName({ firstName }));
    dispatch(storeLastName({ lastName }));
    dispatch(storePhoneNumber({ phoneNumber }));

    history.push('/reset-password');
  };

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <BackButton />
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Confirm Your Information
          </h2>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8">
              <form noValidate onSubmit={onSubmit}>
                <UserInformationFirstNameInput
                  errors={errors}
                  onBlur={() => { setFirstName(firstName && firstName.trim()); }}
                  onChange={(event) => { setFirstName(event.target.value); }}
                  valueOverride={firstName}
                />
                <UserInformationLastNameInput
                  errors={errors}
                  onBlur={() => { setLastName(lastName && lastName.trim()); }}
                  onChange={(event) => { setLastName(event.target.value); }}
                  valueOverride={lastName}
                />
                <UserInformationPhoneNumberInput
                  errors={errors}
                  onChange={(event) => { setPhoneNumber(event.target.value); }}
                  valueOverride={phoneNumber}
                />
                <SubmitInput />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
