import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmailAddressInput from '../../../components/application/common/EmailAddressInput';
import SubmitInput from '../../../components/application/common/SubmitInput';
import { changeEmail, signOut } from '../../../redux/actions/userActions';

const ChangeEmail = ({ history }) => {
  const dispatch = useDispatch();
  const [confirmNewEmail, setConfirmNewEmail] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [newEmail, setNewEmail] = useState(undefined);
  const changeEmailErrorCode = useSelector((state) => state.user.changeEmailErrorCode);
  const changeEmailFailed = useSelector((state) => state.user.changeEmailFailed);
  const userHasChangedEmail = useSelector((state) => state.user.hasChangedEmail);

  const onSubmit = (event) => {
    event.preventDefault();

    setErrorMessage(undefined);

    if (newEmail === confirmNewEmail) {
      // TODO: We need to add a verification email prior to updating the user's email
      dispatch(changeEmail({ newEmail }));
    } else {
      setErrorMessage('The emails do not match.');
    }
  };

  useEffect(() => {
    if (userHasChangedEmail) {
      dispatch(signOut());

      history.push('/sign-in', { message: 'The email has been updated. Please login with your new credentials.' });

      return;
    }

    if (changeEmailFailed) {
      setErrorMessage(changeEmailErrorCode.message);
    }
  }, [changeEmailErrorCode, changeEmailFailed, dispatch, history, userHasChangedEmail]);

  return (
    <div>
      <div className="leading-normal tracking-normal grey-bg pb-20">
        <div className="container mx-auto pl-2 pr-2 pt-8 pb-8">
          <h2 className="text-xl sm:text-2xl w-full m-auto text-center">
            Enter New Email Address
          </h2>
          <div className="leading-normal tracking-normal">
            <div className="container form-width mx-auto m-8 sign-in">
              <form onSubmit={onSubmit}>
                <EmailAddressInput
                  id="change-email-address-input"
                  label="Email address"
                  onChange={(event) => setNewEmail(event.target.value)}
                  valueOverride={newEmail}
                />
                <EmailAddressInput
                  id="confirm-change-email-address-input"
                  label="Confirm email address"
                  onChange={(event) => setConfirmNewEmail(event.target.value)}
                  valueOverride={confirmNewEmail}
                />
                <SubmitInput />
              </form>
            </div>
          </div>
          {errorMessage && <p className="failure sm:w-1/2 w-full">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
